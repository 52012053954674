/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CSVFileValidator from 'csv-file-validator';
import more from 'material-ui/svg-icons/notification/more';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import redirectURL from '../redirectURL';
var moment = require('moment');

export default class Sourcevendorcomponent extends Component {
    state = {
        openModal: false,
        vendorOptions: [],
        email_options: [],
        selected_vendor: "",
        refetch: false,
        selected_emails: "",

        show: false,
        basicTitle: '',
        basicType: "default",
        loadshow: "show-n",
        overly: "show-n",
        rowData: [],
        pivotmodeis: false,
        pivotcols: [],
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true

        },
        frameworkComponents: {
        },
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        csvcontent: [],
        file: '',
        slider: 'show-n',
        source: '',
        source_list: [{ 'label': 'Hosur', 'value': 'Hosur' }, { 'label': 'Indore', 'value': 'Indore' }, { 'label': 'Alwar', 'value': 'Alwar' }, { 'label': 'Pantnagar', 'value': 'PNR' }]
    }
    componentDidMount() {
        this.loadData()
    }
    loadData = () => {
        this.setState({ loadshow: "show-m" });
        redirectURL.post("/consignments/gettransusers", {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
            }
        }).then(res => {
            let respData = res.data
            if (respData != '' && respData != undefined) {
                var data = []
                respData.map((e) => {
                    if (e.source != '' && e.source != undefined) {
                        data.push(e)
                    }
                })
                var groupedData = data.reduce((acc, curr) => {
                    let existing = acc.find(item => item.source === curr.source);
                    if (existing) {
                      existing.email += `, ${curr.email}`;
                      if(curr.nominated_for_bidding === 1) existing.notified_for_bidding_emails.push(curr.email)
                    } else {
                      acc.push({ 
                            source: curr.source, 
                            email: curr.email, 
                            notified_for_bidding_emails: curr.nominated_for_bidding === 1 ? [curr.email] : [] ,
                            source_name:curr.source_name
                        });
                    }
                    return acc;
                  }, []);

                //   console.log("test --", groupedData);

                let temp = [];

                if(groupedData && groupedData.length>0){
                    groupedData.forEach((ele)=>{
                        if(ele.source) temp.push({ value: ele.source, label: ele.source })
                    })
                }
                this.setState({
                    rowData: groupedData,
                    vendorOptions: temp,
                })
            }
        }).catch((err)=>{
            console.log("error - ", err);
        }).finally(()=>{
            this.setState({ loadshow: "show-n" });
        })
    }
    closeAlert = () => {
        $("#uploadFile").val("")
        this.setState({
            show: false,
            basicType: "",
            basicTitle: "",
            refetch: false,
        })
        if(this.state.refetch){
            this.onCloseModalHandler();
            this.loadData();
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    changeTptFileHandler = async (e) => {
        if (e.target.files.length !== 0) {

            const config = {
                headers: [
                    {
                        name: 'transporter_code',
                        inputName: 'transporter_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'transporter_name',
                        inputName: 'transporter_name',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },

                    {
                        name: 'transporter_email',
                        inputName: 'transporter_email',
                        required: false,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        },
                        width: '200px'

                    },

                ]
            }
            const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9 -.@/(),#_&]*$/); // define allowed characters using regex
            const validEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            const validateEmail = new RegExp(/^(^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/);
            const validMobile = new RegExp(/^[6789] \d{9}$/);
            const validText = new RegExp(/^[a-zA-Z .@-_&/()]*$/);

            var data = CSVFileValidator(e.target.files[0], config)
                .then((csvData) => {
                    console.log('csvData', csvData)
                    let keys = Object.keys(csvData.data[0])
                    let items = ['transporter_name', 'transporter_code', 'transporter_contact_no', 'transporter_email', 'transporter_secondary_contact_no', 'transporter_secondary_email']
                    let check = false
                    if (csvData.inValidMessages.length === 0) {
                        for (let i in keys) {
                            // console.log("items.includes(keys[i]) ", items.includes(keys[i]))
                            if (items.includes(keys[i])) {
                                check = true
                            } else {
                                check = false
                                break
                            }
                        }
                    }
                    else {
                        check = false

                    }
                    var dateemailMes = 1;
                    var mobMes = 1;
                    var textMes = 1;
                    let csvaluesarr = Object.values(csvData.data)
                    // console.log("csvalues", csvaluesarr)
                    if (csvaluesarr.length > 0) {
                        for (var a = 0; a < csvaluesarr.length; a++) {
                            var csvalues = Object.values(csvaluesarr[a])
                            for (var c in csvalues) {
                                console.log("c", c)

                                console.log("csvalues value ", c + "=====" + aplhanumeichypen.test(csvalues[c]))
                                if (aplhanumeichypen.test(csvalues[c]) === false) {
                                    check = false;
                                    break;
                                }

                            }
                        }
                    }
                    // console.log("check", check)
                    if (check === false) {
                        $("#uploadFile").val("")

                        this.setState({
                            show: true,
                            basicTitle: 'dont upload other fields or special characters',
                            basicType: 'danger'
                        });

                    } else {
                        this.setState({
                            csvcontent: csvData.data
                        });
                    }
                })
                .catch(err => { })
            // console.log("e.target.files[0]",e.target.result);
            var contentList = []

            var out = new Promise(function (reject, resolve) {
                var reader = new FileReader();
                reader.onload = async function (e) {
                    var contents = await e.target.result;
                    // document.getElementById("appednCSVData").innerHTML = contents
                    resolve(contents);
                };
                var tt = reader.readAsText(e.target.files[0]);
            });
            // console.log(contentList);
            this.setState({
                file: e.target.files[0]
            });
            // console.log("e.target.files[0].type ", e.target.files[0]);

            if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))

            }
            else {
                e.target.value = null;
                this.setState({
                    uploadFile: '',
                    file: "",
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }
    }
    handleSelectChangeforsource = (name, selectedOption) => {

    }
    uploadBulkFormHandler = e => {
        e.preventDefault()
        const { csvcontent } = this.state
        // console.log("csvcontent", csvcontent)
        if (csvcontent.length > 0) {
            redirectURL.post("/masters/uploadTransportersMasterData", csvcontent, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                }
            }).then(res => {
                // console.log("response", res)
                // console.log("response", res.data.message)
                if (res.data.message === 'success') {
                    this.setState({
                        slider: "show-n",
                        csvcontent: [],
                        file: '',
                        overly: "show-n",
                        loadshow: 'show-n',
                        basicTitle: "Success.",
                        basicType: "success",
                        show: true
                    })
                    $("#uploadFile").val("")
                    // document.getElementById("appednCSVData").innerHTML = ''
                }
                setTimeout(() => {
                    this.loadData()
                }, 1000)
            })
        }
        else {
            this.setState({
                overly: "show-n",
                loadshow: 'show-n',
                basicTitle: "cant insert empty data",
                basicType: "warning",
                show: true
            })
        }

    }

    bulkUploadTptMaster = () => {
        this.setState({
            slider: 'slider-translate-40p',
            overly: "show-m",
            loadshow: 'show-n'
        })
    }
    resetUpload = () => {
        $("#uploadFile").val("")
        // document.getElementById("appednCSVData").innerHTML = ''
        // this.setState({
        //     slider: 'show-n',
        //     overly: "show-n",
        //     loadshow: 'show-n'
        // })
    }
    onCloseSliderDiv = () => {
        $("#uploadFile").val("")
        // document.getElementById("appednCSVData").innerHTML = ''
        this.setState({
            slider: 'show-n',
            overly: "show-n",
            loadshow: 'show-n'
        })
    }

    onClickHideAll = () => {
        $("#uploadFile").val("")
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            slider: "",
        });
    }

    onCellClicked = (e) => {
        if (e.colDef.field == "delete") {
            var rowId = e.data._id;
            // console.log("delete",e.data);
            var qry = {
                rowId: rowId,
                collection: 'master_transporter_code',
                email: localStorage.getItem('email')
            }
            //var confirm = confirm("Are You Sure! Do you want really delete?");
            if (window.confirm("Are You Sure! Do you want really delete?")) {
                redirectURL.post("/masters/deleteRowData", qry, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                    }
                }).then((response) => {
                    // console.log("response",response);
                    if (response.data.status == "success") {
                        this.setState({
                            basicTitle: "Deleted Successfully.",
                            basicType: "success",
                            show: true
                        })
                        //window.location.reload();
                        this.loadData()
                    }
                    else {
                        this.setState({
                            basicTitle: "Failed to Delete.",
                            basicType: "warning",
                            show: true
                        })
                    }
                }).catch((e) => {
                    console.log(e);
                })
            }
        }
    }

    onFormSubmitHandler = (e)=>{
        e.preventDefault();
        
        if(!(this.state.selected_vendor && this.state.selected_vendor.value)) return
        
        let add_emails = []

        // if(this.state.selected_emails && this.state.selected_emails.length>0){
        //     add_emails = this.state.selected_emails.map(ele=>ele.value)
        // }
        if(this.state.selected_emails && this.state.selected_emails.value){
            add_emails.push(this.state.selected_emails.value);
        }
        let vendor = this.state.selected_vendor.value

        let temp1 = this.state.rowData.find((ele) => ele.source === vendor);

        let remove_emails = [];
        if(temp1.notified_for_bidding_emails){
            let temp2_emails = temp1.notified_for_bidding_emails;
            remove_emails = temp2_emails.filter((ele)=> {console.log(ele); return !add_emails.includes(ele)});
        }

        let payload = {
            vendor: vendor,
            add_emails: add_emails || [],
            remove_emails: remove_emails || [],
        }

        // console.log("test --payload", payload );
        this.setState({ loadshow: "show-m" });
        redirectURL.post("/consignments/nomiateVendorEmailsForBidding", payload)
        .then(res => {
            if(res.status === 200 && res.data.status === "success"){
                this.setState(({ show: true, basicType: "success", basicTitle: "Updated successfully", loadshow: "show-n", refetch: true}))
            }else{
                this.setState({ loadshow: "show-n" });
            }
        }).catch((err)=>{
            console.log("error --", err);
            this.setState({ loadshow: "show-n" });
        })

    }

    convertToOptions = (arr)=>{
        if(arr && arr.length>0){
            return arr.map((ele)=>({ value: ele, label: ele }))
        }
        return [];
    }
    onChangeSelectVendor = (e)=>{
        this.setState({ selected_vendor: e });
        let row_data = this.state.rowData;

        if(row_data && row_data.length>0){
            let temp1 = row_data.find((ele) => ele.source === e.value);
            let email_options = [];
            if(temp1.email){
                let temp2_emails = temp1.email.split(", ");
                email_options = this.convertToOptions(temp2_emails);
            }
            let temp_seleted_emails = this.convertToOptions(temp1.notified_for_bidding_emails);
            temp_seleted_emails = temp_seleted_emails && temp_seleted_emails.length>0 ? temp_seleted_emails[0] : temp_seleted_emails;
             this.setState({ selected_emails: temp_seleted_emails, email_options: email_options })
        }
    }

    onChangeSelectEmails = (e)=>{
        this.setState({selected_emails: e});
    }

    onCloseModalHandler = ()=>{
        this.setState({ openModal: false, selected_emails: [], email_options: [], selected_vendor: "" })
    }

    render() {
        const { show, basicType, basicTitle, rowData, modules, defaultColDef, statusBar, slider, loadshow, overly, csvcontent } = this.state
        const transportersColumnDefs = [

            {
                headerName: "Transporter Name",
                field: "source",
                width: 150,
                filter: "agSetColumnFilter",
                resizable: true,
            },

            {
                headerName: "Transporter Email",
                field: "email",
                width: 900,
                filter: "agSetColumnFilter",
                resizable: true,

                // valueGetter: param => {
                //     if (!["", NaN, undefined, null].includes(param.data.state)) return param.data.state
                // }
            },
            {
                headerName: "Nominated for Bidding",
                field: "nominated_for_bidding",
                width: 500,
                filter: "agSetColumnFilter",
                resizable: true,
                valueGetter: param => {
                    if(param && param.data && param.data.notified_for_bidding_emails && param.data.notified_for_bidding_emails.length>0){
                        return param.data.notified_for_bidding_emails.toString();
                    }
                    else{
                        return "NA"
                    }
                }
            },
            {
                headerName: "Source",
                field: "source_name",
                width: 200,
                filter: "agSetColumnFilter",
                resizable: true,

                // valueGetter: param => {
                //     if (!["", NaN, undefined, null].includes(param.data.state)) return param.data.state
                // }
            },


            // {
            //     pinned: "left",
            //     headerName: "",
            //     field: "delete",
            //     width: 50,
            //     resizable: true,
            //     editable: false,
            //     cellRenderer: params => {
            //         return '<i class="icofont icofont-ui-delete"></i>';
            //     },
            //     filter: false,
            // }
        ]

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className='row'>
                    <div className="card col-xl-12 col-lg-12" style={{height: "80vh"}}>
                        <div className="card-header" style={{ padding: '10px', borderBottom:"0px" }}>
                            <h5>Vendor Master</h5>
                            <div className="row">
                                <div className="col-sm-12" style={{}}>
                                    {/* <button className="float-right btn btn-warning" style={{borderRadius: "5px"}} onClick={this.bulkUploadTptMaster}>
                                        Add Vendor
                                    </button> */}
                                    <button className="float-right btn btn-success mr-2" style={{borderRadius: "5px"}} onClick={ ()=>{ this.setState({openModal: true})} }>
                                        Nominate for Bidding
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="height-equal equal-height-lg" style={{ padding: '10px', height: "100%" }}>
                            <div className="card-body" style={{ padding: '10px', height: "100%" }}>
                                <div id="myGrid" style={{ height: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={modules}
                                        columnDefs={transportersColumnDefs}
                                        defaultColDef={defaultColDef}
                                        rowData={rowData}
                                        enableCharts={false}
                                        enableRangeSelection={true}
                                        statusBar={statusBar}
                                        pagination={true}
                                        groupHeaderHeight={40}
                                        headerHeight={40}
                                        floatingFiltersHeight={50}
                                        pivotGroupHeaderHeight={30}
                                        pivotHeaderHeight={50}
                                        rowHeight={40}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        onCellClicked={this.onCellClicked}
                                    //sideBar={sideBar}
                                    // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                    />
                                </div>

                            </div>

                        </div>
                        <div className={"dataLoadpage " + (this.state.loadshow)}>
                        </div>
                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>


                    </div>
                    {/* Nominate email for biding  */}
                    <Modal 
                        open={this.state.openModal} 
                        onClose={this.onCloseModalHandler}
                        styles={{
                            modal: {
                              maxWidth: "50%",
                            },
                          }}
                        classNames={{ modal: "cust-modal"}}
                    >
                        <h5 style={{fontWeight:"600"}}>Nominate Email for Bidding</h5>
                        <form onSubmit={this.onFormSubmitHandler}>
                            <div className='d-flex flex-column '>
                                <div>
                                    <div className='mt-20p'>
                                        <label className="">Select Vendor<span className='err-txt'>*</span></label>
                                        <Select
                                            value={this.state.selected_vendor} 
                                            closeMenuOnSelect={true}
                                            onChange={this.onChangeSelectVendor}
                                            options={this.state.vendorOptions}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    minHeight: '45px', // Add your desired min-height here
                                                }),
                                            }}
                                            required
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className='mt-30p'>
                                        <label className="">Select Email<span className='err-txt'>*</span></label>
                                        <Select
                                            value={this.state.selected_emails}
                                            closeMenuOnSelect={true}
                                            // isMulti={true}
                                            onChange={this.onChangeSelectEmails}
                                            options={this.state.email_options}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    minHeight: '45px', // Add your desired min-height here
                                                }),
                                            }}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group d-flex alitems-center justify-content-end mt-30p mb-20p" style={{gap: "15px"}}>
                                    <button type="button" className="btn btn-default" onClick={this.onCloseModalHandler}>Cancel</button>
                                    <button type="submit" className="btn btn-success" disabled={this.state.selected_vendor && this.state.selected_vendor.value ?  false :true}>Submit</button>
                                </div>

                            </div>
                        </form>
                    </Modal>
                    {/* tpt bulk upload  */}
                    <div className={"slide-r " + (slider)} style={{ overflow: "hidden" }}>
                        <h3 className="subH">Add Vendor
                            <span
                                className="float-right closebtn"
                                style={{ marginRight: "25px" }}
                                onClick={this.onCloseSliderDiv}
                            >
                                X
                            </span>
                        </h3>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="container-fluid">
                                <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                                    <div className="form-group mt-20p">
                                        <label className="">Source</label>
                                        <Select
                                            value={this.state.source_list.filter(e => e.value == this.state.source)}
                                            closeMenuOnSelect={true}
                                            onChange={this.handleSelectChangeforsource}
                                            options={this.state.source_list}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    minHeight: '45px', // Add your desired min-height here
                                                }),
                                            }}
                                            required
                                        />
                                    </div>
                                    <div className="form-group mt-20p">
                                        <label className="">Vendor Email ID</label>
                                        <input type="text" id="email" name="email" onChange={this.changeTptFileHandler} className="form-control" required />


                                    </div>
                                    <div className="form-group mt-20p">
                                        <label className="">Vendor Name</label>
                                        <input type="text" id="vendor_name" name="vendor_name" onChange={this.changeTptFileHandler} className="form-control" required />


                                    </div>
                                    {/* <div className='p-2' id="appednCSVData"></div> */}
                                    <div className="form-group col-sm-12 mb-20p">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" className="btn btn-default" onClick={this.resetUpload}>Cancel</button>
                                    </div>
                                </form>
                               
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}