/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
import React, { Component } from "react";
import ReactDom, { render } from 'react-dom';
import axios from 'axios';
import { Redirect } from 'react-router';
import LoginForm from './Loginform';
import LmLoginForm from './LmLoginform';

//import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

var images = require.context('../../assets/images/', true);

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            style: {},
            email: '',
            password: '',
            loginMessage: '',
            is_enmovil_transporter: 0,
            is_load_management: 0,
        };
    }

    componentDidMount() {
        setTimeout(function () {
            this.setState({ style: { display: 'none' } });
        }.bind(this), 1000)
        var urlPath = this.props.match.path;
        // console.log(urlPath,"urlPath")
        // console.log("location.hostname ", window.location.hostname)
        if (urlPath == "/tptlogin") {
            this.setState({
                is_enmovil_transporter: 1
            });
        }
        else if (urlPath == "/optirun") {
            this.setState({
                is_load_management: 1
            });
        }
        else if (urlPath == "/loadmanagement/login") {
            this.setState({
                is_load_management: 1
            });
        }
    }


    render() {
        let style = this.state.style;
        if (window.location.hostname == "3.108.113.108") {
            var background = require('../../assets/images/login-image.jpg');
        }
        else {
            if (this.state.is_enmovil_transporter == 1 || window.location.hostname != "autometrics.in" || this.state.is_load_management == 1) {
                // var background = require('../../assets/images/ashokleyland_bg_img.png');
                var background = require('../../assets/images/logo_image_5.png');
            }
            else {
                var background = require('../../assets/images/login-image.jpg');
            }
        }

        // console.log("window.location.hostname-",window.location.hostname)
        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">
                    <div className="container-fluid">
                        {/*login page start*/}
                        <div className="authentication-main" >
                            <div className="row">                                
                                <div className="col-md-7 p-0">
                                    <div className="auth-innerleft" style={{ backgroundImage: "url(" + background + ")", position: 'fixed', top: '0', left: '0', width: "100%", zIndex: '1', backgroundSize: 'cover' }}>
                                    {/* <div className="" style={{position:'absolute' , top : '55px' , left:"190px"}}>
                                            <img src={require('../../assets/icons/track_and_trace_image.png')}
                                                    className="logo-login" style={{width:'40%'}} alt=""/>
                                        </div>                                        <div className="" style={{position:'absolute' , bottom : '20px'}}>
                                            <img src={require('../../assets/icons/VALUEPARTS.png')}
                                                    className="logo-login" style={{width:'20%'}} alt=""/>
                                        </div>
                                        <div className="" style={{position:'absolute' , bottom : '30px', right:'-800px'}}>
                                            <img src={require('../../assets/icons/LEYPARTS.png')}
                                                    className="logo-login" style={{width:'20%'}} alt=""/>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-md-5 p-0">
                                    <div className="auth-innerright" style={{paddingTop: "100px"}}>
                                        <div className="authentication-box float-left">
                                            <div className="card mt-6 p-4 mb-0" style={{width : "370px" , height : "370px"}}>
                                                <img src={require("../../assets/images/autometrics-logo.png")} alt="login-logo" style={{ width: 220, "margin": "auto" }} />
                                                <LoginForm is_enmovil_transporter={this.state.is_enmovil_transporter} is_load_management={this.state.is_load_management} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*login page end*/}
                    </div>
                </div>
                {/*page-wrapper Ends*/}

            </div>

        )
    }
}


export default Login;
