/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
 
const ViewDocumentsList = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickViewDocumentsList(props);
    };
// console.log("props ", props.data)
    return (
        // <div>
        //     <button type="button"
        //       className={"btn btn-info nopadding font12"} 
        //       title="View Items" 
        //       onClick={handleClick}>
        //           <i className="icofont icofont-eye f16"></i>&nbsp; View Items
        //     </button>          
        // </div>
         <div>
         <button onClick={handleClick} className="btn btn-info" style={{padding: "2px 10px",fontSize: "13px",margin: "0"}} title="View Items" >View Documents </button>
     </div>
    );
};

export default ViewDocumentsList;
