/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { Component  } from 'react';
import redirectURL from "../redirectURL";
 
const UpdateTransporterGSTAction = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        console.log("EE ",props.data )
        // props.gridOptions.context.onClickUpdateTransporterGSTData(props);
        updateRowData(props.data);
        
        // this.props.agGridReact.props.gridOptions.context.componentParent.onClickUpdateTransporterGSTData(props.data);
    };
    const updateRowData = (rowdata) => {
        console.log("rowdata ", rowdata)
        var params = {
            rowdata:JSON.stringify([rowdata])
        }
        console.log("params ", params)
        redirectURL.post("saveTransporterGST",params)
        .then((response) => {
            if(response.data.status === "success")
            {

            }
        })
    }
// console.log("props ", props.data)
    return (
        <div>
            <button type="button"
              className={"btn btn-danger nopadding font12"} 
              title="Update GST" 
              onClick={handleClick}>
                  <i className="icofont icofont-eye f16"></i>&nbsp; Update
            </button>
          
        </div>
    );
};

export default UpdateTransporterGSTAction;
