/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
 
const UpdateItem = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickUpdateItem(props);
    };
// console.log("props ", props.data)
    return (
        <div>
            <button type="button"
              className={"btn btn-info nopadding font12"} 
              title="Update" 
              onClick={handleClick}>
                  <i className="icofont icofont-eye f16"></i>&nbsp; Update
            </button>
          
        </div>
    );
};

export default UpdateItem;
