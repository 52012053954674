/* eslint-disable use-isnan */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Modal from 'react-responsive-modal';
import _, { difference } from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import CSVFileValidator from 'csv-file-validator';
import Deliverconsignment from './deliverconsignment';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Consignmentforceclose from './consignmentforceclose.js';
import MarkDeliver from "./markDeliveredButton.js";
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import CourierActions from './courierActions';
import InvalidDocket from './invalidDocket';
import UpdateTransitDay from "./updateTransitDay";
import 'react-vertical-timeline-component/style.min.css';
import TruckSidebarComponent from './truckSidebarComponent';
import * as Datetime from 'react-datetime';
import CourierCounter from './couriercounter';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDDHHMMSS, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getYYYYMMDD } from '../common/utils';
import $ from 'jquery';
//import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
//import datetimepicker from 'jquery-datetimepicker';
import Select from 'react-select';
import AddManualOrders from "./addManualOrders";
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');

var moment = require('moment');
// $.datetimepicker.setLocale('en');
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var geoFencingArea;

export default class ManageCourierConsignments extends Component {

	constructor(props) {
		super(props);
		this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			sliderInvalidDocket: '',
			sliderTransitNa:"",
			tabsliderTranslate: '',
			timelineinfo: '',
			consignment_code: '',
			mapinfo: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			markDeliverDefault: "",
			deliveredOtherReason: "",
			basicTitle: '',
			basicType: "default",
			showPriority: false,
			basicPriorityTitle: '',
			basicPriorityType: "default",
			selectedRow: "",
			modules: AllModules,
			filterstatess: {},
			columnsdatas: {},
			rowgroupdcols: [],
			valuecolumns: [],
			pivotmodeis: false,
			pivotcols: [],
			defTransitCoords: '',
			overly : 'show-n',
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true
			},
			rowData: null,

			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			frameworkComponents: {
				courierActions: CourierActions,
				consignmentforceclose: Consignmentforceclose,
				MarkDeliver: MarkDeliver,
				customLoadingCellRenderer: CustomLoadingCellRenderer,
				deliverconsignment: Deliverconsignment,
				InvalidDocket:InvalidDocket,
				UpdateTransitDay:UpdateTransitDay
			},
			// components: { datePicker: getDatePicker() },
			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,
			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				// "highlitRow": "data.transit_delay == 1"
			},
			intransit: [],
			outdeliver: [],
			deliver: [],
			datana: [],
			intransitcount: [],
			outdelivercount: [],
			delivercount: [],
			undeliver: [],
			undeliverCount:0,
			datanacount: 0,
			prioritydata: [],
			prioritydatacount: 0,
			totalcount: 0,
			from_date: "2023-07-01",
			to_date: "",
			consigner: [{ "value": "all", "label": "All" }],
			flTransporter: "",
			selectedFilter: [],
			consigners: [],
			originaldata: [],
			counttype: 'active',
			forceCloseRowNode: "",
			forceclosedata: "",
			sliderForceCloseTranslate: "",
			overly: "show-n",
			markDeliverRowNode: "",
			markDeliverData: "",
			sliderMarkDeliverTranslate: "",
			bulkPrioritySlide: "",
			bulkForceCloseSlide: "",
			bulkMarkDeliverSlide: "",
			file: "",
			inputMarkDeliverFile: "",
			csvcontent: "",
			mdCsvContent: "",
			deliveryStatus: "",
			deliverStatusValue: "",
			distance_from_dealer_location: "",
			deliveredDeptCode: "",
			deliveredTruckNo: "",
			delivermodal: false,
			hideTransporterBtns: "show-m",
			confirmDelivery: "",
			displayOtherField: "show-n",
			selectedDeliveryReason: "",
			usergridstate: [],
			screenurl: "",
			region_code:"",
			plant_code:"",
			transit_days:"",
			screentitle: "",
			critical_data: [],
			critical_counter_data: [],
			filterTransporters: [],
			cancelled_data: [],
			pod_received_data: [],
			trip_closed_by_pod: [],
			delDate: "none",
			on_time_data: [],
			delayed_data: [],
			booked_data: [],
			force_closed: [],
			plants: [],
			shipment_file: "",
			shipment_csvcontent: [],
			shipment_slider: "",
			manual_upload_slider: 'show-n',
			gcWayBillList: [],
			gc_way_bill_No_sel: [{ value: '', label: '' }],
			manual_csvcontent: '',
			manual_file: '',
			customers:[],
			produceRowData:[],
			invalidRowItem:[],
			transitRowNa:[],
			invalid_gc_waybill:"",
			transit_days:'',
			transitna_data:[],
			csvcontent2:[],
			transit_data_for_update:"",
			show_delivered_date : false,
			delivered_date_for_update:'',
			epodDoc : '',
			row_data : '',
			sac_codes:[],
		}
		this.uploadShipmentXLSHandler = this.uploadShipmentXLSHandler.bind(this);
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	async componentDidMount() {
		// loadDateTimeScript();
		// $("#from_date,#to_date").on("click",function(){
		// 	$(".xdsoft_timepicker").css("display","none");
		// });
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({ eventAction: eventAction });

		if(![undefined , null , "undefined" , ""].includes(localStorage.getItem("sac_code"))){
			
			var sac_codes = localStorage.getItem("sac_code").split(",")
			var data = sac_codes.map(e => {
				var a = e.trim()
				return a
			})
			console.log(data, "sac_codes")
			await this.setState({
				sac_codes:data
			})

		}

		redirectURL.post("/masters/getCustomersMaster",{},{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem("userid")
			  }
		  })
		.then(async (resp) => {
			await this.setState({
				customers: resp.data.plantMasters
			});
		})
		redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname },{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem("userid")
			  }
		  })
		.then(async (resp) => {
				await this.setState({
					usergridstate: resp.data
				});
				await this.restoreGridStates();
			})
		//console.log("Props ", this.props.match)
		// if (this.props.match.path == "/activecourierconsignments") {
		// 	var fdate = moment.parseZone().subtract(30, 'days').format('YYYY-MM-DD');
		// }
		// else {
		// 	// var fdate = moment.parseZone().subtract(30, 'days').format('YYYY-MM-DD');
		// 	var fdate = '2022-05-01'

		// }
		redirectURL.post("/consignments/plants", { dept_code: '' },{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem("userid")
			  }
		  })
		.then((resp) => {
				// console.log("plant_resp", resp)
				this.setState({
					plants: resp.data
				});
			})
		redirectURL.post("/consignments/getConsignmentsNewFilterKeys",{},{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem("userid")
			  }
		    })
			.then((resp) => {
				    // console.log("responseforDropDown", resp)
				var transporters = resp.data.transportersList;
				//  console.log(transporters,"308")
				var data = resp.data.data
				let tlist = [];
				let tlistarr = [];
				try{
					
					transporters.map((t) => {
						tlistarr.push({
							"value": t.transporter_code,
							"label": t.transporter_name+" - "+t.transporter_code
						});
					});
					var cities = data.cities;
					var filterCities = [];
					if (cities.length > 0) {
						filterCities.push({
							label: "All",
							value: "",
						});
						cities.map(function (c) {
							filterCities.push({
								label: c,
								value: c,
							});
						});
					}
					var states = data.states;
					var filterStates = [];
					if (states.length > 0) {
						filterStates.push({
							label: "All",
							value: "",
						});
						states.map(function (s) {
							filterStates.push({
								label: s,
								value: s,
							});
						});
					}
					// var zones = data.zones;
					// var filterZones = [];
					// if (zones.length > 0) {
					// 	filterZones.push({
					// 		label: "All",
					// 		value: "",
					// 	});
					// 	zones.map(function (cl) {
					// 		filterZones.push({
					// 			label: cl,
					// 			value: cl,
					// 		});
					// 	});
					// }
					// console.log("transporters",transporters)
					// var transporters = data.transporters;
					// console.log(transporters,"365")
					 var filterTransporters = [];
					if (transporters.length > 0) {
						filterTransporters.push({
							label: "All",
							value: ""
						});
						transporters.map((t) => {
							tlistarr.push({
							"value": t.transporter_code,
							"label": t.transporter_name+" - " +t.transporter_code,
						});
						});
					}
					var tptCodes = data.tptCodes;
					var filterTptCodes = [];
					if (tptCodes.length > 0) {
						filterTptCodes.push({
							label: "All",
							value: ""
						});
						tptCodes.map(t => {
							filterTptCodes.push({
								label: t,
								value: t
							});
						});
					}
					//  console.log(tlist,"392")

					 let newArray = [];               
						// Declare an empty object 
						let totuniqueObject = {}; 
						var totobjTitle;
						// Loop for the array elements 
						for (let i in tlistarr) { 

							// Extract the title 
							totobjTitle = tlistarr[i]['value']; 

							// Use the title as the index 
							totuniqueObject[totobjTitle] = tlistarr[i]; 
						} 

						// Loop to push unique object into array 
						for (var a in totuniqueObject) { 
							newArray.push(totuniqueObject[a]); 
						}
						tlist.push({value:"",label:"All"})
					if(newArray.length > 0)
					{
						newArray.map((item) => {
							tlist.push(item)
						})
					}
					this.setState({
						filterCities: filterCities,
						filterStates: filterStates,
						filterTransporters: tlist,
						filterTptCodes: filterTptCodes
					});
				}catch(e){
					this.setState({
						filterCities: [],
						filterStates: [],
						filterTransporters: [],
						filterTptCodes: []
					});
				}				
			})

		var fdate = moment.parseZone().subtract(7 , 'days').format("YYYY-MM-DD")  //by default we are giving data from july 1
		var todayDate = moment.parseZone().format('YYYY-MM-DD');
		// Hard coded to-date to show lesser records and load faster. Need to remove in PROD
		var tdate = '2023-06-01';
		var fdt = fdate.split("-");
		var fromdate = fdt[0] + "-" +fdt[1]  + "-" + fdt[2]
		var tdt = todayDate.split("-");
		var todate = tdt[0] + "-" + tdt[1] + "-" + tdt[2];
		// document.getElementById("from_date").value=fdate;
		// document.getElementById("to_date").value=tdate;

		var urlpath;
		var dpt;
		var counttype;
		var serviceProviders = [];
		if (localStorage.getItem("user_type") == "TRANSPORTER") {
			this.setState({
				hideTransporterBtns: "show-n"
			});
			serviceProviders = JSON.parse(localStorage.getItem('supplier_code'))
		}


		if (this.props.match.path == "/courierconsignments") {
			urlpath = '/consignments/couriers';
			counttype = 'all';

			var preqparams = {
				from_date: moment.parseZone().subtract(7 , 'days').format("YYYY-MM-DD"),
				to_date: moment.parseZone().format('YYYY-MM-DD'),
				consigner_code: [{ "value": 'all', "label": "All" }],
				serviceProviders: serviceProviders,
				transporter:serviceProviders,
				is_filter_call: 0
			}
			if(![undefined , null , "undefined" , ""].includes(this.state.sac_codes)){
				if(this.state.sac_codes.length > 0){
					preqparams.sac_code = this.state.sac_codes
				}
			}
			this.setState({
				counttype: counttype,
				loadshow: 'show-m',
				from_date: fdate,
				to_date: todayDate,

			});
			this.onLoadShowCouriers(preqparams);
			// redirectURL.post("/consignments/getPRTCourierCriticalData",{dept_code:"LOG-PRT (Courier)"})
			// .then(async (res) =>{
			// 	// console.log("res.data.message[0] ",res.data)
			// 	var criticaldata=res.data.critical_data;
			// 	this.setState({
			// 		pageTitle : "Courier Consignments (All)",
			// 		critical_data : criticaldata
			// 	});
			// 	// this.onLoadShowCouriers(preqparams);
			// }).catch((e)=>{
			// 	console.log(e);
			// });

		}
		else if (this.props.match.path == "/activecourierconsignments") {
			urlpath = '/consignments/activecouriers';
			counttype = 'active';
			//  console.log("transporter type", typeof (transporter.value))
			var preqparams = {
				from_date: moment.parseZone().subtract(7 , 'days').format("YYYY-MM-DD"),
				to_date: moment.parseZone().format('YYYY-MM-DD'),
				consigner_code: [{ "value": 'all', "label": "All" }],
				serviceProviders: serviceProviders,
				transporter:serviceProviders,
				is_filter_call: 0
			}
			if(![undefined , null , "undefined" , ""].includes(this.state.sac_codes)){
				if(this.state.sac_codes.length > 0){
					preqparams.sac_code = this.state.sac_codes
				}
			}
			this.setState({
				counttype: counttype,
				loadshow: 'show-m',
				from_date: fdate,
				to_date: todayDate,
				pageTitle: "Courier Consignments (Active)",
			});
			this.onLoadShowCouriers(preqparams);
			// redirectURL.post("/consignments/getPRTCourierCriticalData",{dept_code:"LOG-PRT (Courier)"})
			// .then(async (res) =>{
			// 	// console.log("res.data.message[0] ",res.data)
			// 	var criticaldata=res.data.critical_data;


			// }).catch((e)=>{
			// 	console.log(e);
			// });

		}
		else if (this.props.match.path == "/deliveredcourierconsignments") {
			urlpath = '/consignments/deliveredcouriers';
			counttype = 'deliver';
			this.setState({
				pageTitle: "Courier Consignments (Delivered)"
			})
			var preqparams = {
				from_date: moment.parseZone().subtract(7 , 'days').format("YYYY-MM-DD"),
				to_date: moment.parseZone().format('YYYY-MM-DD'),
				consigner_code: [{ "value": 'all', "label": "All" }],
				serviceProviders: serviceProviders,
				transporter:serviceProviders,
				is_filter_call: 0
			}
			if(![undefined , null , "undefined" , ""].includes(this.state.sac_codes)){
				if(this.state.sac_codes.length > 0){
					preqparams.sac_code = this.state.sac_codes
				}			}
			this.setState({
				counttype: counttype,
				loadshow: 'show-m',
				from_date: fdate,
				to_date: todayDate,

			});
			this.onLoadShowCouriers(preqparams);
		}
		else if (this.props.match.path == "/invalidcourierconsignments") {
			urlpath = '/consignments/getInvalidCourierDataByParams';
			counttype = 'invalid';
			this.setState({
				pageTitle: "Courier Consignments (Invalid)"
			})
			var preqparams = {
				from_date: moment.parseZone().subtract(1 , 'month').format("YYYY-MM-DD"),
				to_date: moment.parseZone().format('YYYY-MM-DD'),
				consigner_code: [{ "value": 'all', "label": "All" }],
				serviceProviders: serviceProviders,
				transporter:serviceProviders,
				is_filter_call: 0
			}
			if(![undefined , null , "undefined" , ""].includes(this.state.sac_codes)){
				if(this.state.sac_codes.length > 0){
					preqparams.sac_code = this.state.sac_codes
				}			}
			this.setState({
				counttype: counttype,
				loadshow: 'show-m',
				from_date: fdate,
				to_date: todayDate,

			});
			this.onLoadShowCouriers(preqparams);
		}

		// preqparams['is_filter_call'] = 0;

	};

	onLoadShowCouriers = (parameters) => {
		var urlpath;


		if (this.props.match.path == "/courierconsignments") {
			urlpath = '/consignments/couriers';
			var pageTitle = "Courier Consignments (All)"
			var counttype = 'all'
		}
		else if (this.props.match.path == "/activecourierconsignments") {
			urlpath = '/consignments/activecouriers';
			pageTitle = "Courier Consignments (Active)"
			var counttype = 'active'
		}
		else if (this.props.match.path == "/deliveredcourierconsignments") {
			urlpath = '/consignments/deliveredcouriers';
			pageTitle = "Courier Consignments (Delivered)"
			var counttype = 'deliver'
		}
		else if (this.props.match.path == "/invalidcourierconsignments") {
			urlpath = '/consignments/getInvalidCourierDataByParams';
		}


		redirectURL.post(urlpath, parameters,{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem("userid")
			}
		})
		.then((response) => {
				var records = response.data.sort(GetSortDescOrder("gc_date"));
				// var transporters = []
				// console.log("records", records);
				var intransitarr = [];
				var priorityarr = [];
				var outdeliverarr = [];
				var deliverarr = [];
				var undeliverarr = [];
				var forcearr = [];
				var datanaarr = [];
				var confirmDelivery = [];
				var transitna_data = [];
				var today = new Date();
				var dd = String(today.getDate()).padStart(2, '0');
				var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy = today.getFullYear();
				today = yyyy + '-' + mm + '-' + dd;
				let gcWayBillList = []
				var recordsarr = [];
				var cancelled_data = []
				records.map((item) => {
					// if((["05","07","22",5,7,22,48,"48","47",47,"01", "02", "03", "04", "10", "11", "12", "14", "15", "17", "18", "21", "24", "25",
					// "29", "30", "31", "32", "45", "46"]).includes(item.shipping_type))
					// {
						gcWayBillList.push({ 'label': item.gc_waybill_no, 'value': item.gc_waybill_no })
						// console.log("Consign Items", item)
						if (item.type_of_delivery == "ODA") {
							item.oda_time = 1
						} else {
							item.oda_time = 0
						}
						item.cutOffDays = 1;
						if (item.revised_eta == "" || item.revised_eta == undefined) {
							// item.elapsed_transit_time = differenceInDays(today, item.gc_date);
							item.elapsed_transit_time = differenceInDays(today, item.gc_date);
							item.ontime_delayed_status = "Delayed";
						} else {
							item.elapsed_transit_time = differenceInDays(item.revised_eta, item.gc_date)

							if (moment.parseZone(item.revised_eta).startOf('day') - moment.parseZone(item.expected_eta).startOf('day') <= 0) {
								item.ontime_delayed_status = "On Time";
								 
							}
							else {
								item.ontime_delayed_status = "Delayed";
							}
						}

						item.expected_eta = moment.parseZone(item.gc_date).add(item.transit_time_days, 'days');

						if (item.consignment_status == "" || item.consignment_status == undefined) {
							item.consignment_status = "In Transit"
						}
						//  console.log("plants", this.state.plants)
						try{
							var idx = this.state.plants.findIndex(p => p.plant_code == item.consigner_code);
							// console.log("idx", idx)
							if( this.state.plants[idx]["plant_name"] !== undefined)
							{
								item.plant_name = this.state.plants[idx]["plant_name"]
							}
							
						}catch(e){
							console.log("error",e)
						}
						// transporters.push(item.transporter_name)
						//In Transit
						if (['PARTIALLY DELIVERED', "Partially Delivered", 'In Transit', 'IN TRANSIT', 'TRANSIT', 'DESTINATION ARRIVED', 'UNDELIVERED'].includes(item.consignment_status)) {
							intransitarr.push(item);
						}

						//Out of Delivery
						if (item.consignment_status == 'Out For Delivery' || item.consignment_status == 'OUT FOR DELIVERY') {
							outdeliverarr.push(item);
						}

						if (item.consignment_status == 'CANCELLED' || item.consignment_status == 'Cancelled') {
							cancelled_data.push(item);
						}

						// //Delivered
						// if (['DELIVERED', "Delivered", "Cancelled", "CANCELLED",].includes(item.consignment_status)) {
						// 	deliverarr.push(item);
						// }
						if (item.consignment_status == 'Delivered' || item.consignment_status == 'Delivered') {
							deliverarr.push(item);
						}
						if(item.consignment_status == 'UnDelivered' || item.consignment_status == 'UnDelivered'){
							undeliverarr.push(item)
						}

						if (item.consignment_status == 'Force Closed' || item.consignment_status == 'FORCE CLOSED') {
							forcearr.push(item);
						}
						//Data Not Available
						if (item.consignment_status == '' || item.consignment_status == null || item.consignment_status == "Invalid Docket"  || item.consignment_status ===  "Invalid docket") {
							datanaarr.push(item);
						}
						if (item.tpt_confirm_delivery == 1) {
							confirmDelivery.push(item);
						}
						//Priority
						if (item.is_priority == 1) {
							priorityarr.push(item)
						}
						if(item.transit_remark === "NA")
						{
							transitna_data.push(item)
						}
						recordsarr.push(item);
					// }

				});
				let newArray = [];
				// console.log("item.consignment_status",outdeliverarr)
				// Declare an empty object 
				let uniqueObject = {};
				// transporters = [...new Set(transporters)];
				// var transData = transporters.map(e => (
				// 	{
				// 		"label": e,
				// 		"value": e
				// 	}
				// ))
				// console.log("transporters", transporters)
				// console.log("transData", transData)
				if(recordsarr.length > 0)
				{
					var objTitle;
					// Loop for the array elements 
					for (let i in recordsarr) {

						// Extract the title 
						objTitle = recordsarr[i]['consigner_code'];

						// Use the title as the index 
						uniqueObject[objTitle] = recordsarr[i];
					}

					// Loop to push unique object into array 
					for (var a in uniqueObject) {
						newArray.push(uniqueObject[a]);
					}

				}
				//console.log("newArray ", newArray)
				if (parameters.is_filter_call == 0) {
					var consigners = [];
					newArray.map((ccode) => {
						consigners.push(ccode.consigner_code);
					})
				}
				else {
					consigners = this.state.consigners
				}

				//console.log("records ", records)
				if (this.props.match.path == "/invalidcourierconsignments") {
					var allrecords = datanaarr;
				}
				if(this.props.match.path == "/courierconsignments"){
					var allrecords = recordsarr
				}
				else {
					var allrecords = recordsarr.filter(item => item.consignment_status != "CANCELLED");
				}
				// console.log(allrecords);
				var critical_counter_data = []
				if (urlpath == "/consignments/couriers" || urlpath == '/consignments/activecouriers') {
					// console.log(this.state.critical_data,"critical data length")
					this.state.critical_data.map(function (e, index) {

						allrecords.map(function (k) {
							if (e.consignment_code == k.consignment_code && getHyphenYYYYMMDDHHMMSS(k.gc_date) == e.gc_date) {
								k['critical_record'] = 1;
								critical_counter_data.push(k);
							}
						})
					})
				}

				// console.log(consigners,"consigners");
				// if(this.props.match.path == "/courierconsignments" || this.props.match.path == "/deliveredcourierconsignments")
				// {
				// 	cancelled_data = allrecords.filter(function(e){
				// 		// return e.cancelled == 1
				// 		// return e.consignment_status == 'UNDELIVERED' || e.consignment_status == 'FORCE CLOSED'
				// 		return e.consignment_status == 'UNDELIVERED'
				// 	})
				// }
				// var pod_received_data = []
				// var trip_closed_by_pod = []
				// if(this.props.match.path == "/courierconsignments" || this.props.match.path == "/activecourierconsignments" 
				// || this.props.match.path == "/deliveredcourierconsignments")
				// {
				// 	pod_received_data = allrecords.filter(function(e){
				// 		return e.pod_received == 1
				// 	})
				// 	trip_closed_by_pod = allrecords.filter(function(e){
				// 		return e.trip_closed_by_pod == 1
				// 	})
				// }
				// var on_time_data = allrecords.filter(e => {
				// 	if (new Date(e.delivery_datetime) <= new Date(e.expected_eta)) {
				// 		return e
				// 	}
				// })
				// var delayed_data = allrecords.filter(e => {
				// 	if ((e.delivery_datetime == "" || e.delivery_datetime == undefined) || (new Date(e.delivery_datetime) > new Date(e.expected_eta))) {
				// 		return e
				// 	}
				// })

				var on_time_data = allrecords.filter(e => ((e.ontime_delayed_status === "On Time" || e.transporter_code === "7402033") && (e.transit_remark !== "NA" || e.transit_remark === undefined)))
					
				
				var delayed_data = allrecords.filter(e => e.ontime_delayed_status == "Delayed" && (e.transit_remark !== "NA" || e.transit_remark === undefined) && (e.transporter_code != "7402033"))
				var transitna_data = allrecords.filter(e => e.transit_remark === "NA" && e.ontime_delayed_status == "Delayed")
				 console.log("transitna_data ", )
				let booked_data = allrecords.filter(e => e.consignment_status == 'BOOKED')
				let force_closed = allrecords.filter(e => e.consignment_status == 'FORCE CLOSED')
				//  console.log('delayed_data',delayed_data, '\n on_time_data',on_time_data)
				console.log('cancelled_data_831',cancelled_data, )
				this.setState({
					// filterTransporters: transData,
					gcWayBillList,
					consigners: consigners,
					rowData: allrecords,
					originaldata: allrecords,
					intransit: intransitarr,
					outdeliver: outdeliverarr,
					deliver: deliverarr,
					undeliver : undeliverarr,
					datana: datanaarr,
					prioritydata: priorityarr,
					intransitcount: intransitarr.length,
					outdelivercount: outdeliverarr.length,
					delivercount: deliverarr.length,
					undeliverCount:undeliverarr.length,
					datanacount: datanaarr.length,
					totalcount: allrecords.length,
					prioritydatacount: priorityarr.length,
					confirmDelivery: confirmDelivery,
					critical_counter_data: critical_counter_data,
					overly: "show-n",
					loadshow: 'show-n',
					cancelled_data: cancelled_data,
					// pod_received_data: pod_received_data,
					// trip_closed_by_pod: trip_closed_by_pod,
					on_time_data,
					delayed_data,
					transitna_data,
					pageTitle,
					counttype,
					booked_data,
					force_closed
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		//    ChartMenu.prototype.saveChart = function (e) {
		// 	   var event = {
		// 	      data: this.gridOptionsWrapper.getApi(),
		// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 	    };
		// 	   //console.log("Testing", event);
		// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	   this.dispatchEvent(event);
		// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

		//    };
	};
	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);



	}
	onRowSelection = (event) => {
		// console.log("row selected")
		var containerslist = [];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		// console.log("rwCount ", rwCount)
		//if(event.node.selected == true)
		//{
		//var containerlist=[];
		// console.log("rowselect", event.data)
		var rowCount = event.data
		//console.log("rowCount ", rowCount)

		rwCount.map((item) => {
			containerslist.push(item.data.truck_no)
		});
		var deliverStatusValue;
		if (event.data.status == 1) {
			deliverStatusValue = "Inside Plant"
		}
		else if (event.data.status == 2) {
			deliverStatusValue = "In Transit"
		}
		else if (event.data.status == 3) {
			deliverStatusValue = "Near Destination"
		}
		else if (event.data.status == 4) {
			deliverStatusValue = "Reached Destination"
		}

		else if (event.data.status == 5) {
			deliverStatusValue = "Left Destination"
		}
		else if (event.data.status == 6) {
			deliverStatusValue = "Force Closed"
		}
		else {
			deliverStatusValue = ""
		}
		this.setState({
			containerslist: containerslist,
			deliveryStatus: event.data.status,
			deliverStatusValue: deliverStatusValue,
			distance_from_dealer_location: event.data.distance_from_dealer_location,
			deliveredDeptCode: event.data.dept_code,
			deliveredTruckNo: event.data.truck_no,
		})
		//  console.log("checkedlist ", containerslist)
		// }
		// else{
		// 	if(containerslist.length > 0)
		// 	{
		// 		containerslist=[]
		// 		this.setState({
		// 			containerslist:containerslist
		// 		})
		// 	}
		// }

	}
	renderMap = () => {
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA9LP10EYr__tHTyPWXJCTrIdBBeSrdjTY&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}

	initMap = () => {
		// console.log("mapinfo ",this.state.mapinfo);
		var currentwindow;

		var jsondata = this.state.mapinfo;
		var locations = jsondata.breaks;
		var arr = jsondata.coords;


		// console.log("Here",arr);
		if (arr.length > 0) {
			var lt = arr[0].lat;
			var ln = arr[0].lng;
		}
		else {
			if (this.state.defTransitCoords == '') {
				var lt = 28.4519751;
				var ln = 77.0310713;
			}
			else {
				var lt = JSON.parse(this.state.defTransitCoords).lat;
				var ln = JSON.parse(this.state.defTransitCoords).lng;
			}

		}
		var routeinfo = jsondata.route_details;
		var mapOptions = {
			zoom: 7,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels: true,
			mapTypeControlOptions: {
				mapTypeIds: ['hybrid', 'roadmap'],
			},
			center: new window.google.maps.LatLng(lt, ln),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};

		var map = new window.google.maps.Map(document.getElementById('map'),
			mapOptions);

		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt, ln));
		// Create our info window content
		var currentinfowindow = null;
		var line = new window.google.maps.Polyline(
			{
				map: map,
				strokeColor: '#157254',
				strokeOpacity: 1.0,
				strokeWeight: 2.5,
				icons: [{
					icon: {
						path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						strokeColor: '#ff8c52',
						fillColor: '#ff8c52',
						fillOpacity: 1,
						strokeWeight: 2
					},
					repeat: '100px',
					path: []
				}]
			});

		if (arr.length > 0) {
			for (let i = 0; i < arr.length; i++) {
				var path = line.getPath().getArray();

				let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
				path.push(latLng);
				line.setPath(path);
				//Change line color based on map type
				window.google.maps.event.addListener(map, 'maptypeid_changed', function () {
					if (map.getMapTypeId() == 'hybrid' || map.getMapTypeId() == 'satellite') {
						var line = new window.google.maps.Polyline(
							{
								map: map,
								strokeColor: '#FFFFFF',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
									icon: {
										path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
										strokeColor: '#ff8c52',
										fillColor: '#ff8c52',
										fillOpacity: 1,
										strokeWeight: 2
									},
									repeat: '100px',
									path: []
								}]
							});
					}
					else {
						var line = new window.google.maps.Polyline(
							{
								map: map,
								strokeColor: '#157254',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
									icon: {
										path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
										strokeColor: '#ff8c52',
										fillColor: '#ff8c52',
										fillOpacity: 1,
										strokeWeight: 2
									},
									repeat: '100px',
									path: []
								}]
							});

					}

					for (i = 0; i < arr.length; i++) {
						var path = line.getPath().getArray();
						let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
						path.push(latLng);
						line.setPath(path);
						//map.setCenter(latLng);
					}

					var x = map.getZoom();
					var c = map.getCenter();
					window.google.maps.event.trigger(map, 'resize');
					map.setZoom(x);
					map.setCenter(c);
				});

				bounds.extend(new window.google.maps.LatLng(arr[i].lat, arr[i].lng));
			}

			var infowindow = new window.google.maps.InfoWindow();
			var marker, l;

			for (l = 0; l < locations.length; l++) {
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
					icon: require('../../assets/icons/cf.png'),
					map: map,
				});

				// eslint-disable-next-line no-loop-func
				window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
					return function () {
						var contentString = "<p style='color:#1F749B'><img src=" + marker.icon + "><B> Break - " + (l + 1) + "</B></p><p>Break time " + locations[l].break_start + " - " + locations[l].break_end + " (" + secondsToString(locations[l].break_time) + ")</p>";

						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						//console.log(marker.position.toJSON());
						//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
					}
				})(marker, l));
			}
			marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
				icon: require('../../assets/icons/track_start.png'),
				map: map,
			});
			window.google.maps.event.addListener(marker, 'click', (function (marker) {
				return function () {
					//	console.log(clusters[0])

					var contentString = "<p style='color:#1F749B'><img src=" + marker.icon + "><B> Starting Point</B></p><p>Started at " + routeinfo.start_time + "</p>";

					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker));
			marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
				icon: require('../../assets/icons/truck-end.png'),
				map: map,
			});
			window.google.maps.event.addListener(marker, 'click', (function (marker) {
				return function () {
					//	console.log(clusters[0])

					var contentString = "<p style='color:#1F749B'><img src=" + marker.icon + "><B> End Point</B></p><p>End at " + routeinfo.end_time + "</p>";

					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker));
		}
		map.fitBounds(bounds)
	}
	onClickForceClose = (params) => {
		//console.log("Pareas ", params)
		this.setState({
			forceCloseRowNode: params,
			forceclosedata: params.data,
			sliderForceCloseTranslate: "slider-translate",
			overly: 'show-m'
		})
	}
	onClickMarkDelivered = (params) => {
		// console.log(params.data);
		const todate = moment.parseZone().format('YYYY-MM-DD');
		if (params.data.gc_date != undefined && params.data.gc_date != null && params.data.gc_date != "") {
			var markdate = params.data.gc_date.split("T")[0];
			// console.log("markdate", markdate)
			this.setState({
				markDeliverDefault: markdate
			});
		}
		else {
			// console.log("else")
			this.setState({
				markDeliverDefault: todate
			});
		}
		this.setState({
			markDeliverRowNode: params,
			markDeliverData: params.data,
			sliderMarkDeliverTranslate: "slider-translate",
			overly: 'show-m',
		})
	}
	closeAlert = () => {
		this.setState({
			show: false
		});
	}
	closePriorityAlert = () => {
		this.setState({
			showPriority: false
		});
		window.location.reload();
	}

	
	uploadBulkPriorityFormHandler = (event) => {
		event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			 document.getElementById("upform").reset()
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var bulkMdData = this.state.pCsvContent;
		//console.log(bulkMdData);
		var flag = 0;
		bulkMdData.map(function (e, index) {
			var keys = Object.keys(e);
			var consignmentKey = ""
			keys.map(function (key) {
				if (key == "Consignment_Code" ||
					key == "Consignment Code" ||
					key == "consignment_code" ||
					key == "consignment code" ||
					key == "ConsignmentCode" ||
					key == "consignmentcode"
				) {
					consignmentKey = key;
				}
			})
			if (index != (bulkMdData.length - 1)) {
				if (e[consignmentKey] == "") {
					flag = 1
				}
			}

		})
		if (flag == 0) {
			var reqParams = {
				email: localStorage.getItem("email"),
				csvData: bulkMdData
			}
			redirectURL.post("/consignments/bulkUploadPriority", reqParams,{
				headers: {
					'Content-Type': 'application/json',
					'Authorization': localStorage.getItem("userid")
				  }
			}).then((response) => {
				this.setState({
					basicTitle: "Success",
					basicType: "success",
					show: true,
					file: "",
					bulkPrioritySlide: "",
					overly: "show-n",
					loadshow: "show-n"
				})
				window.location.reload();
			}).catch((e) => {
				console.log(e);
			})

		}
		else {
			$("#bulkUploadPID").val();
			this.setState({
				basicTitle: "Consignment_code fields must not be empty",
				basicType: "danger",
				show: true,
				file: "",
				pCsvContent: "",
			})
		}
	}
	uploadBulkForceFormHandler = (event) => {
		event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var bulkData = this.state.csvcontent;
		// console.log(bulkData);
		var flag = 0;
		bulkData.map(function (e) {
			var keys = Object.keys(e);
			// console.log("keys",keys)
			var consignmentKey = ""
			keys.map(function (key) {
				if (key == "Consignment_Code" ||
					key == "Consignment Code" ||
					key == "consignment_code" ||
					key == "consignment code" ||
					key == "ConsignmentCode" ||
					key == "consignmentcode"
				) {
					consignmentKey = key;
				}
			})
			if (e[consignmentKey] == "" || e.reason == "" || e.gc_date == "") {
				flag = 1
			}
		})
		if (flag == 0) {
			if (bulkData.length > 0) {
				var reqParams = {
					email: localStorage.getItem("email"),
					csvData: bulkData
				}
				redirectURL.post("/consignments/bulkUploadForceClose", reqParams,{
					headers: {
						'Content-Type': 'application/json',
						'Authorization': localStorage.getItem("userid")
					}
				})
				.then((response) => {
					this.setState({
						basicTitle: "Success",
						basicType: "success",
						show: true,
						file: "",
						bulkForceCloseSlide: "",
						overly: "show-n",
						loadshow: "show-n"
					})
					// window.location.reload();
				}).catch((e) => {
					console.log(e);
				})
			}
			else {
				this.setState({
					basicTitle: "Invalid File",
					basicType: "warning",
					show: true
				})
			}

		}
		else {
			$("#bulkUploadID").val();
			this.setState({
				basicTitle: "Consignment_code, reason, gc_date fields must not be empty",
				basicType: "danger",
				show: true,
				file: "",
				csvData: "",
			})
		}
	}

	uploadBulkMarkDeliverHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var bulkMdData = this.state.mdCsvContent;
		let markDeliverDefault = this.state.markDeliverDefault
		// console.log("bulkMdData",bulkMdData)
		// console.log("markDeliverDefault",markDeliverDefault)
		// var delivery_date_time = $("#bulk_delivery_date_time").val();
		var delivery_date_time = markDeliverDefault
		//console.log(delivery_date_time)
		// console.log(bulkMdData);
		// if (delivery_date_time != "__-__-____") {
		if (delivery_date_time != "____-__-__") {
			var flag = 0;
			bulkMdData.map(function (e, index) {
				var keys = Object.keys(e);
				var consignmentKey = ""
				keys.map(function (key) {
					if (key == "Consignment_Code" ||
						key == "Consignment Code" ||
						key == "consignment_code" ||
						key == "consignment code" ||
						key == "ConsignmentCode" ||
						key == "consignmentcode"
					) {
						consignmentKey = key;
					}
				})
				if (index != (bulkMdData.length - 1)) {
					if (e[consignmentKey] == "") {
						flag = 1
					}
				}
			})
			if (flag == 0) {
				var reqParams = {
					email: localStorage.getItem("email"),
					deliveryDate: delivery_date_time,
					csvData: bulkMdData
				}
				// console.log("reqParams",reqParams)
				redirectURL.post("/consignments/bulkUploadMarkDeliver", reqParams,{
					headers: {
						'Content-Type': 'application/json',
						'Authorization': localStorage.getItem("userid")
					}
				})
				.then((response) => {
					this.setState({
						basicTitle: "Success",
						basicType: "success",
						show: true,
						file: "",
						mdCsvContent:"",
						bulkMarkDeliverSlide: "",
						overly: "show-n",
						loadshow: "show-n",
						markDeliverDefault: ''
					})
					 window.location.reload();
				}).catch((e) => {
					console.log(e);
				})
			}
			else {
				 $("#bulkUploadMDID").val();
				this.setState({
					basicTitle: "Consignment_code fields must not be empty",
					basicType: "danger",
					show: true,
					file: "",
					mdCsvContent: "",
					markDeliverDefault: ''
				})
			}
		}
		else {
			 $("#bulkUploadMDID").val();
			this.setState({
				basicTitle: "Delivery Date is Not Selected",
				basicType: "danger",
				show: true,
				file: "",
				mdCsvContent: "",
				markDeliverDefault: ''
			})
		}
	}

	onShowCourierTrack = async (params) => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.trackCourier,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		if (params != 0) {

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "Transit Date",
								field: "intransit_date",
								valueGetter: function (params) {
									// console.log("IN onShowCourierTrack, params.data = ", typeof(params.data.intransit_date));
									// var intransitdate=getDDMMYYYY(params.data.intransit_date)+" "+params.data.intransit_time
									//    var intransitdate= params.data.intransit_date +" "+ params.data.intransit_time
									//    return intransitdate
									if ((params.data.intransit_date).includes('Z')) return getHyphenDDMMMYYYYHHMM(params.data.intransit_date)
									return (params.data.intransit_date)
								},
								filter: true,
								resizable: true
							},
							{
								headerName: "Location",
								field: "intransit_location",
								filter: true,
								resizable: true
							},
							{
								headerName: "Status",
								field: "intransit_status",
								filter: true,
								resizable: true
							}
						]
					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",overspeedData)
						redirectURL.post('/consignments/trackcourier', 
							{consignment_code: params.data.consignment_code},{
							headers: {
								'Content-Type': 'application/json',
								'Authorization': localStorage.getItem("userid")
							}
						})
							.then(async (response) => {
								// console.log("Step 1 ",response.data)
								var trackData = response.data;
								// console.log("trackData ", trackData);
								try {
									trackData.sort((a, b) => new Date(a.intransit_date) - new Date(b.intransit_date))
								} catch (e) {
									trackData = []
								}
								// console.log("trackDataAfter ", trackData);

								if (trackData.status == 'failure') {
									params.successCallback([]);
								}
								else {
									params.successCallback(trackData);
								}

								//console.log("Step 2 ",response.data)

							});

					},
					masterDetail: true
				}
			});

			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		//console.log("params.column.colId ", params.column)
		if (params.column.colDef.field == '_id') {
			params.node.setExpanded(!params.node.expanded);
		}
		else {

			params.node.setExpanded(false);
		}
	}
	hideAlert = () => {
		this.setState({
			alert: null
		});
	}
	// onClickCounterShowData(ctype){
	// 	console.log("CType ", ctype);
	// }
	resetUpload = () => {
		$("#uploadFile").val("")
	    $("#manualUploadFile").val("")
		$("#bulkUploadMDID").val("")
		$("#bulkUploadPID").val("")
	    document.getElementById("upform").reset();
		// document.getElementById("appednCSVData").innerHTML = ''
		this.setState({
			uploadDivWidth: '0%',
			bulkPrioritySlide: '',
			bulkForceCloseSlide: '',
			// bulkMarkDeliverSlide: '',
			showDiv: 'show-n',
			overly: 'show-n',
			file: '',
			mdCsvContent:"",
			sliderInvalidDocket:"",
			sliderTransitNa:"",
			overly:"show-n",
			invalidRowItem:[],
			transitRowNa:[],
			invalid_gc_waybill:"",
		    // shipment_slider: ''
		});
		
	}
	onCloseDeliverModal = () => {
		this.gridApi.deselectAll();
		this.setState({ delivermodal: false, delDate: "none" });
	};
	// handlerDeliverDateTime = (event, currentDate, selectedDate) => {
	// 	var d = new Date(event._d);

	// 	var deliverdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());

	// 	this.setState({
	// 		deliverdate:deliverdate
	// 	});
	// }
	handlerDeliverDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var deliverdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		var temp = deliverdate
		if (temp.split("-")[1] < 10) {
			temp = temp.split("-")[0] + "-0" + temp.split("-")[1] + "-" + temp.split("-")[2]
		}

		var checkDeliveryDate = new Date(temp);
		var gc_date = this.state.selectedRow.gc_date;
		gc_date = new Date(gc_date.split("T")[0]);
		var currentdate = moment.parseZone(new Date()).format("YYYY-MM-DD");
		// console.log(checkDeliveryDate,gc_date,currentdate);
		var flag = 0;
		if (checkDeliveryDate < gc_date || checkDeliveryDate > new Date(currentdate)) {
			flag = 1;
		}
		if (flag == 0) {
			// console.log(deliverdate)
			this.setState({
				deliverdate: deliverdate
			});
		}
		else {
			this.setState({
				deliverdate: "",
				basicTitle: "Selected Date Must be Between Booking Date and Current Date",
				basicType: "warning",
				show: true
			});
		}

	}

	handlerTransitDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		console.log(event._d , "d_1729")
		var date = moment.parseZone(new Date(d)).format("YYYY-MM-DD HH:mm:ss")
		this.setState({
			transit_data_for_update : date
		})
		

	}

	handlerUpdateDeliveredDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var date = moment.parseZone(new Date(d)).format("YYYY-MM-DD HH:mm:ss")
		this.setState({
			delivered_date_for_update : date
		})
		

	}

	

	selectReason() {
		var rowdata = this.state.selectedRow;
		var arr = []
		arr.push({ "label": "Delay in Pick up", "value": "Delay in Pick up" });
		arr.push({ "label": "Delay in transit/Network Delay", "value": "Delay in transit/Network Delay" });
		arr.push({ "label": "Delay due to Strike, Natural Calamity, Govt. Restrictions", "value": "Delay due to Strike, Natural Calamity, Govt. Restrictions" });
		arr.push({ "label": "Consignee Door Closed/Consignee Shifted", "value": "Consignee Door Closed/Consignee Shifted" });
		arr.push({ "label": "Consignee Refused to Accept", "value": "Consignee Refused to Accept" });
		arr.push({ "label": "Consignee asked to reschedule delivery", "value": "Consignee asked to reschedule delivery" });
		arr.push({ "label": "Incorrect Address/Pin Code", "value": "Incorrect Address/Pin Code" });
		if (rowdata.vehicle_mode == "AIR CARGO") {
			arr.push({ "label": "Consignment Offloaded due to DG goods/Oversize", "value": "Consignment Offloaded due to DG goods/Oversize" });
			arr.push({ "label": "Consignment offloaded by airlines", "value": "Consignment offloaded by airlines" });
		}
		arr.push({ "label": "Cartons short from MSIL", "value": "Cartons short from MSIL" });
		arr.push({ "label": "Cartons short received at Delivery Hub", "value": "Cartons short received at Delivery Hub" });
		arr.push({ "label": "Local Festival", "value": "Local Festival" });
		arr.push({ "label": "Manual Delivery/Dispatched via other Transporter", "value": "Manual Delivery/Dispatched via other Transporter" });
		arr.push({ "label": "Consignment delivered, awaiting confirmation from local branch", "value": "Consignment delivered, awaiting confirmation from local branch" });
		arr.push({ "label": "Invalid Docket (Correct GR no. to be specified)", "value": "Invalid Docket (Correct GR no. to be specified)" });
		arr.push({ "label": "Others (Please Specify)", "value": "Others (Please Specify)" });
		return arr;
	}
	setSelectedReason = (event) => {
		var selectedReason = event.value;
		if (selectedReason == "Invalid Docket (Correct GR no. to be specified)" || selectedReason == "Others (Please Specify)") {
			this.setState({
				displayOtherField: "show-m"
			})
		}
		else {
			this.setState({
				displayOtherField: "show-n"

			})
		}
		if (selectedReason == "Manual Delivery/Dispatched via other Transporter" || selectedReason == "Consignment delivered, awaiting confirmation from local branch") {
			var delDate = "flex";
		}
		else {
			var delDate = "none";
		}
		this.setState({
			selectedDeliveryReason: event.value,
			delDate: delDate
		})
	}
	changeReasonHandler(e) {
		this.setState({
			deliveredOtherReason: e.target.value
		})
	}
	formSetDeliveredDate(event) {
		event.preventDefault();
		var deliverrow = this.state.deliverrow;
		var deliverdate = this.state.deliverdate;
		var tpt_delivery_reason = this.state.selectedDeliveryReason;
		var tpt_delivery_other_reason = this.state.deliveredOtherReason;
		var selectedDeliveryReason = this.state.selectedDeliveryReason;
		//console.log("deliverrow ", deliverrow);
		// console.log(deliverdate);
		var dateFlag = 0;
		if (tpt_delivery_reason == "Manual Delivery/Dispatched via other Transporter" || tpt_delivery_reason == "Consignment delivered, awaiting confirmation from local branch") {
			if (moment.parseZone(deliverdate).format("YYYY-MM-DD") == "Invalid date" || moment.parseZone(deliverdate).format("YYYY-MM-DD") == "" || moment.parseZone(deliverdate).format("YYYY-MM-DD") == undefined) {
				var dateFlag = 1;
			}
		}

		var flag = 0;
		if (tpt_delivery_reason == "Others (Please Specify)") {
			if (tpt_delivery_other_reason.replace(/\s/g, "").length == 0) {
				flag = 1;
			}
		}

		if (flag == 1) {
			this.setState({
				basicTitle: "Other Reason Should not be Empty",
				basicType: "warning",
				show: true
			});
		}
		else {
			if (dateFlag == 1) {
				this.setState({
					basicTitle: "Invalid Delivery Date",
					basicType: "warning",
					show: true
				});
			}
			else {
				if (tpt_delivery_reason != undefined && tpt_delivery_reason != '') {
					var delReason = tpt_delivery_reason;
				}
				else {
					var delReason = "Imvalid Docket (Correct GR no. to be specified)";
				}
				var reqparams = {
					tpt_confirm_delivery: 1,
					dept_code: this.state.deliveredDeptCode,
					truck_no: this.state.deliveredTruckNo,
					email: localStorage.getItem("email"),
					tpt_delivery_date: moment.parseZone(deliverdate).format("YYYY-MM-DD"),
					consigment_code: deliverrow.data.consignment_code,
					tpt_delivery_reason: delReason,
					tpt_delivery_other_reason: tpt_delivery_other_reason
				}
				redirectURL.post("/consignments/setCouriersDeliverDate", reqparams,{
					headers: {
						'Content-Type': 'application/json',
						'Authorization': localStorage.getItem("userid")
					}
				})
					.then((response) => {
						// console.log("resss ", response.data)
						if (response.data.ok == 1) {
							this.setState({
								delivermodal: false,
								delDate: "none",
								deliverdate: '',
								deliveryStatus: "",
								deliveredDeptCode: "",
								deliveredTruckNo: "",
								overly: "show-m",
								loadshow: "show-m"
							});
							this.submitForm();
						}
					})
			}
		}
	}
	onClickCounterShowData(params) {
		// console.log("IN onClickCounterShowData, params = ",params);
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				//console.log("eventLabel = ", eventLabel);
			}

			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		if (params == "critical") {
			this.gridApi.setRowData(this.state.critical_counter_data);
			// this.gridApi.onFilterChanged();
		}
		if (params == "intransit") {
			var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");

			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["consignment_status"]) {
					// return (rec["consignment_status"] == "IN TRANSIT" || rec["consignment_status"] == "In Transit");
					return ["DESTINATION ARRIVED", "IN TRANSIT", "Partially Delivered", "PARTIALLY DELIVERED", 'In Transit', 'TRANSIT', 'UNDELIVERED'].includes(rec["consignment_status"]);
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		if (params == "confirmDelivery") {

			this.gridApi.setRowData(this.state.confirmDelivery);
			this.gridApi.onFilterChanged();
		}

		if (params == "outfordeliver") {
			var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			// console.log(filterComponent);
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");

			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["consignment_status"]) {
					return rec["consignment_status"] == "Out for Delivery";
				}
			});
			// console.log(isFilterDataAvailable);
			this.gridApi.setRowData(this.state.outdeliver);
			this.gridApi.onFilterChanged();
		}
		if (params == "deliver") {
			// var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");

			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["consignment_status"]) {
					return ['DELIVERED', "Delivered",].includes(rec["consignment_status"])
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}

		if(params == "undelivered"){
			let undeliverFilterData = this.state.rowData.filter(item => {
				if (item["consignment_status"]) {
					return ['UNDELIVERED', "UnDelivered",].includes(item["consignment_status"])
				}
			});
			this.gridApi.setRowData(undeliverFilterData);
			this.gridApi.onFilterChanged();
		}

		if (params == "transitna") {
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["transit_remark"]) {
					return ['NA'].includes(rec["transit_remark"])
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}

		if (params == "datana") {
			// var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");

			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["consignment_status"]) {
					return (rec["consignment_status"] == "" || rec["consignment_status"] == null || rec["consignment_status"] == "Invalid Docket");
				}
			});
			this.gridApi.setRowData(this.state.datana);
			this.gridApi.onFilterChanged();
		}

		if (params == "all") {
			// console.log('clicked_all')
			// var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");			
			// filterComponent.applyModel();

			this.gridApi.setRowData(this.state.originaldata);
			this.gridApi.onFilterChanged();
		}
		if (params == "cancelled") {
			console.log("PARAMS_CANCELLED" , this.state.cancelled_data) 
			this.gridApi.setRowData(this.state.cancelled_data);
			this.gridApi.onFilterChanged();
		}
		if (params == "force_closed") {
			this.gridApi.setRowData(this.state.force_closed);
			this.gridApi.onFilterChanged();
		}

		if (params == "booked") {
			this.gridApi.setRowData(this.state.booked_data);
			this.gridApi.onFilterChanged();
		}
		if (params == "trip_closed") {
			this.gridApi.setRowData(this.state.trip_closed_by_pod);
			this.gridApi.onFilterChanged();
		}
		if (params == "pod") {
			this.gridApi.setRowData(this.state.pod_received_data);
			this.gridApi.onFilterChanged();
		}
		if (params == "priority") {
			this.gridApi.setRowData(this.state.prioritydata);
			this.gridApi.onFilterChanged();
		}
		if (params == "on_time") {

			this.gridApi.setRowData(this.state.on_time_data);
			this.gridApi.onFilterChanged();
		}
		if (params == "delayed") {
			var rowdt = [];
			if(this.state.delayed_data.length > 0)
			{
				this.state.delayed_data.map((itm) => {
					if(itm.transporter_code == "7402033")
					{

					}
					else{
						rowdt.push(itm);
					}
				})
			}
			this.gridApi.setRowData(rowdt);
			this.gridApi.onFilterChanged();
		}
		
		if (params == "invalid") {

			this.gridApi.setRowData(this.state.datana);
			this.gridApi.onFilterChanged();
		}

	}
	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		if (this.state.to_date != "" && startdate > this.state.to_date) {
			this.setState({
				show: true,
				basicTitle: 'From date should be less than To date',
				basicType: "danger",
				loadshow: 'show-n',
				startDate: "",
			})
		}
		else {
			this.setState({
				from_date: startdate
			});
		}
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var edate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		if (this.state.from_date != "" && edate < this.state.from_date) {
			this.setState({
				show: true,
				basicTitle: 'To date should be greater than From date',
				basicType: "danger",
				loadshow: 'show-n',
				endDate: "",
			})
		}
		else {
			this.setState({
				to_date: edate
			});
		}
	}

	onChangeMarkedDeliverDate = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		console.log("markDeliverDefault", startdate)
		this.setState({
			markDeliverDefault: startdate
		});

		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handleChange = date => {
		this.setState({
			to_date: date
		});
	};
	handleChange1 = date => {
		this.setState({
			from_date: date
		});
	};

	reloadPageData = () => {
		window.location.reload();
	}

	onClickHideAll = () => {
		$("#uploadFile").val("")
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderForceCloseTranslate: '',
			sliderMarkDeliverTranslate: "",
			manual_upload_slider:"",
			sliderInvalidDocket:"",
			sliderTransitNa:"",
			bulkPrioritySlide: "",
			bulkForceCloseSlide: "",
			bulkMarkDeliverSlide: "",
			shipment_slider:"",
			sliderInvalidDocket:"",
			overly:"show-n",
			invalidRowItem:[],
			transitRowNa:[],
			invalid_gc_waybill:"",
			update_row_slider:'',
			status_for_update:'',
			transit_data_for_update:'',
		});
		$("#location_for_update").val("")
		$("#epodDocforupdate").val("")

	}

	onBulkPriorityBtn = () => {
		this.setState({
			overly: "show-m",
			bulkPrioritySlide: "slider-translate"
		})
	}
	onBulkForceCloseBtn = () => {
		this.setState({
			overly: "show-m",
			bulkForceCloseSlide: "slider-translate"
		})
	}
	onBulkMarkDeliverBtn = () => {
		$("#bulkUploadMDID").val("")
		this.setState({
			overly: "show-m",
			bulkMarkDeliverSlide: "slider-translate"
		})
	}

	formForceCloseHandler = (event) => {
		event.preventDefault()
		var consignmentId = this.state.forceclosedata.consignment_code;
		var forceCloseReason = $("#forceClosureResonID").val();
		var email = localStorage.getItem("email");
		// console.log(forceCloseReason,email);
		var reqParams = {
			consignmentId: consignmentId,
			forceCloseReason: forceCloseReason,
			email: email
		}
		if (forceCloseReason.replace(/\s/g, "").length) {
			redirectURL.post("/consignments/updateCourierForceClosure", reqParams,{
				headers: {
					'Content-Type': 'application/json',
					'Authorization': localStorage.getItem("userid")
				}
			})
			.then((response) => {
				this.setState({
					sliderForceCloseTranslate: "",
					loadshow: "show-n",
					overly: "show-n",
					basicTitle: "Force Closed Successfully",
					basicType: "success",
					show: true,
					forceCloseRowNode: "",
					forceclosedata: "",
				});
				window.location.reload();
			}).catch((e) => {
				console.log(e)
			})
		}
		else {
			this.setState({
				basicTitle: "Reason Should not be Empty.",
				basicType: "danger",
				show: true,
			});
		}


	}

	formMarkDeliverHandler = (event) => {
		event.preventDefault()
		var consignmentId = this.state.markDeliverData.consignment_code;
		var delivery_date_time = $("#delivery_date_time").val();
		var delivery_date_time = this.state.markDeliverDefault
		// console.log('markdeliverdefault', this.state.markDeliverDefault)
		// console.log("delivery_dateTime", delivery_date_time)
		if (delivery_date_time != "__-__-____") {
			var rowdata = this.state.markDeliverData;

			var temp = delivery_date_time
			if (temp.split("-")[1].length < 2) {
				temp = temp.split("-")[0] + "-0" + temp.split("-")[1] + "-" + temp.split("-")[2]
			}
			else {
				temp = temp.split("-")[0] + "-" + temp.split("-")[1] + "-" + temp.split("-")[2]
			}
			// console.log(temp,"temp")
			var flag = 0;
			var checkDeliveryDate = new Date(temp);
			var gc_date = this.state.markDeliverData.gc_date;
			gc_date = new Date(gc_date.split("T")[0]);
			var currentdate = moment.parseZone(new Date())
			  console.log(new Date(currentdate),gc_date,checkDeliveryDate);
			if (checkDeliveryDate < gc_date) {
				flag = 1;
			}
			if (flag == 0) {
				var email = localStorage.getItem("email");
				var reqParams = {
					consignmentId: consignmentId,
					delivery_datetime: delivery_date_time,
					email: email
				}
				console.log(reqParams);
				redirectURL.post("/consignments/courierMarkDelivered", reqParams,{
					headers: {
						'Content-Type': 'application/json',
						'Authorization': localStorage.getItem("userid")
					}
				})
				.then((response) => {
					this.setState({
						sliderMarkDeliverTranslate: "",
						loadshow: "show-n",
						overly: "show-n",
						basicTitle: "Marked as Delivered",
						basicType: "success",
						show: true,
						markDeliverRowNode: "",
					})
					 window.location.reload();
				}).catch((e) => {
					console.log(e)
				})
			}
			else {
				this.setState({
					deliverdate: "",
					basicTitle: "Selected Date Must be Between greater than GC Date",
					basicType: "warning",
					show: true
				});
			}
		}
		else {
			this.setState({
				basicTitle: "Delivery Date is Not Selected.",
				basicType: "danger",
				show: true
			})
		}
	}

	changeConsignerArrayHandler = consigner => {
		// console.log(consigner,"2073")
		if(this.state.consigner != null && consigner != undefined){
			if(consigner.length>6){
				this.setState({
					show:"true",
					basicTitle:"Selection should not be greater than 6",
					basicType:"danger"
				})
			}else{
				this.setState(
					{ consigner },
					//   () => console.log(`Consigner Option selected:`, this.state.consigner)
				);
			}
		
		}else{
			this.setState({
				consigner:[]
			})
		}
		
			this.setState({
				errorMessage: '',
				successMessage: ''
			})
	}
	selectConsignerOptionsItems() {
		let items = [];
		var plants = this.state.plants;
		items.push({ value: 'all', label: 'All' });
		if (plants.length > 0) {
			plants.forEach(item => {
				items.push({ value: item.plant_code, label: item.plant_code + "-" + item.plant_name });
			})
		}
		// this.state.consigners.forEach(item => {
		// 	items.push({ value: item, label: item });
		// });

		return items;
	}
	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'reason',
					inputName: 'reason',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'gc_date',
					inputName: 'gc_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},



			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				// console.log(csvData)
				this.setState({
					csvcontent: csvData.data
				});
			})
			.catch(err => { })
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
		});
		// console.log(contentList);
		this.setState({
			file: e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
			// console.log(fileData);
			// console.log(typeof(fileData))

		}
		else {
			e.target.value = null;
			this.setState({
				uploadFile: '',
				file: "",
				show: true,
				basicType: 'danger',
				basicTitle: 'Please upload file having extensions .csv only.',
			});
		}

	}
	changeShipmentFileHandler = async (e) => {
		if(e.target.files.length !== 0){
			const config = {
				headers: [
					{
						name: 'Shipment Number',
						inputName: 'consignment_code',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Shipment type',
						inputName: 'shipment_type',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Tran.plan.point',
						inputName: 'consigner_code',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Vehicle No',
						inputName: 'truck_no',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					
					{
						name: 'GC/Way Bill No',
						inputName: 'gc_waybill_no',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'GC Date',
						inputName: 'gc_date',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'New Road Permit No',
						inputName: 'newroadpermint_no',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Transporter',
						inputName: 'transporter_code',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Transporter Name',
						inputName: 'transporter_name',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Ship to Party',
						inputName: 'consignee_code',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Name',
						inputName: 'name',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Region Code',
						inputName: 'region_code',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Region Name',
						inputName: 'consignee_state',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Transport.zone',
						inputName: 'transporter_zone',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Name',
						inputName: 'consignee_city',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Date of Shipment Start',
						inputName: 'date_of_shipement_start',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Actual Delivery Date',
						inputName: 'revised_eta',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Date of Shipment End',
						inputName: 'shipment_completion_date',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Shipping type',
						inputName: 'shipping_type',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Shipping type Desc',
						inputName: 'shipping_type_desc',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Overall Transportation status',
						inputName: 'overall_transportation_status',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					
					{
						name: 'Allow Total Wt.',
						inputName: 'allowable_weight',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Shipment value',
						inputName: 'shipment_value',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Shipment Cost No',
						inputName: 'shipment_cost_no',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Costs item',
						inputName: 'costs_item',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Item Category',
						inputName: 'item_category',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Net Value',
						inputName: 'net_value',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Tax Amount',
						inputName: 'tax_amount',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Pricing Date',
						inputName: 'pricing_date',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Settlement date',
						inputName: 'settlement_date',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Status of transfer',
						inputName: 'statusof_transfer',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Ext.ID1',
						inputName: 'ext_1d1',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Ext.ID2',
						inputName: 'ext_1d2',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Purchasing Document',
						inputName: 'purchasing_document',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Item No',
						inputName: 'item_no',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Entry Sheet No',
						inputName: 'entry_sheet_no',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Ser Entr AccDoc',
						inputName: 'ser_entr_accdoc',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Posting Dt',
						inputName: 'posting_dt',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Serv Entry Amt',
						inputName: 'serv_entry_amt',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Entry Sheet Date',
						inputName: 'entry_sheet_date',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'MIRO Doc No',
						inputName: 'miro_doc_no',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'MIRO RefDoc No',
						inputName: 'miro_refdoc_no',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Accounting Document no',
						inputName: 'accounting_documentr_no',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Payment Doc No',
						inputName: 'paymenyt_doc_no',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Payment Date',
						inputName: 'payment_date',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Cheque No',
						inputName: 'cheque_no',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Cheque Date',
						inputName: 'cheque_date',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Cheque Amount',
						inputName: 'cheque_amount',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Name of Payer',
						inputName: 'name_of_payer',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Basic Trans Costs',
						inputName: 'basic_trans_cost',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Loading/Unloading Ch',
						inputName: 'loading_unloading_ch',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Unloading Charges',
						inputName: 'unloading_charges',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Detention Charges',
						inputName: 'detention_charges',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Octroi',
						inputName: 'octroi',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'ODA Charges',
						inputName: 'oda_charges',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Rentals',
						inputName: 'rentals',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'ODC charges',
						inputName: 'odc_charges',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Penalty',
						inputName: 'penalty',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Incentive',
						inputName: 'incentive',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Transhipment penalty',
						inputName: 'transhipment_penalty',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Other Charges',
						inputName: 'other_charges',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Per Kg Slab Rate',
						inputName: 'per_kg_slab_rate',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'No.of lines',
						inputName: 'no_of_lines',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'HASH_QTY',
						inputName: 'hash_qty',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Departure.zone',
						inputName: 'departure_zone',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Agreed Transit days',
						inputName: 'agreed_transit_days',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Actual Transit days',
						inputName: 'actual_transit_days',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Delay.Transit days',
						inputName: 'delay_tranit_days',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Total Net Weight',
						inputName: 'total_net_weight',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Total Gross Weight',
						inputName: 'total_gross_weight',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'Total volume',
						inputName: 'total_volume',
						required: false,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
										
					// {
					// 	name: 'consigner_code',
					// 	inputName: 'consigner_code',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// {
					// 	name: 'truck_no',
					// 	inputName: 'truck_no',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// {
					// 	name: 'gc_waybill_no',
					// 	inputName: 'gc_waybill_no',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// {
					// 	name: 'consignment_code',
					// 	inputName: 'consignment_code',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// {
					// 	name: 'gc_date',
					// 	inputName: 'gc_date',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// {
					// 	name: 'shipment_completion_date',
					// 	inputName: 'shipment_completion_date',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// {
					// 	name: 'shipping_type',
					// 	inputName: 'shipping_type',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// {
					// 	name: 'transporter_code',
					// 	inputName: 'transporter_code',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// {
					// 	name: 'consignee_code',
					// 	inputName: 'consignee_code',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// // {
					// // 	name: 'consignee_name',
					// // 	inputName: 'consignee_name',
					// // 	required: true,
					// // 	requiredError: function (headerName, rowNumber, columnNumber) {
					// // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// // 	}
					// // },
					// {
					// 	name: 'consignee_city',
					// 	inputName: 'consignee_city',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// {
					// 	name: 'consignee_state',
					// 	inputName: 'consignee_state',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// {
					// 	name: 'consignee_region',
					// 	inputName: 'consignee_region',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// {
					// 	name: 'consignee_pincode',
					// 	inputName: 'consignee_pincode',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// {
					// 	name: 'allowable_weight',
					// 	inputName: 'allowable_weight',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// {
					// 	name: 'per_kg_slab_rate',
					// 	inputName: 'per_kg_slab_rate',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					// {
					// 	name: 'basic_trans_cost',
					// 	inputName: 'basic_trans_cost',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
				]
			}
			var data = CSVFileValidator(e.target.files[0], config)
				.then((csvData) => {
					//  console.log('csvData', csvData)
					 
					let keys = Object.keys(csvData.data[0])
					//'consignee_code','shipping_type' above headers should be in items
					let items = ['consignment_code','shipment_type','consigner_code','truck_no','gc_waybill_no','gc_date','newroadpermint_no','transporter_code','transporter_name','consignee_code','name','region_code','consignee_state','transporter_zone','consignee_city','date_of_shipement_start','revised_eta','shipment_completion_date','shipping_type','shipping_type_desc','overall_transportation_status','allowable_weight','shipment_value','shipment_cost_no','costs_item','item_category','net_value','tax_amount','pricing_date','settlement_date','statusof_transfer','ext_1d1','ext_1d2','purchasing_document','item_no','entry_sheet_no',
					'ser_entr_accdoc','posting_dt','serv_entry_amt','entry_sheet_date','miro_doc_no','miro_refdoc_no','accounting_documentr_no','paymenyt_doc_no','payment_date','cheque_no','cheque_date','cheque_amount','name_of_payer','basic_trans_cost','loading_unloading_ch','unloading_charges','detention_charges','octroi','oda_charges','rentals','odc_charges','penalty','incentive','transhipment_penalty','other_charges','per_kg_slab_rate','no_of_lines','hash_qty','departure_zone','agreed_transit_days','actual_transit_days','delay_tranit_days','total_net_weight','total_gross_weight','total_volume',
					]
					// console.log("items",items)
					let check = false
					if(data.inValidMessages.length === 0)
                     {
						for (let i in items){
							 console.log("items.includes(keys[i]) ", items.includes(keys[i]))
							if(keys.includes(items[i])){
								check = true                 
							}else{
								check = false
								break
							}
						}
					 }else{
						check = false
						
					 }	
					//  console.log("check", check)
					if (check === false) {
						$("#uploadFile").val("")
						// document.getElementById("appednCSVData").innerHTML = ''
						this.setState({
							show: true,
							basicTitle: 'Upload a CSV file with fields matching the provided template',
							basicType: 'danger'
						});
					} else {
						this.setState({
							csvcontent: csvData.data
						});
					}
				})
				.catch(err => { })
			 console.log("e.target.files[0]",e.target.result);
			var contentList = []
	
			var out = new Promise(function (reject, resolve) {
				var reader = new FileReader();
				reader.onload = async function (e) {
					var contents = await e.target.result;
					resolve(contents);
				};
				var tt = reader.readAsText(e.target.files[0]);
			});
			// console.log(contentList);
			this.setState({
				shipment_file: e.target.files[0]
			});
			 console.log("e.target.files[0].type ", e.target.files[0]);
	
			if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel' || e.target.files[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
				// console.log(fileData);
				// console.log(typeof(fileData))
			}
			else {
				e.target.value = null;
				this.setState({
					uploadFile: '',
					file: "",
					show: true,
					basicType: 'danger',
					basicTitle: 'Please upload file having extensions .csv only.',
				});
			}

		}
	}

	changeManualFileHandler = async (e) => {
		// console.log('changeManualFileHandler',e.target.files)
		if(e.target.files.length !== 0){
			const config = {
				headers: [
					{
						name: 'truck_no',
						inputName: 'truck_no',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'gc_waybill_no',
						inputName: 'gc_waybill_no',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'gc_date',
						inputName: 'gc_date',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'shipment_completion_date',
						inputName: 'shipment_completion_date',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'shipping_type',
						inputName: 'shipping_type',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'consignee_name',
						inputName: 'consignee_name',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'consignee_pincode',
						inputName: 'consignee_pincode',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'allowable_weight',
						inputName: 'allowable_weight',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'per_kg_slab_rate',
						inputName: 'per_kg_slab_rate',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'basic_trans_cost',
						inputName: 'basic_trans_cost',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
	
				]
			}
			var data = CSVFileValidator(e.target.files[0], config)
				.then((csvData) => {
					console.log('csvData', csvData)
					this.setState({
						manual_csvcontent: csvData.data
					});
					let keys = Object.keys(csvData.data[0])
                let items = ['truck_no','gc_waybill_no','gc_date','shipment_completion_date','shipping_type','consignee_name','consignee_pincode','allowable_weight','per_kg_slab_rate','basic_trans_cost']
                let check = false
                for (let i in items){
                    if(keys.includes(items[i])){                 
                    }else{
                        check = true
                        break
                    }
                }
                // console.log("check", check)
                if (check === false) {
                    $("#uploadFile").val("")
                    // document.getElementById("appednCSVData").innerHTML = ''
                    this.setState({
                        show: true,
                        basicTitle: 'Upload a CSV file with fields matching the provided template',
                        basicType: 'danger'
                    });
                } else {
                    this.setState({
                        manual_csvcontent: csvData.data
                    });
                }
				})
				.catch(err => { })
			// console.log("e.target.files[0]",e.target.result);
			var contentList = []
			var out = new Promise(function (reject, resolve) {
				var reader = new FileReader();
				reader.onload = async function (e) {
					var contents = await e.target.result;
					// document.getElementById("appednCSVData").innerHTML = contents
					resolve(contents);
				};
				if(e.target.files){
					var tt = reader.readAsText(e.target.files[0]);
				}
			});
			
			// console.log(contentList);
			this.setState({
				manual_file: e.target.files[0]
			});
			// console.log("e.target.files[0].type ", e.target.files[0]);
	
			if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
				// console.log(fileData);
				// console.log(typeof(fileData))
	
			}
			else {
				e.target.value = null;
				this.setState({
					uploadFile: '',
					file: "",
					show: true,
					basicType: 'danger',
					basicTitle: 'Please upload file having extensions .csv only.',
				});
			}

		}
	}

	onClickUploadData = () => {
		$("#uploadFile").val("")
		this.setState({
			shipment_slider: "slider-translate-40p", // slide30
			overly: "show-m",
		})
	}
	onClickUploadManualData = () => {
		this.setState({
			manual_upload_slider: "slider-translate-60p",
			overly: "show-m",
			
		})
	}

	uploadBulkFormHandler = e => {
		e.preventDefault()
		
		this.setState({
			loadshow: "show-m"
		})
		const { shipment_csvcontent } = this.state
		//  console.log("shipment_csvcontent", shipment_csvcontent)
		redirectURL.post("/consignments/uploadBulkShipmentData", shipment_csvcontent,{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem("userid")
			}
		}).then(res => {
			//  console.log("response", res)
			 $("#UploadFile").val("")
			this.setState({
				shipment_slider: "show-n",
				shipment_csvcontent: [],
				file: '',
				show: true,
				overly : 'show-n',
				loadshow: 'show-n',
				basicType: 'info',
				basicTitle: 'documents uploaded, data would be reflected after few minutes'

			})
		
		})
	}

	uploadManualBulkFormHandler = () => {
		//e.preventDefault()
		const { gc_way_bill_No_sel } = this.state

		if (gc_way_bill_No_sel.value !== "") {
			let params = {
				gc_way_bill_No_sel: gc_way_bill_No_sel.value,
				
			}
			redirectURL.post("/consignments/uploadBulkManualData", params,{
				headers: {
					'Content-Type': 'application/json',
					'Authorization': localStorage.getItem("userid")
				}
			}).then(res => {
				//  console.log("response", res)
				if (res.data.message === 'success') {
					$("#manualUploadFile").val("")
					 document.getElementById("appednCSVData").innerHTML = ''
					this.setState({
						manual_upload_slider: "show-n",
						manual_csvcontent: [],
						manual_file: '',
						show: true,
						overly: 'show-n',
						basicType: 'info',
						basicTitle: 'documents uploaded, data would be reflected after few minutes'
					})
				}
			})
			// console.log("params", params)
		}

		this.setState({
			manual_upload_slider: "show-n",
			manual_csvcontent: [],
			manual_file: '',
			show: true,
			overly: 'show-n',
			basicType: 'info',
			basicTitle: 'Maunal order uploaded successfully, data would be reflected after few minutes'
		})
	}
	
	FailedManualOrder = (e) => {
		this.setState({
			manual_upload_slider: "show-n",
			manual_csvcontent: [],
			manual_file: '',
			show: true,
			overly: 'show-n',
			basicType: 'danger',
			basicTitle: 'Failed to create manual orders'
		})
	}

	setSelectedGcWayNo = (e) => {
		// console.log("item", e)
		this.setState({
			gc_way_bill_No_sel: e
		})
	}

	onCloseSliderDiv = () => {
		
		this.setState({
			manual_upload_slider: 'show-n',
			bulkMarkDeliverSlide: 'show-n',
			shipment_slider: "show-n",
			shipment_csvcontent: [],
			shipment_file: '',
			slidershow: "show-n",
			bulkslidershow: "show-n",
			sliderSupportshow: 'show-n',
			overly: "show-n",
			selected_row_nodes: [],
			loadshow: 'show-n',
			overly: 'show-n',
			multiplePodDocs: [],
			update_row_slider:"",
		});
	};

	changeMDHandler = async (e) => {
		//console.log(e.target.files[0])
	   if(e.target.files[0] !== 0){
			var dt = '';
			// const config = {
			// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
			// }

			const config = {
				headers: [
					{
						name: 'consignment_code',
						inputName: 'consignment_code',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'delivery_date',
						inputName: 'delivery_date',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
				]
			}
			var data = CSVFileValidator(e.target.files[0], config)
				.then((csvData) => {
					let keys = Object.keys(csvData.data[0])
                    let items = ['consignment_code','delivery_date']
                    let check = false
                    if(csvData.inValidMessages.length === 0)
                    {
                        for (let i in keys){
                            //  console.log("items.includes(keys[i]) ", items.includes(keys[i]))
                            if(items.includes(keys[i])){                 
                                check = true             
                            }else{
                                check = false
                                break
                            }
                        }
                    }
                    else{
                        check = false
                        
                    }
					if(check === false){
						$("#bulkUploadMDID").val("")
						this.setState({
							show: true,
							basicTitle: 'Upload a CSV file with fields matching the provided template',
							basicType: 'danger'
						});
					}
					else{
						this.setState({
							mdCsvContent: csvData.data
						});
					}
					
				})
				.catch(err => { })
			// console.log("e.target.files[0]",e.target.result);
			var contentList = []

			var out = new Promise(function (reject, resolve) {
				var reader = new FileReader();
				reader.onload = async function (e) {
					var contents = await e.target.result;
					resolve(contents);
				};
				var tt = reader.readAsText(e.target.files[0]);
			});
			this.setState({
				file: e.target.files[0]
			});
			// console.log("e.target.files[0].type ", e.target.files[0]);

			if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
				// console.log(fileData);
				// console.log(typeof(fileData))

			}
			else {
				e.target.value = null;
				this.setState({
					uploadFile: '',
					file: "",
					show: true,
					basicType: 'danger',
					basicTitle: 'Please upload file having extensions .csv only.',
				});
			}
	    }

	}
	changePriorityFileHandler = async (e) => {
		var dt = '';

		const config = {
			headers: [
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					pCsvContent: csvData.data
				});
			})
			.catch(err => { })
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
		});
		this.setState({
			file: e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
		}
		else {
			e.target.value = null;
			this.setState({
				uploadFile: '',
				file: "",
				show: true,
				basicType: 'danger',
				basicTitle: 'Please upload file having extensions .csv only.',
			});
		}

	}
	onClickShowDeliverPopup = (params) => {
		var containerslist = []
		var event = params
		var deliverStatusValue;
		if (event.data.status == 1) {
			deliverStatusValue = "Inside Plant"
		}
		else if (event.data.status == 2) {
			deliverStatusValue = "In Transit"
		}
		else if (event.data.status == 3) {
			deliverStatusValue = "Near Destination"
		}
		else if (event.data.status == 4) {
			deliverStatusValue = "Reached Destination"
		}

		else if (event.data.status == 5) {
			deliverStatusValue = "Left Destination"
		}
		else if (event.data.status == 6) {
			deliverStatusValue = "Force Closed"
		}
		else {
			deliverStatusValue = ""
		}
		// console.log(event.data);
		this.setState({
			containerslist: containerslist,
			deliveryStatus: event.data.status,
			deliverStatusValue: deliverStatusValue,
			distance_from_dealer_location: event.data.distance_from_dealer_location,
			deliveredDeptCode: event.data.dept_code,
			deliveredTruckNo: event.data.truck_no,
			selectedRow: event.data
		})
		this.setState({
			delivermodal: true,
			deliverrow: params,
			deliverdate: ''
		});
	}
	submitForm = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		// var fromdate = document.getElementById("from_date").value;
		// var todate = document.getElementById("to_date").value;

		var fromdate = this.state.from_date
		var todate = this.state.to_date
		var transporter = this.state.flTransporter
		
		if (fromdate != "" && fromdate != undefined && todate != "" && todate != undefined) {
			this.setState({
				loadshow: 'show-m'
			});
			if (this.state.consigner == null) {
				var consigners = [{ "value": "all" }]
			}
			else {
				var consigners = this.state.consigner;
			}
			// if(fromdate == '' && todate == '')
			// {
			// 	var reqparasm = {
			// 		from_date:fdate,
			// 		to_date:tdate,
			// 		consigner_code:[{"value":"all"}],
			// 	}
			// }
			// else
			// {
			// console.log("tdate ",tdate)
			// var consignsarr = [];
			// if(this.state.consigner.length > 0)
			// {
			// 	this.state.consigner.map((item) => {
			// 		consignsarr.push(item.value)
			// 	})
			// }
			// else{
			// 	consignsarr.push({"value":'all'})
			// }
			var serviceProviders = [];
			if (localStorage.getItem("user_type") == "TRANSPORTER") {
				serviceProviders = JSON.parse(localStorage.getItem('supplier_code'))
			}
			if(consigners.length > 0)
			{
				var consignerslist = consigners;
			}
			else{
				var consignerslist = [{value:"all",label:"All"}]
			}
			var reqparams = {
				from_date: fromdate,
				to_date: todate,
				consigner_code: consignerslist,
				serviceProviders: serviceProviders,
				transporter:serviceProviders,
				is_filter_call: 1
			}
			if(![undefined , null , "undefined" , ""].includes(this.state.sac_codes)){
				if(this.state.sac_codes.length > 0){
					reqparams.sac_code = this.state.sac_codes
				}
			}
			if (transporter !== "" && transporter !== undefined && transporter !== null) {
				if (transporter.value != "") {
					// console.log("transporter value", transporter.value)
					// parameters['transporter'] = [transporter.label]
					reqparams['transporter'] = [transporter.value]
				}
			}
			//}
			// console.log("reqparams ", reqparams)
			var urlpath;
			if (this.props.match.path == "/courierconsignments") {
				urlpath = '/consignments/filtercouriers';
			}
			else if (this.props.match.path == "/activecourierconsignments") {
				urlpath = '/consignments/getFilteredActiveCourierDataByParams';
			}
			else if (this.props.match.path == "/deliveredcourierconsignments") {
				urlpath = '/consignments/filterdelivercouriers';
			}

			this.onLoadShowCouriers(reqparams)

			// redirectURL.post(urlpath,reqparasm)
			// .then((response)=>{
			// 	console.log("Resposne ", response.data)
			// 	var records = response.data;
			// 	//console.log("records", records);
			// 	var intransitarr=[];
			// 	var outdeliverarr = [];
			// 	var deliverarr = [];
			// 	var datanaarr = [];
			// 	records.map((item) => {

			// 		//In Transit
			// 		if(item.consignment_status == 'In Transit')
			// 		{
			// 			intransitarr.push(item);
			// 		}

			// 		//Out of Delivery
			// 		if(item.consignment_status == 'Out for delivery')
			// 		{
			// 			outdeliverarr.push(item);
			// 		}

			// 		//Delivered
			// 		if(item.consignment_status == 'Delivered')
			// 		{
			// 			deliverarr.push(item);
			// 		}

			// 		//Data Not Available
			// 		if(item.consignment_status == '' || item.consignment_status == null)
			// 		{
			// 			datanaarr.push(item);
			// 		}


			// 	});
			// 	let newArray = []; 

			// 	// Declare an empty object 
			// 	let uniqueObject = {}; 
			// 	var objTitle;
			// 	// Loop for the array elements 
			// 	for (let i in records) { 

			// 		// Extract the title 
			// 		objTitle = records[i]['consigner_code']; 

			// 		// Use the title as the index 
			// 		uniqueObject[objTitle] = records[i]; 
			// 	} 

			// 	// Loop to push unique object into array 
			// 	for (var a in uniqueObject) { 
			// 		newArray.push(uniqueObject[a]); 
			// 	} 

			// 	//console.log("newArray ", newArray)
			// 	var consigners=[];
			// 	newArray.map((ccode) => {
			// 		consigners.push(ccode.consigner_code)
			// 	})
			//     this.setState({
			// 		consigners:consigners,
			// 		rowData:records,
			// 		intransit:intransitarr,
			// 		outdeliver:outdeliverarr,
			// 		deliver:deliverarr,
			// 		datana:datanaarr,
			// 		intransitcount:intransitarr.length,
			// 		outdelivercount:outdeliverarr.length,
			// 		delivercount:deliverarr.length,
			// 		datanacount:datanaarr.length,
			// 		totalcount:records.length,
			// 		loadshow:'show-n'
			//     });
			// })
			// .catch(function(e){
			// 	console.log("Error ", e)
			// })
		}
		else {
			this.setState({
				show: true,
				basicType: "warning",
				basicTitle: "Please select dates to continue",
				from_date: fromdate,
				to_date: todate,
			});
		}
	}
	getTruckConsignments = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Search for truck/consignment",
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var selectedFilter = $("#selectedFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if (selectedFilter == "Select..." || inputTruckConsignment == "") {
			this.setState({
				basicTitle: "Fields Should not be Empty",
				basicType: "danger",
				show: true
			})
		}
		else {
			// console.log(selectedFilter, inputTruckConsignment);
			var urlpath = ""
			if (this.props.match.path == "/activecourierconsignments") {
				urlpath = "/consignments/filterActiveCourierByConsignment";
				var pageurl = "/activecourierconsignments";
				var counttype = 'active'

			}
			if (this.props.match.path == "/deliveredcourierconsignments") {
				urlpath = "/consignments/filterActiveCourierByConsignment";
				var pageurl = "/deliveredcourierconsignments";
				var counttype = 'deliver'

			}
			if (this.props.match.path == "/courierconsignments") {
				urlpath = "/consignments/filterActiveCourierByConsignment";
				var pageurl = "/courierconsignments";
				var counttype = 'all'
			}
			if (this.props.match.path == "/invalidcourierconsignments") {
				urlpath = "/consignments/filterActiveCourierByConsignment";
				var pageurl = "/invalidcourierconsignments";
			}
			redirectURL.post(urlpath,
				{
					"selectedFilter": selectedFilter,
					"inputTruckConsignment": inputTruckConsignment,
					"urlpath": pageurl
				},{
					headers: {
						'Content-Type': 'application/json',
						'Authorization': localStorage.getItem("userid")
					}
				})
				.then((response) => {
					// console.log("getTruckConsignments", response)
					var records = response.data;
					var intransitarr = [];
					var priorityarr = [];
					var outdeliverarr = [];
					var deliverarr = [];
					var undeliverarr = [];
					var forcearr = [];
					var datanaarr = [];
					var confirmDelivery = [];
					var pod_received_data = [];
					var trip_closed_by_pod = [];
					var cancelled_data = [];
					var today = new Date();
					var dd = String(today.getDate()).padStart(2, '0');
					var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
					var yyyy = today.getFullYear();
					today = yyyy + '-' + mm + '-' + dd;
					records.map((item) => {
						if (item.type_of_delivery == "ODA") {
							item.oda_time = 1
						} else {
							item.oda_time = 0
						}
						item.cutOffDays = 1;
						if (item.revised_eta == "" || item.revised_eta == undefined) {
							// item.elapsed_transit_time = differenceInDays(today, item.gc_date);
							item.elapsed_transit_time = differenceInDays(today, item.gc_date);
							item.ontime_delayed_status = "Delayed";
						} else {
							item.elapsed_transit_time = differenceInDays(item.revised_eta, item.gc_date)
							if (moment.parseZone(item.revised_eta).startOf('day') - moment.parseZone(item.expected_eta).startOf('day') <= 0) {
								item.ontime_delayed_status = "On Time";
								
							}
							 else {
								item.ontime_delayed_status = "Delayed";
							}
						}

						item.expected_eta = moment.parseZone(item.gc_date).add(item.transit_time_days, 'days');

						if (item.consignment_status == "" || item.consignment_status == undefined) {
							item.consignment_status = "In Transit"
						}
						try{
						var idx = this.state.plants.findIndex(p => p.plant_code == item.consigner_code);
						// console.log("idx",idx);
					   if( this.state.plants[idx]["plant_name"] !== undefined)
							{
								item.plant_name = this.state.plants[idx]["plant_name"]
							}
							
						}catch(e){
							console.log("error",e)
						}
						//In Transit
						if (['PARTIALLY DELIVERED', "Partially Delivered", 'In Transit', 'IN TRANSIT', 'TRANSIT', 'DESTINATION ARRIVED', 'UNDELIVERED'].includes(item.consignment_status)) {
							intransitarr.push(item);
						}

						//Out of Delivery
						if (item.consignment_status == 'Out For Delivery' || item.consignment_status == 'OUT FOR DELIVERY') {
							outdeliverarr.push(item);
						}

						//Delivered
						if (['DELIVERED', "Delivered", "Cancelled", "CANCELLED",].includes(item.consignment_status)) {
							deliverarr.push(item);
						}
						if(item.consignment_status == 'UnDelivered' || item.consignment_status == 'UnDelivered'){
							undeliverarr.push(item)
						}

						if (item.consignment_status == 'Force Closed' || item.consignment_status == 'FORCE CLOSED') {
							forcearr.push(item);
						}
						//Data Not Available
						if (item.consignment_status == "Invalid Docket" || item.consignment_status == "Invalid Docket" || item.consignment_status ===  "Invalid docket") {
							datanaarr.push(item);
						}
						if (item.tpt_confirm_delivery == 1) {
							confirmDelivery.push(item);
						}
						//Priority
						if (item.is_priority == 1) {
							priorityarr.push(item)
						}
						//POD
						if (item.pod_received == 1) {
							pod_received_data.push(item)
						}
						//POD TRIP CLOSED
						if (item.trip_closed_by_pod == 1) {
							trip_closed_by_pod.push(item)
						}
						//Cancelled
						if (item.consignment_status == 'CANCELLED' || item.consignment_status == 'Cancelled') {
							cancelled_data.push(item);
						}
					});
					let newArray = [];
					// console.log("item.consignment_status",outdeliverarr)
					// Declare an empty object 
					let uniqueObject = {};
					var objTitle;
					// Loop for the array elements 
					for (let i in records) {

						// Extract the title 
						objTitle = records[i]['consigner_code'];

						// Use the title as the index 
						uniqueObject[objTitle] = records[i];
					}

					// Loop to push unique object into array 
					for (var a in uniqueObject) {
						newArray.push(uniqueObject[a]);
					}

					//console.log("newArray ", newArray)
					var consigners = [];
					newArray.map((ccode) => {
						consigners.push(ccode.consigner_code)
					})
					//console.log("records ", records)
					if (this.props.match.path == "/invalidcourierconsignments") {
						var allrecords = datanaarr;
					}
					if(this.props.match.path == "/courierconsignments"){
						var allrecords = records
					}
					else {
						var allrecords = records.filter(item => item.consignment_status != "CANCELLED")
					}
					var transitna_data = allrecords.filter(e => {
						if (e.transit_remark === "NA") {
							return e
						}
					})
					var on_time_data = allrecords.filter(e => {
						if (new Date(e.delivery_datetime) <= new Date(e.expected_eta)) {
							return e
						}
					})
					var delayed_data = allrecords.filter(e => {
						if(e.transporter_code == "7402033")
						{

						}
						else{
							if (new Date(e.delivery_datetime) > new Date(e.expected_eta)) {
								return e
							}
						}
					})

					let booked_data = allrecords.filter(e => e.consignment_status == 'BOOKED')
					let force_closed = allrecords.filter(e => e.consignment_status == 'FORCE CLOSED')
					// console.log("cancelled_data",cancelled_data)
					this.setState({
						rowData: allrecords,
						originaldata: allrecords,
						intransit: intransitarr,
						outdeliver: outdeliverarr,
						deliver: deliverarr,
						datana: datanaarr,
						prioritydata: priorityarr,
						undeliver : undeliverarr,
						intransitcount: intransitarr.length,
						outdelivercount: outdeliverarr.length,
						delivercount: deliverarr.length,
						undeliverCount:undeliverarr.length,
						datanacount: datanaarr.length,
						totalcount: allrecords.length,
						prioritydatacount: priorityarr.length,
						confirmDelivery: confirmDelivery,
						pod_received_data: pod_received_data,
						trip_closed_by_pod: trip_closed_by_pod,
						cancelled_data: cancelled_data,
						loadshow: 'show-n',
						counttype,
						on_time_data,
						transitna_data,
						delayed_data,
						booked_data,
						force_closed
					});
					// this.setState({
					// 	rowData : response.data,
					// })
				}).catch = ((e) => {
					console.log(e);
				})
		}
	}

	onClickSaveGridState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();

		let screenpage = '';

		if (this.props.match.path == "/courierconsignments") {
			screenpage = 'transporter courier consignments (all)';
		}
		else if (this.props.match.path == "/activecourierconsignments") {
			screenpage = 'transporter courier consignments (active)';
		}
		else if (this.props.match.path == "/deliveredcourierconsignments") {
			screenpage = 'transporter courier consignments (delivered)';
		}
		else if (this.props.match.path == "/invalidcourierconsignments") {
			screenpage = 'transporter courier consignments (invalid)';
		}

		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams,{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem("userid")
			}
		})
			.then((response) => {
				//console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}

	restoreGridStates() {
		try {


			if (this.state.usergridstate.length > 0) {
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			}
		}
		catch (e) { }
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams,{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem("userid")
			}
		})
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};
	onCellClicked = async (e) => {
		this.setState({
			detailCellRendererParams : {}
		})
		if (e.colDef.field == "priority") {
			if (e.data.is_priority != 1) {
				this.setState({
					loadshow: "show-m",
					overly: "show-m",
				});
				var consignmentId = e.data.consignment_code;
				var email = localStorage.getItem("email");
				var reqParams = {
					consignmentId: consignmentId,
					email: email
				}
				redirectURL.post("/consignments/updateCourierPriority", reqParams,{
					headers: {
						'Content-Type': 'application/json',
						'Authorization': localStorage.getItem("userid")
					}
				}).then((response) => {
					this.setState({
						loadshow: "show-n",
						overly: "show-n",
						basicPriorityTitle: "Consignment Marked As Priority",
						basicPriorityType: "success",
						showPriority: true,
					});
				}).catch((e) => {
					console.log(e)
				})
			}
		}
		if (e.colDef.field == 'gc_waybill_no') {
			const data = e.data
			// console.log("cellClicked", data)
			// if (data.manual_data) {
				await this.setState({
					detailCellRendererParams: {
						detailGridOptions: {
							columnDefs: [
								{
									headerName: "Truck No",
									field: "truck_no",
									filter: true,
									resizable: true
								},
								{
									headerName: "Gc WayBill No",
									field: "gc_waybill_no",
									filter: true,
									resizable: true
								},
								{
									headerName: "Gc Date",
									field: "gc_date",
									// valueGetter: function (params) {
									// 	// console.log("IN onShowCourierTrack, params.data = ", typeof(params.data.intransit_date));
									// 	// var intransitdate=getDDMMYYYY(params.data.intransit_date)+" "+params.data.intransit_time
									// 	//    var intransitdate= params.data.intransit_date +" "+ params.data.intransit_time
									// 	//    return intransitdate
									// 	if ((params.data.intransit_date).includes('Z')) return getHyphenDDMMMYYYYHHMM(params.data.intransit_date)
									// 	return (params.data.intransit_date)
									// },
									filter: true,
									resizable: true,
									valueGetter: function (params) {
										return getHyphenDDMMMYYYY(params.data.gc_date)
									},
								},
								{
									headerName: "Customer name",
									field: "consignee_name",
									filter: true,
									resizable: true
								},
								{
									headerName: "Customer Pincode",
									field: "consignee_pincode",
									filter: true,
									resizable: true
								},
								{
									headerName: "Allowable Weight",
									field: "allowable_weight",
									filter: true,
									resizable: true
								},
								{
									headerName: "Basic Trans Cost",
									field: "basic_trans_cost",
									filter: true,
									resizable: true
								},
								{
									headerName: "Per Kg Slab Rate",
									field: "per_kg_slab_rate",
									filter: true,
									resizable: true
								},
								{
									headerName: "Shipping Type",
									field: "shipping_type",
									filter: true,
									resizable: true
								}
							]
						},
						getDetailRowData: function (params) {
							// console.log("Step 4 ", data)
							let reqParam = {
								gc_waybill_no : data.gc_waybill_no
							}
							// console.log("req",reqParam)
							redirectURL.post('/consignments/getManualCourierCons', reqParam,{
								headers: {
									'Content-Type': 'application/json',
									'Authorization': localStorage.getItem("userid")
								}
							}).then(async (response) => {
									// console.log("Step 1 ",response.data)
									var manualData = response.data;
									// console.log("manualData ", manualData);
									try {
										
									} catch (e) {
										manualData = []
									}
									// console.log("manualDataAfter ", manualData);
	
									if (manualData.length == 0) {
										params.successCallback([]);
									}
									else {
										params.successCallback(manualData);
									}	
									//console.log("Step 2 ",response.data)	
								});
						},
						masterDetail: true
					}
				});
				//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
				//console.log("params.column.colId ", params.column)
				if (e.column.colDef.field == 'gc_waybill_no') {
					e.node.setExpanded(!e.node.expanded);
				}
				else {
					e.node.setExpanded(false);
				}
		}
		if(e.colDef.field == 'view'){
			this.setState({
				update_row_slider: "slider-translate-30p",
				overly: "show-m",
				row_data : e.data,
			})
		}
	}
	newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}
	onCellEdit = async (node) => {
		const colId = node.colDef.field;
        var updateCheck = 0;
        var clickedValue = node.value;
        if (clickedValue >= 0 && clickedValue != '' && clickedValue != undefined) {
            updateCheck = 1;
        }
        if (updateCheck == 1) {
            var rowData=[];
            this.gridApi.forEachNode((e) => {
                var a = (e.data.allowable_weight !== "" && e.data.allowable_weight !== undefined)?e.data.allowable_weight:0;
                var b = (e.data.per_kg_slab_rate !== "" && e.data.per_kg_slab_rate !== undefined)?e.data.per_kg_slab_rate:0;
                e.data.basic_trans_cost = parseFloat(a)*parseFloat(b);
                rowData.push(e.data)
            })
			setTimeout(() => {
				this.setState({
					produceRowData:rowData
				});
				this.gridApi.refreshCells();
			},600)
           
        }
    }
	onClickTriggerParentFn(){
        setTimeout(() => {
            let rownodes = [];
            this.gridApi.forEachNode(node => {
                if(node.data.consignee_name !== "" && node.data.consignee_name !== undefined)
                {
                    var cc = node.data.consignee_name.split(" - ");
                    node.data.consignee_code = cc[1];
                    node.data.consignee_name = cc[0];
                    var allcustomers = this.props.customers;
                    if(allcustomers.length > 0)
                    {
                        var customer = allcustomers.filter((f) => f.consignee_code === cc[1])
                        if(customer.length > 0)
                        {
                            // console.log("customer ", customer)
                            node.data.consignee_pincode = customer[0].postal_code
                            node.data.consignee_city = customer[0].consignee_city
                            node.data.consignee_state = customer[0].consignee_state
                            node.data.consignee_zone = customer[0].consignee_zone
                        }
                        
                    }
                }
                if(node.data.shipping_type !== "" && node.data.shipping_type !== undefined)
                {
                    if(node.data.shipping_type === "surface"){
                        node.data.shipping_type_code = "05";
                    }
                    if(node.data.shipping_type === "air"){
                        node.data.shipping_type_code = "07";
                    }
                    if(node.data.shipping_type === "train"){
                        node.data.shipping_type_code = "22";
                    }
					if(node.data.shipping_type === "Part Load"){
                        node.data.shipping_type_code = ["48","47"];
                    }
                }
                rownodes.push(node.data)
            });
            var params ={
                rownodes: JSON.stringify(rownodes)
            }
            // console.log("params ", params)
            redirectURL.post("/consignments/uploadBulkManualData",params,{
				headers: {
					'Content-Type': 'application/json',
					'Authorization': localStorage.getItem("userid")
				}
			})
            .then((response) => {
                // this.props.context.onClickSaveProduceItems();
            })
        },500)
        
    }
	addItemRow(){
        var row = [{
            truck_no:"",
            gc_waybill_no:"",
            gc_date:"",
            consignee_name:"",
            allowable_weight:"",
            per_kg_slab_rate:"",    
            basic_trans_cost:""
        }]
        this.gridApi.updateRowData({
            add: row
        });
    }
	onGridReady1 = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };

	onShowCourierInvalidDocket(rownode){
		// console.log("Rownd ", rownode);
		this.setState({
			sliderInvalidDocket:"slider-translate-30p",
			overly:"show-m",
			invalidRowItem:[rownode.data],
			invalid_gc_waybill:""
		})
	}
	onShowCourierTransitTime(rownode){
		 console.log("Rownd ", rownode);
		this.setState({
			sliderTransitNa:"slider-translate-30p",
			overly:"show-m",
			transitRowNa:[rownode.data],
			transit_days:""
		})
	}
	// changeHandler(event){
	// 	this.setState({
	// 		[event.target.name] : event.target.value
	// 	})
	// }
	changeHandler = (event) => {
        let name = event.target.name;
        const inputValue = event.target.value;
        console.log(inputValue, "inputValue")
        const characterRegex = /^[0-9]*$/;
        console.log(!characterRegex.test(inputValue), "910")
        if (!characterRegex.test(inputValue)) {
            if (inputValue == "") {
                this.setState({
                    [name]: "",
                });
            }
        } else {
            this.setState({
                [name]: inputValue,
            });
        }
    }
	changeHandler2 = (event) => {
        let name = event.target.name;
        const inputValue = event.target.value;
        console.log(inputValue, "inputValue")
        const characterRegex = /^[A-Za-z0-9]*$/;
        console.log(!characterRegex.test(inputValue), "910")
        if (!characterRegex.test(inputValue)) {
            if (inputValue == "") {
                this.setState({
                    [name]: "",
                });
            }
        } else {
            this.setState({
                [name]: inputValue,
            });
        }
    }
	onClickupdateTransitTime(event){
		event.preventDefault();
		var transit_days = this.state.transit_days;
		// var region_code = this.state.region_code;
		// var plant_code = this.state.plant_code;
		// var transit_days = this.state.transit_days;
		var row = this.state.transitRowNa;

		// console.log("row",row)
		if(transit_days !== "" && transit_days !== undefined){
			if(transit_days >0){
				var params = {
					transit_days:transit_days,
					gc_waybill_no:row[0].gc_waybill_no,
					region_code:row[0].region_code,
					consigner_code:row[0].consigner_code,
					// transit_days:row[0].transit_days
				}
						
				// console.log("params",params)
				redirectURL.post("/couriers/updateTransitTime",params)
					.then((response) =>{
						if(response.data.status === "success"){

							this.setState({
								sliderTransitNa:"",
								overly:"show-n",
								transit_days:"",
								transitRowNa:[],
								show:true,
								basicType:"success",
								basicTitle:"successfully  updated"
							})
							var serviceProviders = [];
							if (localStorage.getItem("user_type") == "TRANSPORTER") {
								serviceProviders = JSON.parse(localStorage.getItem('supplier_code'))
							}
						
							var preqparams = {
								from_date: this.state.from_date,
								to_date: this.state.to_date,
								consigner_code: [{ "value": 'all', "label": "All" }],
								serviceProviders: serviceProviders,
								transporter:serviceProviders,
								is_filter_call: 0
							}
							this.onLoadShowCouriers(preqparams);
						}else{
							this.setState({
								sliderInvalidDocket:"",
								overly:"show-n",
								transitRowNa:[],
								transit_days:"",
								show:true,
								basicType:"success",
								basicTitle:"Failed to update"
							})

						} 
					})
			}
			
		}
		else{
			this.setState({
				sliderInvalidDocket:"",
				overly:"show-n",
				transitRowNa:[],
				transit_days:"",
				show:true,
				basicType:"success",
				basicTitle:"Please enter valid transit days"
			})

		}


	}
	updateInvalidDocketGCWayBill(event)
	{
		event.preventDefault();
		var invalid_gc_waybill = this.state.invalid_gc_waybill;
		// console.log("len invalid_gc_waybill ", invalid_gc_waybill.length);
		var rowitem = this.state.invalidRowItem;
		// console.log("invalid_gc_waybill ", invalid_gc_waybill)
		// console.log("rowitem ", rowitem)
		if(invalid_gc_waybill !== "" && invalid_gc_waybill.length !== undefined)
		{
			if(invalid_gc_waybill.length <=12)
			{
				var params = {
					invalid_gc_waybill:invalid_gc_waybill,
					consignment_code:rowitem[0].consignment_code,
					old_gc_waybill_no:rowitem[0].gc_waybill_no
				}
				redirectURL.post("/couriers/updateGCWaybillToInvalidDocket",params)
				.then((response) => {
					if(response.data.status === "success")
					{
						this.setState({
							sliderInvalidDocket:"",
							overly:"show-n",
							invalidRowItem:[],
							invalid_gc_waybill:"",
							show:true,
							basicType:"success",
							basicTitle:"Successfully Update"
						})
						var serviceProviders = [];
						if (localStorage.getItem("user_type") == "TRANSPORTER") {
							serviceProviders = JSON.parse(localStorage.getItem('supplier_code'))
						}

						var preqparams = {
							from_date: this.state.from_date,
							to_date: this.state.to_date,
							consigner_code: [{ "value": 'all', "label": "All" }],
							serviceProviders: serviceProviders,
							transporter:serviceProviders,
							is_filter_call: 0
						}
						this.onLoadShowCouriers(preqparams);
					}
					else{
						
						this.setState({
							sliderInvalidDocket:"",
							overly:"show-n",
							invalidRowItem:[],
							invalid_gc_waybill:"",
							show:true,
							basicType:"success",
							basicTitle:"Failed to update"
						})
					}
				})
				console.log("arpasa ", params);
			}
			else{
				this.setState({
					sliderInvalidDocket:"",
					overly:"show-n",
					invalidRowItem:[],
					invalid_gc_waybill:"",
					show:true,
					basicType:"success",
					basicTitle:"Please enter valid gc waybill no should be less than equals to 12 character / numbers"
				})
			}
		}
		else{
			this.setState({
				sliderInvalidDocket:"",
				overly:"show-n",
				invalidRowItem:[],
				invalid_gc_waybill:"",
				show:true,
				basicType:"success",
				basicTitle:"Please enter valid gc waybill no"
			})
		}
	}
	changeXlsFile(e){
		const config = {
			headers: [
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consigner_code',
					inputName: 'consigner_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},       
				{
					name: 'truck_no',
					inputName: 'truck_no',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},                   
				{
					name: 'gc_waybill_no',
					inputName: 'gc_waybill_no',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},            
				{
					name: 'gc_date',
					inputName: 'gc_date',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'transporter_code',
					inputName: 'transporter_code',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignee_code',
					inputName: 'consignee_code',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'state_code',
					inputName: 'state_code',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'shipping_type',
					inputName: 'shipping_type',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'allowable_weight',
					inputName: 'allowable_weight',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'order_type',
					inputName: 'order_type',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'shipment_completion_date',
					inputName: 'shipment_completion_date',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignee_name',
					inputName: 'consignee_name',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignee_city',
					inputName: 'consignee_city',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignee_state',
					inputName: 'consignee_state',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignee_region',
					inputName: 'consignee_region',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignee_pincode',
					inputName: 'consignee_pincode',
					required: false,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}

				
			  
			]
		}
		
		if (e.target.files[0] !== undefined) {
			var data = CSVFileValidator(e.target.files[0], config)
				.then((csvData) => {
					console.log('csvData', csvData)
					let keys = Object.keys(csvData.data[0])
					let items = ["consignment_code", "consigner_code", "truck_no", "gc_waybill_no", "gc_date", "transporter_code", "consignee_code", "state_code", "shipping_type", "allowable_weight", "order_type", "shipment_completion_date", "consignee_name", "consignee_city", "consignee_state", "consignee_region", "consignee_pincode"]
					let check = false
					if (csvData.inValidMessages.length === 0) {
						for (let i in keys) {
							// console.log("items.includes(keys[i]) ", items.includes(keys[i]))
							if (items.includes(keys[i])) {
								check = true
							} else {
								check = false
								break
							}
						}
					}
					else {
						check = false

					}
					var date_check = true
					if (csvData.data != "") {
						let csvaluesarr = Object.values(csvData.data)
						console.log(csvaluesarr, "csvaluesarr")
						if (csvaluesarr.length > 0) {
							for (var a = 0; a < csvaluesarr.length; a++) {
								var csvalues = Object.values(csvaluesarr[a])
								console.log(csvalues, "csvalues")
								for (var c in csvalues) {
									if (parseInt(c) == 4 || parseInt(c) == 11) {
										var value = csvalues[c].split("-")
										if (value[0].length != 4 && csvalues[c] != "") {
											date_check = false
											break
										}
									}



								}
							}
						}
					}


					if (check === false) {
						$("#uploadFileforTraceandTrace").val("")

						this.setState({
							show: true,
							basicTitle: 'Upload a CSV file with fields matching the provided template',
							basicType: 'danger'
						});

					}
					else if (date_check == false) {
						$("#uploadFileforTraceandTrace").val("")

						this.setState({
							show: true,
							basicTitle: 'Please check the Date format. It should be yyyy-mm-dd hh:mm',
							basicType: 'danger'
						});
					}
					else {
						this.setState({
							csvcontent2: csvData.data
						});
					}
				})
				.catch(err => { })
			// console.log("e.target.files[0]",e.target.result);
			var contentList = []

			var out = new Promise(function (reject, resolve) {
				var reader = new FileReader();
				reader.onload = async function (e) {
					var contents = await e.target.result;
					// document.getElementById("appednCSVData").innerHTML = contents
					resolve(contents);
				};
				var tt = reader.readAsText(e.target.files[0]);
			});
			// console.log(contentList);
			this.setState({
				file: e.target.files[0]
			});
			// console.log("e.target.files[0].type ", e.target.files[0]);

			if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
				// console.log(fileData);
				// console.log(typeof(fileData))

			}
			else {
				e.target.value = null;
				this.setState({
					uploadFileforTraceandTrace: '',
					file: "",
					show: true,
					basicType: 'danger',
					basicTitle: 'Please upload file having extensions .csv only.',
				});
			}
		}
	}
	
	uploadShipmentXLSHandler = e => {
		e.preventDefault()
			 var records = this.state.csvcontent2;
			 console.log("records ", records)
			 if(records.length > 0)
			 {
				var shipment_csvcontent = []
				records.map(e => {
					if(e.gc_waybill_no!=""){
						shipment_csvcontent.push(e)
					}
				})
				console.log(shipment_csvcontent , "shipment_csvcontent")
				redirectURL.post("/consignments/uploadBulkShipmentData", shipment_csvcontent,{
					headers: {
						'Content-Type': 'application/json',
						'Authorization': localStorage.getItem("userid")
					}
				}).then(res => {
					//  console.log("response", res)
					$("#UploadFile").val("")
					this.setState({
						shipment_slider: "show-n",
						shipment_csvcontent: [],
						file: '',
						show: true,
						overly : 'show-n',
						basicType: 'info',
						basicTitle: 'documents uploaded, data would be reflected after few minutes'
		
					})
				
				})
			}
			else{
				this.setState({
					overly: "show-n",
					loadshow: 'show-n',
					basicTitle: "cant insert empty data",
					basicType: "warning",
					show: true
				})
			}
		
	}

	changeFileHandlerForupdate = (event) => {
        // console.log("event.target.files[0] ", event.target.files[0])
        this.setState({
            epodDoc: event.target.files[0]
            
        })
    }

	onUpdateConsignmentDetails = (e) => {
		e.preventDefault()
		
		if (this.state.transit_data_for_update != '') {
			console.log(this.state.status_for_update, "status_for_update")
			if (!["", undefined, "undefined", null].includes(this.state.status_for_update)) {
				if (this.state.status_for_update.value == 'Delivered') {
					if (this.state.delivered_date_for_update != "") {
						var params = {
							"consignment_status": this.state.status_for_update.value,
							"delivery_datetime": this.state.delivered_date_for_update,
							'delivery_station': '',
							// 'last_process_on': new Date(),
							'last_date': this.state.transit_data_for_update,
							'last_location': $("#location_for_update").val(),
							'last_status': this.state.status_for_update.value,
							'row_data':this.state.row_data,
						}
						var rownode = [this.state.row_data]
						var rowid = rownode[0]._id;
						var rownodes = rownode;
						const formData = new FormData();
						formData.append("podDoc", this.state.epodDoc);
						formData.append("delivered_date", this.state.delivered_date_for_update.split(" ")[0]);
						formData.append("rowid", rowid);
						formData.append("rownodes", JSON.stringify(rownodes)); 
						redirectURL.post("/billing/savepoddoc", formData)
						.then((resp) => {
							console.log(resp.data , "5001_checking")
							if (resp.data.status == 'success') {
								$("#epodDocforupdate").val("")
							}
						})
					}
					else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Please Select Delivery Data'
						})
					}
				}
				else {
					var params = {
						"consignment_status": this.state.status_for_update.value,
						'delivery_station': '',
						// 'last_process_on': new Date(),
						'last_date': this.state.transit_data_for_update,
						'last_location': $("#location_for_update").val(),
						'last_status': this.state.status_for_update.value,
						'row_data':this.state.row_data
					}
				}

				console.log(params)
				this.setState({
					loadshow:'show-m',
					overly:'show-m'
				})
				redirectURL.post("/consignments/updateConsignmetsData",params).then(resposne => {
					console.log(resposne.data)
					if(resposne.data.status == 'success'){
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Data Updateded Successfully',
							transit_data_for_update:'',
							status_for_update:'',
							delivered_date_for_update:'',
							loadshow:'show-n',
							overly:'show-n',update_row_slider:'',
                            show_delivered_date: false,
						})
						$("#location_for_update").val("")
						this.componentDidMount()
					}
				})

			}
			else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Please Select Status'
				})
			}
		}
		else {
			this.setState({
				show: true,
				basicType: 'danger',
				basicTitle: 'Please Select Transit Data'
			})
		}

	}

	render() {
		const { loadshow, overly } = this.state;
		var hideonlyacive = (this.props.match.path == "/activecourierconsignments") ? true : false;
		let hideCouriersCheckBox = ((this.props.match.path == "/activecourierconsignments")
			&& (localStorage.getItem("user_type") == "TRANSPORTER")) ? false : true;
		//console.log("Rendere ",this.state.deptcode)
		if (this.state.counttype == "active") {
			var hidden = false
		}
		else {
			var hidden = true
		}
		if (this.state.counttype != "active" && this.state.counttype != "invalid") {
			var reasonHide = false;
		}
		else {
			var reasonHide = true;
		}
		if (localStorage.getItem("user_type") == "TRANSPORTER") {
			hidden = true;
			reasonHide = true;
		}
		const hideCritical = ((this.state.counttype == "active") || (this.state.counttype == "all")) ? false : true;
		var hideupdate = localStorage.getItem("email") == "vkenterpriseghy@ashokleyland.in" ? false : true
		const columnwithDefs = [
			{
				headerName: "",
				field: "_id",
				width: 70,
				pinned: 'left',
				cellRenderer: 'courierActions',
				filter: false,

			},
			{
				headerName: "",
				field: "view",
				width: 130,
				pinned: 'left',
				filter: false,
				hide : true,
				cellRenderer: function (params){
					if(params.data.consignment_status == 'Delivered'){
						return `<button class="btn btn-info" disabled>Update</button>`
					}else{
						return `<button class="btn btn-info">Update</button>`
					}
				}
			},
			
			{
				headerName: "Shipment No.",
				field: "consignment_code",
				width: 115,
				editable: false,
				pinned: 'left',
				filter: "agSetColumnFilter",
				//cellRenderer: "agGroupCellRenderer"
			},
			{
				headerName: "GC/LR/Way Bill No",
				field: "gc_waybill_no",
				width: 115,
				editable: false,
				pinned: 'left',
				filter: "agSetColumnFilter",
				//cellRenderer: "agGroupCellRenderer"
			},
			// {
			// 	headerName: "Invoice No",
			// 	field: "invoice_number",
			// 	width: 115,
			// 	editable: false,
			// 	pinned: 'left',
			// 	filter: "agSetColumnFilter",
			// 	hide:true,
			// 	//cellRenderer: "agGroupCellRenderer"
			// },
			{
				headerName: "GC date",
				field: "gc_date",
				width: 120,
				// filter: "agSetColumnFilter",
				cellRenderer: '',	
				pinned: 'left',
				//  sort: "desc",
				valueGetter: function (params) {
					return getHyphenDDMMMYYYY(params.data.gc_date)
				},
				// filter: "agDateColumnFilter",
				// comparator: dateComparator1,

			},
			{
				headerName: "Shipment Completion Date",
				field: "shipment_completion_date",
				width: 120,
				// pinned: 'left',
				resizable: true,
				hide: hideonlyacive,
				valueGetter: function (params) {
					if (params.data.shipment_completion_date !== "") {
						return getHyphenDDMMMYYYYHHMM(params.data.shipment_completion_date);
					}
				},
			},
			{
				headerName: "Planned Delivery Date",
				field: "estimated_datetime",
				width: 120,
				// pinned: 'left',
				resizable: true,
				valueGetter: function (params) {
					if(params.data.transit_remark === "NA")
					{
						return "NA";
					}
					else{
						
						if(params.data.transporter_code !== "" && params.data.transporter_code !== undefined)
						{
							if(params.data.transporter_code === "7402033")
							{
								return getHyphenDDMMMYYYY(params.data.gc_date);
							}
							else{
								if (params.data.service_provider == "" || params.data.service_provider == undefined) {
									if(![undefined , null , "undefined" , ""].includes(params.data.actual_delivery_date)){
										return getHyphenDDMMMYYYY(params.data.actual_delivery_date);
									}else{
										return getHyphenDDMMMYYYY(params.data.estimated_datetime);
									}
								} else {
									return getHyphenDDMMMYYYY(params.data.estimated_datetime);
								}
							}
						}
						else{
							if (params.data.service_provider == "" || params.data.service_provider == undefined) {
								if(![undefined , null , "undefined" , ""].includes(params.data.actual_delivery_date)){
									return getHyphenDDMMMYYYY(params.data.actual_delivery_date);
								}else{
									return getHyphenDDMMMYYYY(params.data.estimated_datetime);
								}							} else {
								return getHyphenDDMMMYYYY(params.data.estimated_datetime);
							}
						}
					}
					
				},
				//filter: "agDateColumnFilter",
				// comparator: dateComparator,
			},
			{
				headerName: "Status",
				field: "consignment_status",
				width: 150,
				// pinned: 'left',
				filter: "agSetColumnFilter",
				cellStyle: function (params) {
					if (params.value == 'Delivered') {
						//mark police cells as red
						return { color: 'green' };
					} else {
						return null;
					}
				},
				valueGetter: function (params) {
					
					if (params.data.consignment_status == "Cancelled") {
						return "Cancelled As Per Ashok Leyland"
					}
					else {
						return params.data.consignment_status
					}
				
				},
				cellRendererSelector: function(params){
					try {
						if(params.data.consignment_status === "Invalid Docket" || params.data.consignment_status === "Invalid docket")
						{
							return {
								component:"InvalidDocket"
							}
						}
						else{
							return "";
						}
					} catch (error) {
						
					}
				},
			},

			{
				headerName: "Current Status",
				field: "last_status",
				width: 150,
				filter: "agSetColumnFilter",
				// valueGetter:function(params){
				// 	if(typeof params.data.track_last_status != 'undefined')
				// 	{
				// 		if(params.data.track_last_status.length > 0)
				// 		{
				// 			var last_track_status = params.data.track_last_status[0].transit_details.sort(GetSortDescOrder("intransit_date"))
				// 			last_track_status = last_track_status.sort(GetSortDescOrder("intransit_time"))
				// 			console.log("Last ",last_track_status)
				// 		}
				// 	}
				// }
			},
			{
				headerName: "Current Location",
				field: "last_location",
				width: 150,
				filter: "agSetColumnFilter"
			},
			{
				headerName: "Delivery Status",
				field: "ontime_delayed_status",
				width: 130,
				// pinned: 'left',
				filter: "agSetColumnFilter",
				cellRenderer: '',
				cellClass: function (params) {
					if(params.data.transporter_code !== "" && params.data.transporter_code !== undefined)
					{
						if(["Invalid docket","CANCELLED","UnDelivered","Cancelled"].includes(params.data.consignment_status )){
							return ''
						}
						else if(params.data.transit_remark === "NA")
						{
							return 'bgColorDangerMedium'
						}
						else{
							if(params.data.transporter_code === "7402033")
							{
								return 'bgColorSuccessMedium'
							}
							else{
								if (params.data.ontime_delayed_status == "Delayed") {
									return 'bgColorDangerMedium'
								}
								return 'bgColorSuccessMedium'
							}
						}
					}
					else{
						if (params.data.ontime_delayed_status == "Delayed") {
							return 'bgColorDangerMedium'
						}
						return 'bgColorSuccessMedium'
					}
				
				},
				valueGetter:function(params)
				{
					try {
						if(params.data.transit_remark === "NA")
						{
							return "Fixed Transit Days - NA";
						}
						else{
							if(params.data.transporter_code !== "" && params.data.transporter_code !== undefined)
							{
								if(["Invalid docket","CANCELLED","UnDelivered","Cancelled"].includes(params.data.consignment_status )){
									return params.data.consignment_status 
								}
								else if(params.data.transporter_code === "7402033")
								{
									return "On Time";
								}
								else{
									return params.data.ontime_delayed_status;	
								}
							}
							else{
								return params.data.ontime_delayed_status;
							}
						}
					} catch (error) {
						
					}
				}
			},
			{
				headerName: "Actual Delivery Date",
				field: "revised_eta",
				width: 120,
				// pinned: 'left',
				resizable: true,
				hide: !hidden,
				valueGetter: function (params) {
					//console.log(" ETA ", params.data)
					if (['DELIVERED', "Delivered",].includes(params.data.consignment_status)) {

						// if (params.data.revised_eta == '' || params.data.revised_eta == undefined) {
						// 	return getHyphenDDMMMYYYY(params.data.revised_eta);
						// }
						// else {
						// 	return getHyphenDDMMMYYYY(params.data.revised_eta);
						// }

							return getHyphenDDMMMYYYYHHMM(params.data.delivery_datetime);
					}
					else {
						return ""
					}

				},
				//filter: "agDateColumnFilter",
				// comparator: dateComparator,
				//   filterParams: {
				// 	  browserDatePicker: true,
				// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	  // provide comparator function
				// 	  comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		  if(cellValue != "" && cellValue != " "){
				// 			  cellValue = cellValue.split(" ")[0].split("-");
				// 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			  var cellDate = new Date(cellValue);
				// 			  if(filteredDate.getMonth() < 10){
				// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			  }
				// 			  else{
				// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			  }
				// 			  if(filterValue.split("-")[2].length < 2)
				// 			  {
				// 				  var temp = filterValue
				// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			  }
				// 			  var filteredDate = new Date(filterValue);
				// 			  cellDate = cellDate.getTime();
				// 			  filteredDate = filteredDate.getTime();
				// 			  // console.log(cellDate,filteredDate);
				// 			  if(cellDate === filteredDate)
				// 			  {
				// 				  return 0;
				// 			  }
				// 			  if(cellDate < filteredDate){
				// 				  return -1;
				// 			  }
				// 			  if(cellDate > filteredDate)
				// 			  {
				// 				  return 1;
				// 			  }
				// 		  }
				// 	  }
				//   },
				// cellClass: function (params) {
				// 	let hourDifference = 0;
				// 	if (params.data.revised_eta && params.data.expected_eta) {
				// 		let revisedDateOnlyMoment = moment(params.data.revised_eta);
				// 		let expectedDateOnlyMoment = moment(params.data.expected_eta);
				// 		hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
				// 		//console.log("diff= ", params.data.consignment_code, hourDifference);
				// 	} else {
				// 		//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
				// 	}
				// 	if (['DELIVERED', "Delivered"].includes(params.data.consignment_status)) {
				// 		if (hourDifference >= 1) {
				// 			return 'bgColorDangerMedium'
				// 		}

				// 		if (hourDifference <= -1) {
				// 			return 'bgColorSuccessMedium'
				// 		}
				// 		else {
				// 			return ''
				// 		}
				// 	}

				// }
				cellClass: function (params) {
					let dayDifference;
					if (params.data.revised_eta && params.data.expected_eta) {
						let revisedDateOnlyMoment = moment(params.data.revised_eta);
						let expectedDateOnlyMoment = moment(params.data.estimated_datetime);
						dayDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'days');
						// console.log("dayDifference" , dayDifference)
						// console.log("expectedDateOnlyMoment" , expectedDateOnlyMoment)
						//console.log("diff= ", params.data.consignment_code, hourDifference);
					} else {
						//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
					}
					// if(params.data.transporter_code === "7402033")
					// {
						
						if (['DELIVERED', "Delivered"].includes(params.data.consignment_status)) {

							
							
							if (dayDifference > 0 || params.data.transit_remark === "NA" )  {
								return 'bgColorDangerMedium'
							}

							if (dayDifference <= 0 ||  params.data.transit_remark !== "NA" ) {
								return 'bgColorSuccessMedium'
							}
							else {
								return ''
							}
						}
					// }
				}
			},
			{
				headerName: "Plant Code",
				field: "consigner_code",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			{
                headerName: "Plant Name",
                field: "plant_name",
                width: 150,
                filter: "agSetColumnFilter",
                resizable: true,
            },
			{
				headerName: "Plant location",
				field: "consigner_location",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			{
				headerName: 'Transportation Type',
				field: 'courier_type',
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
				
			},
			{
				headerName: "Transporter Code",
				field: "transporter_code",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			{
				headerName: "Transporter Name",
				field: "service_provider",
				width: 135,
				filter: "agSetColumnFilter",
				resizable: true,
				editable: false,
				valueGetter: function (params) {
					return params.data.service_provider;
				},
			},
			{
				headerName: "Vehicle Number",
				field: "truck_no",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			{
				headerName: "Ship to Party",
				field: "consignee_code",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			{
				headerName: "Dealer Name",
				field: "consignee_name",
				width: 130,
				filter: "agSetColumnFilter",
				resizable: true
			},
			{
				headerName: "Dealer City",
				field: "consignee_city",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			{
				headerName: "Dealer State",
				field: "consignee_state",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			{
				headerName: "Dealer Pin Code",
				field: "consignee_pincode",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			{
				headerName: "Shipping Type Description",
				field: "shipping_type",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: '',
				valueGetter:function(params){
                    try {
                        var sdata = params.data.shipping_type; 
                        var sdataoVal = sdata;
                        if(sdata === "05" || sdata === 5)
                        {
                            var sdataoVal = "surface";
                        }
                        if(sdata === "07" || sdata === 7)
                        {
                            var sdataoVal = "air";
                        }
                        if(sdata === "22" || sdata === 22)
                        {
                            var sdataoVal = "train";
                        }
						if(sdata === "48" || sdata === 48 || sdata === "47" || sdata === 47)
                        {
                            var sdataoVal = "Part Truck Load";
                        }
						if(["01", "02", "03", "04", "10", "11", "12", "14", "15", "17", "18", "21", "24", "25",
						"29", "30", "31", "32", "45", "46"].includes(sdata)){
							var sdataoVal = "Full Truck Load"
						}
						if (sdata === "86" || sdata === 86) {
							var sdataoVal = "Adjustment Load";
						}
						if (sdata === "00" || sdata === "06") {
							var sdataoVal = "Over Dimensional Cargo";
						}
						if (sdata === "74" || sdata === 74) {
							var sdataoVal = "Multi Point Delivery";
						}
						if (sdata === "HD") {
							var sdataoVal = "Hand Delivery";
						}
                        return sdataoVal;
                    } catch (error) {
                        
                    }
                }
			},
			{
				headerName: "Shipping Type",
				field: "shipping_type",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: '',
				
			},
			{
				headerName: "Agreed Transit Time (Days)",
				field: "TAT",
				width: 150,
				filter: "agSetColumnFilter",
				valueGetter:function(params){
					try {
						if(params.data.TAT !== "" && params.data.TAT !== undefined && params.data.TAT !== 0 && params.data.TAT !== "0")
						{
							return params.data.TAT;
						}
						else{
							return params.data.transit_remark;
						}
					} catch (error) {
						
					}
				},
				cellRendererSelector: function(params){
					try {
						if(params.data.transit_remark === "NA" || params.data.transit_remark === "NA")
						{
							return {
								component:"UpdateTransitDay"
							}
						}
						else{
							return "";
						}
					} catch (error) {
						
					}
				},
			},
			
			{
				headerName: "Holidays (Days)",
				field: "holiday_count",
				width: 130,
				filter: "agSetColumnFilter",
				// cellRenderer: '',
				valueGetter:function(params){
					try {
						if(params.data.transit_remark === "NA")
						{
							return "NA";
						}
						else{
							var s = (params.data.count_sunday !== "" && params.data.count_sunday !== undefined)?params.data.count_sunday:0;
							var h = (params.data.holiday_count !== "" && params.data.holiday_count !== undefined)?params.data.holiday_count:0;
							var d = parseInt(s)+parseInt(h);
							return d;
						}
					} catch (error) {
						
					}
				}
			},
			{
				headerName: "Grace Time (Days)",
				field: "grace_count",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			// {
			// 	headerName: "Cut Off Time (Days)",
			// 	field: "cutOffDays",
			// 	width: 130,
			// 	filter: "agSetColumnFilter",
			// 	cellRenderer: ''
			// },
			
			{
				headerName: "ODA Time (Days)",
				field: "ODA_TAT",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			{
				headerName: "Total Transit Time (Days)",
				field: "transit_time_days",
				width: 130,
				filter: "agSetColumnFilter",
				valueGetter:function(params){
					try {
						if(params.data.transit_remark === "NA")
						{
							return "NA";
						}
						else{
							return params.data.transit_time_days;
						}
					} catch (error) {
						
					}
				}
			},
			// {
			// 	headerName: "Transit Remarks",
			// 	field: "transit_remark",
			// 	width: 130,
			// 	filter: "agSetColumnFilter",
			// 	cellRenderer: ''
			// },
			
			// {
			// 	headerName: "Elapsed Transit Time (Days)",
			// 	field: "elapsed_transit_time",
			// 	width: 130,
			// 	filter: "agSetColumnFilter",
			// 	cellRenderer: ''
			// },
			{
				headerName: "Destination Type",
				field: "destination_type",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			
			{
				headerName: "Allowable Weight(Kgs)",
				field: "allowable_weight",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			{
				headerName: "Slab Rate Per Kg (Rs)",
				field: "per_kg_slab_rate",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			{
				headerName: "Basic Transportation Cost(Rs)",
				field: "basic_trans_cost",
				width: 130,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			// {
			// 	headerName: "Expected Transit Time (Days)",
			// 	field: "transit_time_days",
			// 	width: 150,
			// 	filter: true,
			// 	resizable: true,
			// 	hide: true,
			// 	editable: false
			// },
			{
				headerName: "Delivered On",
				field: "delivery_datetime",
				hide: hideonlyacive,
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer: '',
				valueGetter: function (params) {
					if (params.data.consignment_status !== 'IN TRANSIT') {
						return getHyphenDDMMMYYYYHHMM(params.data.delivery_datetime)
					}
				},
				//filter: "agDateColumnFilter",
				// comparator: dateComparator,

			},
			{
				headerName: "Delay in Delivery (Days)",
				field: "",
				width: 130,
				valueGetter: function (params) {
					var revisedeta = params.data.revised_eta;
					var originaleta = params.data.estimated_datetime;
					// console.log("Original ETA", originaleta)
					// console.log("Revised ETA", revisedeta)
					
					if (originaleta != "" && originaleta != null && originaleta != NaN && revisedeta != "" && revisedeta != null && revisedeta != NaN) {
						if (new Date(originaleta) > new Date(revisedeta)) return 0
						var diff = differenceInDays(revisedeta, originaleta);
						  console.log("Difference Delay", diff)
						
						return diff;
					}
					else {
						return "";
					}
				},
				cellClass: function (params) {
					var revisedeta = params.data.revised_eta;
					var originaleta = params.data.estimated_datetime;
					if (originaleta != "" && originaleta != null && originaleta != NaN && revisedeta != "" && revisedeta != null && revisedeta != NaN) {
						if (new Date(originaleta) > new Date(revisedeta)) return 0
						var diff = differenceInDays(revisedeta, originaleta);

						return diff;

						// if (diff > 0) {
						// 	return 'bgColorDangerMedium'
						// }
						// // if (diff <= 1) {
						// // 	return 'bgColorSuccessMedium'
						// // }
						// else {
						// 	return ''
						// }
					}
					else {
						return "";
					}
				}
			},
			{
				headerName: "Turn Around Time (TAT)",
				field: "",
				width: 150,
				// hide : !hidden,
				hide: hideonlyacive,
				valueGetter: function (params) {
					var delivery_datetime = params.data.delivery_datetime;
					var gc_date = params.data.gc_date;
					if (delivery_datetime == "" || delivery_datetime == undefined || delivery_datetime == " " || delivery_datetime == NaN || delivery_datetime == "Invalid date") {
						delivery_datetime = new Date();
					}
					if (['DELIVERED', "Delivered"].includes(params.data.consignment_status)) {
						if (gc_date != "" && gc_date != null && gc_date != NaN) {
							var diff = deliveryInTransit(delivery_datetime, gc_date);
							if (diff > 0) {
								return diff;
							} else {
								return 0;
							}
						}
						else {
							return "";
						}
					}
				}
			},
			{
				headerName: "Updated On",
				field: "last_date",
				width: 150,
				// filter: "agSetColumnFilter",
				valueGetter: function (params) {
					if (params.data.last_date != "" && params.data.last_date !== undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.last_date);
					}
				},
				//filter: "agDateColumnFilter",
				// comparator: dateComparator,

			},
			{
				headerName: "Last Processed On",
				field: "last_process_on",
				width: 150,
				// filter: "agSetColumnFilter",
				valueGetter: function (params) {
					if (params.data.last_process_on != "" && params.data.last_process_on !== undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.last_process_on);
					}
				},
				//filter: "agDateColumnFilter",
				// comparator: dateComparator,

			},
			{
				headerName: "Delivered At",
				field: "delivery_station",
				hide: hideonlyacive,
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer: ''
			},
			{
				headerName: "Delivered?",
				field: "",
				width: 120,
				// pinned: "left",
				//checkboxSelection: true,
				hide: hideCouriersCheckBox,
				cellRendererSelector: function (params) {

					var rendComponent = {
						component: 'deliverconsignment'
					};
					return rendComponent

				},
				// valueGetter:function(params){
				//     if(params.data.tpt_confirm_delivery == 1)
				//     {
				//         //console.log("tpt_delivery_date ",params.data.tpt_delivery_date);
				//         return getHyphenDDMMMYYYYHHMM(params.data.tpt_delivery_date)
				//     }
				//     else{
				//         return '';
				//     }
				// },
				filter: false,
				resizable: true
			},
			// {
			// 	headerName: "Service Provider Name",
			// 	field: "service_provider",
			// 	width: 135,
			// 	filter: "agSetColumnFilter",
			// 	valueGetter: function (params) {
			// 		return params.data.service_provider;
			// 	},
			// },
			
			
			
			
			
			
			
			
			
			
			//   {
			//     headerName: "In Transit Delay (Days)",
			//     field: "",                    
			//     width: 150,
			//     valueGetter : function(params){
			// 		var currentDate = new Date();
			// 		var gc_date = params.data.gc_date;
			// 		if(gc_date != "" && gc_date != null && gc_date != NaN)
			// 		{
			// 			var diff = InTransitDelay(currentDate,gc_date);
			// 			return diff;
			// 		}
			// 		else{
			// 			return "";
			// 		}
			// 	}
			//   }, 
			

			
			{
				headerName: "Vehicle Mode",
				field: "mode_of_transport",
				width: 130,
				filter: "agSetColumnFilter",
				resizable: true,
				hide: true,
			},
			

			// {
			//     headerName: "MSIL POD Date",
			//     field:"pod_date",
			//     width:110,
			// 	valueGetter:(params)=>{
			// 		if(params.data.pod_date != undefined && params.data.pod_date != null)
			// 		{
			// 			return getHyphenDDMMMYYYYHHMM(params.data.pod_date)
			// 		}
			// 		else{
			// 			return ""
			// 		}
			// 	},
			// 	resizable: true,

			// },
			// {
			// 	headerName: "TPT Delivery Date",
			// 	field: "tpt_delivery_date",				
			// 	width: 135,
			// 	filter: "agSetColumnFilter",
			// 	valueGetter:function(params){
			// 		if(params.data.tpt_delivery_date != "")
			// 		{
			// 			return getHyphenDDMMMYYYYHHMM(params.data.tpt_delivery_date);
			// 		}
			// 		else
			// 		{
			// 			return ""
			// 		}

			// 	}, 
			// 	//filter: "agDateColumnFilter",
			// 	  comparator: dateComparator,
			// 	//   filterParams: {
			// 	// 	  browserDatePicker: true,
			// 	// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	// 	  // provide comparator function
			// 	// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 	// 		cellValue = cellValue.replace(/\//g,"-")
			// 	// 		  if(cellValue != "" && cellValue != " "){
			// 	// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 	// 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
			// 	// 			  var cellDate = new Date(cellValue);
			// 	// 			  if(filteredDate.getMonth() < 10){
			// 	// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 	// 			  }
			// 	// 			  else{
			// 	// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 	// 			  }
			// 	// 			  if(filterValue.split("-")[2].length < 2)
			// 	// 			  {
			// 	// 				  var temp = filterValue;
			// 	// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 	// 			  }
			// 	// 			  var filteredDate = new Date(filterValue);
			// 	// 			  cellDate = cellDate.getTime();
			// 	// 			  filteredDate = filteredDate.getTime();
			// 	// 			  // console.log(cellDate,filteredDate);
			// 	// 			  if(cellDate === filteredDate)
			// 	// 			  {
			// 	// 				  return 0;
			// 	// 			  }
			// 	// 			  if(cellDate < filteredDate){
			// 	// 				  return -1;
			// 	// 			  }
			// 	// 			  if(cellDate > filteredDate)
			// 	// 			  {
			// 	// 				  return 1;
			// 	// 			  }
			// 	// 		  }
			// 	// 	  }
			// 	//   },        
			// },
			// {
			// 	headerName: "TPT Delivery Reason",
			// 	field: "tpt_delivery_reason",				
			// 	width: 200,
			// 	filter: "agSetColumnFilter",   
			// },
			// {
			// 	headerName : "Critical",
			// 	field : "critical_record",
			// 	width: 120,
			// 	hide : hideCritical,
			// 	valueGetter : function(params)
			// 	{
			// 		if(params.data.critical_record == 1)
			// 		{
			// 			return "Yes";
			// 		}
			// 		else
			// 		{
			// 			return "No";
			// 		}
			// 	}
			// }, 
			// {
			// 	headerName: "Priority",
			// 	field: "is_priority",
			// 	width: 120,
			// 	//hide : hidden,
			// 	valueGetter: function (params) {
			// 		if (params.data.is_priority == 1) {
			// 			return "Yes";
			// 		}
			// 		else {
			// 			return "No";
			// 		}
			// 	}
			// },
			// {
			// 	headerName: "Mark Priority",
			// 	field: "priority",
			// 	width: 130,
			// 	cellRenderer: function (params) {
			// 		if (params.data.is_priority == 1) {
			// 			return "";
			// 		}
			// 		else {
			// 			var htmloption = '<button class="btn btn-info" style="padding-top:0px">Priority</button>';
			// 			return htmloption;
			// 		}
			// 	},
			// 	//hide : hidden,
			// 	filter: false,
			// 	resizable: true,
			// },
			// {
			// 	headerName: "Force Close",
			// 	field: "consignment_code",
			// 	width: 80,
			// 	cellRendererSelector: function (params) {
			// 		var rendComponent = {
			// 			component: 'consignmentforceclose'
			// 		};
			// 		return rendComponent;
			// 	},
			// 	hide: hidden,
			// 	filter: false, resizable: true,
			// },
			// {
			// 	headerName: "Mark Delivered",
			// 	field: "consignment_code",
			// 	width: 100,
			// 	cellRendererSelector: function (params) {
			// 		var rendComponent = {
			// 			component: 'MarkDeliver'
			// 		};
			// 		return rendComponent;
			// 	},
			// 	hide: hidden,
			// 	filter: false, resizable: true,
			// },
			// {
			// 	headerName: "Force Close Reason",
			// 	field: "force_close_reason",
			// 	width: 150,
			// 	hide: reasonHide,
			// 	filter: false, resizable: true,
			// },


			// {
			//     headerName: "Vehicle Mode",
			//     field: "vehicle_mode",
			//     width: 180,                 
			//     filter: "agSetColumnFilter",
			// 	cellRenderer:''	    	          
			// },
			// {
			//     headerName:"Route ID",
			//     field:"route_id",
			//     width:200,
			//     filter:"agSetColumnFilter",
			// 	cellRenderer:''
			// },


			// {
			//     headerName:"Items",
			//     field:"items",
			//     width:200,
			//     filter:"agSetColumnFilter",
			// 	cellRenderer:''
			// },            
			// {
			//   headerName: "Driver1 Code",
			//   field: "driver1_code",
			//   width: 120,                 
			//   filter: "agSetColumnFilter",
			//   cellRenderer:''    	          
			// },
			// {
			//     headerName: "Driver2 Code",
			//     field: "driver2_code",
			//     width: 180,                 
			//     filter: "agSetColumnFilter",
			// 	cellRenderer:''	    	          
			// },
			// {
			//     headerName: "Cluster",
			//     field: "cluster",
			//     width: 180,                 
			//     filter: "agSetColumnFilter",
			// 	cellRenderer:''	    	          
			// },



		];
		// if(this.props.match.path == "/activecourierconsignments" || this.props.match.path == "/courierconsignments" )
		// {
		// 	columnwithDefs.push({
		// 		headerName: "",
		// 		field: "",
		// 		width: 160,
		// 		// pinned: 'left',
		// 		cellRendererSelector: function(params){
		// 			try {
		// 				if(params.data.consignment_status === "Invalid Docket" || params.data.consignment_status === "Invalid docket")
		// 				{
		// 					return {
		// 						component:"InvalidDocket"
		// 					}
		// 				}
		// 				else{
		// 					return "";
		// 				}
		// 			} catch (error) {
						
		// 			}
		// 		},
		// 		filter: false,

		// 	})
		// }
		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: this.props.marginLeft,

		}
		const { delivermodal } = this.state;
		const modalStyles = {
			width: '1300px !important',
		}

		var customers = this.state.customers
        var allcustomers = []
        if(customers.length > 0)
        {
            customers.map((item) => {
                allcustomers.push(item.consignee_name+" - "+item.consignee_code)
            })
        }
        // console.log("allcustomers ", allcustomers)
        var columnProduceDefs = [
            
            {
				headerName: "Truck No",
				field: "truck_no",
				width: 120,
				filter: false,
                editable:true
			},
            {
				headerName: "GC Way Bill No",
				field: "gc_waybill_no",
				width: 120,
				filter: false,
                editable:true
			},
            {
				headerName: "GC Date",
				field: "gc_date",
				width: 120,
                editable:true,
				filter: false,
				// filter: "agSetColumnFilter",
                cellEditor: DateEditor,
				// cellEditorFramework: DateEditor,
			},
            {
				headerName: "Consignee Name",
				field: "consignee_name",
				width: 120,
				filter: false,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: allcustomers 
                },
			},
            {
				headerName: "Shipment Type",
				field: "shipping_type",
				width: 120,
				filter: false,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['surface','air','Train','Part Load'] 
                },
			},
            {
				headerName: "Allowable Weight",
				field: "allowable_weight",
				width: 120,
				filter: false,
                editable:true
			},
            {
				headerName: "Per KG Slab Rate",
				field: "per_kg_slab_rate",
				width: 120,
				filter: false,
                editable:true
			},
            {
				headerName: "Total Cost",
				field: "basic_trans_cost",
				width: 120,
				filter: false,
                editable:false
			}
        ]
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				<SweetAlert
					show={this.state.showPriority}
					type={this.state.basicPriorityType}
					title={this.state.basicPriorityTitle}
					onConfirm={this.closePriorityAlert}
				>
				</SweetAlert>
				<div className="row" style={{ marginBottom: "1%" }}>
					<div className="col-xl-12 col-lg-12">
						<button onClick={this.reloadPageData.bind(this)} className="btn btn-info" style={{ float: "right" }}>Reset Filters</button>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-4 col-lg-4">
						<div className=" card" style={{ minHeight: "234px" }}>
							<div className="row card-body" style={{ padding: "10px 30px" }}>
								<form className="row" onSubmit={this.getTruckConsignments.bind(this)}>
									<div className="form-group col-xl-12 col-lg-12">
										<label>Select Type</label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedFilter"
											style={{ borderRadius: "0px" }}
											onChange={e => { this.setState({ selectedFilter: e.label }) }}
											options={[{ label: "GC/LR/Way Bill No", value: "GC/LR/Way Bill No" }, { label: "Shipment No", value: "Shipment No" }]} required />
									</div>
									<div className={"form-group col-xl-12 col-lg-12"}>
										<label className="reason">Shipment No / GC/LR/Way Bill No</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{}}>
										<button type="submit" style={{}} className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="col-xl-8 col-lg-8">
						<div className=" card" style={{ height: "234px" }}>
							<div className="row card-body" style={{ padding: "10px" }}>
							
								<form method="POST" className="row theme-form col-xl-12 col-lg-12">
								{/* <div className="row col-xl-12 col-lg-12" style="margin-left: 0px; padding:0px;"> */}
									<div className="form-group col-xl-6 col-lg-4">
										<label>Select Consigner </label>
										<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{ borderRadius: "0px" }} options={this.selectConsignerOptionsItems()} required />
									</div>
									
									{localStorage.getItem("user_type") != "TRANSPORTER" ?
									<div className="form-group col-xl-4 col-lg-4">
										<label>Transporter   </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											closeMenuOnSelect={true}
											id="selectedTransporter"
											style={{ borderRadius: "0px" }}
											value={this.state.flTransporter}
											onChange={this.newFilter.bind(this, "flTransporter")}
											options={this.state.filterTransporters}
										/>
									</div>
									:""}
									{/* <div className="form-group col-xl-4 col-lg-4">
									<label>From Date</label> */}
									{/* <input type="text" placeholder="From Date" id="from_date" onChange={this.handlerStartDateTime.bind(this)} value={this.state.from_date} className="datepicker form-control" /> */}
									{/* <input type="text" placeholder="From Date" autoComplete="off" id="from_date" value={this.state.from_date}  className="datetimepicker_date form-control" required />
									
								    </div> */}
									<div className="form-group col-xl-4 col-lg-4">
										<label>From Date </label>
										<Datetime 
										    value={this.state.from_date}
											disableCloseOnClickOutside={false}
											closeOnSelect={true}
											inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete: 'off',readOnly: true }}
											dateFormat="YYYY-MM-DD"
											// timeFormat="HH:mm:ss"
											name="fromDate"
											timeFormat={false}
											onChange={this.handlerStartDateTime} />
									</div>
								{/* </div>	 */}
									{/* <div className="form-group col-xl-4 col-lg-4">
									<label>To Date</label>
									<input type="text" autoComplete="Off" placeholder="To Date" id="to_date" value={this.state.to_date}  className="datetimepicker_date form-control" required /> */}
									{/* <input type="text" placeholder="To Date" id="to_date" onChange={this.handlerEndDateTime.bind(this)} value={this.state.to_date} className="datepicker form-control" /> 
								</div>*/}
									<div className="form-group col-xl-4 col-lg-4">
										<label>To Date </label>
										<Datetime
											value={this.state.to_date}
											disableCloseOnClickOutside={false}
											closeOnSelect={true}
											inputProps={{ placeholder: 'To Date', name: 'toDate', autoComplete: 'off',readOnly:true }}
											dateFormat="YYYY-MM-DD"
											timeFormat={false}
											name="toDate"
											onChange={this.handlerEndDateTime} />
									</div>

									<div className="form-group col-xl-4 col-lg-4"   >
										<button className="btn btn-success mt-30p"  type="submit" onClick={this.submitForm.bind(this)}>Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-xl-12 col-lg-12">

						<CourierCounter 
							context={this}
							records={this.state.rowData}
							intransitcount={this.state.intransitcount}
							outdelivercount={this.state.outdelivercount}
							delivercount={this.state.delivercount}
							undeliverCount = {this.state.undeliverCount}
							datanacount={this.state.datanacount}
							prioritydatacount={this.state.prioritydatacount}
							total={this.state.totalcount}
							counttype={this.state.counttype}
							critical_counter_data={this.state.critical_counter_data.length}
							confirmDelivery={this.state.confirmDelivery.length}
							cancelled_data={this.state.cancelled_data}
							trip_closed_by_pod={this.state.trip_closed_by_pod.length}
							pod_received_data={this.state.pod_received_data.length}
							path={this.props.match.path}
							on_time_data={this.state.on_time_data}
							delayed_data={this.state.delayed_data}
							transitna_data={this.state.transitna_data}
							booked_data={this.state.booked_data}
							force_closed={this.state.force_closed}
							
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5 style={{ float: "left" }}>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {this.state.pageTitle}</span>

								</h5>
								{(this.state.counttype == "active") ?
									<div className='row'>
										<div className="float-right button-group col-xl-12 col-lg-12">
											<button type="button" className="btn btn-warning float-right" onClick={this.onClickUploadManualData}>Upload Manual Data</button>
											<button type="button" className="btn btn-success float-right" onClick={this.onClickUploadData}>Upload Shipment Data</button>
											{/* <button
												onClick={this.onBulkMarkDeliverBtn}
												className={"btn btn-info float-right " + (this.state.hideTransporterBtns)}
												type="button">
												Bulk Mark Delivered
											</button> */}
											{/* <button
													title="Bulk Upload"
													onClick={this.onBulkForceCloseBtn}
													className={"btn se-ico btn-success ml-5px " + (this.state.hideTransporterBtns)}
													type="button">
													Bulk Force Close
												</button> */}
										</div>
									</div>
									: ""}

								{/* <div className="float-right">
									<button
										title="Bulk Upload"
										onClick={this.onBulkPriorityBtn}
										className={"btn se-ico btn-danger ml-5px " + (this.state.hideTransporterBtns)}
										type="button">
										Bulk Mark Priority
									</button>
								</div> */}
							</div>

							<div className="row card-body">
								<div className="col-xl-12">
									<span className="layoutbtns float-right">
										<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span>
								</div>
								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										headerHeight={50}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowSelection={true}
										rowClassRules={this.state.rowClassRules}
										onRowSelected={this.onRowSelection}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										onCellClicked={this.onCellClicked}
									/>

									<div className={"dataLoadpage " + (this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " + (this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
									<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={"slide-r " + (this.state.sliderForceCloseTranslate)} style={{ overflow: "auto" }}>
					{/* <div className="slide-r-title">
						<h4>
							Force Closure
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll} >X</span>

						</h4>
					</div> */}
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "302px" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler}>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reached Dealer Time</label>
								
                                <input type="text" placeholder="Reached Dealer " id="reached_dealer_time" className="datetimepicker_mask form-control"  />
							
							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Left Dealer Time</label>
								
                                <input type="text" placeholder="Left Dealer " id="left_dealer_time" className="datetimepicker_mask form-control" />
							
							</div> */}
								{/* <ForceCloseSideBar context={this} handleInput = {this.handleInput}/> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Reason for closure</label>
									<textarea name="reasonforceclose" id="forceClosureResonID" rows="5" className="form-control col-xl-12 col-lg-12" required></textarea>
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.sliderMarkDeliverTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Mark Delivered
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "302px" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formMarkDeliverHandler}>

								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Delivery Time</label>
									<input type="text" placeholder="" autoComplete="off" class="form-control datetimepicker_date" value={this.state.markDeliverDefault} id="delivery_date_time" required />
								</div> */}
								<div className="form-group col-xl-12 col-lg-12">
									<label>Delivery Date: </label>
									<Datetime
										// id="delivery_date_time" 
										value={this.state.markDeliverDefault}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'Delivery Date', name: 'markDeliverDefault', autoComplete: 'off',readOnly: true }}
										dateFormat="YYYY-MM-DD"
										onChange={this.onChangeMarkedDeliverDate.bind(this)}
										required />
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.bulkPrioritySlide)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Bulk Priority</h3>

					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkPriorityFormHandler}>

								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" id="bulkUploadPID" onChange={this.changePriorityFileHandler} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
								</div>
							</form>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_couriers_mark_deliver_sample.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}

							</div>
						</div>
					</div>
				</div>

				{/* <div className={"slide-r " + (this.state.bulkForceCloseSlide)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Bulk Force Close</h3>

					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkForceFormHandler}>

								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
								</div>
							</form>
							<div className="form-group">
								<p style={{ color: "#ff0000" }}>* Note : All fields related to Force close are mandatory and cannot be empty</p> */}
				{/* <p style={{color:"green"}}>* Please use the below fields as headers while uploading</p>
								<p style={{color:"cornflowerblue"}}> -> consignment_code</p>
								<p style={{color:"cornflowerblue"}}> -> reason</p> */}

				{/* </div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_couriers_force_close_sample.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="form-group"> */}
				{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}

				{/* </div>
						</div>
					</div>
				</div> */}

				<div className={"slide-r " + (this.state.bulkMarkDeliverSlide)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Bulk Mark Delivered
						<span
							className="float-right closebtn"
							style={{ marginRight: "25px" }}
							onClick={this.onCloseSliderDiv}
						>
							X
						</span>

					</h3>

					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkMarkDeliverHandler}>
								{/* <div className="form-group mt-20p">
									<label className="">Select Delivery Time</label>
									<input type="text" autoComplete="off" class="form-control datetimepicker_date" id="bulk_delivery_date_time" required />
								</div> */}
								{/* <div className="form-group col-xl-12 col-lg-12">
									<label>Delivery Date: </label>
									<Datetime
										// id="delivery_date_time" 
										value={this.state.markDeliverDefault}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'Delivery Date', name: 'markDeliverDefault', autoComplete: 'off' }}
										dateFormat="YYYY-MM-DD"
										onChange={this.onChangeMarkedDeliverDate.bind(this)}
										required />
								</div> */}
								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" id="bulkUploadMDID" onChange={this.changeMDHandler} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
								</div>
							</form>
							<div className="form-group">
								<p style={{ color: "#ff0000" }}>*Note: </p>
								<p><b style={{ color: "#ff0000" }}> * </b>All fields related to Mark Delivered are mandatory and cannot be empty</p>
								<p><b style={{ color: "#ff0000" }}> * </b>Delivery Date Must be Greater than Booking Datetime.</p>
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_couriers_mark_deliver_sample.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}

							</div>
						</div>
					</div>
				</div>

				{/* shipments bulk upload  */}
				<div className={"slide-r " + (this.state.shipment_slider)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Bulk Upload Shipment Data
						<span
							className="float-right closebtn"
							style={{ marginRight: "25px" }}
							onClick={this.onCloseSliderDiv}
						>
							X
						</span>
					</h3>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="container-fluid">
							<form method="POST" enctype="multipart/form-data" id="upform" className="theme-form" onSubmit={this.uploadShipmentXLSHandler}>
								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" id="uploadFile" name="uploadFile" onChange={this.changeXlsFile.bind(this)} className="form-control" required /><br />
									{/* <span style={{color:"#ff0000",fontSize:"12px", fontWeight:"bold"}}>Note: Please upload .csv file extension only</span> */}
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
								</div>
							</form>
							

							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_consignment_upload.csv')} target="_blank">Sample Template</a>

							</div>
							<p className='pl-3' style={{ color: 'red' }}>Note :  please format the dates in yyyy-mm-dd hh:mm before uploading</p>
						</div>
					</div>
				</div>
				{/* manual_upload_slider */}
				<div className={"slide-r " + (this.state.manual_upload_slider)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Enter Manual Orders
						<span
							className="float-right closebtn"
							style={{ marginRight: "25px" }}
							onClick={this.onCloseSliderDiv}
						>
							X
						</span>
					</h3>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="container-fluid">
							{/* <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadManualBulkFormHandler}>
								<div className="row form-group col-xl-12 col-lg-12">
									<label className="col-xl-4 col-lg-4">Select Gc Way Bill No</label>
									<Select
										className="border-radius-0 col-xl-6 col-lg-6"
										isMulti={false}
										id="selectedGcWayBillNo"
										value={this.state.gc_way_bill_No_sel}
										onChange={this.setSelectedGcWayNo}
										style={{ borderRadius: "0px" }}
										options={this.state.gcWayBillList} required />
								</div>
								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" id="manualUploadFile" name="uploadFile" onChange={this.changeManualFileHandler} className="form-control" required />
								</div>								
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
								</div>
							</form> */}
							

{/* 
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_manual_cons_upload.csv')} target="_blank">Sample Template</a>
							</div> */}

							<AddManualOrders
								gcWayBillList={this.state.gcWayBillList}
								customers={this.state.customers}
								context={this}
							/>

						</div>
					</div>
				</div>

				<Modal open={delivermodal} onClose={this.onCloseDeliverModal} styles={modalStyles}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formSetDeliveredDate.bind(this)}>

							<div className="row form-group col-xl-12 col-lg-12">
								<label className="col-xl-4 col-lg-4">Reason For Delay/Current Status</label>
								<Select
									className="border-radius-0 col-xl-6 col-lg-6"
									isMulti={false}
									id="selectedFilter"
									onChange={this.setSelectedReason.bind(this)}
									style={{ borderRadius: "0px" }}
									options={this.selectReason()} required />
							</div>

							<div className={"col-xl-12 col-lg-12 row form-group"} style={{ display: this.state.delDate }}>
								<label className="col-xl-4 col-lg-4">Delivery Date</label>
								<Datetime
									value={this.state.deliverdate}
									disableCloseOnClickOutside={false}
									closeOnSelect={true}
									inputProps={{ placeholder: 'Delivery Date', name: 'deliverdate', autoComplete: 'off' }}
									dateFormat="YYYY-MM-DD"
									name="deliverdate"
									className="col-xl-6 col-lg-6"
									onChange={this.handlerDeliverDateTime.bind(this)}
								/>
							</div>
							<div className={"form-group col-xl-12 col-lg-12 row " + (this.state.displayOtherField)}>
								<label className="col-xl-4 col-lg-4" style={{ float: "left" }}>Other Reason</label>
								<textarea name="reason" onChange={this.changeReasonHandler.bind(this)} rows="5" className="form-control col-xl-6 col-lg-6" ></textarea>
							</div>
							<div className="col-xl-12 col-lg-12 form-group" style={{ textAlign: "center" }}>
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
					</div>
				</Modal>
				
				<div className={"slide-r " + (this.state.sliderInvalidDocket)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Update Invalid Docket
					
					<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll} >X</span>
					</h3>

					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.updateInvalidDocketGCWayBill.bind(this)}>

								<div className="form-group mt-20p">
									<label className="">GC Waybill No:</label>
									<input type="text" name="invalid_gc_waybill" id="invalid_gc_waybill" value={this.state.invalid_gc_waybill} onChange={this.changeHandler2.bind(this)} className="form-control" size="12" autoComplete='Off' required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className={"slide-r " + (this.state.update_row_slider)} style={{ overflow: "hidden" }}>
					<h3 className="subH"> Upadate Row Details
						<span
							className="float-right closebtn"
							style={{ marginRight: "25px" }}
							onClick={this.onCloseSliderDiv}
						>
							X
						</span>
					</h3>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onUpdateConsignmentDetails}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Transit Date*</label>
									<Datetime
										value={this.state.transit_data_for_update}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'Transit Date', name: 'transitdate', autoComplete: 'off' }}
										dateFormat="YYYY-MM-DD"
										timeFormat="HH:mm:ss"
										name="transitdate"
										className="col-xl-12 col-lg-12"
										onChange={this.handlerTransitDateTime.bind(this)}
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Location*</label>
									<input type="text" placeholder="Enter Location"  id="location_for_update" name="location_for_update" className="form-control col-xl-12 col-lg-12" style={{marginLeft:'15px'}} required />
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Status*</label>
									<Select
										className="col-xl-12"
										onChange={(e) =>this.setState({
											status_for_update : e,
											show_delivered_date : e.value == 'Delivered' ? true : false
										})}
										value={this.state.status_for_update}
										options={[
											{label : 'In Transit' , value : 'In Transit'},
											{label : 'Out For Delivery' , value : 'Out For Delivery'},
											{label : 'Delivered' , value : 'Delivered'},
											{label : 'CANCELLED' , value : 'CANCELLED'},
										]}
										required={true}
									/>
								</div>

								{this.state.show_delivered_date ?
									<>
										<div className="col-xl-12 col-lg-12 row form-group">
											<label className="col-xl-12 col-lg-12">Delivered Date *</label>
											<Datetime
												value={this.state.delivered_date_for_update}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'Delivery Date', autoComplete: 'off' }}
												dateFormat="YYYY-MM-DD"
												timeFormat="HH:mm:ss"
												className="col-xl-12 col-lg-12"
												onChange={this.handlerUpdateDeliveredDateTime.bind(this)}
											/>
										</div>
										<div className="form-group col-sm-12 mb-20p">
											<label>Upload Document<span className="rclr fbold">*</span></label>
											<input type="file" name="epodDocforupdate" id="epodDocforupdate" className="form-control" onChange={this.changeFileHandlerForupdate}  required />
										</div>
									</>
									: ""}
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>

						</div>
					</div>
				</div>
				<div className={"slide-r " + (this.state.sliderTransitNa)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Update Transit Time
					
					<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll} >X</span>
					</h3>

					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.onClickupdateTransitTime.bind(this)}>

								<div className="form-group mt-20p">
									<label className="">Transit Time</label>
									<input type="text" name="transit_days" id="transit_days" value={this.state.transit_days} onChange={this.changeHandler.bind(this)} className="form-control" size="12" autoComplete='Off' required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}


function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	// console.log(yearNumber, monthNumber, dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1, date2) {
	//console.log(date1,date2);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split("-")[1]);
		date1 = date1.replace(date1.split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split("-")[1]);
		date2 = date2.replace(date2.split("-")[1], date_2);
		var date1Number = monthToComparableNumber1(date1);
		var date2Number = monthToComparableNumber1(date2);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}

function monthToComparableNumber1(date) {
	// console.log(date.length);
	// console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}


// window.onload = function (){
// 	// $(".datepicker").datepicker({
//     //     dateFormat: "dd-mm-yy",
//     //     changeMonth: true,
//     //     changeYear: true
// 	// });
// 	$('.datetimepicker_mask1').datetimepicker({
// 		mask:'39-19-9999',
// 		format:'d-m-Y',
// 		timepicker:false
// 	});
// };

// function loadDateTimeScript(){
// 	// alert("timeDate");
// 	$('.datetimepicker_mask').datetimepicker({
// 		mask:'39-19-9999 29:59',
// 		format:'d-m-Y H:i'
// 	});
// 	$('.datetimepicker_date').datetimepicker({
// 		mask:'39-19-9999',
// 		format:'d-m-Y',
// 		timepicker:false
// 	});
//     var index  =window.document.getElementsByTagName("script")[1]
//     var script = window.document.createElement("script")
//     // script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
// 	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
// 	script.async=true
//     script.defer = true
//     index.parentNode.insertBefore(script,index)
// }

function differenceInDays(startDate, endDate) {
	// console.log("startDate, endDate", startDate,endDate);
	startDate = moment.parseZone(startDate);
	endDate = moment.parseZone(endDate);
	// var sDate = startDate.setHours(0, 0, 0, 0);
	// var eDate = endDate.setHours(0, 0, 0, 0);
	// console.log("start",sDate);
	// console.log("end",eDate);
	var res = Math.abs((startDate - endDate) / 1000);
	return Math.floor((res) / 86400);
}

function deliveryInTransit(startDate, endDate) {
	// console.log(startDate,endDate);
	endDate = endDate.split("T")[0];
	if (typeof (startDate) == "string") {
		if (startDate.indexOf(":") >= 0) {
			startDate = startDate.split(" ")[0];
		}
		if (startDate.indexOf(":") >= 0 && startDate.indexOf("T") >= 0) {
			startDate = startDate.split("T")[0];
		}

		// console.log(startDate);
		// console.log(startDate.split("-")[1]+"/"+startDate.split("-")[2]+"/"+startDate.split("-")[0]);
		// console.log(endDate.split("-")[1]+"/"+endDate.split("-")[2]+"/"+endDate.split("-")[0]);
		if (startDate.indexOf("/") < 0) {
			startDate = new Date(startDate.split("-")[1] + "/" + startDate.split("-")[2] + "/" + startDate.split("-")[0]);
		}
		else {
			startDate = new Date(startDate.split("/")[1] + "/" + startDate.split("/")[2] + "/" + startDate.split("/")[0]);
		}
	}
	else {
		var dd = String(startDate.getDate()).padStart(2, '0');
		var mm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = startDate.getFullYear();
		startDate = mm + '/' + dd + '/' + yyyy;
		startDate = new Date(startDate);
	}

	endDate = new Date(endDate.split("-")[1] + "/" + endDate.split("-")[2] + "/" + endDate.split("-")[0]);
	// console.log(startDate,endDate);
	var difference = startDate.getTime() - endDate.getTime();
	// console.log(difference);
	// var res = Math.abs((startDate - endDate)/1000);
	return Math.abs(Math.round((difference) / (1000 * 3600 * 24)));

}

function InTransitDelay(startDate, endDate) {
	endDate = endDate.split("T")[0];
	var dd = String(startDate.getDate()).padStart(2, '0');
	var mm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
	var yyyy = startDate.getFullYear();
	startDate = mm + '/' + dd + '/' + yyyy;
	startDate = new Date(startDate);
	endDate = new Date(endDate.split("-")[1] + "/" + endDate.split("-")[2] + "/" + endDate.split("-")[0]);
	// console.log(startDate,endDate);
	var difference = startDate.getTime() - endDate.getTime();
	// console.log(difference);
	// var res = Math.abs((startDate - endDate)/1000);
	return Math.abs(Math.round((difference) / (1000 * 3600 * 24)));
}

function GetSortDescOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
} 

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};