const axios = require('axios');

const axiosInstance = axios.create({
  /* Setting the base URL for the axios instance. */
  // baseURL: 'http://34.131.171.186:5001/ashokleyland_app/',
   baseURL: 'https://al-autometrics.in/ashokleyland_app/', //prod server

  // baseURL:'http://localhost:5024/ashokleyland_app',
  /* other custom settings */
});

module.exports = axiosInstance;
