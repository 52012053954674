
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const InvalidDocket = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowCourierInvalidDocket(props);
    };

    return (
        <div>
			<button onClick={handleClick} className="btn btn-warning f12" style={{padding:"2px 10px"}} title="Update GC Waybill No">Update GC Waybill </button>
       
        </div>
    );
};

export default InvalidDocket;
