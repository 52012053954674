import React,{useState, useRef, useEffect} from 'react'

import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import moment from "moment";
import Select from 'react-select';

var redirectURL = require('../redirectURL');

const getFinancialYear = (startMonth = 3) => {
  const today = moment();
  const currentMonth = today.month(); // Month is zero-indexed (0 for January)
  const currentYear = today.year();

  //   console.log("test year - ", currentMonth, currentYear);

  // Determine the start year of the financial year
  let startYear, endYear;
  if (currentMonth >= startMonth) {
    // If current month is April (3) or later, current year is the start year
    startYear = currentYear;
    endYear = currentYear + 1;
  } else {
    // If current month is January (0) to March (2), previous year is the start year
    startYear = currentYear - 1;
    endYear = currentYear;
  }

  // Format the financial year as YYYY  - 2425 using Moment.js
  const financialYear = `${moment(startYear, "YYYY").format("YYYY")}-${moment(endYear,"YYYY").format("YYYY")}`;
  return financialYear;
};

const getAllFinancialYears = (noOfYears = 3) => {
  const currentYear = moment().year();
    const currentMonth = moment().month();
    
    // Financial year starts from April 1st
    const financialYears = [];
    
    // Generate past 5 financial years
    for (let i = 0; i < noOfYears; i++) {
      // If current month is before April, adjust the year calculation
      const startYear = currentMonth < 3 
        ? currentYear - i - 1 
        : currentYear - i;
      
      const financialYear = `${startYear}-${startYear + 1}`;
      financialYears.push(financialYear);
    }
    return financialYears;
};

const VendorSOB = () => {
  const [sweetAlert, setSweetAlert] = useState({ show: false, basicType: "default", basicTitle: "", });
  const [columnDef, setColumnDef] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [defaultColDef, setdefaultColDef] = useState({
    sortable: true,
    filter: true,
    resizable: true,
    pivot: false,
    enableValue: true,
    enableRowGroup: true,
  });
  const [loader, setLoader] = useState(false);
  const [fyOptions, setFYOptions] = useState([]);
  const [selectFy, setSelectFY] = useState("");

  const gridApi = useRef(null);
  const gridColumnApi = useRef(null);

  const fetchData = (fy)=>{
    setLoader(true);
    let params = { financial_year: fy || "" }
    redirectURL.post('/billing/getvendorsob', params).then(response => {
      setLoader(false);

      if(response.data && response.data.records && response.data.records.length>0){
        let records = response.data.records;
        let first_row = records[0];
        let temp_coldef = [];

        Object.keys(first_row).map((key) =>{
          if(["_id", "financial_year"].includes(key)) return ""

          let col = {
            headerName: key,
            field: key,              
            filter: true,
            resizable: true,
            editable: true,
          }

          if(key === "vendor_name"){
            col.headerName = "Vendor Name";
            col.pinned = "left";
          }
          else if(key){
            col.headerName = key.toUpperCase();
          }

          temp_coldef.push(col);
        } );
        setColumnDef(temp_coldef);
        setRowData(records);

      }
      else{
        setColumnDef([]);
        setRowData([]);
      }
      console.log("test --", response.data);
      // setShow(true);
      // setBasicType(response.data.status === "success" ? "success" : "danger");
      // setBasicTitle(response.data.message);
      // setLoadshow("show-n");
    })
    .catch(error => {
      setColumnDef([]);
      setRowData([]);
      setLoader(false);
      console.error('Error:', error.response || error.message);
    })
  }

  useEffect(()=>{
    let temp_fy = getFinancialYear();
    let financial_year_Options = getAllFinancialYears(3);
    let temp = financial_year_Options.map((ele)=>({label: ele, value: ele}))

    setFYOptions(temp);
    setSelectFY({label: temp_fy, value: temp_fy})

    fetchData(temp_fy);
  },[])

  const onSubmitHandler = (e)=>{
    e.preventDefault();
    if(selectFy.value){
      fetchData(selectFy.value);
    }
  }


  const onGridReady = params => {
    gridApi.current = params.api;
    //console.log("Grid is: ", params);
    gridColumnApi.current = params.columnApi;
  };

  const closeAlert = () => {
    setSweetAlert({ show: false, basicType: "default", basicTitle: "", });
  };

  return (
    <div className="container-fluid">
      <SweetAlert
          show={sweetAlert.show}
          type={sweetAlert.basicType}
          title={sweetAlert.basicTitle}
          onConfirm={closeAlert}
      >
      </SweetAlert>
      <div className='row'>
          <div className="card col-xl-12 col-lg-12">
              <div className="card-header" style={{ padding: '10px', borderBottom:"0px" }}>
                  <h5>Vendor SOB</h5>
              </div>
              <form onSubmit={onSubmitHandler}>
                <div className="d-flex align-items-center mr-4" style={{float: "right", gap: "10px"}}>
                  <div className='d-flex align-items-center' style={{ gap: "10px"}}>
                    <label className="" style={{marginBottom: "0px"}}>Financial Year<span className='err-txt'>*</span></label>
                    <div style={{width: "200px"}}>
                      <Select
                          value={selectFy}
                          closeMenuOnSelect={true}
                          onChange={(selectedOption) => setSelectFY(selectedOption)}
                          options={fyOptions}
                          styles={{
                              control: (provided, state) => ({
                                  ...provided,
                                  minHeight: '30px', // Add your desired min-height here
                              }),
                          }}
                          required
                      />

                    </div>
                  </div>
                  <div>
                    <button type='submit' className='btn btn-success'>Submit</button>
                  </div>
                </div>
              </form>
              <div className="height-equal equal-height-lg" style={{ padding: '10px' }}>
                  <div className="card-body" style={{ padding: '10px' }}>
                      <div id="myGrid" style={{ height: "400px" }} className="ag-theme-balham">
                          <AgGridReact
                              // modules={modules}
                              columnDefs={columnDef}
                              defaultColDef={defaultColDef}
                              rowData={rowData}
                              enableCharts={false}
                              enableRangeSelection={true}
                              statusBar={{
                                statusPanels: [
                                  { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left", },
                                  { statusPanel: "agTotalRowCountComponent", align: "center", },
                                  { statusPanel: "agFilteredRowCountComponent" },
                                  { statusPanel: "agSelectedRowCountComponent" },
                                  { statusPanel: "agAggregationComponent" },
                                ],
                              }}
                              // sideBar={{
                              //   toolPanels: [
                              //     {
                              //       id: "columns",
                              //       labelDefault: "Columns",
                              //       labelKey: "columns",
                              //       iconKey: "columns",
                              //       toolPanel: "agColumnsToolPanel",
                              //       toolPanelParams: { suppressPivotMode: true, },
                              //     },
                              //     {
                              //       id: "filters",
                              //       labelDefault: "Filters",
                              //       labelKey: "filters",
                              //       iconKey: "filter",
                              //       toolPanel: "agFiltersToolPanel",
                              //     },
                              //   ],
                              // }}
                              pagination={true}
                              groupHeaderHeight={40}
                              headerHeight={40}
                              floatingFiltersHeight={50}
                              pivotGroupHeaderHeight={30}
                              pivotHeaderHeight={50}
                              rowHeight={40}
                              onGridReady={onGridReady}
                              // onGridState={this.onGridState}
                              // onCellClicked={this.onCellClicked}
                          //sideBar={sideBar}
                          // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                          />
                      </div>

                  </div>

              </div>
              <div className={"dataLoadpage " + (loader ? "show-m":"show-n")}>
              </div>
              <div className={"dataLoadpageimg " + (loader ? "show-m":"show-n")}>
                  <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
              </div>
              {/* <div className={"overlay-part " + (loader ? "show-m":"show-n")} onClick={this.onClickHideAll}></div> */}


          </div>
              
        </div>

    </div>
  )
}

export default VendorSOB