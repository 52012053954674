import React, { Component } from 'react';
import Select from 'react-select';
import './createshipment.css'; // Assuming you create a CSS file for styling
import XLSX from "xlsx/dist/xlsx.full.min.js";
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Constants from "../constants";
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYY } from "../common/utils";
var redirectURL = require('../redirectURL');


class CreateShipment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailId: '',
            userMobile: '',
            firstName: '',
            lastName: '',
            username: '',
            sapId: '',
            zone: '',
            ro: '',
            ao: '',
            modules: AllModules,
            rowData : [],
            showCreateUser : false,
            roles: [],
            roleOptions: [],
            consignee_state_options : [],
            savedSACCodes: [],
            uploadedData: [],
            consignee_state : [],
            filterOption : '',
            sacCode: '',
            error: '',
            emailExists: false, // To track email existence
            emailError: '', // To show email-related error
            
        detailCellRendererParams: {
            detailGridOptions: {
                columnDefs: [
                    { field: 'callId' },
                    { field: 'direction' },
                    { field: 'number', minWidth: 150 },
                    { field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
                    { field: 'switchCode', minWidth: 150 },
                ],
                defaultColDef: {
                    flex: 1,
                    resizable : true,
                },
            },
            getDetailRowData: (params) => {

                params.successCallback(params.data.callRecords);
            },
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
            show: false,
            loadshow: 'show-n',
            overly: "show-n",
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "sacCode") {
            const regex = /^[a-zA-Z0-9]*$/;

            if (regex.test(value) || value === '') {
                this.setState({ sacCode: value, error: '' });
            } else {
                this.setState({ error: 'Special characters or spaces are not allowed!' });
            }
        } else {
            this.setState({ [name]: value, error: '' });
        }
    };

    handleAddSACCode = () => {
        const { sacCode, savedSACCodes } = this.state;
        const trimmedSac = sacCode.trim();

        if (trimmedSac === '') {
            this.setState({ error: 'SAC code cannot be empty!', sacCode: '' });
            return;
        }

        if (savedSACCodes.includes(trimmedSac)) {
            this.setState({ error: 'SAC code already exists!', sacCode: '' });
            return;
        }

        this.setState(prevState => ({
            savedSACCodes: [...prevState.savedSACCodes, trimmedSac],
            sacCode: '',
            error: '',
        }));
    };

    handleDeleteSAC = (sac) => {
        this.setState((prevState) => ({
            savedSACCodes: prevState.savedSACCodes.filter((item) => item !== sac),
        }));
    };

    checkEmailExists = async (email) => {
        try {
            const resp = await redirectURL.post("/dashboard/checkEmailExists", { email });

            if (resp.data && resp.data.length > 0) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error checking email:', error);
            return false;
        }
    };

    handleEmailChange = async (e) => {
        const email = e.target.value;
        this.setState({ emailId: email });
        console.log(email, "check_email")
        if (email && email != "") {
            const exists = await this.checkEmailExists(email);
            console.log(exists, "exists_or_not")
            if (exists) {
                this.setState({ emailError: 'Email already exists' });
            } else {
                this.setState({ emailError: '' });
            }
        }
        else {
            this.setState({ emailError: '' });
        }
    };

    validateForm = () => {
        var { emailId, username, firstName, lastName, userMobile, sapId, zone, ro, roles, savedSACCodes, emailError, filterOption,consignee_state } = this.state
        console.log(savedSACCodes, "savedSACCodes")
        var msg = ""
        if (!emailId) {
            msg = 'Email ID is required'
        }
        if (!username) {
            msg = 'User Name is required';
        }
        if (!firstName) {
            msg = 'First Name is required';
        }

        if (roles.length == 0) {
            msg = 'Roles is required';
        }

        if (filterOption && filterOption.value == "sacCode" && savedSACCodes.length == 0) {
            msg = 'Atleast one SAC Code is required';
        }

        if (filterOption && filterOption.value == "consignee_state" && consignee_state.length == 0) {
            msg = 'Consignee State is required';
        }
        console.log(consignee_state.length,"consignee_state")

        if (msg.length > 0) {
            console.log("check msg", msg)
            this.setState({
                basicTitle: msg,
                basicType: "danger",
                show: true,
            })
            return false
        }
        return true
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        if (!this.validateForm()) {
            return;
        }
        var { emailId, username, firstName, lastName, userMobile, sapId, zone, ro, roles, savedSACCodes, emailError,consignee_state } = this.state
        if (emailError != "") {
            console.log("")
            this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Please provide valid email id"
            })
            return;
        }
        var params = { "Mail ID": emailId, "Username": username, "First Name": firstName, "Last Name": lastName, "Mobile No": userMobile, "SAP ID": sapId, "Zone": zone, "RO/AO": ro, "Role": roles, "SAC Code": savedSACCodes,"Consignee State" : consignee_state }
        if (localStorage.getItem("email")) {
            params.updated_by = localStorage.getItem("email")
        }
        console.log(params, "check_params")
        redirectURL.post("/dashboard/createUserLogin", params)
            .then((resp) => {
                if (resp.data.status == "Success") {
                    console.log(resp.data.message)
                    this.setState({
                        show: true,
                        basicType: "success",
                        basicTitle: resp.data.message,
                        emailId: "",
                        username: "",
                        firstName: "",
                        lastName: "",
                        userMobile: "",
                        sapId: "",
                        zone: "",
                        ro: "",
                        roles: [],
                        consignee_state : [],
                        savedSACCodes: []
                    })
                }
            })
            .catch((e) => {
                console.log("error", e);
            });
    }

    onShowUploadDiv = () => {
        console.log("Click Upload Button");
        this.setState({
            uploadDivWidth: '30%',
            sliderTranslate: "slider-translate",
            showDiv: 'show-m',
            csvmessage: '',
            csverrmessage: '',
        });
    }

    createUserr = () => {
        this.setState(prevState => ({
            showCreateUser: !prevState.showCreateUser 
        }));
    }
    

    uploadFormHandler = (event) => {
        event.preventDefault();

        const reqData = {
            uploaded_data: this.state.uploadedData,
            uploaded_by: localStorage.getItem('email')
        };

        redirectURL.post('/dashboard/submitBulkUploadShipment', reqData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const records = response.data;
                // console.log("Response data:", records);

                this.setState({
                    loadshow: 'show-m',
                    show: true,
                    basicTitle: records.message || 'Logins created successfully!',
                    basicType: records.status || 'success',
                    loadshow: 'show-n',
                    sliderTranslate: "",
                    uploadFile: "",
                    overly: 'show-n',
                });
                document.getElementById("upform").reset();
            })
            .catch((error) => {
                console.error("Error:", error);
                this.setState({
                    show: true,
                    basicTitle: 'An error occurred while updating.',
                    basicType: "danger",
                    loadshow: 'show-n'
                });
            });
    }

    changeFileHandler = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        // Validate file type (accept only .xlsx and .xls)
        if (!['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type)) {
            event.target.value = null; // Reset the input field
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload a file having extensions .xlsx or .xls only.',
            });
            return;
        }

        // Read the file contents using FileReader
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result); // Read file as binary array
            const workbook = XLSX.read(data, { type: 'array' }); // Parse the Excel file

            // Assuming the first sheet is used
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            // Convert the sheet to JSON format
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Array of arrays
            if (jsonData.length === 0) {
                this.setState({
                    uploadFile: '',
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'The uploaded Excel file is empty.',
                });
                return;
            }

            // Extract header row and data rows
            const [header, ...dataRows] = jsonData;

            // Convert rows to objects based on headers
            const structuredData = dataRows.map((row) => {
                return header.reduce((obj, key, index) => {
                    obj[key] = row[index] || null; // Map header keys to row values
                    return obj;
                }, {});
            });

            // Update state with the structured data
            this.setState({
                uploadedData: structuredData,
            });

            // Optionally log the structured data
            console.log('Parsed Excel Data:', structuredData);
        };

        reader.onerror = (error) => {
            console.error('Error reading the file:', error);
        };

        reader.readAsArrayBuffer(file); // Read the file as an array buffer for binary parsing
    };

    resetUpload = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: '',
            showDiv: 'show-n',
            uploadFile: '',
            file: ''
        });
        document.getElementById("upform").reset();
    }

    onClickHideAll = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: '',
            showDiv: 'show-n',
            uploadFile: '',
            file: ''
        });
        document.getElementById("upform").reset();
    }

    parseCSV = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = (e) => {
                const csvData = this.convertCSVToJSON(e.target.result);
                resolve(csvData);
            };
            reader.onerror = () => reject(["Error reading file"]);
            reader.readAsText(file);
        });
    }

    convertCSVToJSON = (csvContent) => {
        const lines = csvContent.split("\n");
        const result = [];
        const headers = lines[0].split(",");
        for (let i = 1; i < lines.length; i++) {
            const row = lines[i].split(",");
            if (row.length === headers.length) {
                const obj = {};
                headers.forEach((header, idx) => {
                    obj[header.trim()] = row[idx].trim();
                });
                result.push(obj);
            }
        }
        return result;
    }
    validateCSVData = (data) => {
        const errors = [];  // Initialize errors as an array
        const requiredFields = ["do_number", "consigner_code", "transporter_code", "truck_no", "device_id", "challan_date_time"];
        const missingColumns = [];  // To track missing columns across all rows

        // First, check if the required columns exist in the data
        requiredFields.forEach(field => {
            const columnExists = data.some(row => row.hasOwnProperty(field));  // Check if the column exists in any row
            if (!columnExists) {
                missingColumns.push(`${field} column is required`);  // If the column is missing, add to missing columns errors
            }
        });

        // Now check for missing field values in rows only for columns that exist in the data
        data.forEach((row, rowIndex) => {
            requiredFields.forEach(field => {
                // If the column is missing completely, we don't check row-specific errors for it
                if (missingColumns.includes(`${field} column is required`)) {
                    return;  // Skip further checks for this column
                }

                if (!row[field]) {
                    errors.push(`${field} is missed in row ${rowIndex + 1}`);
                }
            });

            // Check if 'challan_date_time' has a valid date format (DD-MM-YYYY)
            if (row["challan_date_time"] && !this.isValidDateFormat(row["challan_date_time"])) {
                errors.push(`challan_date_time should be in DD-MM-YYYY format in row ${rowIndex + 1}`);
            }
        });

        // Combine missing column errors with missing field errors
        const allErrors = [...errors, ...missingColumns];

        return allErrors;
    };
    isValidDateFormat = (date) => {
        return /^(\d{2})-(\d{2})-(\d{4})$/.test(date); // Validates DD-MM-YYYY format
    }

    componentDidMount() {
        try {
            redirectURL.post("/dashboard/getLoginUsers", {})
                .then((resp) => {
                    let users = resp.data
                    this.setState({
                        rowData: users
                    });
                })
            
            redirectURL.post("/dashboard/getUserRoles", {})
                .then((resp) => {
                    let roleOps = []
                    resp.data.map((item) => {
                        roleOps.push({ "label": item, "value": item })
                    })
                    this.setState({
                        roleOptions: roleOps
                    });
                })

            redirectURL.post("/dashboard/getConsigneeState", {})
                .then((resp) => {
                    let consigneeStateOps = []
                    resp.data.map((item) => {
                        consigneeStateOps.push({ "label": item, "value": item })
                    })
                    this.setState({
                        consignee_state_options: consigneeStateOps
                    });
                })

        }
        catch (e) {
            console.log("error", e)
        }


    }

    closeAlert = () => {
        this.setState({
            show: false
        });

    }

    render() {
        let columnDefs = [
            {
                headerName: "Email Id",
                field: "email",
                resizable : true,
                filter : true,
                sortable:true,
                width: 200,
            },
            {
                headerName: "User Name",
                field: "username",
                resizable : true,
                filter : true,  
                sortable:true,              
                width: 140,
            },
            {
                headerName: "User Status",
                field: "verified",
                width: 120,
                resizable : true,
                filter : true,   
                sortable:true,             
                valueGetter: function (params) {
                    try {
                        if (params.data && params.data.verified == 1) {
                           return "Active"
                        }
                        else{
                            return "Not Active"
                        }
                    }
                    catch (e) { }
                }
            },
            {
                headerName: "Login Attempts",
                field: "login_attempts",
                width: 140,
                resizable : true,
                filter : true,
                sortable:true,
            },
            {
                headerName: "User Type",
                field: "user_type",
                width: 120,
                resizable : true,
                filter : true,
                sortable:true,
            },
            {
                headerName: "Supplier code",
                field: "supplier_code",
                width: 150,
                resizable : true,
                filter : true,
                sortable:true,
                valueGetter: function (params) {
                    try {
                        if (params.data && Array.isArray(params.data.supplier_code)) {
                            return params.data.supplier_code; 
                        } else {
                            return []; 
                        }
                    } catch (e) {
                        return []; 
                    }
                }
            },
            {
                headerName: "Consignee State",
                field: "consignee_state",
                width: 140,
                resizable : true,
                filter : true,
                sortable:true,
                valueGetter: function (params) {
                    try {
                        if (params.data && Array.isArray(params.data.consignee_state)) {
                            return params.data.consignee_state; 
                        } else {
                            return []; 
                        }
                    } catch (e) {
                        return []; 
                    }
                }
            },
            {
                headerName: "Sac Codes",
                field: "sac_code",
                width: 400,
                resizable: true,
                filter: true,
                autoHeight : true,
                sortable:true, 
                valueGetter: function (params) {
                    try {
                        if (params.data && Array.isArray(params.data.sac_code)) {
                            return params.data.sac_code; 
                        } else {
                            return []; 
                        }
                    } catch (e) {
                        return []; 
                    }
                },
                cellClass: 'wrap-text', // This CSS class ensures text wraps within the cell
                cellStyle: {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word' // Ensure that the content wraps within the cell
                }
            },
            
        ]
        const { modules, rowData, defaultColDef, detailCellRendererParams, sideBar, frameworkComponents, statusBar, paginationPageSize, rowSelection} = this.state
        return (

            <div className="container-fluid bscreen">
                {/* <h3 className="mt-3 text-left"><strong>{this.state.showCreateUser ? 'Create User' : 'User Information'}</strong></h3> */}
                {/* <div className="col-xl-12 mb-2 mt-2">
                    <h1 className="h3 text-gray-800"><strong>{this.state.showCreateUser ? 'Create User' : 'User Information'}</strong>
                    </h1>
                </div> */}
                    <div className="col-xl-12 mt-2" style={{marginLeft : "-28px"}}>
                        <h4 className={this.state.showCreateUser ? 'text-center':''}>
                            <b><span>{this.state.showCreateUser ? 'Create User' : 'User Information'} </span></b>

                        </h4>
                </div>
                <div className="col-xl-12 mb-10p">
                    <span className="layoutbtns">
                        <button type="button" className="float-right btn cs-btn btn-warning" onClick={this.onShowUploadDiv.bind(this)}>
                            <i className="icofont icofont-upload-alt"></i> Bulk Upload</button>
                        <button type="button" className="float-right btn cs-btn btn-info" style={{ marginRight: "10px" }} onClick={this.createUserr.bind(this)}>{this.state.showCreateUser ? 'View Users' : 'Create User'} </button>

                    </span>
                </div>
                {this.state.showCreateUser != true ?
                    <div style={{ width: "100%", height: "70vh" }} className="ag-theme-balham">
                        <AgGridReact
                            modules={modules}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            enableRangeSelection={true}
                            enableCharts={true}
                            detailCellRendererParams={detailCellRendererParams}
                            masterDetail={true}
                            onGridReady={this.onGridReady}
                            sideBar={sideBar}
                            frameworkComponents={frameworkComponents}
                            statusBar={statusBar}
                            stopEditingWhenGridLosesFocus={true}
                            paginationPageSize={paginationPageSize}
                            suppressRowClickSelection={true}
                            pagination={true}
                            headerHeight={50}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            rowSelection={rowSelection}
                            onCellClicked={this.onCellClicked}

                        />
                    </div>
                    : ""}
                {this.state.showCreateUser == true ?
                    <div className="card1 mt-3">
                        <form method="POST" className="consignment-form1">
                            <div className="form-groupp custom-truck-number-input">
                                <label>Email ID <span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="email"
                                    name="emailId"
                                    value={this.state.emailId}
                                    onChange={this.handleEmailChange}
                                    placeholder="Email Id"
                                />
                                {this.state.emailError && (
                                    <p style={{ color: 'red', marginBottom: '0rem' }}>{this.state.emailError}</p>
                                )}
                            </div>
                            <div className="form-groupp custom-truck-number-input">
                                <label>Username <span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="text"
                                    name="username"
                                    placeholder="Username"
                                    value={this.state.username}
                                    onChange={this.handleChange}
                                />
                            </div>



                            <div className="form-groupp custom-truck-number-input">
                                <label>First Name <span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={this.state.firstName}
                                    placeholder="First Name"
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-groupp custom-truck-number-input">
                                <label>Last Name</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={this.state.lastName}
                                    placeholder="Last Name"
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-groupp custom-truck-number-input">
                                <label>Mobile Number</label>
                                <input
                                    type="tel"
                                    name="userMobile"
                                    value={this.state.userMobile}
                                    placeholder="Mobile Number"
                                    onChange={this.handleChange}
                                />
                            </div>


                            <div className="form-groupp custom-truck-number-input">
                                <label>SAP ID</label>
                                <input
                                    type="text"
                                    name="sapId"
                                    value={this.state.sapId}
                                    placeholder="SAP ID"
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-groupp custom-truck-number-input">
                                <label>Zone</label>
                                <input
                                    type="text"
                                    name="zone"
                                    value={this.state.zone}
                                    placeholder="Zone"
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-groupp custom-truck-number-input">
                                <label>RO/AO</label>
                                <input
                                    type="text"
                                    name="ro"
                                    value={this.state.ro}
                                    placeholder="RO/AO"
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-groupp custom-truck-number-input">
                                <label>Roles <span style={{ color: "red" }}>*</span></label>
                                <Select
                                    closeMenuOnSelect={true}
                                    name="roles"
                                    className="border-radius-0"
                                    onChange={(event) => {
                                        this.setState({ roles: event });
                                    }}
                                    options={this.state.roleOptions}
                                    value={this.state.roles}
                                    required
                                />
                            </div>
                            <div className="form-groupp custom-truck-number-input">
                                <label>Filter Criteria <span style={{ color: "red" }}>*</span></label>
                                <Select
                                    closeMenuOnSelect={true}
                                    name="filterOptions"
                                    className="border-radius-0"
                                    onChange={(event) => {
                                        this.setState({ filterOption: event });
                                    }}
                                    options={[
                                        { value: 'all', label: 'All' },
                                        { value: 'consignee_state', label: 'Consignee State' },
                                        { value: 'sacCode', label: 'SAC Code' }
                                    ]}
                                    value={this.state.filterOption} // Bind the selected value to state
                                    required
                                />

                            </div>
                            {this.state.filterOption && this.state.filterOption.value == "sacCode" ?
                                <div className="form-groupp custom-truck-number-input">
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="d-flex flex-column" style={{ marginRight: '10px' }}>
                                            <label>Enter SAC Codes <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                type="text"
                                                name="sacCode"
                                                value={this.state.sacCode}
                                                onChange={this.handleChange}
                                                placeholder="Enter SAC Code"
                                                className="form-control"
                                            />
                                        </div>

                                        <button
                                            type="button"
                                            onClick={this.handleAddSACCode}
                                            className="btn btn-primary"
                                            style={{ padding: '5px 10px', marginTop: '25px', borderRadius: '2px' }}
                                        >
                                            Add
                                        </button>
                                    </div>
                                    {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}
                                    <div>
                                        <div
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(5, 1fr)',
                                                gap: '10px',
                                                marginTop: '20px',
                                            }}
                                        >
                                            {this.state.savedSACCodes.map((sac, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        padding: '5px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        backgroundColor: '#f9f9f9',
                                                    }}
                                                >
                                                    {sac}
                                                    <button
                                                        type="button"
                                                        onClick={() => this.handleDeleteSAC(sac)}
                                                        style={{
                                                            background: 'none',
                                                            border: 'none',
                                                            color: 'red',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        &#x2716;
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                : ""}

                            {this.state.filterOption && this.state.filterOption.value == "consignee_state" ?
                                <div className="form-groupp custom-truck-number-input">
                                    <label>Consignee State<span style={{ color: "red" }}>*</span></label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        name="consignee_state"
                                        className="border-radius-0"
                                        onChange={(event) => {
                                            this.setState({ consignee_state: event });
                                        }}
                                        options={this.state.consignee_state_options}
                                        value={this.state.consignee_state}
                                        required
                                    />
                                </div> : ""
                            }

                        </form>
                        <div className="form-group" style={{ textAlign: "center", marginTop: "40px" }}>
                            <button type="submit" onClick={this.handleSubmit.bind(this)} className="btn btn-success">Submit</button>
                        </div>


                    </div>
                    : ""}

                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>

                <div className={"slide-r " + this.state.sliderTranslate} style={{ overflow: "auto", zIndex: "9999" }}>
                    <div className="slide-r-title">
                        <h4>
                            Upload File
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll}>X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="col-xl-12 col-lg-12">
                            <form id="upform" className="theme-form" onSubmit={this.uploadFormHandler}>
                                <div className="form-group mt-20p">
                                    <label>Upload File</label>
                                    <input type="file" name="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success mr-1">Submit</button>
                                    <button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
                                </div>
                                <div className="form-group">
                                    <a className="btn btn-primary" href={require('../../assets/json/bulk_upload_template.xlsx')} target="_blank">Sample Template</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}

export default CreateShipment;
