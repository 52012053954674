import React, { Component } from 'react';
 
const ViewDamageChild= (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickViewDamageItem(props);
    };
// console.log("props ", props.data)
    return (
        <div>
           
            <button type="button"
              className={"btn btn-info nopadding font12"} 
              title="ViewRequest"
              onClick={handleClick} >
                  <i className="icofont icofont-eye f16"></i>&nbsp;With Defect
            </button>
        </div>
    );
};

export default ViewDamageChild;