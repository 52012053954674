
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');


const BillingActions = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowBillingDetails(props);
    };

    return (
        <div>
            <button onClick={handleClick} className="custom-btn label label-success" title="Track Courier">
                <i style={{ fontSize: "30px" }} className="icofont icofont-fast-delivery"></i>
            </button>

        </div>
    );
};

export default BillingActions;
