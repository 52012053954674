/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types'
import { isArray } from 'highcharts';
import $ from 'jquery';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class Loginform extends Component{
	constructor(props) {
		super(props);
		var pageTitle = "Login";
		this.state = {
			//pageTitle: pageTitle,
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			style: {},
			email:'',
			password:'',
			loginMessage:'',
			currentDepartmentCode: null ,
			login_url: "",
			otpAuthentication : 0,
			otp : "",
			otpMessage:"",
			successLogin : 1,
			is_load_management: 0,
			userData : []
		};
		this.cipher =this.cipher.bind(this);
		this.decipher = this.decipher.bind(this);

		var url = window.location.href;
		var arr = url.split("/");
		var ww = arr[2]
		// console.log("here ",ww)
		if(ww.indexOf("www.") >= 0)
		{
			var sp = ww.split("www.");
			var urlpathis = arr[0]+"//"+sp[1];
			// console.log("urlpathis ",urlpathis)
			window.location.href=urlpathis
		}
		
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount(){
		loadCaptcha();
		localStorage.setItem("_grecaptcha","")
		this.logPageView(); // Log page view to GA
		redirectURL.post("/lmconsignees/generateADFSMetaData").then((response)=>{
			var login_url = response.data;
			
			this.setState({
				login_url : login_url,
			})
		}).catch((e)=>{
			console.log(e);
		})		
	};
	componentWillReceiveProps(){
		var pageTitle = "Login";
		//console.log(this.props.is_enmovil_transporter,"teststststs")
		if(this.props.is_enmovil_transporter == 1)
		{
			var pageTitle = "Transporter Login";
		}
		else if(this.props.is_load_management == 1)
		{
			var pageTitle = "Route Optimization Login";
		}
		this.setState({
			pageTitle: pageTitle,
			is_enmovil_transporter: this.props.is_enmovil_transporter,
			is_load_management : this.props.is_load_management
		});
	};
	changeHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({[name]:value});
	}
	otpformHandler  = (event) => {
		event.preventDefault();
		var entered_otp = $("#entered_otp").val();
		if(entered_otp !='')
		{
			// var generateOtp = this.state.otp;
			// //console.log(generateOtp,entered_otp)
			// if(generateOtp == entered_otp)
			// {
				
				var userData = this.state.userData
				var userid = userData.userid;
				var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
				var usrotp = ppwwdd(entered_otp)
				var params = {
					otp : usrotp,
					userid : userid,
				}
				redirectURL.post("/consignments/otpSuccess",params).then(async (response1)=>{
					//console.log(userData.user,"userData.user")
					if(response1.data.message == "fail")
					{
						this.setState({
							otpMessage : "Invalid OTP"
						})
						$("#entered_otp").val("");
					}
					else
					{
						var userloggparams = {
							token:userData.token,
							userId:userData.userid
						}
						await redirectURL.post("/manage/saveuserlogged",userloggparams)
						.then((resp) => {
							console.log("resp ", resp)
						})
						
						//	console.log("In  LoginForm userData.user.dept_code = ", userData.user.dept_code);
						localStorage.setItem('token', userData.token);
									
						localStorage.setItem('userid', userData.userid);
						let deptCode = JSON.stringify(userData.user.dept_code);
						//console.log("In  LoginForm, userData = ", userData);
						localStorage.setItem('dept_code', deptCode);
						localStorage.setItem('is_admin', userData.user.is_admin);
						localStorage.setItem('is_dept_admin', userData.user.is_dept_admin);
						localStorage.setItem('is_global', userData.user.is_global);
						localStorage.setItem('firstname', userData.user.firstname);
						localStorage.setItem('username', userData.user.username);
						localStorage.setItem('lastname', userData.user.lastname);
						localStorage.setItem('email', userData.user.email);
						localStorage.setItem('user_type', userData.user.user_type);
						//console.log("userData.menus ", userData.menus);
						localStorage.setItem('usermenus', JSON.stringify(userData.menus));
						//console.log("userData.usermenucodes ", userData.usermenucodes);
						localStorage.setItem('usermenucodes', JSON.stringify(userData.usermenucodes));
						localStorage.setItem('transportercode', JSON.stringify(userData.user.transporter_code));
						localStorage.setItem('is_sales_type', userData.user.is_sales_type);
						localStorage.setItem('sales_type', userData.user.sales_type);
						localStorage.setItem('region', userData.user.region);
						localStorage.setItem('roles', userData.user.roles);
						localStorage.setItem('is_enmovil_transporter', this.state.is_enmovil_transporter);
						localStorage.setItem('is_load_management', this.state.is_load_management);
						localStorage.setItem('is_sub_user', userData.user.is_sub_user);
						localStorage.setItem('cha_code', userData.user.cha_code);
						
						var md = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
						var m = md(JSON.stringify(userData.user))
						localStorage.setItem('m', m);
						localStorage.setItem('supplier_code', JSON.stringify(userData.user.supplier_code));
						try{
							if(userData.user.ro_zone != undefined){
                            localStorage.setItem('ro_zone', userData.user.ro_zone);
							}
							else if(userData.user.vehicle_group != undefined)
							{
								localStorage.setItem('vehicle_group', userData.user.vehicle_group);
							}
							else{
								localStorage.setItem('vehicle_group', "");
							}
						}
						catch(e){}
						
						//console.log('users roles', typeof userData.user.roles);
						sessionStorage.setItem('ref', 1);
						var comp_code = "";
						if(userData.user.loading_port_code !=undefined)
						{
							var params = {
								loading_port_code : userData.user.loading_port_code
							}							
							await redirectURL.post("/lmconsignees/getLmLoadingPortName",params).then((response1)=>{
								//console.log(response1.data,"LLPN");
								var lpData = response1.data;
								var lpNames = [];
								if(lpData.length > 0)
								{
									lpData.map((item) => {
										if(item.comp_short_name != '' && item.comp_short_name != undefined)
										{
											lpNames.push({
												loading_port_code:item.loading_port_code,
												loading_port:item.comp_short_name,
											})
											if(userData.user.loading_port_code[0] == item.loading_port_code)
											{
												comp_code = item.comp_code;
												localStorage.setItem('comp_code', item.comp_code);
											}
										}
									})
									
								}
								//console.log(lpNames,"lpNames")
							localStorage.setItem('loadingPointName', JSON.stringify(lpNames));
								//console.log(localStorage.getItem("loadingPointName"),"Point Name")
							}).catch((e)=>{
								console.log(e);
							})
							localStorage.setItem('loading_port_code', userData.user.loading_port_code[0]);
						}
						else if(userData.user.is_load_management == 1)
						{
							if(userData.user.roles == "lm_site_admin")
							{
								await redirectURL.post("/lmconsignees/getLmLoadingPorts").then((response2)=>{
									//console.log(response1.data,"LLPN");
									var lpData = response2.data;
									var lpNames = [];
									if(lpData.length > 0)
									{
										lpData.map((item) => {
											if(item.comp_short_name != '' && item.comp_short_name != undefined)
											{
												lpNames.push({
													loading_port_code:item.loading_port_code,
													loading_port:item.comp_short_name,
												})
												if(userData.user.loading_port_code[0] == item.loading_port_code)
												{
													comp_code =  response2.data[0].comp_code;
													localStorage.setItem('comp_code', response2.data[0].comp_code);
												}
											}
										})
										comp_code =  lpData[0].comp_code;
										localStorage.setItem('comp_code', lpData[0].comp_code);
										localStorage.setItem('loading_port_code', lpData[0].loading_port_code);
									}
									//console.log(lpNames,"lpNames")
								localStorage.setItem('loadingPointName', JSON.stringify(lpNames));
									//console.log(localStorage.getItem("loadingPointName"),"Point Name")
								}).catch((e)=>{
									console.log(e);
								})
							}
						}
						if(userData.user.is_load_management !=undefined)
						{
							localStorage.setItem('is_load_management', userData.user.is_load_management);
							if(comp_code !='' && comp_code !=undefined)
							{
								var userloggparams = {
									token:userData.token,
									userId:userData.userid,
									comp_code:comp_code
								}
								await redirectURL.post("/manage/updateUserCompCode",userloggparams)
								.then((resp) => {
									console.log("resp ", resp)
								})
							}
						}
						if(userData.user.lm_department !=undefined)
						{
							localStorage.setItem('lm_department', userData.user.lm_department);
						}
						if(userData.user.designation !=undefined)
						{
							localStorage.setItem('designation', userData.user.designation);
						}
						if(userData.user.active_status !=undefined)
						{
							localStorage.setItem('active_status', userData.user.active_status);
						}
						if(userData.user.is_department_admin !=undefined)
						{
							localStorage.setItem('is_department_admin', userData.user.is_department_admin);
						}
						if(userData.user.staff_id !=undefined)
						{
							localStorage.setItem('staff_id', userData.user.staff_id);
						}
						if(userData.user.dept_head_id !=undefined)
						{
							localStorage.setItem('dept_head_id', userData.user.dept_head_id);
						}
						if(userData.user.report_manager_id !=undefined)
						{
							localStorage.setItem('report_manager_id', userData.user.report_manager_id);
						}
						
						this.setState({
							currentDepartmentCode: userData.user.dept_code
						});
						if (userData.token) {
						axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`;
						} else {
						delete axios.defaults.headers.common['Authorization'];
						}

						this.setState({
							username:'',
							email:'',
							password:'',
							loginMessage:userData.msg
						});
						this.setState({
							successLogin : 1
						})
					}
				}).catch((e)=>{
					console.log(e);
				})
			// }
			// else
			// {
			// 	this.setState({
			// 		otpMessage : "Invalid OTP"
			// 	})
			// 	$("#entered_otp").val("");
			// }
		}
		else
		{
			this.setState({
				otpMessage : "Invalid OTP"
			})
			$("#entered_otp").val("");
		}
	}

	resendOtp = (event) => {
		event.preventDefault();
		var userid = localStorage.getItem('userid');
		var params = {
			userid : userid,
			email : localStorage.getItem('email'),
			username : localStorage.getItem('username'),
		}
		redirectURL.post("/consignments/resendotp",params).then((response)=>{
			this.setState({
				"otpAuthentication" : response.data.otpAuthentication,
				"otp" : response.data.otp,
				"otpMessage" : "New OTP has been sent your Email"
			})
		}).catch((e)=>{
			console.log(e);
		})
	} 
	loginWithAdfs = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedLogin,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log(fdata);
		
		redirectURL.post('/lmconsignees/getMSILUsersData',{
			headers:{
			//       'Content-Disposition': 'application/x-www-form-urlencoded',
				'Content-Type':'application/json'
			}
		})
			.then( async (response) => {
				console.log("After login ",response);
				if(response.statusText == 'OK')
				{
					if(response.data.auth == true)
					{						
						if(response.data.otpAuthentication == 1 && response.data.otp !='')
						{
							this.setState({
								"otpAuthentication" : response.data.otpAuthentication,
								"otp" : response.data.otp,
								"otpMessage" : "OTP has been sent to your Email",
								"userData" : response.data,
								"successLogin" : 0,
							})
							$("#entered_otp").val("");
						}
						else
						{
							this.setState({
								"successLogin" : 1,
							})
							console.log(response.data.user,"response.data.user")
							var userloggparams = {
								token:response.data.token,
								userId:response.data.userid
							}
							await redirectURL.post("/manage/saveuserlogged",userloggparams)
							.then((resp) => {
								console.log("resp ", resp)
							})
							
							//	console.log("In  LoginForm response.data.user.dept_code = ", response.data.user.dept_code);
							localStorage.setItem('token', response.data.token);
												
							localStorage.setItem('userid', response.data.userid);
							let deptCode = JSON.stringify(response.data.user.dept_code);
							//console.log("In  LoginForm, response.data = ", response.data);
							localStorage.setItem('dept_code', deptCode);
							localStorage.setItem('is_admin', response.data.user.is_admin);
							localStorage.setItem('is_dept_admin', response.data.user.is_dept_admin);
							localStorage.setItem('is_global', response.data.user.is_global);
							localStorage.setItem('firstname', response.data.user.firstname);
							localStorage.setItem('username', response.data.user.username);
							localStorage.setItem('lastname', response.data.user.lastname);
							localStorage.setItem('email', response.data.user.email);
							localStorage.setItem('user_type', response.data.user.user_type);
							//console.log("response.data.menus ", response.data.menus);
							localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
							//console.log("response.data.usermenucodes ", response.data.usermenucodes);
							localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
							localStorage.setItem('transportercode', JSON.stringify(response.data.user.transporter_code));
							localStorage.setItem('is_sales_type', response.data.user.is_sales_type);
							localStorage.setItem('sales_type', response.data.user.sales_type);
							localStorage.setItem('region', response.data.user.region);
									
							localStorage.setItem('roles', response.data.user.roles);
							localStorage.setItem('is_enmovil_transporter', this.state.is_enmovil_transporter);
							localStorage.setItem('is_load_management', this.state.is_load_management);
							localStorage.setItem('is_sub_user', response.data.user.is_sub_user);
							localStorage.setItem('cha_code', response.data.user.cha_code);
							var md = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
							var m = md(JSON.stringify(response.data.user))
							localStorage.setItem('m', m);
							
							//localStorage.setItem('m', JSON.stringify(response.data.user));
							localStorage.setItem('supplier_code', JSON.stringify(response.data.user.supplier_code));
							try{
								if(response.data.user.ro_zone != undefined){
								localStorage.setItem('ro_zone', response.data.user.ro_zone);
								}
								else if(response.data.user.vehicle_group != undefined)
								{
									localStorage.setItem('vehicle_group', response.data.user.vehicle_group);
								}
								else{
									localStorage.setItem('vehicle_group', "");
								}
							}
							catch(e){}
							
							//console.log('users roles', typeof response.data.user.roles);
							sessionStorage.setItem('ref', 1);
							var comp_code = "";
							// if(response.data.user.loading_port_code !=undefined)
							// {
							// 	var params = {
							// 		loading_port_code : response.data.user.loading_port_code
							// 	}							
							// 	await redirectURL.post("/lmconsignees/getLmLoadingPortName",params).then((response1)=>{
							// 		//console.log(response1.data,"LLPN");
							// 		var lpData = response1.data;
							// 		var lpNames = [];
							// 		if(lpData.length > 0)
							// 		{
							// 			lpData.map((item) => {
							// 				if(item.comp_short_name != '' && item.comp_short_name != undefined)
							// 				{
							// 					lpNames.push({
							// 						loading_port_code:item.loading_port_code,
							// 						loading_port:item.comp_short_name,
							// 					})
							// 					if(response.data.user.loading_port_code[0] == item.loading_port_code)
							// 					{
							// 						comp_code = item.comp_code;
							// 						localStorage.setItem('comp_code', item.comp_code);
							// 					}
							// 				}
							// 			})
										
							// 		}
							// 		//console.log(lpNames,"lpNames")
							// 	localStorage.setItem('loadingPointName', JSON.stringify(lpNames));
							// 		//console.log(localStorage.getItem("loadingPointName"),"Point Name")
							// 	}).catch((e)=>{
							// 		console.log(e);
							// 	})
							// 	localStorage.setItem('loading_port_code', response.data.user.loading_port_code[0]);
							// }
							if(response.data.user.is_load_management == 1)
							{
								if(response.data.user.roles == "lm_site_admin")
								{
									await redirectURL.post("/lmconsignees/getLmLoadingPorts").then((response2)=>{
										//console.log(response1.data,"LLPN");
										var lpData = response2.data;
										var lpNames = [];
										if(lpData.length > 0)
										{
											lpData.map((item) => {
												if(item.comp_short_name != '' && item.comp_short_name != undefined)
												{
													lpNames.push({
														loading_port_code:item.loading_port_code,
														loading_port:item.comp_short_name,
													})
												}
											})
											comp_code = lpData[0].comp_code;
											localStorage.setItem('comp_code', lpData[0].comp_code);
											localStorage.setItem('loading_port_code', lpData[0].loading_port_code);
										}
										//console.log(lpNames,"lpNames")
									localStorage.setItem('loadingPointName', JSON.stringify(lpNames));
										//console.log(localStorage.getItem("loadingPointName"),"Point Name")
									}).catch((e)=>{
										console.log(e);
									})
								}
							}
							if(response.data.user.is_load_management !=undefined)
							{
								localStorage.setItem('is_load_management', response.data.user.is_load_management);
								if(comp_code !='' && comp_code !=undefined)
								{
									var userloggparams = {
										token:response.data.token,
										userId:response.data.userid,
										comp_code:comp_code
									}
									await redirectURL.post("/manage/updateUserCompCode",userloggparams)
									.then((resp) => {
										console.log("resp ", resp)
									})
								}
							}
							if(response.data.user.lm_department !=undefined)
							{
								localStorage.setItem('lm_department', response.data.user.lm_department);
							}
							if(response.data.user.designation !=undefined)
							{
								localStorage.setItem('designation', response.data.user.designation);
							}
							if(response.data.user.active_status !=undefined)
							{
								localStorage.setItem('active_status', response.data.user.active_status);
							}
							if(response.data.user.is_department_admin !=undefined)
							{
								localStorage.setItem('is_department_admin', response.data.user.is_department_admin);
							}
							if(response.data.user.staff_id !=undefined)
							{
								localStorage.setItem('staff_id', response.data.user.staff_id);
							}
							if(response.data.user.dept_head_id !=undefined)
							{
								localStorage.setItem('dept_head_id', response.data.user.dept_head_id);
							}
							if(response.data.user.report_manager_id !=undefined)
							{
								localStorage.setItem('report_manager_id', response.data.user.report_manager_id);
							}
							this.setState({
								currentDepartmentCode: response.data.user.dept_code
							});
							if (response.data.token) {
							axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
							} else {
							delete axios.defaults.headers.common['Authorization'];
							}

							this.setState({
								username:'',
								email:'',
								password:'',
								loginMessage:response.data.msg
							});
						}
					}
				else{
				
					this.setState({
						username:'',
						email:'',
						password:'',
						loginMessage:response.data.msg
					});
					
				}
					
					
				}
				else
				{
					this.setState({
						username:'',
						email:'',
						password:'',
						loginMessage:response.data.msg
					});
				}
			//console.log(localStorage.getItem('token'));
			})
			.catch(function (error) {
				console.log(error);
			});
		
			
			// another call to find menus for the user
			redirectURL.post('/menus/getmenus', {},{
			headers:{
				'Content-Type':'application/json'
			}
		})
		.then( response => {
			//console.log("Menus ",response);
			if (response.statusText == 'OK') {
				//console.log("In  SIDEBAR LoginForm MENUS response.data = ", response.data);
				let menuText = JSON.stringify(response.data);
				localStorage.setItem('menuText', menuText);

			} else {
				this.setState({
					username:'',
					email:'',
					password:'',
					loginMessage:response.data.msg
				});
			}
			let MENUITEMS = getMenuLocalStorage();

			//console.log("IN Sidebar componentWillMount, From DB MENUITEMS menuList ", MENUITEMS);
		})
		.catch(function (error) {
			console.log(error);
		});
	}
	formHandler = (event) => {
		
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedLogin,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		// var check_domain = /enmovil\.in/;
		// if (check_domain.test(this.state.email.trim().toLowerCase())) {
		// 	this.setState({
		// 		username: '',
		// 		email: '',
		// 		password: '',
		// 		loginMessage: "Please Login Using Enmovil SSO"
		// 	});
		// }
		// else {

		var formdata = new FormData(event.target);
		// console.log("formdata ",formdata);
		var email = this.state.email.replace(/\s/g,"");
		var is_enmovil_transporter = $("#is_enmovil_transporter").val();

		var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var usrpwd = ppwwdd(this.state.password)
		//console.log("ppwwdd ", usrpwd)

		var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var usremail = ppwwdd(email)
		const fdata = {
			em:usremail,
			a:usrpwd,
			is_enmovil_transporter: is_enmovil_transporter
		}
		// console.log("_grecaptcha ", localStorage.getItem("_grecaptcha"));
		//  if(email == "venkatesh.jalla@enmovil.in" ||  email == "jalla.venkatesh@gmail.com"
		//  ||  email == "nanda@enmovil.in" || email == "rake.planning@enmovil.in" || email == "moganty.venkat@enmovil.in")
		//  {
			var loginCheck = 1;
			if(this.props.is_load_management == 1)
			{
				if(email == "moganty.venkat@enmovil.in")
				{
					loginCheck = 1;
				}
				else
				{
					loginCheck = 0;
				}
			}
				if(loginCheck == 1)
			{
				redirectURL.post('/applogin', fdata,{
				//redirectURL.post('/applogin', fdata,{
					headers:{
						'Content-Disposition': 'application/x-www-form-urlencoded',
						'Access-Control-Allow-Methods': 'POST'
						// 'Content-Type': 'application/x-www-form-urlencoded',
						// 'Content-Type':'application/json'
					}
				})
					.then( async (response) => {
						console.log("After login ",response);
						if(response.statusText == 'OK')
						{
							if(response.data.auth == true)
							{							
								if(response.data.otpAuthentication == 1)
								{
									this.setState({
										"otpAuthentication" : response.data.otpAuthentication,
										//"otp" : response.data.otp,
										"otpMessage" : "OTP has been sent to your Email",
										"userData" : response.data,
										"successLogin" : 0,
									})
									$("#entered_otp").val("");
								}
								else
								{
									this.setState({
										"successLogin" : 1,
									})
								//	console.log(response.data.user,"response.data.user")
									//var verifytkn = jwtDecode(response.data.token);
									//console.log("verify token ", verifytkn);	
									// if(verifytkn.header.alg == "HS256" && verifytkn.payload.id == email)
									// {

										var userloggparams = {
											token:response.data.token,
											userId:response.data.userid
										}
										await redirectURL.post("/manage/saveuserlogged",userloggparams)
										.then((resp) => {
											console.log("resp ", resp)
										})
										
										//	console.log("In  LoginForm response.data.user.dept_code = ", response.data.user.dept_code);
										localStorage.setItem('token', response.data.token);
															
										localStorage.setItem('userid', response.data.userid);
										let deptCode = JSON.stringify(response.data.user.dept_code);
										//console.log("In  LoginForm, response.data = ", response.data);
										localStorage.setItem('dept_code', deptCode);
										localStorage.setItem('is_admin', response.data.user.is_admin);
										localStorage.setItem('is_dept_admin', response.data.user.is_dept_admin);
										localStorage.setItem('is_global', response.data.user.is_global);
										localStorage.setItem('firstname', response.data.user.firstname);
										localStorage.setItem('username', response.data.user.username);
										localStorage.setItem('lastname', response.data.user.lastname);
										localStorage.setItem('is_sales_type', response.data.user.is_sales_type);
										localStorage.setItem('is_tracking_view',response.data.user.is_tracking_view)
										localStorage.setItem('region', response.data.user.region);
										localStorage.setItem('sales_type', response.data.user.sales_type);
										var useremailat = response.data.user.email.replace("[at]","@")
										var useremail = useremailat.replace("[dot]",".")
										
										localStorage.setItem('email', useremail);
										localStorage.setItem('user_type', response.data.user.user_type);
										// localStorage.setItem('prt_transporter_code', response.data.user.prt_transporter_code);
										localStorage.setItem('prt_transporter_code', JSON.stringify(response.data.user.prt_transporter_code));
										
										//console.log("response.data.menus ", response.data.menus);
										localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
										//console.log("response.data.usermenucodes ", response.data.usermenucodes);
										localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
										localStorage.setItem('transportercode', JSON.stringify(response.data.user.transporter_code));
										
										localStorage.setItem('roles', response.data.user.roles);
										localStorage.setItem('is_enmovil_transporter', this.state.is_enmovil_transporter);
										localStorage.setItem('is_sub_user', response.data.user.is_sub_user);
										localStorage.setItem('cha_code', response.data.user.cha_code);
										var md = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
										var m = md(JSON.stringify(response.data.user))
										localStorage.setItem('m', m);
										localStorage.setItem('supplier_code', JSON.stringify(response.data.user.supplier_code));
										if(![undefined,null , "undefined" , ""].includes(response.data.user.sac_code)){
											localStorage.setItem('sac_code', response.data.user.sac_code);
										}
										try{
											if(response.data.user.ro_zone != undefined){
												localStorage.setItem('ro_zone', response.data.user.ro_zone);
											}
											else if(response.data.user.vehicle_group != undefined)
											{
												localStorage.setItem('vehicle_group', response.data.user.vehicle_group);
											}
											else{
												localStorage.setItem('vehicle_group', "");
											}
										}
										catch(e){}
										
										//console.log('users roles', typeof response.data.user.roles);
										sessionStorage.setItem('ref', 1);
										var comp_code = "";
										if(response.data.user.loading_port_code !=undefined)
										{
											var params = {
												loading_port_code : response.data.user.loading_port_code
											}							
											await redirectURL.post("/lmconsignees/getLmLoadingPortName",params).then((response1)=>{
												//console.log(response1.data,"LLPN");
												var lpData = response1.data;
												var lpNames = [];
												if(lpData.length > 0)
												{
													lpData.map((item) => {
														if(item.comp_short_name != '' && item.comp_short_name != undefined)
														{
															lpNames.push({
																loading_port_code:item.loading_port_code,
																loading_port:item.comp_short_name,
															})
															if(response.data.user.loading_port_code[0] == item.loading_port_code)
															{
																comp_code = item.comp_code;
																localStorage.setItem('comp_code', item.comp_code);
															}
														}
													})
													
												}
												//console.log(lpNames,"lpNames")
											localStorage.setItem('loadingPointName', JSON.stringify(lpNames));
												//console.log(localStorage.getItem("loadingPointName"),"Point Name")
											}).catch((e)=>{
												console.log(e);
											})
											localStorage.setItem('loading_port_code', response.data.user.loading_port_code[0]);
										}
										else if(response.data.user.is_load_management == 1)
										{
											if(response.data.user.roles == "lm_site_admin")
											{
												await redirectURL.post("/lmconsignees/getLmLoadingPorts").then((response2)=>{
													//console.log(response1.data,"LLPN");
													var lpData = response2.data;
													var lpNames = [];
													if(lpData.length > 0)
													{
														lpData.map((item) => {
															if(item.comp_short_name != '' && item.comp_short_name != undefined)
															{
																lpNames.push({
																	loading_port_code:item.loading_port_code,
																	loading_port:item.comp_short_name,
																})
															}
														})
														comp_code = lpData[0].comp_code;
														localStorage.setItem('comp_code', lpData[0].comp_code);
														localStorage.setItem('loading_port_code', lpData[0].loading_port_code);
													}
													//console.log(lpNames,"lpNames")
												localStorage.setItem('loadingPointName', JSON.stringify(lpNames));
													//console.log(localStorage.getItem("loadingPointName"),"Point Name")
												}).catch((e)=>{
													console.log(e);
												})
											}
										}
										if(response.data.user.is_load_management !=undefined)
										{
											localStorage.setItem('is_load_management', response.data.user.is_load_management);
											if(comp_code !='' && comp_code !=undefined)
											{
												var userloggparams = {
													token:response.data.token,
													userId:response.data.userid,
													comp_code:comp_code
												}
												await redirectURL.post("/manage/updateUserCompCode",userloggparams)
												.then((resp) => {
													console.log("resp ", resp)
												})
											}
										}
										if(response.data.user.lm_department !=undefined)
										{
											localStorage.setItem('lm_department', response.data.user.lm_department);
										}
										if(response.data.user.designation !=undefined)
										{
											localStorage.setItem('designation', response.data.user.designation);
										}
										if(response.data.user.active_status !=undefined)
										{
											localStorage.setItem('active_status', response.data.user.active_status);
										}
										if(response.data.user.is_department_admin !=undefined)
										{
											localStorage.setItem('is_department_admin', response.data.user.is_department_admin);
										}
										if(response.data.user.staff_id !=undefined)
										{
											localStorage.setItem('staff_id', response.data.user.staff_id);
										}
										if(response.data.user.dept_head_id !=undefined)
										{
											localStorage.setItem('dept_head_id', response.data.user.dept_head_id);
										}
										if(response.data.user.report_manager_id !=undefined)
										{
											localStorage.setItem('report_manager_id', response.data.user.report_manager_id);
										}
										this.setState({
											currentDepartmentCode: response.data.user.dept_code
										});
										if (response.data.token) {
										axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
										} else {
										delete axios.defaults.headers.common['Authorization'];
										}

										this.setState({
											username:'',
											email:'',
											password:'',
											loginMessage:response.data.msg
										});
									// }
									// else{
									// 	this.setState({
									// 		username:'',
									// 		email:'',
									// 		password:'',
									// 		loginMessage:"Invalid Token"
									// 	});
										
									// }
								}
								
							}
						else{
						
							this.setState({
								username:'',
								email:'',
								password:'',
								loginMessage:response.data.msg
							});
							
						}
							
							
						}
						else
						{
							this.setState({
								username:'',
								email:'',
								password:'',
								loginMessage:response.data.msg
							});
						}
					//console.log(localStorage.getItem('token'));
					})
					.catch(function (error) {
						console.log(error);
					});
				}
				else
				{
					this.setState({
						username:'',
						email:'',
						password:'',
						loginMessage:"You dont access to login from here, Please use Login with adfs"
					});
				}
		// }
		// else{
		// 	this.setState({
		// 		username:'',
		// 		email:'',
		// 		password:'',
		// 		loginMessage:"Please check captcha"
		// 	});
		// }
			
			// another call to find menus for the user
			redirectURL.post('/menus/getmenus', {},{
			headers:{
				'Content-Type':'application/json'
			}
		})
		.then( response => {
			//console.log("Menus ",response);
			if (response.statusText == 'OK') {
				//console.log("In  SIDEBAR LoginForm MENUS response.data = ", response.data);
				let menuText = JSON.stringify(response.data);
				localStorage.setItem('menuText', menuText);

			} else {
				this.setState({
					username:'',
					email:'',
					password:'',
					loginMessage:response.data.msg
				});
			}
			let MENUITEMS = getMenuLocalStorage();

			//console.log("IN Sidebar componentWillMount, From DB MENUITEMS menuList ", MENUITEMS);
		})
		.catch(function (error) {
			console.log(error);
		});
	// }
	}

	getUserDashboardUrl(pathToMatch = null) {
 
		var uroles = localStorage.getItem("roles");
		var arr = []
				
		
		// redirectURL.post("/menus/roleinfo",{role:uroles})
		// .then((res) => {
		// 	//console.log("repson ", response.data)
		// 	var rec = res.data
		// 	if(rec.length > 0)
		// 	{
		// 		rec.map((item) => {
		// 			if(item.dashboard_url != '' && item.dashboard_url != undefined)
		// 			{
		// 				arr.push(item.dashboard_url)
		// 			}
		// 		})
				
		// 	}
		// 	else
		// 	{
		// 		arr.push("/dashboard/default")
		// 	}
		// 	if(arr.length > 0)
		// 	{
				
		// 		this.setState({
		// 			landingpage:arr[0]
		// 		});
		// 	}
		// 	else
		// 	{
		// 		this.setState({
		// 			landingpage:"/dashboard/default"
		// 		});
		// 	}
		// 	//return dashboardUrl;
		// })

		
		let dashboardUrl = "/activecourierconsignments";
		if(uroles.includes("CUSTOMER") === true)
		{
			dashboardUrl = "/trips";
		}
		else if(uroles.includes("TRANSPORTER") === true){
			dashboardUrl = "/epodScreen"
		}
		return dashboardUrl;
	}


	cipher = salt => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
	
		return text => text.split('')
			.map(textToChars)
			.map(applySaltToChar)
			.map(byteHex)
			.join('');
	}
	
	decipher = salt => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
		return encoded => encoded.match(/.{1,2}/g)
			.map(hex => parseInt(hex, 16))
			.map(applySaltToChar)
			.map(charCode => String.fromCharCode(charCode))
			.join('');
	}

	render(){
		if(localStorage.getItem('token') != '' && localStorage.getItem('token') != null)
		{
			var successLogin = this.state.successLogin;
			if(successLogin == 1)
			{
				let dashboardUrl = this.getUserDashboardUrl();
				//let dashboardUrl = this.state.landingpage;
				// return <Redirect to="dashboard/snddefault" push={true} />

				return <Redirect to={dashboardUrl} push={true} />
			}
		}
		return (
				<div>
				 {(this.state.otpAuthentication == 1) ?
				 <form className="theme-form" onSubmit={this.otpformHandler}  autoComplete="off">
					 {(this.state.otpMessage)?
					 <div className="alert alert-danger">{this.state.otpMessage}</div>
						:""}
                     <div className="form-group">
                         <label className="col-form-label pt-0">Enter OTP</label>
                         <input autoComplete="off" required type="text" name="entered_otp" id="entered_otp"  className="form-control form-control-lg"/>
                     </div>
                     
                     <div className="form-group form-row mt-3">
						 <div className="col-md-12 textAlignCenter">
                             <button type="submit" id="loginButton" className="btn btn-secondary">Submit
                             </button>
                         </div>
                     </div>

					<div className="form-group form-row mt-3">
						 <div className="col-md-12">
                             <a href="javascript:;" onClick={this.resendOtp} className="float-right">Resend OTP</a>
                         </div>
                 	</div>
				</form>
				:
				<form className="theme-form" id="lForm" onSubmit={this.formHandler} >
					{(this.state.loginMessage)?
					<div className="alert alert-danger">{this.state.loginMessage}</div>
					:""}
					<div className="form-group">
						<label className="col-form-label pt-0">Email ID</label>
						<input required type="text" name='email' className="form-control form-control-lg" value={this.state.email} onChange={this.changeHandler} autoComplete="off" />
					</div>
					<div className="form-group">
						<label className="col-form-label">Password</label>
						<input required type="password" name='password' value={this.state.password} onChange={this.changeHandler} className="form-control form-control-lg" autoComplete="off" />
					</div>
					<div className="form-group form-row mt-3">
						<div className="col-md-12">
							{/* <div class="g-recaptcha" data-callback="recaptchaCallback" data-sitekey="6LfhvrAcAAAAADHZb9CQ2QE7UpTBcJV_WLEzpA1J"></div> */}
							<button type="submit" id="loginButton" className="btn btn-danger">LOGIN
							</button>
							
						</div>
					</div>
					<input type="hidden" name="is_enmovil_transporter" id="is_enmovil_transporter" value={this.props.is_enmovil_transporter}></input>

					{(this.props.is_load_management == 1) ?
					<div className="form-group form-row mt-3">
					<div className="col-md-4">
						<a href={"/resetpassword"} className="float-left">Forgot Password</a>
					</div>
					<div className="col-md-5">
						{/* <a href={"/register"} className="float-left">Register</a> */}
					</div>
					<div className="col-md-3">
						<a href={this.state.login_url} className="float-right">Login with ADFS</a>
						{/* <a onClick={this.loginWithAdfs} className="btn btn-primary float-right">Login with ADFS</a> */}
					</div>
				</div>
					: 
				<div className="form-group form-row mt-3">
						<div className="col-md-6">
							<a href={"/resetpassword"} className="float-left">Forgot Password</a>
						</div>
						<div className="col-md-6">
							{/* <a href={"/register"} className="float-right">Register</a> */}
						</div>
					</div>
				}
				</form> 
				 }
			</div>
		)
	}
};
function getMenuLocalStorage() {
    let menuText = localStorage.getItem('menuText');
    let MENUITEMS = null;
    //   console.log("deptCodes = ", deptCodes);
    //   console.log("Type of deptCodes = ", typeof deptCodes);
       try {
         MENUITEMS = JSON.parse(menuText);
       } catch (error) {
           console.log('Error while Parsing menuText', error);
       }
       return MENUITEMS;
}
Loginform.contextTypes={
		router:PropTypes.object.isRequired
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function jwtDecode(t) {
	let token = {};
	token.raw = t;
	token.header = JSON.parse(window.atob(t.split('.')[0]));
	token.payload = JSON.parse(window.atob(t.split('.')[1]));
	return (token)
  }

function loadCaptcha(){
	var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.google.com/recaptcha/api.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function onSubmit(token) {
	document.getElementById("lForm").submit();
  }
  $(document).ready(function(){
	  checkCaptcha()
  })
var grecaptcha;
function checkCaptcha(){
	var checkCaptch = false;
     var verifyCallback = function(response) {
        if (response == "") {
             checkCaptch = false;
         }
         else {
             checkCaptch = true;
         }
     };
     $(document).ready(function() {
         $("#loginButton").click(function() {
             if (checkCaptch && grecaptcha.getResponse()!="") {
				  //Write your success code here
				  alert("here")
             }
         });
     })
}