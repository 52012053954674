/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
var isNonEmpty = function(string) {
    if(string == '' || string == undefined)
    {
        return false;
    }
    else{
        return true;
    }
}
exports.isNonEmpty = isNonEmpty;

var isObjFieldNonEmpty = function(obj) {
    var isReturn = true;
    for(var oField in obj)
    {
        if(obj[oField] == "")
        {
            isReturn = false;
        }
    }
    return isReturn;
}
exports.isObjFieldNonEmpty = isObjFieldNonEmpty;