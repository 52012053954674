import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const UpdateTransitDay = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowCourierTransitTime(props);
    };

    return (
        <div>
			<button onClick={handleClick} className="btn btn-warning f12" style={{padding:"2px 10px"}} title="Update Fixed Transit Days">Update Transit </button>
       
        </div>
    );
};

export default UpdateTransitDay;