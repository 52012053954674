import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

drilldown(Highcharts);
export default class ColumnGroupDrilldownChart extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	// console.log("this.props.drilldownseries ", this.props.drilldownseries)
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions =  {
            chart: {
              type: this.props.chartType
            },
            credits : false,
            title: {
                text: this.props.title
            },
            subtitle: {
                text: this.props.subtitle
            },
            
            xAxis: {
                type: 'category',
                title: {
                    text: null
                },
                labels: {
                    enabled:true,//default is true
                    //y : 20, rotation: -45, align: 'right' 
                }
    
            },
            yAxis: {
                min: 0,
                title: {
                    text: this.props.yaxistitle
                }
            },
            legend: {
              enabled: true
            },
          
            plotOptions: {
                
                colorByPoint : true,
                series: {
                    borderWidth: 0,
                    dataLabels: {
                    enabled: true,
                    format:"{point.label}"
                    }
                }
            },
          
            series: this.props.series,
            drilldown: {
              series: this.props.drilldownseries
            }
          }
              

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}












