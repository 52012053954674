/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
 
const RevertPODButton = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickRevertPOD(props);
    };
// console.log("props ", props.data)
    return (
        // <div>
        //     <a href={"javascript:;"} 
        //         onClick={handleClick}
        //       className={"btn btn-danger nopadding font12"} 
        //       title="Upload POD" >
        //           <i className="icofont icofont-eye f16"></i>&nbsp; Upload POD
        //     </a>          
        // </div>
        <div>
        <button onClick={handleClick} className="btn btn-danger" style={{padding: "2px 10px",fontSize: "13px",margin: "0"}} title="Revert Pod" >Revert POD </button>
    </div>
    );
};

export default RevertPODButton;