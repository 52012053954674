/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

// import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
// import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
// import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import { getHyphenDDMMMYYYYHHMM } from "../common/utils";
import CSVFileValidator from 'csv-file-validator'

export default class UploadBillingTrips extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modules: AllModules,
			showApiInp: "show-n",
			csvcontent: "",
			isuploadcsv: 0,
			defaultColDef: {
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable: true,
				sortable: true
			},
			frameworkComponents: {},
			detailCellRendererParams: {
				detailGridOptions: {
					columnDefs: [
						{ field: 'callId' },
						{ field: 'direction' },
						{ field: 'number', minWidth: 150 },
						{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
						{ field: 'switchCode', minWidth: 150 },
					],
					defaultColDef: {
						flex: 1,
					},
				},
				getDetailRowData: (params) => {
					params.successCallback(params.data.callRecords);
				},
			},
			rowData: [],
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			showgrid: "show-n"
		}
		this.uploadCSVFile = this.uploadCSVFile.bind(this);
		this.changeCSVFile = this.changeCSVFile.bind(this);
		this.loadTrips = this.loadTrips.bind(this);
	}
	componentDidMount() {
		redirectURL.post("/billing/configurations")
			.then((response) => {
				//console.log("response ", response.data)
				var records = response.data.records;
				if (records.length > 0) {
					if (records[0].trips_data_from == "api") {
						this.setState({
							isuploadcsv: 0
						})
						this.loadTrips();
					}
					else {
						this.setState({
							isuploadcsv: 1
						})
						this.loadTrips();
					}
				}
				// else {
				// 	redirectToConfigurations()
				// }
			})
	}
	loadTrips() {
		var params = {
			uploadtype: "csv"
		}
		redirectURL.post("/billing/trips", params,{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem("userid")
			  }
		  })
			.then((response) => {
				// console.log("response ", response.data)
				var records = response.data.records;
				if (records.length > 0) {
					this.setState({
						showgrid: "show-m"
					})
				}
				else {

					this.setState({
						showgrid: "show-n"
					})
				}
				this.setState({
					rowData: records
				})

			})
	}
	// changeSelectItem(column,opt)
	// {
	//     // console.log("columnevent ", column)
	//     // console.log("column ", opt)
	//     this.setState({
	//         [column]:opt
	//     })
	// }
	changeCSVFile(e) {
		const config = {
			headers: [
				{
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'dept_code',
					inputName: 'dept_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consigner_code',
					inputName: 'consigner_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consigner_name',
					inputName: 'consigner_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'invoice_no',
					inputName: 'invoice_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'invoice_date',
					inputName: 'invoice_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'invoice_time',
					inputName: 'invoice_time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				// { 
				// 	name: 'gate_in_time',
				// 	inputName: 'gate_in_time',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				{
					name: 'gate_out_time',
					inputName: 'gate_out_time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignee_code',
					inputName: 'consignee_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignee_name',
					inputName: 'consignee_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignee_area',
					inputName: 'consignee_area',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignee_city',
					inputName: 'consignee_city',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignee_state',
					inputName: 'consignee_state',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'distance_in_km',
					inputName: 'distance_in_km',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'transit_time',
					inputName: 'transit_time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'transporter_code',
					inputName: 'transporter_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'transporter_name',
					inputName: 'transporter_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'zone',
					inputName: 'zone',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'zone_code',
					inputName: 'zone_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'distance_travelled_in_24_hrs_kms',
					inputName: 'distance_travelled_in_24_hrs_kms',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				// { 
				// 	name: 'aprox_dist_travelled_from_start',
				// 	inputName: 'aprox_dist_travelled_from_start',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// { 
				// 	name: 'approx_dist_calc_till',
				// 	inputName: 'approx_dist_calc_till',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				{
					name: 'recent_dealer_reported',
					inputName: 'recent_dealer_reported',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'total_travelled_trip_dist_km',
					inputName: 'total_travelled_trip_dist_km',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'trip_duration_days',
					inputName: 'trip_duration_days',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'total_distance_travelled_km',
					inputName: 'total_distance_travelled_km',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'total_google_distance_travelled_km',
					inputName: 'total_google_distance_travelled_km',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					csvcontent: csvData.data
				});
			})
			.catch(err => { })

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				console.log("contents ", contents)

				document.getElementById("csvdata").innerHTML = "<pre>" + contents + "</pre>";
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
			// console.log("tt ",tt)
		});
		//console.log("Out ", out);

		// this.setState({
		// 	csvcontent:e.target.files[0]
		// });
	}
	uploadCSVFile(event) {
		event.preventDefault();
		// console.log(this.state.csvcontent)
		if (this.state.csvcontent != "" && this.state.csvcontent != undefined) {
			var params = {
				csvcontent: JSON.stringify(this.state.csvcontent)
			}
			redirectURL.post("/billing/savecsvtrips", params)
				.then((response) => {
					if (response.data.status == "success") {
						redirectToTripsSummaryPage()
					}
				})
		}
		else {
			this.setState({
				show: true,
				basicTitle: "Please upload csv file",
				basicType: "danger"
			})
		}
	}
	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

	};
	render() {
		var columnDefs = [
			{
				headerName: Constants.TRIP_TRUCK_NO,
				field: "truck_no",
				width: 120,
			},
			{
				headerName: Constants.TRIP_CONSIGN_NO,
				field: "consignment_code",
				width: 160,
			},
			{
				headerName: Constants.TRIP_INV_DATE,
				field: "invoice_date",
				width: 140,
				valueGetter: function (params) {
					try {
						if (params.data.invoice_date != undefined && params.data.invoice_date != "") {
							return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
						}

					}
					catch (e) { }
				}
			},
			{
				headerName: Constants.TRIP_REACHED_DEST_DATE,
				field: "recent_dealer_reported",
				width: 140,
				valueGetter: function (params) {
					try {
						if (params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != "") {
							return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
						}

					}
					catch (e) { }
				}
			},
			{
				headerName: Constants.TRIP_PLANT_NAME,
				field: "consigner_name",
				width: 100,
			},
			{
				headerName: Constants.TRIP_CUSTOMER_CODE,
				field: "consignee_code",
				width: 100,
			},
			{
				headerName: Constants.TRIP_CUSTOMER_NAME,
				field: "consignee_name",
				width: 180,
			},
			{
				headerName: Constants.TRIP_CUSTOMER_CITY,
				field: "consignee_city",
				width: 180,
			},
			{
				headerName: Constants.TRIP_CUSTOMER_STATE,
				field: "consignee_state",
				width: 180,
			},
			{
				headerName: Constants.TRIP_TRANSPORTER_CODE,
				field: "transporter_code",
				width: 180,
			},
			{
				headerName: Constants.TRIP_TRANSPORTER_NAME,
				field: "transporter_name",
				width: 180,
			},
			{
				headerName: Constants.TRIP_TOTAL_DISTANCE_KM,
				field: "total_distance_travelled_km",
				width: 140,
				valueGetter: function (params) {
					try {
						if (params.data.total_distance_travelled_km != undefined && params.data.total_distance_travelled_km != "") {
							return parseInt(parseInt(params.data.total_distance_travelled_km) / 1000);
						}
					}
					catch (e) { }
				}
			},
			{
				headerName: Constants.TRIP_TOTAL_TRIP_DISTANCE_KM,
				field: "total_travelled_trip_dist_km",
				width: 100,
			},
			{
				headerName: Constants.TRIP_DURATION_DAYS,
				field: "trip_duration_days",
				width: 100,
			},
			{
				headerName: "Data From",
				field: "data_from",
				width: 100,
			}
		]

		return (
			<div className="container-fluid">
				<div className="d-sm-flex align-items-center justify-content-between mb-4">
					<h1 className="h3 mb-0 text-gray-800">Upload Billing Trips Data</h1>
					<a href={"/trips"} className={"btn btn-success float-right " + (this.state.showgrid)}>Go To Trips Screen</a>
				</div>
				<div className="row">

					<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="card-body">
								<div className="row">
									<div className="col-xl-4">
										<form onSubmit={this.uploadCSVFile}>
											<div className="form-group">
												<label>Upload CSV file</label>
												<input type="file" name="csvcontent" onChange={this.changeCSVFile} className="form-control" required />
											</div>
											<div className="form-group">
												<button type="submit" className="btn btn-danger">Save &amp; Import</button>
											</div>
										</form>
									</div>

									<div className="col-xl-8" style={{ padding: "20px" }} id="csvdata"></div>
								</div>
							</div>
						</div>
					</div>

				</div>
				<div className={"row " + (this.state.showgrid)}>

					<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="card-body">
								<div style={{ width: "100%", height: "60vh" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										rowData={this.state.rowData}
										columnDefs={columnDefs}
										defaultColDef={this.state.defaultColDef}
										enableRangeSelection={true}
										enableCharts={true}
										detailCellRendererParams={this.state.detailCellRendererParams}
										masterDetail={true}
										onGridReady={this.onGridReady}
										sideBar={this.state.sideBar}
										frameworkComponents={this.state.frameworkComponents}
										statusBar={this.state.statusBar}
										stopEditingWhenGridLosesFocus={true}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										gridOptions={{
											context: { componentParent: this }
										}}

									/>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
		);
	}
}
function redirectToTripsSummaryPage() {
	window.location.href = "/trips"
}
function redirectToConfigurations() {
	window.location.href = "/configurations"
}