import React, { Component } from 'react';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import PRTOnTimeChart from './PRTOnTimeChart';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getHyphenDDMMMYYYY } from '../common/utils';
import CountUp from 'react-countup';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

const couriercolumns = [
    {
        headerName: "Consignement Code",
        field: "consignment_code",
        width: 115,
        editable: false,
        filter: "agSetColumnFilter",
    },
    {
        headerName: "GC/Way Bill No",
        field: "gc_waybill_no",
        width: 115,
        editable: false,
        filter: "agSetColumnFilter",
    }, {
        headerName: "GC date",
        field: "gc_date",
        width: 120,
        cellRenderer: '',
        valueGetter: function (params) {
            return getHyphenDDMMMYYYYHHMM(params.data.gc_date)
        },

    },
    {
        headerName: "Shipment Completion Date",
        field: "shipment_completion_date",
        width: 150,
        resizable: true,
        valueGetter: function (params) {
            if (params.data.shipment_completion_date !== "") {
                return getHyphenDDMMMYYYY(params.data.shipment_completion_date);
            }
        },
    },
    {
        headerName: "Planned Delivery Date",
        field: "estimated_datetime",
        width: 120,
        resizable: true,
        valueGetter: function (params) {
            if (params.data.transit_remark === "NA") {
                return "NA";
            }
            else {
                if (params.data.transporter_code !== "" && params.data.transporter_code !== undefined) {
                    if (params.data.transporter_code === "7402033") {
                        return getHyphenDDMMMYYYY(params.data.gc_date);
                    }
                    else {
                        if (params.data.service_provider == "" || params.data.service_provider == undefined) {
                            return getHyphenDDMMMYYYY(params.data.actual_delivery_date);
                        } else {
                            return getHyphenDDMMMYYYY(params.data.estimated_datetime);
                        }
                    }
                }
                else {
                    if (params.data.service_provider == "" || params.data.service_provider == undefined) {
                        return getHyphenDDMMMYYYY(params.data.actual_delivery_date);
                    } else {
                        return getHyphenDDMMMYYYY(params.data.estimated_datetime);
                    }
                }
            }
        },
    },
    {
        headerName: "Status",
        field: "consignment_status",
        width: 120,
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
            if (params.value == 'Delivered') {
                //mark police cells as red
                return { color: 'green' };
            } else {
                return null;
            }
        },
        valueGetter: function (params) {

            if (params.data.consignment_status == "Cancelled") {
                return "Cancelled As Per Ashok Leyland"
            }
            else {
                return params.data.consignment_status
            }

        },
        cellRendererSelector: function (params) {
            try {
                if (params.data.consignment_status === "Invalid Docket" || params.data.consignment_status === "Invalid docket") {
                    return {
                        component: "InvalidDocket"
                    }
                }
                else {
                    return "";
                }
            } catch (error) { }
        },
    },
    {
        headerName: "Delivery Status",
        field: "ontime_delayed_status",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: '',
        cellClass: function (params) {
            if (params.data.transporter_code !== "" && params.data.transporter_code !== undefined) {
                if (params.data.transit_remark === "NA") {
                    return 'bgColorDangerMedium'
                }
                else {
                    if (params.data.transporter_code === "7402033") {
                        return 'bgColorSuccessMedium'
                    }
                    else {
                        if (params.data.ontime_delayed_status == "Delayed") {
                            return 'bgColorDangerMedium'
                        }
                        return 'bgColorSuccessMedium'
                    }
                }
            }
            else {
                if (params.data.ontime_delayed_status == "Delayed") {
                    return 'bgColorDangerMedium'
                }
                return 'bgColorSuccessMedium'
            }
        },
        valueGetter: function (params) {
            try {
                if (params.data.transit_remark === "NA") {
                    return "Fixed Transit Days - NA";
                }
                else {
                    if (params.data.transporter_code !== "" && params.data.transporter_code !== undefined) {
                        if (params.data.transporter_code === "7402033") {
                            return "On Time";
                        }
                        else {
                            return params.data.ontime_delayed_status;
                        }
                    }
                    else {
                        return params.data.ontime_delayed_status;
                    }
                }
            } catch (error) { }
        }
    },
    {
        headerName: "Actual Delivery Date",
        field: "revised_eta",
        width: 120,
        resizable: true,
        valueGetter: function (params) {
            if (['DELIVERED', "Delivered",].includes(params.data.consignment_status)) {

                if (params.data.revised_eta == '' || params.data.revised_eta == undefined) {
                    return getHyphenDDMMMYYYY(params.data.revised_eta);
                }
                else {
                    return getHyphenDDMMMYYYY(params.data.revised_eta);
                }
            }
            else {
                return ""
            }
        },
        cellClass: function (params) {
            let dayDifference;
            if (params.data.revised_eta && params.data.expected_eta) {
                let revisedDateOnlyMoment = moment(params.data.revised_eta);
                let expectedDateOnlyMoment = moment(params.data.estimated_datetime);
                dayDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'days');
            } else {
            }
            if (['DELIVERED', "Delivered"].includes(params.data.consignment_status)) {
                if (dayDifference > 0) {
                    return 'bgColorDangerMedium'
                }
                if (dayDifference <= 0) {
                    return 'bgColorSuccessMedium'
                }
                else {
                    return ''
                }
            }
        }
    },
    {
        headerName: "Plant Code",
        field: "consigner_code",
        width: 100,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Plant location",
        field: "consigner_location",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Vehicle No",
        field: "truck_no",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: 'Courier Type',
        field: 'courier_type',
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''

    },
    {
        headerName: "Transporter Name",
        field: "service_provider",
        width: 135,
        filter: "agSetColumnFilter",
        resizable: true,
        editable: false,
        valueGetter: function (params) {
            return params.data.service_provider;
        },
    },
    {
        headerName: "Transporter Code",
        field: "transporter_code",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Dealer Code",
        field: "consignee_code",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Dealer Name",
        field: "consignee_name",
        width: 130,
        filter: "agSetColumnFilter",
        resizable: true
    },
    {
        headerName: "Dealer City",
        field: "consignee_city",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Dealer State",
        field: "consignee_state",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Shipment Type",
        field: "shipping_type",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: '',
        valueGetter: function (params) {
            try {
                var sdata = params.data.shipping_type;
                var sdataoVal = sdata;
                if (sdata === "05" || sdata === 5) {
                    var sdataoVal = "surface";
                }
                if (sdata === "07" || sdata === 7) {
                    var sdataoVal = "air";
                }
                if (sdata === "22" || sdata === 22) {
                    var sdataoVal = "train";
                }
                if (sdata === "48" || sdata === 48 || sdata === "47" || sdata === 47) {
                    var sdataoVal = "Part Load";
                }
                return sdataoVal;
            } catch (error) { }
        }
    },
    {
        headerName: "Destination Type",
        field: "destination_type",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Fixed Transit Time (Days)",
        field: "TAT",
        width: 200,
        filter: "agSetColumnFilter",
        valueGetter: function (params) {
            try {
                if (params.data.TAT !== "" && params.data.TAT !== undefined && params.data.TAT !== 0 && params.data.TAT !== "0") {
                    return params.data.TAT;
                }
                else {
                    return params.data.transit_remark;
                }
            } catch (error) { }
        },
        cellRendererSelector: function (params) {
            try {
                if (params.data.transit_remark === "NA" || params.data.transit_remark === "NA") {
                    return {
                        component: "UpdateTransitDay"
                    }
                }
                else {
                    return "";
                }
            } catch (error) { }
        },
    },
    {
        headerName: "Holidays (Days)",
        field: "holiday_count",
        width: 130,
        filter: "agSetColumnFilter",
        valueGetter: function (params) {
            try {
                if (params.data.transit_remark === "NA") {
                    return "NA";
                }
                else {
                    var s = (params.data.count_sunday !== "" && params.data.count_sunday !== undefined) ? params.data.count_sunday : 0;
                    var h = (params.data.holiday_count !== "" && params.data.holiday_count !== undefined) ? params.data.holiday_count : 0;
                    var d = parseInt(s) + parseInt(h);
                    return d;
                }
            } catch (error) { }
        }
    },
    {
        headerName: "Grace (Days)",
        field: "grace_count",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },

    {
        headerName: "ODA Time (Days)",
        field: "ODA_TAT",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Total Transit Days",
        field: "transit_time_days",
        width: 130,
        filter: "agSetColumnFilter",
        valueGetter: function (params) {
            try {
                if (params.data.transit_remark === "NA") {
                    return "NA";
                }
                else {
                    return params.data.transit_time_days;
                }
            } catch (error) { }
        }
    },
    {
        headerName: "Pin Code",
        field: "consignee_pincode",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Allowable Weight(Kgs)",
        field: "allowable_weight",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Per Kg Slab Rate(Rps)",
        field: "per_kg_slab_rate",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Basic Trans Cost(Rps)",
        field: "basic_trans_cost",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Delivered On",
        field: "delivery_datetime",
        width: 150,
        filter: "agSetColumnFilter",
        cellRenderer: '',
        valueGetter: function (params) {
            if (params.data.consignment_status !== 'IN TRANSIT') {
                return getHyphenDDMMMYYYYHHMM(params.data.delivery_datetime)
            }
        },
    },
    {
        headerName: "Transit Delays (Days)",
        field: "",
        width: 130,
        valueGetter: function (params) {
            var revisedeta = params.data.revised_eta;
            var originaleta = params.data.estimated_datetime;
            if (originaleta != "" && originaleta != null && originaleta != NaN && revisedeta != "" && revisedeta != null && revisedeta != NaN) {
                if (new Date(originaleta) > new Date(revisedeta)) return 0
                var diff = differenceInDays(revisedeta, originaleta);
                return diff;
            }
            else {
                return "";
            }
        },
        cellClass: function (params) {
            var revisedeta = params.data.revised_eta;
            var originaleta = params.data.estimated_datetime;
            if (originaleta != "" && originaleta != null && originaleta != NaN && revisedeta != "" && revisedeta != null && revisedeta != NaN) {
                if (new Date(originaleta) > new Date(revisedeta)) return 0
                var diff = differenceInDays(revisedeta, originaleta);
                return diff;
            }
            else {
                return "";
            }
        }
    },
    {
        headerName: "Delivery in Transit (Days)",
        field: "",
        width: 150,
        valueGetter: function (params) {
            var delivery_datetime = params.data.delivery_datetime;
            var gc_date = params.data.gc_date;
            if (delivery_datetime == "" || delivery_datetime == undefined || delivery_datetime == " " || delivery_datetime == NaN || delivery_datetime == "Invalid date") {
                delivery_datetime = new Date();
            }
            if (['DELIVERED', "Delivered"].includes(params.data.consignment_status)) {
                if (gc_date != "" && gc_date != null && gc_date != NaN) {
                    var diff = deliveryInTransit(delivery_datetime, gc_date);
                    if (diff > 0) {
                        return diff - 1;
                    } else {
                        return 0;
                    }
                }
                else {
                    return "";
                }
            }
        }
    },
    {
        headerName: "Courier Status",
        field: "last_status",
        width: 150,
        filter: "agSetColumnFilter",

    },
    {
        headerName: "Courier Location",
        field: "last_location",
        width: 150,
        filter: "agSetColumnFilter"
    },

    {
        headerName: "Updated On",
        field: "last_date",
        width: 150,
        valueGetter: function (params) {
            if (params.data.last_date != "" && params.data.last_date !== undefined) {
                return getHyphenDDMMMYYYYHHMM(params.data.last_date);
            }
        },
    },
    {
        headerName: "Last Processed On",
        field: "last_process_on",
        width: 150,
        valueGetter: function (params) {
            if (params.data.last_process_on != "" && params.data.last_process_on !== undefined) {
                return getHyphenDDMMMYYYYHHMM(params.data.last_process_on);
            }
        },
    },
    {
        headerName: "Vehicle Mode",
        field: "mode_of_transport",
        width: 130,
        filter: "agSetColumnFilter",
        resizable: true,
        hide: true,
    },
    {
        headerName: "Delivered At",
        field: "delivery_station",
        width: 150,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
]

const prtcolumns = [
    {
        headerName: "Truck No",
        field: "truck_no",
        width: 130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consignment Code",
        field: "consignment_code",
        width: 130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consigner Code",
        field: "consigner_code",
        width: 130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consignee Code",
        field: "consignee_code",
        width: 130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Gate Out Time",
        field: "gate_out_time",
        width: 130,
        resizable: true,
        ediatable: false,
        valueGetter: function (params) {
            if (params.data.gate_out_time) {
                return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)
            }
            else {
                return ""
            }
        }
    },
    {
        headerName: "Consignee State",
        field: "consignee_state",
        width: 130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consignee City",
        field: "consignee_city",
        width: 130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Zone",
        field: "zone",
        width: 130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Expected ETA",
        field: "expected_trip_end",
        width: 130,
        resizable: true,
        ediatable: false,
        valueGetter: function (params) {
            if (params.data.expected_trip_end) {
                return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end)
            }
            else {
                return ""
            }
        }
    },
    {
        headerName: "Reached Destination At",
        field: "recent_dealer_reported",
        width: 130,
        resizable: true,
        ediatable: false,
        valueGetter: function (params) {
            if (params.data.recent_dealer_reported) {
                return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported)
            }
            else {
                return ""
            }
        }
    },
    {
        headerName: "MSIL Transit Days",
        field: "transit_time",
        width: 110,
        resizable: true,
        ediatable: false,
    },
    {
        headerName: "Actual Transit Days",
        field: "",
        width: 110,
        resizable: true,
        ediatable: false,
        valueGetter: function (params) {
            const oneDay = 24 * 60 * 60 * 1000
            var startDate = params.data.gate_out_time;
            var endDate = params.data.recent_dealer_reported;
            return Math.round(Math.abs((new Date(moment.parseZone(startDate).format("YYYY-MM-DD HH:MM")) - new Date(moment.parseZone(endDate).format("YYYY-MM-DD HH:MM"))) / oneDay));
        }
    },
    {
        headerName: "Transporter Code",
        field: "parent_transporter_code",
        width: 130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Transporter Name",
        field: "transporter_name",
        width: 130,
        resizable: true,
        ediatable: false
    },
]

export default class TPTDashboardComponent extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            loadshow: 'show-m',
            overly: 'show-m',
            couriercolumns: couriercolumns,
            prtcolumns: prtcolumns,
            modules: AllModules,
            show: false,
            basicTitle: '',
            basicType: "default",
            defaultColDef: {
                sortable: true,
                filter: true,
                autoHeight: true,
                resizable: true
            },
            rowData: [],
            context: { componentParent: this },
            paginationPageSize: 50,
            start_date: "",
            end_date: "",
            selected_lsps: [],
            selected_cities: [{ "label": "All", "value": "all" }],
            selected_specific_state : [],
            lspOptions: [],
            cityOptions: [],
            courierLSPOptions: [],
            courierCityOptions: [],
            firstChartArr: [],
            secondChartArr: [],
            fourthChartArr: [],
            thirdChartArr: [],
            DealerChartArr: [],
            DealerChartArr2: [],
            tptLevelData: [],
            prtCourierActive: "",
            prtActive: "activet",
            prtSurfaceCourierActive: "",
            chartQuery: "",
            columnwithdefs: [],
            showChartData: "show-m",
            showGridData: "show-n",
            gridTab: "",
            chartTab: "activet",
            shipping_type: "",
            transporter_data: [],
            stateList: [{ "value": "all", "label": "All" }],
            shipping_type_tab: "all",
            dealerList: [],
            selected_shipping_type: { label: "All", value: "all" },
            changed_shipping_type: "COURIER",
            current_state_list: [],
            current_transporter_list: [],
            top_on_time_data: [],
            low_on_time_data: [],
            on_time_df: [],
            plantsOptions: [{ label: "All", value: "all" }],
            selected_plant: { label: "All", value: "all" },
            summaryData: {},
            ShippingTypeOptions: [{ label: "All", value: "all" }],
            shippingTypeCounter: {},
        };
        this.getCombinedChartData = this.getCombinedChartData.bind(this);
        this.getInitialData = this.getInitialData.bind(this);
    }
    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
        });
    }

    async componentDidMount() {
        const edate = moment.parseZone().format('YYYY-MM-DD');
        const sdate = moment.parseZone(new Date()).subtract(7, 'days').format('YYYY-MM-DD');
        const dateParams = {
            start_date: sdate,
            end_date: edate
        };
        try {
            // Fetch initial data
            const initialResponse = await redirectURL.post("/masters/getinputDatafortptdashboard", dateParams);
            const { stateList: state_list, transporter_data: transporterData, plants_list: plantsData = [], shipping_type_list: ShippingTypeOptions = [] } = initialResponse.data;
            // Prepare plant options and mapping
            const plantsByCode = plantsData.reduce((acc, item) => {
                acc[item.plant_code] = item.plant_name;
                return acc;
            }, {});
            const plantsOptions = plantsData.map(item => ({ label: item.plant_code, value: item.plant_code }));
            plantsOptions.unshift({ label: "All", value: "all" });
            ShippingTypeOptions.unshift({ label: "All", value: "all" });
            // Prepare state and transporter options
            const stateList = [{ value: "all", label: "All" }, ...state_list.map(e => ({ label: e, value: e }))];
            const transporterOptions = [...transporterData.map(b => ({
                value: b.transporter_code,
                label: `${b.transporter_name} - ${b.transporter_code}`
            }))];
            // Set initial state
            this.setState({
                transporter_data: transporterOptions,
                stateList,
                selected_plant: plantsOptions[0],
                plantsOptions,
                start_date: sdate,
                end_date: edate,
                selected_lsps: {label:"Safexpress - 7442453",value:"7442453"},
                ShippingTypeOptions,
            }, async () => {
                await this.getInitialData(dateParams);
                var shippingType = "all"
                const commonChartParams = {
                    from_date: `${sdate} 00:00:00`,
                    to_date: `${edate} 23:59:59`,
                    state_list: "all",
                    transporterData: JSON.stringify(["7442453"]),
                    shipping_type: shippingType,
                };
                if (this.state.selected_plant.value != "all") commonChartParams.consigner_code = this.state.selected_plant.value
                const chartQuery = { ...commonChartParams, api_name: "TptWiseData" };
                // Fetch charts in parallel
                await this.getCombinedChartData(chartQuery);
                this.setState({
                    chartQuery
                });
                // const dealer = this.state.dealerList.length > 0 ? this.state.dealerList[0].value : "";
                // const dealerChartParams = {
                //     ...commonChartParams,
                //     from_date: `${this.state.start_date} 00:00:00`,
                //     to_date: `${this.state.end_date} 23:59:59`,
                //     api_name: "dealerWiseTransporterOnTimeAPI",
                //     shipping_type: this.state.shipping_type_tab,
                // };
                // await this.getDealerChartData(dealerChartParams);
                const gridQueryParams = {
                    ...commonChartParams,
                };
                if (this.state.selected_plant.value != "all") gridQueryParams.consigner_code = this.state.selected_plant.value
                const gridResponse = await redirectURL.post("/masters/getTptWiseGridDetails", gridQueryParams);
                const records = gridResponse.data;
                const today = moment().format('YYYY-MM-DD');
                records.forEach(e => {
                    if (!e.revised_eta) {
                        e.elapsed_transit_time = differenceInDays(today, e.gc_date);
                        e.ontime_delayed_status = "Delayed";
                    } else {
                        e.elapsed_transit_time = differenceInDays(e.revised_eta, e.gc_date);
                        e.ontime_delayed_status = moment.parseZone(e.revised_eta).startOf('day') - moment.parseZone(e.expected_eta).startOf('day') <= 0
                            ? "On Time"
                            : "Delayed";
                    }
                });
                this.setState({
                    rowData: records,
                    columnwithdefs: this.state.couriercolumns
                });
            });
        } catch (error) {
            console.error("Error during componentDidMount:", error);
        }
    }
    async getInitialData(params) {
        try {
            const response = await redirectURL.post("/masters/getinputDatafortptdashboard", params);
            // Use getOptions method to generate options for LSP and City
            const lspOptions = this.getOptions(response.data.transporter_data);
            const cityOptions = this.getOptions(response.data.stateList);
            const ShippingTypeOptions = this.getOptions(response.data.shipping_type_list)
            // Generate plant options and mapping
            const plantsData = response.data.plants_list || [];
            const plantsOptions = plantsData.map(item => ({
                label: item.plant_code,
                value: item.plant_code
            }));
            plantsOptions.unshift({ label: "All", value: "all" });
            // Set state with all necessary data
            this.setState({
                lspOptions,
                cityOptions,
                plantsOptions,
                selected_plant: plantsOptions[0],
                ShippingTypeOptions: ShippingTypeOptions
            });
        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    }

    async getCombinedChartData(params) {
        try {
            // Fetch data from the API
            const response = await redirectURL.post("/masters/prtOnTimePerformanceChart", params);
            // console.log(response, "response");
            // Parse the response data safely using a helper function
            const parseData = (key) => {
                try {
                    return response.data[key] ? JSON.parse(response.data[key]) : [];
                } catch (error) {
                    console.error(`Error parsing ${key}: `, error);
                    return [];
                }
            };
            // Use the helper function to parse data
            const first_level = parseData('state_wise_df');
            const second_level = parseData('city_df');
            const plant_level = parseData('plant_df');
            const tpt_level = parseData('tpt_df');
            console.log(tpt_level, "tpt_level")
            const summaryData = response.data.summary_data || {}
            const shippingTypeCounterData = summaryData.shipping_type_counter || []
            const shippingTypeCounter = {}
            shippingTypeCounterData.map(item => shippingTypeCounter[item.shipping_type] = item.counts)

            // Helper function to process data for charts
            const processChartData = (levelData, seriesName, drilldownIdKey, categoryKey, dataKey) => {
                const categories = [];
                const seriesData = [];
                const drilldownData = [];
                levelData.forEach(item => {
                    categories.push(item[categoryKey]);
                    seriesData.push({
                        name: item[drilldownIdKey],
                        y: item.total_records,
                        total_records: item.total_records,
                        on_time_records: item.on_time_records,
                        on_time_percentage: item.on_time_percentage,
                        drilldown: item[drilldownIdKey],
                        showTooltip: 2,
                    });
                    // Prepare drilldown data
                    const drillData = {
                        name: item[drilldownIdKey],
                        id: item[drilldownIdKey],
                        data: item.days_diff.map(diff => ({
                            name: `${diff.days_diff} Days`,
                            y: diff.count,
                            showTooltip: 1,
                            color: GetRandomColor()
                        }))
                    };
                    drilldownData.push(drillData);
                });
                return {
                    categories,
                    series: [{
                        name: seriesName,
                        colorByPoint: true,
                        data: seriesData
                    }],
                    drilldown: drilldownData
                };
            };
            if (params.state_wise_cities != 1) {
                // Process the second chart data (State-wise data)
                const secondChartData = processChartData(first_level, "States", "consignee_state", "consigner_code", "total_records");
                // Process the fourth chart data (Plant-wise data)
                const fourthChartData = processChartData(plant_level, "Plant Code", "consigner_code", "consigner_code", "total_records");
                // Set both charts' data to the state
                const thirdChartData = processChartData(second_level, "Cities", "consignee_city", "consignee_city", "total_records");
                const tptLevelData = processChartData(tpt_level, "Transporter", "service_provider", "service_provider", "total_records")
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    secondChartArr: [secondChartData],
                    fourthChartArr: [fourthChartData],
                    thirdChartArr: [thirdChartData],
                    summaryData: summaryData,
                    tptLevelData: [tptLevelData],
                    shippingTypeCounter: shippingTypeCounter
                })
            } else {
                const second_level = parseData('city_df');
                const thirdChartData = processChartData(second_level, "Cities", "consignee_city", "consignee_city", "total_records");
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    thirdChartArr: [thirdChartData],
                })
            }
        } catch (error) {
            console.error("Error fetching combined chart data:", error);
        }
    }

    async getDealerChartData(params) {
        // Set loading state
        await this.setState({
            loadshow: "show-m",
            overly: "show-m"
        });
        try {
            const response = await redirectURL.post("/masters/prtOnTimePerformanceChart", params);
            let lsp_df = [];
            try {
                if (response.data.lsp_df) {
                    lsp_df = JSON.parse(response.data.lsp_df);
                } else {
                    console.error("lsp_df is undefined or empty", response.data);
                }
            } catch (error) {
                console.error("Error parsing lsp_df:", error);
            }
            const on_time_df = JSON.parse(response.data.on_time_df).sort(GetSortDescOrder("on_time_percentage")) || [];
            const top_on_time_data = on_time_df.filter(e => e.on_time_percentage > 50).slice(0, 50);
            const low_on_time_data = on_time_df.filter(e => e.on_time_percentage <= 50).slice(-50);
            // Function to generate chart data and drilldown
            const generateChartData = (data, lspData) => {
                const categories = [];
                const seriesData = [];
                const drilldownData = [];
                data.forEach(e => {
                    categories.push(e.consignee_code);
                    seriesData.push({
                        name: e.consignee_code,
                        y: Math.round(e.on_time_percentage),
                        total_records: e.total_records,
                        on_time_records: e.on_time_records,
                        drilldown: e.consignee_code
                    });
                    const drilldata = {
                        name: e.consignee_name,
                        id: e.consignee_code,
                        data: []
                    };
                    const trans_df = lspData.filter(f => f.consignee_code === e.consignee_code);
                    trans_df.forEach(trans => {
                        drilldata.data.push({
                            name: trans.transporter_name,
                            y: Math.round(trans.on_time_percentage),
                            total_records: trans.total_records,
                            on_time_records: trans.on_time_records
                        });
                    });
                    drilldownData.push(drilldata);
                });
                return {
                    categories,
                    series: [{
                        name: "Dealer",
                        colorByPoint: true,
                        data: seriesData
                    }],
                    drilldown: drilldownData
                };
            };
            // Generate top and low on-time data chart
            const topDealerChart = generateChartData(top_on_time_data, lsp_df);
            const lowDealerChart = generateChartData(low_on_time_data, lsp_df);
            // Set state with chart data
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                on_time_df,
                DealerChartArr: topDealerChart,
                DealerChartArr2: lowDealerChart
            });
        } catch (error) {
            console.log("Error fetching dealer chart data:", error);
            this.setState({
                loadshow: "show-n",
                overly: "show-n"
            });
        }
    }
    async onClickFilterDashboard(type) {
        // Reset state before new data fetch
        this.setState({
            thirdChartArr: type == "main" ? [] : this.state.thirdChartArr,
            selected_specific_state : type == "main" ? []:this.state.selected_specific_state,
            secondChartArr: type == "main" ? [] : this.state.secondChartArr,
            fourthChartArr: type == "main" ? [] : this.state.fourthChartArr,
            tptLevelData: type == "main" ? [] : this.state.tptLevelData,
            loadshow: type == "main" ? "show-m" : "show-n",
            overly: type == "main" ? "show-m" : "show-n",
            rowData:type == "main" ? "" : this.state.rowData,
        });
        // Extract relevant state values
        const { selected_lsps, selected_cities, selected_shipping_type, selected_plant, start_date, end_date,selected_specific_state } = this.state;
        // Prepare transporter data, fallback to empty string if selected_lsps or selected_lsps.value doesn't exist
        const transporterData = selected_lsps && selected_lsps.value ? JSON.stringify([selected_lsps.value]) : "";
        // Get the selected states, default to "all" if empty
        let state_list = type == "state" ? this.getSelectedValues(selected_specific_state) || "all" : this.getSelectedValues(selected_cities) || "all";
        if (Array.isArray(state_list)) {
            state_list = JSON.stringify(state_list);
        }
        // Set shipping type based on selected value
        // const shipping_type = (() => {
        //     if (selected_shipping_type && selected_shipping_type.value) {
        //         switch (selected_shipping_type.value) {
        //             case "PTL":
        //                 return ["48"];
        //             case "FTL":
        //                 return ["01", "02", "03", "04", "10", "11", "12", "14", "15", "17", "18", "21", "24", "25", "29", "30", "31", "32", "45", "46"];
        //             case "COURIER":
        //                 return ["05"];
        //             default:
        // return "all";
        //         }
        //     }
        //     return "all";
        // })();
        let shippingType = selected_shipping_type || "all";
        if (Array.isArray(shippingType)) {
            shippingType = shippingType.map(item => item.value)
            if (shippingType.includes("all")) {
                shippingType = "all";  // Update the value of shippingType
            } else {
                shippingType = JSON.stringify(shippingType);  // Convert the array to JSON string
            }
        } else {
            if (shippingType.value === "all") {
                shippingType = "all";
            }
        }


        const queryBase = {
            from_date: `${start_date} 00:00:00`,
            to_date: `${end_date} 23:59:59`,
            state_list: state_list.length ? state_list : "all",
            transporterData: transporterData || "",
            shipping_type: shippingType,
            consigner_code: selected_plant && selected_plant.value ? selected_plant.value : "all"
        };
        if (type == "state") {
            queryBase.state_wise_cities = 1
        }
        else {
            queryBase.state_wise_cities = 0
        }

        try {
            // Make API calls concurrently
            const [gridResponse, combinedChartResponse] = await Promise.all([
                type === "main" ? this.fetchGridData(queryBase) : null,
                this.getCombinedChartData({ ...queryBase, api_name: "TptWiseData" })
            ]);
            // Process grid data
            if (type === "main" && gridResponse) {
                this.processGridData(gridResponse.data);
            }
            // Fetch and process dealer chart data
            // const dealerParams = { ...queryBase, api_name: "dealerWiseTransporterOnTimeAPI" };
            // await this.getDealerChartData(dealerParams);
            // Update the state with the queries and other necessary data
            this.setState({
                chartQuery: { ...queryBase, api_name: "TptWiseData" },
                current_state_list: state_list,
                current_transporter_list: transporterData,
                changed_shipping_type: selected_shipping_type ? selected_shipping_type : "all",
                loadshow: "show-n", // Hide loading state after fetching data
                overly: "show-n"
            });

        } catch (error) {
            console.error("Error during onClickFilterDashboard:", error);
            this.setState({
                loadshow: "show-n",
                overly: "show-n"
            });
        }
    }

    processGridData(records) {
        const today = moment().format('YYYY-MM-DD');
        records.forEach(e => {
            if (!e.revised_eta) {
                e.elapsed_transit_time = differenceInDays(today, e.gc_date);
                e.ontime_delayed_status = "Delayed";
            } else {
                e.elapsed_transit_time = differenceInDays(e.revised_eta, e.gc_date);
                e.ontime_delayed_status = moment.parseZone(e.revised_eta).startOf('day') - moment.parseZone(e.expected_eta).startOf('day') <= 0
                    ? "On Time"
                    : "Delayed";
            }
        });
        this.setState({
            rowData: records,
            columnwithdefs: this.state.couriercolumns
        });
    }

    getOptions(lst) {
        return [{ label: "ALL", value: "all" }, ...lst.map(e => ({ label: e, value: e }))];
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    onGridReady = (params) => {
        // Store the grid API and column API
        const { api, columnApi } = params;
        this.gridApi = api;
        this.gridColumnApi = columnApi;
        // Update the state with the grid API
        this.setState({
            gridapi: api
        });
    };

    onGridState = () => {
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);

    }

    handlerGridStartDateTime = (event) => {
        const eventDate = moment(event._d);
        const formattedDate = eventDate.format('YYYY-MM-DD');
        if (!this.state.end_date) {
            this.setState({ start_date: formattedDate });
            return;
        }
        const endDate = moment(this.state.end_date);
        if (eventDate.isAfter(endDate)) {
            this.setState({
                show: true,
                basicTitle: 'From date should be less than To date',
                basicType: "danger",
                loadshow: 'show-n',
                startDate: "",
            });
        } else {
            this.setState({ start_date: formattedDate });
        }
    }

    handlerGridEndDateTime = (event) => {
        const eventDate = moment(event._d);
        const formattedDate = eventDate.format('YYYY-MM-DD');
        if (!this.state.start_date) {
            this.setState({ end_date: formattedDate });
            return;
        }
        const startDate = moment(this.state.start_date);
        if (eventDate.isBefore(startDate)) {
            this.setState({
                show: true,
                basicTitle: 'To date should be greater than From date',
                basicType: "danger",
                loadshow: 'show-n',
                endDate: "",
            });
        } else {
            this.setState({ end_date: formattedDate });
        }
    }

    getSelectedValues(selectedItems) {
        if (!Array.isArray(selectedItems) || selectedItems.some(item => item.value === "all")) {
            return [];
        }
        return selectedItems.filter(item => item.value !== "all").map(item => item.value);
    }

    async fetchGridData(query) {
        try {
            // Check if state_list is not "all" and parse it if needed
            if (query.state_list !== "all") {
                query = { ...query, state_list: JSON.parse(query.state_list) };
            }
            if (query.shipping_type !== "all") {
                query = { ...query, shipping_type: JSON.parse(query.shipping_type) };
            }
            // Make the API call with the updated query
            const gridResponse = await redirectURL.post("/masters/getTptWiseGridDetails", query);
            return gridResponse;
        } catch (error) {
            console.error("Error fetching grid data:", error);
            throw error;
        }
    }

    onClickDataTab(tab) {
        if (tab == "chart") {
            this.setState({
                showChartData: "show-m",
                showGridData: "show-n",
                chartTab: "activet",
                gridTab: ""
            })
        }
        else {
            this.setState({
                showGridData: "show-m",
                showChartData: "show-n",
                chartTab: "",
                gridTab: "activet"
            })
        }
    }

    render() {

        console.log(this.state.summaryData, "summary 2")
        var columnwithDefs = [
            {
                headerName: "Consignee Code",
                field: "consignee_code",
                width: 115,
                editable: false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Consignee Name",
                field: "consignee_name",
                width: 115,
                editable: false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Total Records",
                field: "total_records",
                width: 115,
                editable: false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "On Time Records",
                field: "on_time_records",
                width: 115,
                editable: false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "On Time Percentage",
                field: "on_time_percentage",
                width: 115,
                editable: false,
                filter: "agSetColumnFilter",
                cellClass: function (params) {
                    if (parseFloat(params.data.on_time_percentage) > 50) {
                        return 'bgColorSuccessMedium'

                    }
                    else {
                        return 'bgColorDangerMedium'
                    }

                },
            },
        ]
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row col-xl-12 col-lg-12">
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>Start Date </label>
                        <Datetime
                            value={this.state.start_date}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off', readOnly: true }}
                            dateFormat="YYYY-MM-DD"
                            className='date-z-index'
                            name="startDate"
                            timeFormat={false}
                            onChange={this.handlerGridStartDateTime.bind(this)} />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>End Date </label>
                        <Datetime value={this.state.end_date}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete: 'off', readOnly: true }}
                            timeFormat={false}
                            className='date-z-index'
                            dateFormat="YYYY-MM-DD"
                            name="endDate"
                            onChange={this.handlerGridEndDateTime.bind(this)} />
                    </div>
                    <div className="form-gplantwiseloadingroup col-xl-3 col-lg-3">
                        <label>State </label>
                        <Select placeholder={"All"}
                            isMulti={true}
                            value={this.state.selected_cities}
                            className="border-radius-0"
                            onChange={(e) => this.setState({ selected_cities: e })}
                            style={{ borderRadius: "0px" }}
                            options={this.state.stateList} required />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>Transporter </label>
                        <Select placeholder={""} isMulti={false} value={this.state.selected_lsps} className="border-radius-0" onChange={(e) => this.setState({ selected_lsps: e })} style={{ borderRadius: "0px" }} options={this.state.transporter_data} required />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label> Shipping Type </label>
                        <Select placeholder={""} isMulti={true} value={this.state.selected_shipping_type} className="border-radius-0" onChange={(e) => this.setState({ selected_shipping_type: e })} style={{ borderRadius: "0px" }} options={this.state.ShippingTypeOptions} required />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>Plant Code </label>
                        <Select placeholder={"Select Plant"} isMulti={false} value={this.state.selected_plant} className="border-radius-0" onChange={(e) => this.setState({ selected_plant: e })} style={{ borderRadius: "0px" }} options={this.state.plantsOptions} required />
                    </div>
                    <div className="form-group col-xl-1 col-lg-1">
                        <label>&nbsp;</label><br />
                        <button type="button" className="btn btn-success"
                            onClick={this.onClickFilterDashboard.bind(this,"main")}>Submit</button>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12">
                    <button className={"btn btn-default " + this.state.chartTab} onClick={this.onClickDataTab.bind(this, "chart")}>Chart Data</button>
                    <button className={"btn btn-default " + this.state.gridTab} onClick={this.onClickDataTab.bind(this, "complete")}>Complete Data</button>
                </div>
                <div className={"row col-sm-12 mt-3 " + this.state.showChartData}>
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        <div className="card mt-0">
                            <div className="card-body m-0 p-2" id="counterCardBody">
                                <h5 className="header p-2" style={{ textAlign: 'center' }}>
                                    Summary Data
                                </h5>
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col p-0 m-0">
                                            <span className="f13" style={{ paddingTop: "7px" }}><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total</span>
                                            <h4 className="greenfont f40"><span className="counter"><CountUp end={(this.state.summaryData && this.state.summaryData.total_records !== undefined && this.state.summaryData.total_records !== null && this.state.summaryData.total_records !== ""
                                                ? this.state.summaryData.total_records
                                                : 0)} /></span>
                                            </h4>
                                        </div>
                                        <div className="col  p-0 m-0">
                                            <span className="f13" style={{ paddingTop: "7px" }}><i className="icofont icofont-clock-time f22 txt-primary"></i><br />On Time</span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.summaryData && this.state.summaryData.on_time_recs !== undefined && this.state.summaryData.on_time_recs !== null && this.state.summaryData.on_time_recs !== ""
                                                ? this.state.summaryData.on_time_recs
                                                : 0)} /></span></h4>

                                        </div>
                                        <div className="col  p-0 m-0">
                                            <span className="f13" style={{ paddingTop: "7px" }}>
                                                <i className="icofont icofont-hour-glass f20 redfont"></i>
                                                <br /> Delay
                                            </span>
                                            <h4 className="redfont f40">
                                                <span className="counter">
                                                    <CountUp
                                                        end={
                                                            this.state.summaryData &&
                                                                this.state.summaryData.total_records &&
                                                                this.state.summaryData.on_time_recs
                                                                ? this.state.summaryData.total_records - this.state.summaryData.on_time_recs
                                                                : 0
                                                        }
                                                    />
                                                </span>
                                            </h4>
                                        </div>
                                        <div className="col  p-0 m-0">
                                            <span className="f13" style={{ paddingTop: "7px" }}><i className="fa fa-percent f16 txt-secondary"></i><br />On Time Percentage</span>
                                            <h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.summaryData && this.state.summaryData.on_time_percentage !== undefined && this.state.summaryData.on_time_percentage !== null && this.state.summaryData.on_time_percentage !== ""
                                                ? this.state.summaryData.on_time_percentage
                                                : 0)} /></span></h4>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        <div className="card mt-0">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col p-0 m-0">
                                            <span className="f13" style={{ paddingTop: "7px" }}><i className="icofont icofont-road f20 text-information"></i><br /> Road </span>
                                            <h4 className="text-information f40"><span className="counter"><CountUp end={(this.state.shippingTypeCounter && this.state.shippingTypeCounter["05"] !== undefined && this.state.shippingTypeCounter["05"] !== null && this.state.shippingTypeCounter["05"] !== ""
                                                ? this.state.shippingTypeCounter["05"]
                                                : 0)} /></span>
                                            </h4>
                                        </div>
                                        <div className="col p-0 m-0">
                                            <span className="f13" style={{ paddingTop: "7px" }}><i className="icofont icofont-airplane f24 bluefont"></i><br /> Air </span>
                                            <h4 className="bluefont f40"><span className="counter"><CountUp end={(this.state.shippingTypeCounter && this.state.shippingTypeCounter["07"] !== undefined && this.state.shippingTypeCounter["07"] !== null && this.state.shippingTypeCounter["07"] !== ""
                                                ? this.state.shippingTypeCounter["07"]
                                                : 0)} /></span>
                                            </h4>
                                        </div>
                                        <div className="col p-0 m-0">
                                            <span className="f13" style={{ paddingTop: "7px" }}><i className="icofont icofont-train-line f22 txt-warning"></i><br /> Train </span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.shippingTypeCounter && this.state.shippingTypeCounter["22"] !== undefined && this.state.shippingTypeCounter["22"] !== null && this.state.shippingTypeCounter["22"] !== ""
                                                ? this.state.shippingTypeCounter["22"]
                                                : 0)} /></span>
                                            </h4>
                                        </div>
                                        <div className="col p-0 m-0">
                                            <span className="f13" style={{ paddingTop: "7px" }}><i className="icofont icofont-fast-delivery f22 txt-danger"></i><br /> PTL </span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.shippingTypeCounter && this.state.shippingTypeCounter["48"] !== undefined && this.state.shippingTypeCounter["48"] !== null && this.state.shippingTypeCounter["48"] !== ""
                                                ? this.state.shippingTypeCounter["48"]
                                                : 0)} /></span>
                                            </h4>
                                        </div>
                                        <div className="col p-0 m-0">
                                            <span className="f13" style={{ paddingTop: "7px" }}><i className="icofont icofont-truck-loaded f25 txt-success"></i><br /> FTL </span>
                                            <h4 className="text-success f40"><span className="counter"><CountUp end={(this.state.shippingTypeCounter && this.state.shippingTypeCounter["FTL"] !== undefined && this.state.shippingTypeCounter["FTL"] !== null && this.state.shippingTypeCounter["FTL"] !== ""
                                                ? this.state.shippingTypeCounter["FTL"]
                                                : 0)} /></span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row col-sm-12 " + this.state.showChartData}>
                    <div className="card-body">
                        <div className={"col-xl-12 col-lg-12 padding-10px"} style={{ background: "#ffffff" }}>
                            {this.state.fourthChartArr.length > 0 ?
                                this.state.fourthChartArr.map((item, index) =>
                                    <div key={index} className="col-xl-12 col-lg-12  p-10">
                                        <PRTOnTimeChart
                                            title={"Plant Wise  Count"}
                                            subtitle={item.series[0].data.length > 0 ? "" : 'No Data Found'}
                                            context={this}
                                            series={item.series}
                                            drilldown={item.drilldown}
                                            categories={item.categories}
                                            yaxistitle={"Count"}
                                            isFilter={"yes"}
                                        />
                                    </div>
                                )
                                : ""}
                        </div>
                    </div>
                </div>
                <div className={"row col-sm-12 " + this.state.showChartData}>
                    <div className="card-body">
                        <div className={"col-xl-12 col-lg-12 padding-10px"} style={{ background: "#ffffff" }}>
                            {this.state.tptLevelData.length > 0 ?
                                this.state.tptLevelData.map((item, index) =>
                                    <div key={index} className="col-xl-12 col-lg-12  p-10">
                                        <PRTOnTimeChart
                                            title={"Transporter Wise  Count"}
                                            subtitle={item.series[0].data.length > 0 ? "" : 'No Data Found'}
                                            context={this}
                                            series={item.series}
                                            drilldown={item.drilldown}
                                            categories={item.categories}
                                            yaxistitle={"Count"}
                                            isFilter={"yes"}
                                        />
                                    </div>
                                )
                                : ""}
                        </div>
                    </div>
                </div>
                <div className={"row col-sm-12 " + this.state.showChartData}>
                    <div className="card-body ">
                        <div className={" col-xl-12 col-lg-12 padding-10px"} style={{ background: "#ffffff" }} >
                            {this.state.secondChartArr.length > 0 ?
                                this.state.secondChartArr.map((item, index) =>
                                    <div key={index} className="col-xl-12 col-lg-12 p-10">
                                        <PRTOnTimeChart
                                            title={"State Wise  Count"}
                                            subtitle={item.series[0].data.length > 0 ? "" : 'No Data Found'}
                                            context={this}
                                            series={item.series}
                                            drilldown={item.drilldown}
                                            categories={item.categories}
                                            yaxistitle={"Count"}
                                        />
                                    </div>)
                                : ""}
                        </div>
                    </div>
                </div>
                <div className={"row col-sm-12 " + this.state.showChartData}>
                    <div className={"card-body"}>
                    <div className={"col-xl-12 col-lg-12 padding-10px"} style={{ background: "#ffffff", position: 'relative' }} >
                            <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2 mt-2 position-absolute" style={{ top: '10px', right: '10px', zIndex: 10 }}>
                                <Select
                                    placeholder={"Select State"}
                                    isMulti={true}
                                    value={this.state.selected_specific_state}
                                    className="border-radius-0"
                                    onChange={(e) => this.setState({ selected_specific_state: e}, this.onClickFilterDashboard.bind(this,"state")) }
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.stateList}
                                    required
                                />
                            </div>
                        </div>
                        <div className={"col-xl-12 col-lg-12 mr-0 pl-0"} style={{ paddingTop: '40px', background: "#ffffff" }}>
                            {this.state.thirdChartArr.length > 0 ?
                                this.state.thirdChartArr.map((item, index) =>
                                    <div key={index} className="col-xl-12 col-lg-12  p-10">
                                        <PRTOnTimeChart
                                            title={"City wise Count"}
                                            subtitle={item.series[0].data.length > 0 ? "" : 'No Data Found'}
                                            context={this}
                                            series={item.series}
                                            drilldown={item.drilldown}
                                            categories={item.categories}
                                            yaxistitle={"Count"}

                                        />
                                    </div>
                                )
                                : ""}
                        </div>
                    </div>
                </div>
                <div className={"card-body " + this.state.showGridData}>
                    <div className={"col-xl-12 col-lg-12"} style={{ background: "#fff", padding: "20px 10px" }}>
                        <div className="form-group col-xl-8 col-lg-8">
                            <h5>Overall Consignment Data</h5>
                        </div>
                        <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham col-xl-12 col-lg-12 " + this.state.showGridData}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={this.state.columnwithdefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                enableRangeSelection={true}
                            />
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}
function differenceInDays(startDate, endDate) {
    startDate = moment.parseZone(startDate);
    endDate = moment.parseZone(endDate);
    var res = Math.abs((startDate - endDate) / 1000);
    return Math.floor((res) / 86400);
}
function deliveryInTransit(startDate, endDate) {
    endDate = endDate.split("T")[0];
    if (typeof (startDate) == "string") {
        if (startDate.indexOf(":") >= 0) {
            startDate = startDate.split(" ")[0];
        }
        if (startDate.indexOf(":") >= 0 && startDate.indexOf("T") >= 0) {
            startDate = startDate.split("T")[0];
        }
        if (startDate.indexOf("/") < 0) {
            startDate = new Date(startDate.split("-")[1] + "/" + startDate.split("-")[2] + "/" + startDate.split("-")[0]);
        }
        else {
            startDate = new Date(startDate.split("/")[1] + "/" + startDate.split("/")[2] + "/" + startDate.split("/")[0]);
        }
    }
    else {
        var dd = String(startDate.getDate()).padStart(2, '0');
        var mm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = startDate.getFullYear();
        startDate = mm + '/' + dd + '/' + yyyy;
        startDate = new Date(startDate);
    }
    endDate = new Date(endDate.split("-")[1] + "/" + endDate.split("-")[2] + "/" + endDate.split("-")[0]);
    var difference = startDate.getTime() - endDate.getTime();
    return Math.abs(Math.round((difference) / (1000 * 3600 * 24)));
}
function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}

function GetRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}