import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Route from 'react-router-dom/Route'
import { BrowserRouter, Switch } from 'react-router-dom'

import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';


// ** Import custom components **
import store from './store';
import App from './components/app';


import Login from './components/login/login'
import Logout from './components/login/logout'
// import Register from './components/login/register'
import AuthCallBack from './components/login/authcallback'
import ForgetPassword from './components/pages/forget-password'
import Comingsoon from './components/pages/comingsoon'
import ManageCourierConsignments from './components/courier/courierconsignmentsComponent'
import ChangePasswordComponent from './components/manage/changepassword.component';
import ResetPassword from './components/login/reset-password';
import Error404 from './components/pages/error-404';
// import CourierConsignmentDashboard from './components/courier/dailyRunningRouteWiseDashboard';
// import CourierConsignmentDashboard from './components/courier/ccDashboard'
import CourierConsignmentDashboard from './components/courier/courierDashboard'
import CourierConsignmentBillingDashboard from './components/courier/courierBillingDashboard';
import OrderShippingMaster from './components/masters/order_shipping_master'
import TransportersCodeMaster from './components/masters/transporters_code_masters'
import PlantMaster from './components/masters/plant_masters'
import TruckMaster from './components/masters/truck_master'
import GstMaster from './components/masters/gst_master'
import OdaMaster from './components/masters/oda_master'
import FreightMaster from './components/masters/freight_master'
// import FreightMasterData from './components/masters/freightmasterdata'
import CustomerMaster from './components/masters/customer_master'
import TransitMaster from './components/masters/transit_master'
import creditMaster from './components/masters/credit_master'
import HolidaysComponent from './components/masters/holiday_master'
import SpecialchargesMasters from './components/masters/specialcharges_masters'
/* start of import of billing files */
import Configurations from "./components/billing/Configurations";
import APIConfigurations from "./components/billing/ApiConfigurations";
import BillingTrips from "./components/billing/BillingTripsComponent";
import UploadBillingTrips from "./components/billing/BillingTripsUploadComponent";
import FreightRates from "./components/billing/FreightRateComponent";
import DieselPriceApproval from "./components/billing/DieselPricesApproval";
import BillingDieselPrices from "./components/billing/BillingDieselPricescreen";
import FreightApprovalSubmit from "./components/billing/freightapprovalaftersubmit";
import FreighLevelApprovals from "./components/billing/BillingFreightLevelApprovals";
import ViewFreightDocument from "./components/billing/freightapprovalaftersubmit";
import OnlyViewFreightDocument from "./components/billing/freightapprovalaftersubmit";
import DownloadFreightDocument from "./components/billing/DownloadFreightDocument";
import BillingModuleUserAccess from "./components/billing/BillingModuleUserAccess";
import BillingTransporterGST from "./components/billing/BillingTransporterGST";
import BillingDeviationFlow from "./components/billing/BillingDeviationflow";
import EpodScreen from './components/billing/epodScreen';
import MISDashboard from './components/reports/misDashboard';
import DeliveryData from './components/billing/tptdeliveryData'
import BillingData from './components/billing/tptBillingData'
import EnmUserLogin from './components/login/enmuserlogin';
import TPTDashboardComponent from "./components/reports/tptDashboard"
/* end of import of billing files*/



class Root extends React.Component {

    render() {
        return (
            <Provider store={store}>
                <BrowserRouter basename={'/'}>
                    <ScrollContext>
                        <Switch>

                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
                            <Route exact path={`${process.env.PUBLIC_URL}/enmuserlogin/:token`} component={EnmUserLogin} />
                            <Route exact path={`${process.env.PUBLIC_URL}/tptlogin`} component={Login} />
                            <Route exact path={`${process.env.PUBLIC_URL}/optirun`} component={Login} />
                            <Route exact path={`${process.env.PUBLIC_URL}/loadmanagement/login`} component={Login} />
                            <Route path={`${process.env.PUBLIC_URL}/loadmanagement/logout`} component={Logout} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/login`} component={Login} />
                            {/* <Route path={`${process.env.PUBLIC_URL}/register`} component={Register} />  */}
                            <Route path={`${process.env.PUBLIC_URL}/authcallback`} component={AuthCallBack} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/forget-password`} component={ForgetPassword} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/reset-password`} component={ResetPassword} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/coming-soon`} component={Comingsoon} />
                            <Route path={`${process.env.PUBLIC_URL}/registration/activation`} component={Comingsoon} />
                            <Route path={`${process.env.PUBLIC_URL}/error-404`} component={Error404} />
                            <Route path={`${process.env.PUBLIC_URL}/resetpassword`} component={ResetPassword} />
                            
                            HolidayCmp
                            
                            
                            <App>
                                <Route path={`${process.env.PUBLIC_URL}/profile/changepassword`} component={ChangePasswordComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/courierconsignments`} component={ManageCourierConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/activecourierconsignments`} component={ManageCourierConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/deliveredcourierconsignments`} component={ManageCourierConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/invalidcourierconsignments`} component={ManageCourierConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/courierconsignmentdashboard`} component={CourierConsignmentDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/courierconsignmentbillingdashboard`} component={CourierConsignmentBillingDashboard} />

                                {/* masters - start*/}
                                <Route path={`${process.env.PUBLIC_URL}/orderShippingMasters`} component={OrderShippingMaster} />
                                <Route path={`${process.env.PUBLIC_URL}/transportersMasters`} component={TransportersCodeMaster} />
                                <Route path={`${process.env.PUBLIC_URL}/plantMasters`} component={PlantMaster} />
                                <Route path={`${process.env.PUBLIC_URL}/truckMasters`} component={TruckMaster} /> 
                                <Route path={`${process.env.PUBLIC_URL}/gst_master`} component={GstMaster} /> 
                                <Route path={`${process.env.PUBLIC_URL}/oda_master`} component={OdaMaster} />                        
                                <Route path={`${process.env.PUBLIC_URL}/freightMasters`} component={FreightMaster} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/freightmasterdata`} component={FreightMasterData} />                */}
                                <Route path={`${process.env.PUBLIC_URL}/customerMasters`} component={CustomerMaster} />
                                <Route path={`${process.env.PUBLIC_URL}/transitMasters`} component={TransitMaster} />
                                <Route path={`${process.env.PUBLIC_URL}/creditMasters`} component={creditMaster} />
                                <Route path={`${process.env.PUBLIC_URL}/holidaysMasters`} component={HolidaysComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/specialchargesMasters`} component={SpecialchargesMasters} />                                 

                                {/*masters - end*/}

                                {/* start of billing import routes */}
                                <Route path={`${process.env.PUBLIC_URL}/configurations`} component={Configurations} />
                                <Route path={`${process.env.PUBLIC_URL}/apisetup`} component={APIConfigurations} />
                                <Route path={`${process.env.PUBLIC_URL}/trips`} component={BillingTrips} />
                                <Route path={`${process.env.PUBLIC_URL}/upload-trips`} component={UploadBillingTrips} />
                                <Route path={`${process.env.PUBLIC_URL}/freightrates`} component={FreightRates} />
                                <Route path={`${process.env.PUBLIC_URL}/dieselpriceapprovals`} component={DieselPriceApproval} />
                                <Route path={`${process.env.PUBLIC_URL}/dieselprices`} component={BillingDieselPrices} />
                                <Route path={`${process.env.PUBLIC_URL}/documents/:document_no`} component={FreightApprovalSubmit} />
                                <Route path={`${process.env.PUBLIC_URL}/view-document-approval/:document_no`} component={ViewFreightDocument} />
                                <Route path={`${process.env.PUBLIC_URL}/view-document/:document_no`} component={OnlyViewFreightDocument} />
                                <Route path={`${process.env.PUBLIC_URL}/view-closed-document/:document_no`} component={OnlyViewFreightDocument } />
                                <Route path={`${process.env.PUBLIC_URL}/download-document/:document_no`} component={DownloadFreightDocument } />
                                <Route path={`${process.env.PUBLIC_URL}/user-access`} component={BillingModuleUserAccess } />
                                <Route path={`${process.env.PUBLIC_URL}/transporter-gst`} component={BillingTransporterGST } />
                                <Route path={`${process.env.PUBLIC_URL}/deviations`} component={BillingDeviationFlow} />
                                <Route path={`${process.env.PUBLIC_URL}/approvals`} component={FreighLevelApprovals} />
                                <Route path={`${process.env.PUBLIC_URL}/epodScreen`} component={EpodScreen} />
                                <Route path={`${process.env.PUBLIC_URL}/misdashboard`} component={MISDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/transporterdeliverydata`} component={DeliveryData} />
                                <Route path={`${process.env.PUBLIC_URL}/tptbillingdata`} component={BillingData} />
                                <Route path={`${process.env.PUBLIC_URL}/tptdashboard`} component={TPTDashboardComponent} />
                                {/* end of billing import routes */}



                            </App>

                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </Provider>
        )
    }
}



ReactDOM.render(<Root />, document.getElementById('root'));


