/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Select from 'react-select';
// import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
// import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
// import 'ag-grid-enterprise';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import redirectURL from '../redirectURL';
import Constants from "../constants";
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYY } from "../common/utils";
// import { createStaticHandler } from '@remix-run/router';
// import BillingTripsData from "./BillingTripsDataGrid";
import CSVFileValidator from 'csv-file-validator';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import ViewPOD from "./viewpodbutton";
import UploadPOD from "./uploadpodbutton";
import RevertPOD from "./revertpodbutton";
import Datetime from 'react-datetime';
// import ViewBatchItems from "./viewbatchitems";
import "react-datetime/css/react-datetime.css";
import BillingActions from './billingActions'
import download from 'js-file-download';
import $ from 'jquery';
//import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CourierActions from '../courier/courierActions';
import { times } from 'lodash';
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class tptBillingData extends Component {

    state = {
        loadshow: 'show-n',
        overly: 'show-n',
        oRowData: [],
        totalrecords: [],
        pending: [],
        liabilityCnt: [],
        modules: AllModules,
        alert: null,
        show: false,
        basicTitle: '',
        basicType: "default",
        isuploadcsv: 0,
        rowData: [],
        frameworkComponents: {
            BillingActions : BillingActions,
            CourierActions: CourierActions,
            UploadPOD: UploadPOD,
            ViewPOD: ViewPOD,
            RevertPOD: RevertPOD
        },
        defaultColDef: {
            // flex: 1,
            minWidth: 100,
            filter: true,
            resizable: true,
            initialWidth: 200,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            sortable: true
        },
        detailCellRendererParams: {
            detailGridOptions: {
                columnDefs: [
                    { field: 'callId' },
                    { field: 'direction' },
                    { field: 'number', minWidth: 150 },
                    { field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
                    { field: 'switchCode', minWidth: 150 },
                ],
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                params.successCallback(params.data.callRecords);
            },
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        supportField: '',
        billNumber: "",
        unique_gcways: [],
        rowSelection: "multiple",
        startDate: moment.parseZone().format('YYYY-MM'),
        delivered_date: '',
        gc_date: '',
        shipment_completion_date: '',
        manual_csvcontent: [],
        manual_file: '',
        gcWayBillList: [],
        shipType: { label: "All", value: "all" },
        multiplePodDocs: [],
        tabOne: "btn-current",
        tabTow: "btn-defaultc",
        tabThree: "btn-defaultc",
        uFile: "",
        dataType: "pending",
        transit_data_for_update: "",
        show_delivered_date: false,
        delivered_date_for_update: '',
        epodDocforupdate: '',
        errMsg: 'd-none',
        totalclosedpods: [],
        invalidDocketRecords: [],
        from_date: moment.parseZone(new Date()).subtract(2, "days").format("YYYY-MM-DD"),
        to_date: moment.parseZone(new Date()).format("YYYY-MM-DD"),

    }

    componentDidMount() {
        this.loadTrips()
    }

    loadTrips = () => {
        this.setState({

            loadshow: 'show-m',
            overly: 'show-m'
        })
        var fdate = this.state.from_date //by default we are giving 1 week data 
        var todayDate = this.state.to_date
        var fdt = fdate.split("-");
        var fromdate = fdt[2] + "-" + fdt[1] + "-" + fdt[0];
        var tdt = todayDate.split("-");
        var todate = tdt[2] + "-" + tdt[1] + "-" + tdt[0];
        var transporter = this.state.flTransporter

        var params = { from_date: fdate, to_date: todayDate, bill_number: this.state.billNumber }

        if (localStorage.getItem("user_type") == 'TRANSPORTER') {
            params.email = localStorage.getItem("email")
        }
        // console.log(params,"params")

        redirectURL.post("/consignments/tptBillingData", params, {

            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
            }
        }).then((response) => {
            var records = response.data.records;

            this.setState({
                rowData: records,
                loadshow: 'show-n',
                overly: 'show-n'
            })

        })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;

    };

    onCellClicked = async (e) => {
        this.setState({
            detailCellRendererParams: {}
        })
    }

    onClickHideAll = () => {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderForceCloseTranslate: '',
            sliderMarkDeliverTranslate: "",
            bulkPrioritySlide: "",
            bulkForceCloseSlide: "",
            bulkMarkDeliverSlide: "",
            bulkslidershow: "",
            sliderscanshow: "",
            slidershow: "",
            delivered_date: "",
            update_row_slider: '',
            status_for_update: '',
            transit_data_for_update: '',
        });
        $("#location_for_update").val("")
        $("#epodDocforupdate").val("")
        document.getElementById("formPOD").reset();
        document.getElementById("formBulkPOD").reset();
        document.getElementById("formBulkSingle").reset();

    }


    onShowBillingDetails = async (params) => {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": googleAnalytics.page.action.trackCourier,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        if (params != 0) {

            await this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [

                            {
                                headerName: "GC Number",
                                field: "gc_number",
                                filter: true,
                                resizable: true
                            },
                            {
                                headerName: "Freight Amount",
                                field: "fright_amount",
                                filter: true,
                                resizable: true
                            },
                        ]
                    },
                    getDetailRowData: function (params) {
                        // console.log("Step 4 ", params.data)

                        redirectURL.post('/consignments/getFreightDetails',
                            { rowId: params.data._id }, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': localStorage.getItem("userid")
                            }
                        })
                            .then(async (response) => {
                                var freightData = response.data.gc_numbers_data;
                                try {

                                } catch (e) {
                                    freightData = []
                                }

                                if (freightData.status == 'failure') {
                                    params.successCallback([]);
                                }
                                else {
                                    params.successCallback(freightData);
                                }

                            });

                    },
                    masterDetail: true
                }
            });

        }
        if (params.column.colDef.field == '_id') {
            params.node.setExpanded(!params.node.expanded);
        }
        else {

            params.node.setExpanded(false);
        }
    }

    handlerStartDeliveryDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (this.state.to_date != "" && startdate > this.state.to_date) {
            this.setState({
                show: true,
                basicTitle: 'Start date should be less than End date',
                basicType: "danger",
                loadshow: 'show-n',
                startDate: "",
            })
        }
        else {
            this.setState({
                from_date: startdate
            });
        }
    }
    handlerEndDeliveryDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var edate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (this.state.from_date != "" && edate < this.state.from_date) {
            this.setState({
                show: true,
                basicTitle: 'End date should be greater than Start date',
                basicType: "danger",
                loadshow: 'show-n',
                endDate: "",
            })
        }
        else {
            this.setState({
                to_date: edate
            });
        }
    }

    newFilter = (filterType, event) => {
        this.setState({ [filterType]: event.target.value });
    }

    onSubmitDataFilters = (event) => {
        event.preventDefault();
        this.setState({
            overly: "show-m",
            loadshow: "show-m"
        })
        var fromdate = this.state.from_date
        var todate = this.state.to_date

        if (!["", undefined].includes(fromdate) && !["", undefined].includes(todate)) {
            this.loadTrips()
        }
    }

    render() {
        const { show, basicTitle, basicType, modules, rowData, defaultColDef, detailCellRendererParams, sideBar, frameworkComponents, statusBar, paginationPageSize, rowSelection, sliderSupportshow, supportDocTitle, shipment_slider, manual_upload_slider, multiplePodDocs, loadshow, overly
        } = this.state

        let columnDefs = [
            {
				headerName: "",
				field: "_id",
				width: 50,
				pinned: 'left',
				cellRenderer: 'BillingActions',
				filter: false,

			},
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 160,
            },
            {
                headerName: "Bill Number",
                field: "bill_number",
                width: 160,
            },
            {
                headerName: "Bill Date",
                field: "bill_date",
                width: 180,
                valueGetter: function (params) {
                    try {
                        if (params.data.bill_date != undefined && params.data.bill_date != "") {
                            return getHyphenDDMMMYYYYHHMM(params.data.bill_date);
                        }
                    }
                    catch (e) { }
                }
            },
            {
                headerName: "Process",
                field: "process",
                width: 180,
                valueGetter: function (params) {
                    try {

                        if (params.data.process !== undefined && params.data.process !== null && params.data.process !== "") {

                            if (params.data.process == 0) {
                                return "Not Processed";
                            } else if (params.data.process == 1) {
                                return "Processed and Field Missing";
                            } else if (params.data.process == 2) {
                                return "Enmovil Processed";
                            }
                        }
                    } catch (e) {
                        console.error("Error:", e);
                    }
                }

            },
            {
                headerName: "Created Date",
                field: "createdDate",
                width: 180,
                valueGetter: function (params) {
                    try {
                        if (params.data.createdDate != undefined && params.data.createdDate != "") {
                            return getHyphenDDMMMYYYYHHMM(params.data.createdDate);
                        }
                    }
                    catch (e) { }
                }
            },
            {
                headerName: "Processed On",
                field: "processed_on",
                width: 180,
                valueGetter: function (params) {
                    try {
                        if (params.data.processed_on != undefined && params.data.processed_on != "") {
                            return getHyphenDDMMMYYYYHHMM(params.data.processed_on);
                        }
                    }
                    catch (e) { }
                }
            },


            {
                headerName: "Remarks",
                field: "remarks",
                width: 200,
            },


        ]
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row mb-1">
                    <div className='card col-xl-12 col-md-12'>
                        <div className="card-body pb-0">
                            <div className="row">
                                <div className="col-2">
                                    <label>Start Date </label>
                                    <Datetime value={this.state.from_date}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete: 'off', readOnly: true }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        name="fromDate"
                                        onChange={this.handlerStartDeliveryDateTime} />
                                </div>
                                <div className="col-2">
                                    <label>End Date </label>
                                    <Datetime
                                        value={this.state.to_date}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'To Date', name: 'toDate', autoComplete: 'off', readOnly: true }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        name="toDate"
                                        onChange={this.handlerEndDeliveryDateTime} />
                                </div>
                                <div className="col-2">
                                    <label>Bill Number</label>
                                    <input
                                        type="text"
                                        id="selectedBillNo"
                                        className='form-control'
                                        value={this.state.billNumber}
                                        onChange={this.newFilter.bind(this, "billNumber")}
                                        placeholder="Enter Bill Number"
                                    />
                                </div>

                                <div className="form-group col-xl-3 col-lg-3"   >
                                    <button className="btn btn-success mt-30p" type="submit" onClick={this.onSubmitDataFilters.bind(this)}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card col-xl-12 col-md-12 beffect'>

                        <div className='card-body'>

                            <div className="row">
                                <div className="col-xl-12 col-md-12 mb-4">
                                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                        <h2 className="col-xl-12 h3 mb-1 text-gray-800" style={{ color: '#3498db', fontFamily: 'Arial, sans-serif' }}>TPT Billing Data
                                        </h2>
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                            <i style={{ fontSize: "30px"}} className="icofont icofont-fast-delivery mt-2"></i>
                                            <span style={{ fontFamily: 'Arial, sans-serif',fontSize: "14px", color: '#003366' , "margin-top":"12px"}}>&nbsp; - GC Numbers Data</span>
                                    </div>

                                    <div className="card-body card shadow py-2">

                                        <div style={{ width: "100%", height: "80vh" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={modules}
                                                rowData={rowData}
                                                columnDefs={columnDefs}
                                                defaultColDef={defaultColDef}
                                                enableRangeSelection={true}
                                                enableCharts={true}
                                                detailCellRendererParams={detailCellRendererParams}
                                                masterDetail={true}
                                                onGridReady={this.onGridReady}
                                                sideBar={sideBar}
                                                frameworkComponents={frameworkComponents}
                                                statusBar={statusBar}
                                                stopEditingWhenGridLosesFocus={true}
                                                paginationPageSize={paginationPageSize}
                                                suppressRowClickSelection={true}
                                                pagination={true}
                                                headerHeight={50}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                rowSelection={rowSelection}
                                                onCellClicked={this.onCellClicked}

                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div className={"dataLoadpage " + (loadshow)}>
                            </div>
                            <div className={"dataLoadpageimg " + (loadshow)}>
                                <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                            </div>
                            <div className={"overlay-part " + (overly)} onClick={this.onClickHideAll}></div>



                        </div>



                    </div>

                </div>

            </div>
        )
    }

}

function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map11.get(key);
        if (!collection) {
            map11.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map11;
}