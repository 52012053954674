var Constants =  {
    baseURL: "http://localhost:5004/billing/",
    TRIP_TRUCK_NO:"Vehicle No",
    TRIP_CONSIGN_NO:"Shipment No.",
    GC_WAY_BILL_NO : 'GC/Way Bill No',
    GC_DATE : 'GC Date',
    CREDIT_SALES : 'Credit Sales',
    SHIPMENT_COMPLETION_TIME : 'Shipment Completion Time',
    SHIPMENT_START_TIME  :'Shipment Start Time',
    SHIPMENT_TYPE  :'Shipping Type',
    TRIP_INV_DATE:"Invoice Date",
    TRIP_REACHED_DEST_DATE:"Reached Destination At",
    TRIP_DELIVERED_DATE:"Delivered Date",
    TRIP_NO_OF_DAYS:"Transit Days",
    TRIP_PLANT_NAME:"Plant Name",
    TRIP_PLANT_CODE:"Plant Code",
    TRIP_CUSTOMER_CODE:"Ship to Party/SAC Code",
    TRIP_CUSTOMER_NAME:"Dealer Name",
    TRIP_CUSTOMER_CITY:"Dealer City",
    TRIP_CUSTOMER_STATE:"Dealer State",
    TRIP_ZONE:"Zone",
    TRIP_PIN_CODE:"Pin Code",
    TRIP_ACTUAL_DELIVERY_DATE:"ACTUAL DELIVERY DATE",
    TRIP_TRANSPORTER_CODE:"Transporter Code",
    TRIP_TRANSPORTER_NAME:"Transporter Name",
    TRIP_TOTAL_DISTANCE_KM:"Total Distance Travelled (KM)",
    TRIP_TOTAL_TRIP_DISTANCE_KM:"Total Trip Distance Travelled (KM)",
    TRIP_DURATION_DAYS:"Trip Duration (days)",
    PAGE_TITLE_FREIGHT_RATES:"Freight Rates",
    FREIGHT_RATE_ROUTE:"Operation / Route",
    FREIGHT_RATE_SOURCE:"Source",
    FREIGHT_RATE_DESTINATION:"Destination",
    FREIGHT_RATE_LSP:"Service Provider (LSP)",
    FREIGHT_RATE_RATE_CLASSIFICATION:"Rate Classification (UOM)",
    FREIGHT_RATE_CONTRACT:"Contract / Approval Note No",
    FREIGHT_RATE_TRUCK_TYPE:"Truck Type",
    FREIGHT_RATE_DISTANCE:"Distance",
    FREIGHT_RATE_MILEAGE:"Mileage",
    FREIGHT_RATE_BASE_FREIGHT_RATE_PER_APPROVAL:"Base Freight as per approval (INR)",
    FREIGHT_RATE_BASE_DIESEL_RATE_PER_LTR:"Base Diesel Rate as per approval (Rs/ltr)",
    FREIGHT_RATE_TOLL_RATE_PER_APPROVAL:"Base Toll Rate as per approval (Rs)",
    FREIGHT_RATE_OTHER_COSTS:"Other Costs",
    FREIGHT_RATE_TOTAL_COST:"Total Cost",
    FREIGHT_RATE_DIESEL_PRICE_APPLICABLE_DATE:"Revised Diesel Price Applicable  Date",
    FREIGHT_RATE_DIESEL_PRICE_CONSIDERED_FOR_REVISION:"Revised Diesel Price (Rs/ltr)",
    FREIGHT_RATE_DIESEL_PRICE_IMPACT_PERCENT:"Diesel Price Impact (%)",
    FREIGHT_RATE_CHANGE_IN_DIESEL_COST:"Change in Diesel Cost",
    FREIGHT_RATE_TOLL_REVISION_APPLICABLE_DATE:"Toll Revision Applicable Date",
    FREIGHT_RATE_CHANGE_IN_TOLL_PRICE:"Change in Toll Price",
    FREIGHT_RATE_NEW_TOLL_PRICE:"New Toll Price",
    FREIGHT_RATE_REVISED_FREIGHT_COST:"Revised Freight Cost",
    FREIGHT_RATE_BASE_DIESEL_CITY:"Base Diesel City",
    FREIGHT_RATE_BASE_DIESEL_STATE:"Base Diesel State",
    FREIGHT_RATE_REVISED_FREIGHT_DATE:"Freight Revision Date",
    FREIGHT_RATE_REVISED_FREIGHT_DATE_FROM:"Revised Freight Start Date",
    FREIGHT_RATE_REVISED_FREIGHT_DATE_TO:"Revised Freight End Date",
    PAGE_TITLE_DIESEL_APPROVAL:"Freight Approvals",
    FREIGHT_RATE_CONTRACT_VALID_FROM:"Valid From",
    FREIGHT_RATE_CONTRACT_VALID_TO:"Valid To",
    FREIGHT_APPROVALS_DOCUMENT_NO:"Document No",
    FREIGHT_APPROVALS_DATETIME:"Approval Date Time",
    FREIGHT_APPROVALS_APPROVAL_STATUS:"Approval Status",
    FREIGHT_APPROVALS_APPLICABLE_DIESEL_PRICE:"Applicable Diesel Price",
    FREIGHT_APPROVALS_NEXTLEVEL_EMAIL:"Email",
    FREIGHT_APPROVALS_NEXTLEVEL_STATUS:"Status",
    FREIGHT_APPROVALS_REVISION_MONTH:"Freight Revision Month",
    FREIGHT_APPROVALS_APPLICABLE_PERIOD_FROM:"Applicable Period From",
    FREIGHT_APPROVALS_APPLICABLE_PERIOD_TO:"Applicable Period To",
    FREIGHT_APPROVALS_STATUS:"Status",
    FREIGHT_APPROVALS_VIEW_DOC:"View Document",
    FREIGHT_APPROVALS_DOWNLOAD_DOC:"Download Document",
    FREIGHT_APPROVALS_APPROVED_BY:"Approved By",
    FREIGHT_APPROVALS_APPROVED_DATETIME:"Approved Date",
    FREIGHT_APPROVALS_REMARKS:"Remarks",
    DIESEL_ROCODE:"RO Code",
    DIESEL_CITY:"Diesel City",
    DIESEL_STATE:"Diesel State",
    DIESEL_ADDR:"Diesel Address",
    DIESEL_PRICE:"Diesel Price (INR)",
    DIESEL_PRICE_DATE:"Diesel Price Date",
    PAGE_TITLE_USER_ACCESS:"User Acces",
    PAGE_TITLE_TRANSPORTER_GST:"Transporters GST",
    DEVIATION_FLOW_PENALITY_COST:"Penality Cost",
    DEVIATION_FLOW_PENALITY_WAIVER_REMARKS:"Waiver Remarks",
    DEVIATION_FLOW_WAIVER_COST:"Waiver Cost",
    DEVIATION_FLOW_DETNETION_COST:"Detention Cost",
    TRIP_STATUS:"Status",
    TRIP_PAYMENT_STATUS:"Payment Status",
    TRIP_FREIGHT_COST:"Freight Cost",
    TRIP_TOTAL_COST:"Total Cost",
    TRIP_INV_AMOUNT:"Invoice Amount",
    TRIP_INV_DATE:"Invoice Date",
    TRIP_INV_No:"Invoice No",
    TRIP_INV_GST:"GST Amount",
}
export default Constants;
// module.exports = Constants;