/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
// import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
// import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY } from "../common/utils";
import ViewPOD from "./viewpodbutton";
import ViewRequest from "./viewrequestbutton";
import $ from 'jquery';
//import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var moment = require('moment');

export default class BillingDeviationFlow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isuploadcsv: 0,
            modules: AllModules,
            defaultColDef: {
                // flex: 1,
                minWidth: 100,
                filter: true,
                resizable: true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
                sortable: true
            },
            frameworkComponents: {
                ViewPOD: ViewPOD,
                ViewRequest: ViewRequest
            },
            detailCellRendererParams: {},
            rowData: [],
            rowSelection: "multiple",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            liable: "btn-active-y",
            pendinglsp: "btn-default",
            pendingcust: "btn-default",
            submitfin: "btn-default",
            paystat: "btn-default",
            liableshow: "show-m",
            pendinglspshow: "show-n",
            pendingcustshow: "show-n",
            submitfinshow: "show-n",
            paystatshow: "show-n",
            actiontype: "liable",
            overlay: "show-n",
            sliderFormula: "",
            remarks: "",
            rowId: "",
            vtransportersh: "show-m",
            vcustomersh: "show-n",
            requestFormula: "",
            transporter: "btn-active",
            customer: "btn-default",
            rownode: [],
            showButtons: 'show-n',
            overly:"show-n"
        }
        this.loadTrips = this.loadTrips.bind(this);
        this.onClickShowFormual = this.onClickShowFormual.bind(this);
        this.onClickViewRequest = this.onClickViewRequest.bind(this);
        this.onClickSubmitFormula = this.onClickSubmitFormula.bind(this);
        this.hideSlideBlock = this.hideSlideBlock.bind(this);
    }
    componentDidMount() {
        
       this.loadTrips();
    }
    loadTrips() {
        var params= {};
        if(localStorage.getItem("supplier_code") !== "" && localStorage.getItem("supplier_code") !== undefined && localStorage.getItem("supplier_code") !== null && localStorage.getItem("supplier_code") !== "undefined")
        {
            params.user_code = JSON.parse(localStorage.getItem("supplier_code"));
            params.user_type = localStorage.getItem("user_type");
        }
        // console.log("params trips ", params)
        redirectURL.post("/billing/deviationtrips", params,{
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': localStorage.getItem("userid")
                              }
                          }).then((response) => {
            console.log("response ", response.data)
            try {
                var records = response.data.records;
                var recordsarr = [];
                if (records.length > 0) {
                    records.map((item) => {
                        console.log("item",item)
                        if(item.trip_status === 6  &&  (item.batch_status === 5 || item.batch_status === 0) )
                        {
                            recordsarr.push(item)
                        }
                    })
                }
                 console.log("recordsarr ", recordsarr)
                this.setState({
                    rowData: recordsarr
                }) 

                if(localStorage.getItem("user_type") === "TRANSPORTER" || localStorage.getItem("user_type") === "TECHUSER" || localStorage.getItem("user_type") === "ORGUSER")
                {
                    this.showTabView(1);
                }
                
                if(localStorage.getItem("user_type") === "CUSTOMER")
                {
                    this.showTabView(2);
                }
            } catch (error) {
                
            }
            

        })
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

    };
    showTabView(oVal) {
        if (oVal === 1) {
            this.gridApi.deselectAll();
            this.setState({
                transporter: "btn-active",
                customer: "btn-default",
                vtransportersh: "show-m",
                vcustomersh: "show-n"
            })
        }
        if (oVal === 2) {
            this.gridApi.deselectAll();
            this.setState({
                transporter: "btn-default",
                customer: "btn-active",
                vtransportersh: "show-n",
                vcustomersh: "show-m"
            })
        }
    }

    hideSlideBlock() {
        this.setState({
            slider: "",
            requestFormula: "",
            sliderFormula: "",
            overlay: "show-n",
            overly:"show-n",
            remarks:""
        })
        document.getElementById("waiverreqForm").reset();
    }
    onClickSubmitFormula(event) {
        event.preventDefault();
        //    console.log("remarks",this.state.remarks)
        //    console.log("rownode",this.state.rownode)

        if (this.state.remarks != "" && this.state.remarks != undefined) {
            var params = {
                remarks: this.state.remarks,
                rowId: this.state.rownode[0]._id,
                rownode: this.state.rownode
            }
            redirectURL.post("/billing/submitrequestwaiver", params,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                  }
              })
                .then((response) => {
                    if (response.data.status == "success") {
                        window.location.reload();
                    }
                    document.getElementById("waiverreqForm").reset();
                })
        }
        else {
            this.setState({
                show: true,
                basicTitle: "Please write comment",
                basicType: "danger"
            })
        }

    }
    onClickViewRequest(props) {
        console.log("props ", props.data)
        var rowId = props.data._id;
        console.log("rowId",rowId)
        this.setState({
            rowId: rowId,
            rownode: [props.data],
            requestFormula: "slider-translate-30p",
            overly: "show-m"
        })

    }
    onClickSendBack() {

    }
    onClickApprove() {
        var rownodes = this.gridApi.getSelectedRows();
        // console.log("rownodes ", rownodes)
        var params = {
            rownodes: JSON.stringify(rownodes)
        }
        // if (this.state.transporter === "btn-active") {

        //     redirectURL.post("/billing/updatetransporterstatus", params,{
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': localStorage.getItem("userid")
        //           }
        //       })
        //         .then((response) => {
        //             if (response.data.status === "success") {
        //                 this.loadTrips();
        //             }
        //         })
        // }
        if (this.state.customer === "btn-active") {
            redirectURL.post("/billing/updatecustomerstatus", params,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                  }
              })
                .then((response) => {
                    if (response.data.status === "success") {
                        this.loadTrips();
                    }
                })
        }

    }

    onClickSendApproval(){
        var rownodes = this.gridApi.getSelectedRows();
        // console.log("rownodes ", rownodes)
        var params = {
            rownodes: JSON.stringify(rownodes)
        }
    
        redirectURL.post("/billing/updatetransporterstatus", params,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
              }
          })
        .then((response) => {
            if (response.data.status === "success") {
                this.loadTrips();
            }
        })
        
    }
    onClickReject() {
        var rownodes = this.gridApi.getSelectedRows();
        // console.log("rownodes ", rownodes)
        var params = {
            rownodes: JSON.stringify(rownodes)
        }
        if (this.state.transporter === "btn-active") {

          
        }
        if (this.state.customer === "btn-active") {
            redirectURL.post("/billing/updatecustomerDeviationRej", params,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                  }
              })
                .then((response) => {
                    if (response.data.status === "success") {
                        this.loadTrips();
                    }
                })
        }
    }
    onClickShowFormual() {
        this.setState({
            sliderFormula: "slide60",
            overly: "show-m"
        })
    }
    changeHandler = (event) => {
        // console.log("event",event);
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        })
    }

    appendVal = async (oVal) => {
        // var dd = document.getElementById("formulas").innerHTML;
        var equaton = this.state.equaton;
        //  console.log("equaton ", equaton)
        var currentTrack = "";
        var previousTrack = "";
        for (let i = 0; i < equaton.length; i++) {
            currentTrack = equaton[i];
            previousTrack = equaton[i];
            // if (i === 0) {
            // //   console.log('previousTrack of ' + currentTrack.key + ' is:', undefined);
            // } else {
            //   previousTrack = equaton[i - 1];
            // }
        }
        if (previousTrack != oVal) {

        }
        //  console.log("previousTrack ", previousTrack)
        await equaton.push({
            field: oVal
        })
        setTimeout(() => {
            this.setState({
                equaton: equaton
            })
            this.appendForm();
        }, 500)

    }
    appendForm() {
        var equaton = this.state.equaton;
        // console.log("equaton ", equaton)
        var html = '';
        var listitesm = '';
        // var ul = document.getElementById("formulasList");
        if (equaton.length > 0) {
            equaton.map((item) => {
                html += item.field;
                // var li = document.createElement("li");
                // var b = '<a href="javascript:;" >Delete</a>'
                // li.appendChild(document.createTextNode(item.field));
                // ul.appendChild(li);
                // listitesm += '<li>'+item.field+'</li>';
            })
        }
        document.getElementById("formulas").value = html
        this.setState({
            formulas: html
        })
        // document.getElementById("formulasList").appendChild = listitesm
    }
    onSaveFormula = async () => {
        var inps = $(".inpfrom");
        var arr = [];
        inps.each((function () {
            var dataID = $(this).attr("data-id");
            var fromc = $("#inp_from_" + dataID).val();
            var toc = $("#inp_to_" + dataID).val();
            arr.push({
                fromcost: fromc,
                tocost: toc
            })
        }));
        // console.log("arr ", arr)
        // var equaton = this.state.equaton;
        // var arr = [];
        // if(equaton.length > 0)
        // {
        //  equaton.map((itm) => {
        //      arr.push(itm.field);
        //  })
        // }
        //  var aprams = {
        //      equation : JSON.stringify(arr)
        //  }
        //  redirectURL.post("/billing/saveDeviationEquation",aprams)
        //  .then((response) => {
        //      if(response.data.status === "success")
        //      {
        //          this.setState({
        //              sliderFormula:"",
        //              overlay:"show-n"
        //          });
        //          // this.loadFreightRates();
        //          window.location.reload();
        //      }

        //  })

    }
    onSelectedRowShowButtons(event) {
        // console.log("event ", event)
        // var isSelected = event.node.selected;
        var isSelected = this.gridApi.getSelectedRows();
        if (isSelected.length > 0) {
            this.setState({
                showButtons: 'show-m'
            })
        }
        else {
            this.setState({
                showButtons: 'show-n'
            })
        }
    }
    onCellEdit(rownode) {
        // console.log("rownode ", rownode)
        if (rownode.colDef.field === "customer_penality_waiver") {
            if (rownode.value !== undefined) {
                var wcost = rownode.value;
            }
            else {
                var wcost = "";
            }
            var params = {
                rowid: rownode.data._id,
                waiver_cost: wcost
            }
            redirectURL.post("/billing/updatecustomerwaivercost", params,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                  }
              })
                .then((response) => {
                    if (response.data.status === "success") {
                        this.loadTrips();
                    }
                })
        }
    }
    onCellClickedFunc(rownode)
    {
        // console.log("rownode ", rownode);
        if (rownode.colDef.field == 'other_charges_details') {
            // var rownode = [cell.data];
            this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                headerName: 'Charge Type',
                                field: "document_name",
                                width: 200
                            },
                            {
                                headerName: 'Charges',
                                field: "charges",
                                width: 200
                            },
                           
                            {
                                headerName: 'View DOC',
                                field: "view_uploaded_doc",
                                width: 160,
                                cellRenderer: (params) => {
                                    try {
                                        if(params.data.view_uploaded_doc !== "" && params.data.view_uploaded_doc !== undefined)
                                        {
                                            return `<a href="`+params.data.view_uploaded_doc+`" target="_blank" class="btn btn-warning" style="padding:0px 5px;font-size:12px"> View Doc</a>`;
                                        }
                                        else{
                                            return "";
                                        }
                                    } catch (error) {
                                        
                                    }
                                }
                            }
                        ],
                        defaultColDef: {
                            // flex: 1,
                            minWidth: 100,
                            filter: true,
                            resizable: true,
                            initialWidth: 200,
                            wrapHeaderText: true,
                            autoHeaderHeight: true,
                            sortable: true
                        },
                        context: this,
                        overlayNoRowsTemplate: 'No rows to show',
                        onCellClicked:this.onCellClickedFunc
    
                    },
                    getDetailRowData: async function (param) {
                        // console.log("param ", param)
                        var rowitems = [];
                        if(param.data.osp_doc !== "" && param.data.osp_doc !== undefined)
                        {
                            rowitems.push({
                                rownode:param.data,
                                document_name:"OSP Document",
                                document_field_name:"osp_doc",
                                charges:(param.data.osp !== "" && param.data.osp !== null && param.data.osp !== undefined)?param.data.osp:0,
                                view_charge_doc:param.data.osp_doc,
                                view_uploaded_doc:param.data.osp_doc,
                            });
                        }
                        else{
                            rowitems.push({
                                rownode:param.data,
                                document_name:"OSP Document",
                                document_field_name:"osp_doc",
                                charges:(param.data.osp !== "" && param.data.osp !== null && param.data.osp !== undefined)?param.data.osp:0,
                                view_charge_doc:param.data.osp_doc,
                                view_uploaded_doc:"",
                            });
                        }
                        if(param.data.lu_charges_doc !== "" && param.data.lu_charges_doc !== undefined)
                        {
                            rowitems.push({
                                rownode:param.data,
                                document_name:"LU Charges Document",
                                document_field_name:"lu_charges_doc",
                                charges:param.data.lu_charges,
                                view_charge_doc:param.data.lu_charges_doc,
                                view_uploaded_doc:param.data.lu_charges_doc,
                            });
                        }
                        else{
                            rowitems.push({
                                rownode:param.data,
                                document_name:"LU Charges Document",
                                document_field_name:"lu_charges_doc",
                                charges:(param.data.lu_charges !== "" && param.data.lu_charges !== null && param.data.lu_charges !== undefined)?param.data.lu_charges:0,
                                view_charge_doc:param.data.lu_charges_doc,
                                view_uploaded_doc:"",
                            });
                        }
                        
                        if(param.data.to_pay_charges_doc !== "" && param.data.to_pay_charges_doc !== undefined)
                        {
                            rowitems.push({
                                rownode:param.data,
                                document_name:"To Pay Charges Document",
                                document_field_name:"to_pay_charges_doc",
                                charges:param.data.to_pay_charges,
                                view_charge_doc:param.data.to_pay_charges_doc,
                                view_uploaded_doc:param.data.to_pay_charges_doc,
                            });
                        }
                        else{
                            rowitems.push({
                                rownode:param.data,
                                document_name:"To Pay Charges Document",
                                document_field_name:"to_pay_charges_doc",
                                charges:(param.data.to_pay_charges !== "" && param.data.to_pay_charges !== null && param.data.to_pay_charges !== undefined)?param.data.to_pay_charges:0,
                                view_charge_doc:param.data.to_pay_charges_doc,
                                view_uploaded_doc:""
                            });
                        }
                        if(param.data.special_approval_doc !== "" && param.data.special_approval_doc !== undefined)
                        {
                            rowitems.push({
                                rownode:param.data,
                                document_name:"Special Approval Document",
                                document_field_name:"special_approval_doc",
                                charges:param.data.special_approval,
                                view_charge_doc:param.data.special_approval_doc,
                                view_uploaded_doc:param.data.special_approval_doc,
                            });
                        }
                        else{
                            rowitems.push({
                                rownode:param.data,
                                document_name:"Special Approval Document",
                                document_field_name:"special_approval_doc",
                                charges:(param.data.special_approval !== "" && param.data.special_approval !== null && param.data.special_approval !== undefined)?param.data.special_approval:0,
                                view_charge_doc:param.data.special_approval_doc,
                                view_uploaded_doc:""
                            });
                        }
                           
                        // console.log("rowitems ", rowitems)
                        param.successCallback(rowitems);
                    },
                    masterDetail: true
                }
            });
            // console.log(propsdata);
            try{
                if (rownode.colDef.field == 'other_charges_details') {
                    rownode.node.setExpanded(!rownode.node.expanded);
                }
                else {
                    rownode.node.setExpanded(false);
                }
            }
            catch(e){}
        }
    }
    onCellDoubleClickedFunc = async (cell) => {
        if (cell.colDef.field == 'other_charges') {

            await this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                headerName: 'GC Way Bill No',
                                field: "gc_waybill_no",
                                width: 200,
                                cellClass:['cellstylegridY'],
                                headerClass:["cellstylegridY"],	
                            },
                            {
                                headerName: 'GC Date',
                                field: "gc_date",
                                width: 200,
                                editable:true,
                                cellClass:['cellstylegridY'],
                                headerClass:["cellstylegridY"],	
                                valueGetter:function(prams){
                                    try {
                                        if(prams.data.gc_date !== "" && prams.data.gc_date !== undefined)
                                        {
                                            return getHyphenDDMMMYYYY(prams.data.gc_date);
                                        }
                                        else{
                                            return "";
                                        }
                                    } catch (error) {
                                        
                                    }
                                }
                            },
                            {
                                headerName: 'Allowable Weight',
                                field: "allowable_weight",
                                width: 200,
                                cellClass:['cellstylegridY'],
                                headerClass:["cellstylegridY"],	
                            },
                            {
                                headerName: 'Per KG Slab Rate',
                                field: "per_kg_slab_rate",
                                width: 200,
                                cellClass:['cellstylegridY'],
                                headerClass:["cellstylegridY"],	
                            },
                            {
                                headerName: 'Freight Cost',
                                field: "basic_trans_cost",
                                width: 200,
                                cellClass:['cellstylegridY'],
                                headerClass:["cellstylegridY"],
                                valueGetter:function(params){
                                    try {
                                        if(params.data.allowable_weight !== "" && params.data.allowable_weight !== undefined && params.data.per_kg_slab_rate !== "" && params.data.per_kg_slab_rate !== undefined)
                                        {
                                            var a = parseFloat(params.data.allowable_weight)*parseFloat(params.data.per_kg_slab_rate);
                                            return a;
                                        }
                                        else{
                                            return 0;
                                        }
                                    } catch (error) {
                                        
                                    }
                                }	
                            },
                        ],
                        defaultColDef: {
                            // flex: 1,
                            minWidth: 100,
                            filter: true,
                            resizable: true,
                            initialWidth: 200,
                            wrapHeaderText: true,
                            autoHeaderHeight: true,
                            sortable: true
                        },
                        context: this,
                        overlayNoRowsTemplate: 'No rows to show'
    
                    },
                    getDetailRowData: async function (param) {
                        var rowData = [];
                        var orms  = {
                            gc_waybill_no:param.data.gc_waybill_no
                        }
                        redirectURL.post("/billing/gcmanualorders",orms,{
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': localStorage.getItem("userid")
                              }
                          })
                        .then(async (response) => {
                            var records = response.data.records;
                            param.successCallback(records);
                        })
                       
                    },
                    masterDetail: true
                }
            });
            // console.log(propsdata);
            try{
                if (cell.colDef.field == 'other_charges') {
                    cell.node.setExpanded(!cell.node.expanded);
                }
                else {
                    cell.node.setExpanded(false);
                }
            }
            catch(e){}
            
        }
    }
    onClickViewPOD(rownode){

    }
    render() {
        if (this.state.customer === 'btn-active') {
            var tabFor = "customer";
        }
        else {
            var tabFor = "transporter";
        }

        let isCustomerTab = this.state.customer === 'btn-active'
        if (isCustomerTab) {
            var columnDefs = [
                {
                    headerName: "",
                    field: "",
                    pinned: "left",
                    width: 30,
                    checkboxSelection:  function(param){
                        try {
                            if(param.data.is_approve_status !== "" && param.data.is_approve_status !== undefined)
                            {
                                if(param.data.is_approve_status === 1)
                                {
                                    return true;
                                }
                                else{
                                    
                                    return false;
                                }
                            }
                            else{
                                
                                return true;
                            }
                        } catch (error) {
                            
                        }
                    }
                    // checkboxSelection:function(params){
                    //     try {
                    //         if(params.data.is_approve_status === 1 || params.data.is_approve_status === 0 || params.data.is_approve_status === undefined || params.data.is_approve_status === "")
                    //         {
                    //             return true;
                    //         }
                    //         else{
                    //             return false;
                    //         }
                    //     } catch (error) {

                    //     }
                    // }
                },

                {
                    headerName: Constants.FREIGHT_APPROVALS_STATUS,
                    field: "is_approve_status",
                    width: 180,
                    cellStyle: { 'white-space': 'normal' },
                    autoHeight: true,
                    valueGetter: function (params) {
                        try {
                            if (params.data.is_approve_status !== "" && params.data.is_approve_status !== undefined) {
                                if (tabFor === "transporter") {
                                    if (params.data.is_approve_status === 1) {
                                        return "Send for Approval";
                                    }
                                    else if (params.data.is_approve_status === 2) {
                                        return "Approved";
                                    }
                                    else if (params.data.is_approve_status === 3) {
                                        return "Rejected";
                                    }
                                    else {
                                        return "Pending";
                                    }
                                }
                                else {
                                    if (params.data.is_approve_status === 1) {
                                        return "Requested for Approval";
                                    }
                                    else if (params.data.is_approve_status === 2) {
                                        return "Approved";
                                    }
                                    else if (params.data.is_approve_status === 3) {
                                        return "Rejected";
                                    }
                                    else {
                                        return "Pending";
                                    }
                                }

                            }
                            else {
                                return "Pending";
                            }
                        } catch (error) {

                        }
                    }
                },
                {
                    headerName: Constants.TRIP_TRUCK_NO,
                    field: "truck_no",
                    width: 120,
                },

                {
                    headerName: Constants.TRIP_CONSIGN_NO,
                    field: "consignment_code",
                    width: 160,
                },
                {
                    headerName: Constants.GC_WAY_BILL_NO,
                    field: "gc_waybill_no",
                    width: 160,
                },
                {
                    headerName: Constants.TRIP_INV_DATE,
                    field: "invoice_date",
                    width: 140,
                    valueGetter: function (params) {
                        try {
                            if (params.data.invoice_date != undefined && params.data.invoice_date != "") {
                                return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                            }

                        }
                        catch (e) { }
                    }
                },
                // {
                //     headerName: Constants.TRIP_REACHED_DEST_DATE,
                //     field: "recent_dealer_reported",
                //     width: 140,
                //     valueGetter: function (params) {
                //         try {
                //             if (params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != "") {
                //                 return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
                //             }

                //         }
                //         catch (e) { }
                //     }
                // },
                {
                    headerName: Constants.TRIP_DELIVERED_DATE,
                    field: "delivery_datetime",
                    width: 140,
                    valueGetter: function (params) {
                        try {
                            if (params.data.delivery_datetime != undefined && params.data.delivery_datetime != "") {
                                return getHyphenDDMMMYYYYHHMM(params.data.delivery_datetime);
                            }

                        }
                        catch (e) { }
                    }

                },

                {
                    headerName: Constants.TRIP_PLANT_CODE,
                    field: "consigner_code",
                    width: 100,
                },
                {
                    headerName: Constants.TRIP_CUSTOMER_CODE,
                    field: "consignee_code",
                    width: 100,
                },
                {
                    headerName: Constants.TRIP_CUSTOMER_NAME,
                    field: "consignee_name",
                    width: 180,
                },
                {
                    headerName: Constants.TRIP_CUSTOMER_CITY,
                    field: "consignee_city",
                    width: 180,
                },
                {
                    headerName: Constants.TRIP_CUSTOMER_STATE,
                    field: "consignee_state",
                    width: 180,
                },
                {
                    headerName: Constants.TRIP_TRANSPORTER_CODE,
                    field: "transporter_code",
                    width: 180,
                },
                {
                    headerName: Constants.TRIP_TRANSPORTER_NAME,
                    field: "service_provider",
                    width: 180,
                },
                // {
                //     headerName: Constants.TRIP_TOTAL_DISTANCE_KM,
                //     field: "total_distance_travelled_km",
                //     width: 140,
                //     valueGetter: function (params) {
                //         try {
                //             if (params.data.total_distance_travelled_km != undefined && params.data.total_distance_travelled_km != "") {
                //                 return parseInt(parseInt(params.data.total_distance_travelled_km) / 1000);
                //             }
                //         }
                //         catch (e) { }
                //     }
                // },
                // {
                //     headerName: Constants.TRIP_TOTAL_TRIP_DISTANCE_KM,
                //     field: "total_travelled_trip_dist_km",
                //     width: 100,
                // },
                // {
                //     headerName: Constants.TRIP_DURATION_DAYS,
                //     field: "trip_duration_days",
                //     width: 100,
                // },
                
                {
                    headerName: Constants.TRIP_NO_OF_DAYS,
                    field: "transit_time_days",
                    width: 100,
                    // valueGetter: function (params) {

                    //     var hourDifference = 0;
                    //     if (params.data.recent_dealer_reported && params.data.delivered_date) {
                    //         var reacheddDateOnlyMoment = moment(params.data.recent_dealer_reported, 'YYYY-MM-DD');
                    //         var deliveredDateOnlyMoment = moment(params.data.delivered_date, 'YYYY-MM-DD');
                    //         return deliveredDateOnlyMoment.diff(reacheddDateOnlyMoment, 'days');
                    //         //    console.log("diff= ", hourDifference);
                    //     }
                    // }

                },
                {
                    headerName: Constants.DEVIATION_FLOW_PENALITY_COST,
                    field: "penality_cost",
                    width: 100,
                },
                {
                    headerName: Constants.DEVIATION_FLOW_PENALITY_WAIVER_REMARKS,
                    field: "transporter_waiver_remarks",
                    width: 200,
                    cellStyle: { 'white-space': 'normal' },
                    autoHeight: true,
                },

                // {
                //     headerName: Constants.DEVIATION_FLOW_WAIVER_COST,
                //     field: "transporter_waiver_remarks",
                //     width: 100,
                //     editable: function (params) {
                //         try {
                //             if (params.data.is_transporter_waiver_request !== "" && params.data.is_transporter_waiver_request !== undefined) {
                //                 if (params.data.is_transporter_waiver_request === 1) {
                //                     return true;
                //                 }
                //                 else {
                //                     return false;
                //                 }
                //             }
                //             else {
                //                 return false;
                //             }
                //         } catch (error) {

                //         }
                //     }
                // },
                {
                    headerName: "POD Doc",
                    field: "invoice_no",
                    width: 120,
                    cellRendererSelector: function (params) {
                        return {
                            component: "ViewPOD"
                        }
                    },
                }
            ]

        } else {
            var columnDefs = [
                {
                    headerName: "",
                    field: "transporter",
                    width: 50,
                    pinned: "left",
                    checkboxSelection: true
                },

                {
                    headerName: Constants.FREIGHT_APPROVALS_STATUS,
                    field: "is_approve_status",
                    width: 180,
                    valueGetter: function (params) {
                        try {
                            if (params.data.is_approve_status !== "" && params.data.is_approve_status !== undefined) {
                                if (tabFor === "transporter") {
                                    if (params.data.is_approve_status === 1) {
                                        return "Send for Approval";
                                    }
                                    else if (params.data.is_approve_status === 2) {
                                        return "Approved";
                                    }
                                    else if (params.data.is_approve_status === 3) {
                                        return "Rejected";
                                    }
                                    else {
                                        return "Pending";
                                    }
                                }
                                else {
                                    if (params.data.is_approve_status === 1) {
                                        return "Requested for Approval";
                                    }
                                    else if (params.data.is_approve_status === 2) {
                                        return "Approved";
                                    }
                                    else if (params.data.is_approve_status === 3) {
                                        return "Rejected";
                                    }
                                    else {
                                        return "Pending";
                                    }
                                }
                            }
                            else {
                                return "Pending";
                            }
                        } catch (error) {

                        }
                    }
                },
                {
                    headerName: Constants.TRIP_TRUCK_NO,
                    field: "truck_no",
                    width: 120,
                },
                {
                    headerName: Constants.TRIP_CONSIGN_NO,
                    field: "consignment_code",
                    width: 160,
                },
                {
                    headerName: Constants.GC_WAY_BILL_NO,
                    field: "gc_waybill_no",
                    width: 160,
                },
                {
                    headerName: Constants.TRIP_INV_DATE,
                    field: "invoice_date",
                    width: 140,
                    valueGetter: function (params) {
                        try {
                            if (params.data.invoice_date != undefined && params.data.invoice_date != "") {
                                return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                            }

                        }
                        catch (e) { }
                    }
                },
                // {
                //     headerName: Constants.TRIP_REACHED_DEST_DATE,
                //     field: "delivery_datetime",
                //     width: 140,
                //     valueGetter: function (params) {
                //         try {
                //             if (params.data.delivery_datetime != undefined && params.data.delivery_datetime != "") {
                //                 return getHyphenDDMMMYYYYHHMM(params.data.delivery_datetime);
                //             }

                //         }
                //         catch (e) { }
                //     }
                // },
                {
                    headerName: Constants.TRIP_DELIVERED_DATE,
                    field: "delivery_datetime",
                    width: 140,
                    valueGetter: function (params) {
                        try {
                            if (params.data.delivery_datetime != undefined && params.data.delivery_datetime != "") {
                                return getHyphenDDMMMYYYYHHMM(params.data.delivery_datetime);
                            }

                        }
                        catch (e) { }
                    }

                },

                {
                    headerName: Constants.TRIP_PLANT_CODE,
                    field: "consigner_code",
                    width: 100,
                },
                {
                    headerName: Constants.TRIP_CUSTOMER_CODE,
                    field: "consignee_code",
                    width: 100,
                },
                {
                    headerName: Constants.TRIP_CUSTOMER_NAME,
                    field: "consignee_name",
                    width: 180,
                },
                {
                    headerName: Constants.TRIP_CUSTOMER_CITY,
                    field: "consignee_city",
                    width: 180,
                },
                {
                    headerName: Constants.TRIP_CUSTOMER_STATE,
                    field: "consignee_state",
                    width: 180,
                },
                {
                    headerName: Constants.TRIP_TRANSPORTER_CODE,
                    field: "transporter_code",
                    width: 180,
                },
                {
                    headerName: Constants.TRIP_TRANSPORTER_NAME,
                    field: "service_provider",
                    width: 180,
                },
                // {
                //     headerName: Constants.TRIP_TOTAL_DISTANCE_KM,
                //     field: "total_distance_travelled_km",
                //     width: 140,
                //     valueGetter: function (params) {
                //         try {
                //             if (params.data.total_distance_travelled_km != undefined && params.data.total_distance_travelled_km != "") {
                //                 return parseInt(parseInt(params.data.total_distance_travelled_km) / 1000);
                //             }
                //         }
                //         catch (e) { }
                //     }
                // },
                // {
                //     headerName: Constants.TRIP_TOTAL_TRIP_DISTANCE_KM,
                //     field: "total_travelled_trip_dist_km",
                //     width: 100,
                // },
                // {
                //     headerName: Constants.TRIP_DURATION_DAYS,
                //     field: "trip_duration_days",
                //     width: 100,
                // },
                
                {
                    headerName: Constants.TRIP_NO_OF_DAYS,
                    field: "transit_time_days",
                    width: 100,
                    // valueGetter: function (params) {

                    //     var hourDifference = 0;
                    //     if (params.data.recent_dealer_reported && params.data.delivered_date) {
                    //         var reacheddDateOnlyMoment = moment(params.data.recent_dealer_reported, 'YYYY-MM-DD');
                    //         var deliveredDateOnlyMoment = moment(params.data.delivered_date, 'YYYY-MM-DD');
                    //         return deliveredDateOnlyMoment.diff(reacheddDateOnlyMoment, 'days');
                    //         //    console.log("diff= ", hourDifference);
                    //     }
                    // }
                },
                {
                    headerName: "POD Doc",
                    field: "invoice_no",
                    width: 120,
                    cellRendererSelector: function (params) {
                        return {
                            component: "ViewPOD"
                        }
                    },
                },
                {
                    headerName: 'Allowable Weight',
                    field: "allowable_weight",
                    width: 100
                },
                {
                    headerName: 'Per KG Slab Rate',
                    field: "per_kg_slab_rate",
                    width: 100	
                },
                {
                    headerName: 'Freight',
                    field: "frieght",
                    width: 100,
                    valueGetter:function(params){
                        try {
                            if(params.data.allowable_weight !== "" && params.data.allowable_weight !== undefined && params.data.per_kg_slab_rate !== "" && params.data.per_kg_slab_rate !== undefined)
                            {
                                var a = parseFloat(params.data.allowable_weight)*parseFloat(params.data.per_kg_slab_rate);
                                return a;
                            }
                            else{
                                return 0;
                            }
                        } catch (error) {
                            
                        }
                    }
                },
                {
                    headerName: 'ODA',
                    field: "ODA_TAT",
                    width: 100,
                },
                {
                    headerName: 'Other Charges',
                    field: "other_charges",
                    width: 160,
                    valueGetter:function(params)
                    {
                        try {
                            if(params.data.other_charges !== "" && params.data.other_charges !== undefined)
                            {
                                var a = params.data.other_charges;
                            }
                            else{
                                var a = 0;
                            }
                            
                            if(params.data.manual_charges !== "" && params.data.manual_charges !== undefined)
                            {
                                var b = params.data.manual_charges;
                            }
                            else{
                                var b = 0;
                            }
                            return parseFloat(a)+parseFloat(b);
                        } catch (error) {
                            
                        }
                    }
                },
                {
                    headerName: '',
                    field: "other_charges_details",
                    width: 160,
                    cellRenderer: () => `<button class="btn btn-success" style="padding:0px 5px;font-size:12px"> View Charges</button>`,
                },
                {
                    headerName: Constants.DEVIATION_FLOW_PENALITY_COST,
                    field: "penality_cost",
                    width: 100,
                },
                {
                    headerName: "",
                    field: "_id",
                    width: 180,
                    cellStyle: { 'white-space': 'normal' },
                    autoHeight: true,
                    cellRendererSelector: function (params) {
                        return {
                            component: "ViewRequest"
                        }
                    }
                },

                {
                    headerName: Constants.DEVIATION_FLOW_PENALITY_WAIVER_REMARKS,
                    field: "transporter_waiver_remarks",
                    width: 200,
                    cellStyle: { 'white-space': 'normal' },
                    autoHeight: true,
                },
                // {
                //     headerName: Constants.DEVIATION_FLOW_DETNETION_COST,
                //     field: "transporter_waiver_remarks",
                //     width: 100,
                //     editable: false
                // }
            ]
        }
        return (
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="col-xl-12 h3 mb-0 text-gray-800">Deviation Work Flow
                    </h1>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-md-12 ">
                        <div className='button-group'>
                            <button onClick={this.showTabView.bind(this, 1)} type="button" className={"btn " + (this.state.transporter)}>TRANSPORTER</button>
                            <button onClick={this.showTabView.bind(this, 2)} type="button" className={"btn " + (this.state.customer)}>CUSTOMER</button>
                        </div>
                        <div className={" " + (this.state.vtransportersh1)}>
                            <div className={"row"}>
                                <div className="col-xl-12 col-md-12 mb-4">
                                    <div className="card shadow h-100 py-2">
                                        <div className="card-body">
                                            <div style={{ width: "100%", height: "60vh" }} className="ag-theme-balham">
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    rowData={this.state.rowData}
                                                    columnDefs={columnDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    enableRangeSelection={true}
                                                    enableCharts={true}
                                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                                    masterDetail={true}
                                                    onGridReady={this.onGridReady}
                                                    sideBar={this.state.sideBar}
                                                    frameworkComponents={this.state.frameworkComponents}
                                                    statusBar={this.state.statusBar}
                                                    stopEditingWhenGridLosesFocus={true}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                    onRowSelected={this.onSelectedRowShowButtons.bind(this)}
                                                    onCellEditingStopped={this.onCellEdit.bind(this)}
                                                    onCellClicked={this.onCellClickedFunc.bind(this)}
                                                    onCellDoubleClicked={this.onCellDoubleClickedFunc.bind(this)}
                                                    rowSelection={this.state.rowSelection}
                                                    suppressRowClickSelection={true}
                                                />
                                            </div>
                                            {
                                                (localStorage.getItem("user_type") === "TRANSPORTER")?
                                                    <div className={"col-md-12 " + (this.state.showButtons)} style={{ textAlign: "right" }}>
                                                        {this.state.transporter === "btn-active"?
                                                        <button type="button" className="btn btn-success btncm" onClick={this.onClickSendApproval.bind(this)} >Send For Approval</button>:""
                                                        }
                                                        
                                                    </div>
                                                :""
                                            }

                                            {
                                                (localStorage.getItem("user_type") === "CUSTOMER")?
                                                   <div className={"col-md-12 " + (this.state.showButtons)} style={{ textAlign: "right" }}>
                                                    {this.state.customer === "btn-active"?
                                                    <button type="button" className="btn btn-success btncm" onClick={this.onClickApprove.bind(this)} >Approve</button>:""
                                                    }
                                                    {/* <button type="button" className="btn btn-danger btncm" onClick={this.onClickReject.bind(this)} >Reject</button> */}
                                                </div>
                                                :""
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={" "+(this.state.vcustomersh)}>
                    <div className={"row"}>
                        <div className="col-xl-12 col-md-12 mb-4">
                            <div className="card shadow h-100 py-2">
                                <div className="card-body">
                                    <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                        <AgGridReact 
                                            rowData={this.state.rowData} 
                                            columnDefs={columnDefs}
                                            defaultColDef ={this.state.defaultColDef }
                                            enableRangeSelection={true}
                                            enableCharts={true}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            masterDetail={true}
                                            onGridReady={this.onGridReady}
                                            sideBar={this.state.sideBar}
                                            frameworkComponents={this.state.frameworkComponents}
                                            statusBar={this.state.statusBar}
                                            stopEditingWhenGridLosesFocus= {true}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            
                                        />
                                    </div> 
                                        
                                    <div className="col-md-12" style={{textAlign:"right"}}>
                                        <button type="button" className="btn btn-primary btncm" onClick={this.onClickSendBack.bind(this)}>Send Back</button>
                                        <button type="button" className="btn btn-success btncm" onClick={this.onClickApprove.bind(this)} >Approve</button>
                                        <button type="button" className="btn btn-danger btncm" onClick={this.onClickReject.bind(this)} >Reject</button>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className={"overlay-part " + (this.state.overly)} onClick={this.hideSlideBlock}></div>

                {/* Send Back Remarks */}
                <div className={"slide-r " + (this.state.requestFormula)}>
                    <div className="slide-r-title">
                        <h4>Remarks</h4>
                    </div>

                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <form className="theme-form" method="POST" id="waiverreqForm" enctype="multipart/form-data" onSubmit={this.onClickSubmitFormula.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Remarks<span className="rclr fbold">*</span></label>
                                        <textarea style={{ height: "100px" }} className="form-control" name="remarks" id="remarks" value={this.state.remarks} onChange={this.changeHandler} rows="8"></textarea>
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>






                {/* <div className={"row"}>
                     <div className="col-xl-12 col-md-12 mb-4">
                         <div className="card shadow h-100 py-2">
                            <div className="card-body">
                                 <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                                  <AgGridReact 
                                        rowData={this.state.rowData} 
                                        columnDefs={columnDefs}
                                        defaultColDef ={this.state.defaultColDef }
                                        enableRangeSelection={true}
                                        enableCharts={true}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        masterDetail={true}
                                        onGridReady={this.onGridReady}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        
                                    />
                                    </div> 
                                <div className="col-md-12" style={{textAlign:"right"}}>
                                    <button type="button" className="btn btn-primary btncm" onClick={this.onClickSendBack.bind(this)}>Send Back</button>
                                    <button type="button" className="btn btn-success btncm" onClick={this.onClickApprove.bind(this)} >Approve</button>
                                    <button type="button" className="btn btn-danger btncm" onClick={this.onClickReject.bind(this)} >Reject</button>
                                </div> 
                            </div>
                        </div>
                    </div>
				</div> */}

            </div>

        );
    }
}

function redirectToConfigurations() {
    window.location.href = "/configurations"
}