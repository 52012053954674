/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMMYYYY, getHyphenDDMMYYYY } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import CountUp from 'react-countup';
import 'react-datetime/css/react-datetime.css'
import $ from 'jquery';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import 'jquery-ui/ui/widgets/datepicker';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class HolidayCmp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "multiple",
            context: { componentParent: this },

            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },

            frameworkComponents: {
                // updateItem:UpdateItem,
                // deleteItem:DeleteItem,
                //statusChange:StatusChange
            },

            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters: [],
            sliderRso: "",
            sliderUpdate: "",
            transporter: "",
            truck_no: "",
            trucktype: "",
            deptcode: "",
            sources: [],
            map_invoice_date: {},
            map_invoice_count: {},
            destinations: [],
            sourceopt: "",
            oem_code: "",
            oem_name: "",
            holiday_state:"",
            holiday_name:"",
            region_code:"",
            city:"",
            holiday_date:"",
            zone:"",
            industry_type: "",
            officer_mobile: "",
            officer_alteratemobile: "",
            officer_locations: [],
            officertype: "",
            rowid: "",
            sliderBulk: "",
            file: "",
            csvcontent: [],
            overly:"show-n",
            loadshow:"show-n",
            viewData: [],
            billing_month: "",
            reck_info: [],
            allrowData: [],
            nottrigerred: [],
            trigerred: [],
            dispatchedrakes: [],
            detailCellRendererParams: {},
            remarks_data: [],
            sliderConfigData: "",
            config_region_data: [],
            sliderrowshow:"show-n",
            rownodes:[],
            components: { datePicker: getDatePicker() },
        };

    }
    // getCurrentDepartmentCode(pathToMatch = null) {
    // 	let department = null;
    // 	let departmentName = null;
    // 	switch(pathToMatch) {
    // 		case "/tptoemmaster":
    // 			department='SNDG';
    // 			departmentName = " ";
    // 			break;

    // 		default:
    // 			console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
    // 	}
    // 	this.setState({
    // 		currentDepartmentTitle:departmentName
    // 	});
    // 	return department;
    // }
    componentDidMount() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n"
        })
        loadDateTimeScript()
        if (googleAnalytics.page.enableGA) {
            this.logPageView(); // Log page view to GA
            let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            this.setState({ eventAction: eventAction });
        }
        // this.setState({
        //     loadshow: "show-n",
        //     overly: "show-n"
        // })
        // get department code to filter records by department
        // let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
        // this.setState({
        //     departmentCode: currentDepartmentCode
        // });
        /*Consignments List*/
        this.onLoadData();
    };
    onLoadData = () => {
        this.setState({
            overly: "show-m",
            loadshow: "show-n"
        })
      
        redirectURL.post('/masters/getHolidaysMaster', {},{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
              }
          }).then((response) => {
            var records = response.data;
           
            this.setState({
                rowData: records.records,             
                overly: "show-n",
                loadshow: "show-n"
            });
        })

    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;

    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);



    }


    onCellEdit = async (node) => {
        // // console.log(node,"clickedData")
        // var updateCheck = 0;
        // var clickedValue = node.value;
        // if (clickedValue >= 0 && clickedValue != '' && clickedValue != undefined) {
        //     updateCheck = 1;
        // }
        // if (updateCheck == 1) {
        //     var rowData = [];
        //     this.gridApi.forEachNode((e) => {
        //         rowData.push(e.data)
        //     })
        //     this.setState({
        //         rowData
        //     });
        //     this.gridApi.refreshCells();
        // }
    }


    handlerStartDateTime = (event, currentDate, selectedDate) => {
        // console.log(event,"event");
        var d = new Date(event._d);
        // console.log('startDate',d, 'changed date', moment.parseZone(event._d).format('YYYY-MM'))
        var startdate = moment.parseZone(event._d).format('YYYY-MM')

        this.setState({
            selection_month: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    /*Alert Popups*/
    closeAlert = () => {
        // $("#holiday").val("")
        this.setState({
            show: false,
            overly:"show-n",
            loadshow:"show-n"
        });
        // window.location.reload()
    }


    onAddRow() {
        // var newItem = 
        // {
        //     "truck_no":"",
        //     "truck_type":"",
        //     "transporter_code":"",
        //     "dept_code":""
        // };
        // console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
            sliderRso: 'slider-translate',
            overly: 'show-m',
            oem_code: "",
            oem_name: "",
            industry_type: ""
        })

    }
    onRowClicked(cell) {
    }


    updateRowHandler = async (cell) => {

        var rowid = cell._id;
        var oem_code = cell.oem_code;
        var oem_name = cell.oem_name;
        var industry_type = cell.industry_type;
        await this.setState({
            sliderUpdate: 'slider-translate',
            overly: 'show-m',
            loadshow:'show-n',
            // oem_code: oem_code,
            // oem_name: oem_name,
            // industry_type: industry_type,
            rowid: rowid
        })

    }

    onClickHideAll = () => {
        //  $("#holiday_name").val("")
        //  $("#holiday_date").val("")
        //  $("#holiday_state").val("")
        //  $("#city").val("")
        //  $("#zone").val("")
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderrowshow:"",
            holiday_name : "",
            holiday_date : "",
            holiday_state : "",
            city : "",
            zone : "",
            region_code:"" 
            
        });
        //  document.getElementById("holiday").reset();

    }
 

    changeHandler = (event) => {
        let name = event.target.name;
        const inputValue = event.target.value;
        console.log(inputValue, "inputValue")
        const characterRegex = /^[a-zA-Z\s]+$/;
        console.log(!characterRegex.test(inputValue), "910")
        if (!characterRegex.test(inputValue)) {
            if (inputValue == "") {
                this.setState({
                    [name]: "",
                });
            }
        } else {
            this.setState({
                [name]: inputValue,
            });
        }
    }
    changeHandler2 = (event) => {
        let name = event.target.name;
        const inputValue = event.target.value;
        console.log(inputValue, "inputValue")
        const characterRegex = /^[A-Za-z0-9]*$/;
        console.log(!characterRegex.test(inputValue), "910")
        if (!characterRegex.test(inputValue)) {
            if (inputValue == "") {
                this.setState({
                    [name]: "",
                });
            }
        } else {
            this.setState({
                [name]: inputValue,
            });
        }
    }
    addNewRow = () => {
        // console.log('addNewRow');
        // console.log('addNewRow',this.gridApi);
        this.gridApi.insertItemsAtIndex(0, [{holiday_date: "", holiday_name: "", state: "", city: "", zone: "",region_code:"" }])
        // this.gridApi.updateRowData({add:[{holiday_date_txt:"",holiday_name:""}]});
    }

    onClickupdateRowDataFunc = (event) =>{
        event.preventDefault();
        // let rowData = this.gridApi.getModel().rowsToDisplay.map(row => row.data)
        // let  rownodes = this.gridApi.getSelectedRows();
        // console.log("rowData",rowData)
        // console.log("rownodes",rownodes)
        var params = {
            holiday_name:this.state.holiday_name,
            holiday_date:this.state.holiday_date,
            holiday_state:this.state.holiday_state,
            city:this.state.city,
            zone:this.state.zone,
            region_code:this.state.region_code
        }
         console.log("params",params)
            redirectURL.post("/masters/updateAddRowData",params,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                  }
              }).then(res =>{
                console.log("response", res)
                // document.getElementById("holiday").reset()
                // $("#holiday_date").val("");
                if(res.data.message == "Cant insert Empty Data"){
                    this.setState({
                        show : true,
                        overly: "show-n",
                        loadshow: 'show-n',
                        sliderrowshow:"show-n",
                        basicTitle : 'Holiady Date is already Existed',
                        basicType : 'warning',
                        holiday_name : "",
                        holiday_date : "",
                        holiday_state : "",
                        city : "",
                        zone : "" ,
                        region_code:""
                    })
                    setTimeout(() =>{
                        this.onLoadData()
                    }, 1000)
                }else{
                    this.setState({
                        show : true,
                        overly: "show-n",
                        loadshow: 'show-n',
                        sliderrowshow:"show-n",
                        basicTitle : 'Success',
                        basicType : 'success',
                        holiday_name : "",
                        holiday_date : "",
                        holiday_state : "",
                        city : "",
                        zone : "" ,
                        region_code:""
                    })
                    setTimeout(() =>{
                        this.onLoadData()
                    }, 1000)
            }
            
           })
                
                   
       
    }
    deleteRowDataFunc = () =>{
        let  rownodes = this.gridApi.getSelectedRows();
        // console.log("369",rownodes)
         var params = {
               rownodes : JSON.stringify(rownodes), 
               collection : 'master_holidays',
               email : localStorage.getItem('email')
        }
        if(rownodes.length){
            
        if (window.confirm("Are You Sure! Do you want really delete?")) {
            redirectURL.post("/masters/deleteAllRowData", params,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                  }
              }).then(response =>{
                  
                if (response.data.status == "success") {
                    this.setState({
                        basicTitle: "Deleted Successfully.",
                        basicType: "success",
                        show: true
                    })
                    
                    //window.location.reload();
                    setTimeout(() =>{
                        this.onLoadData()
                    }, 1000)
                }
               }).catch((e) => {
                console.log(e);
            })
        
          }
        }else{
            alert("please select atleast one record!!")
        }
    
    }
   

    onChangeDepartment(deptcode) {
        this.setState(
            { deptcode },
            () => console.log(`Option selected:`, this.state.deptcode)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    onClickShowBulk() {
        this.setState({
            overly: "show-m",
            sliderBulk: 'slider-translate',
        })
    }

    // changeFileHandler = async (e) => {
    //     //console.log(e.target.files[0])
    //     var dt = '';
    //     // const config = {
    //     // 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
    //     // }

    //     const config = {
    //         headers: [
    //             {
    //                 name: 'oem_name',
    //                 inputName: 'oem_name',
    //                 required: true,
    //                 requiredError: function (headerName, rowNumber, columnNumber) {
    //                     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //                 }
    //             },
    //             {
    //                 name: 'oem_code',
    //                 inputName: 'oem_code',
    //                 required: true,
    //                 requiredError: function (headerName, rowNumber, columnNumber) {
    //                     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //                 }
    //             },
    //             {
    //                 name: 'industry_type',
    //                 inputName: 'industry_type',
    //                 required: true,
    //                 requiredError: function (headerName, rowNumber, columnNumber) {
    //                     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //                 }
    //             }

    //         ]
    //     }
    //     var data = CSVFileValidator(e.target.files[0], config)
    //         .then((csvData) => {
    //             this.setState({
    //                 csvcontent: csvData.data
    //             });
    //         })
    //         .catch(err => { })

    //     var out = new Promise(function (reject, resolve) {
    //         var reader = new FileReader();
    //         reader.onload = async function (e) {
    //             var contents = await e.target.result;
    //             // console.log("contents ", contents )

    //             resolve(contents);
    //         };
    //         var tt = reader.readAsText(e.target.files[0]);
    //         // console.log("tt ",tt)
    //     });
    //     //console.log("Out ", out);

    //     this.setState({
    //         file: e.target.files[0]
    //     });

    // }
    onCellClicked = (e) => {
        if (e.colDef.field == "delete") {
            var rowId = e.data._id;
            // console.log("delete",e.data);
            var qry = {
                rowId: rowId,
                collection : 'master_holidays',
                email : localStorage.getItem('email')
            }
            //var confirm = confirm("Are You Sure! Do you want really delete?");
            if (window.confirm("Are You Sure! Do you want really delete?")) {
                redirectURL.post("/masters/deleteRowData", qry,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  }).then((response) => {
                    // console.log("response",response);
                    if (response.data.status == "success") {
                        this.setState({
                            basicTitle: "Deleted Successfully.",
                            basicType: "success",
                            show: true
                        })
                        //window.location.reload();
                        setTimeout(() =>{
                            this.onLoadData()
                        }, 1000)
                    }
                    else {
                        this.setState({
                            basicTitle: "Failed to Delete.",
                            basicType: "warning",
                            show: true
                        })
                    }
                }).catch((e) => {
                    console.log(e);
                })
            }
        }
    }
    hideSlideBlock = () => {
        //  $("#podDoc").val("")
        
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderrowshow:""
            
        });
        //   document.getElementById("holiday").reset();
        
    }
    reseUpload = () => {
        this.setState({
            sliderrowshow:"",
            loadshow: 'show-n',
            overly: 'show-n',
            
        })
        // document.getElementById("holiday").reset();
       
    }
    onClickHolidayCalender(){
        // var rownodes = this.gridApi.getSelectedRows();

        // console.log("rownode",rownode)
        this.setState({
            sliderrowshow: "slider-translate-30p", // slide30
            overly: "show-m",
            loadshow: "show-n" 
            //  rownode: rownodes
        });
       
       
    }
    changeHolidayDateTimeValue = (event, currentDate, selectedDate) => {
        // console.log("event", event)
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        // console.log("getHyphenDDMMMYYYYHHMM(d) ",getHyphenDDMMMYYYYHHMM(d))
        this.setState({
            holiday_date: getHyphenDDMMMYYYY(d)
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    onCloseSliderDiv = () => {
        this.setState({
            sliderrowshow: "show-n",
            overly: "show-n",
            loadshow: 'show-n',
            holiday_name : "",
            holiday_date : "",
            holiday_state : "",
            city : "",
            zone : "",
            region_code:"" 

        });
    };
 
    onSubmitBulkUpload(event) {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": "Buk Upload",
                "action": "form",
                "label": "Buk Upload",
            }
            googleAnalytics.logEvent(eventOptions);
        }

        var params = {
            csvcontent: this.state.csvcontent,
        }
        // console.log("content ",this.state.csvcontent)
        // console.log("Params ", params)
        redirectURL.post("/dashboard/bulkuploadoemdata", params,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
              }
          })
            .then((response) => {
                if (response.data.status == "success") {
                    this.setState({
                        show: true,
                        basicTitle: 'Successfully added items',
                        basicType: "success",
                        loadshow: "show-n",
                        overly: "show-n",
                        sliderBulk: "",
                        file: ""
                    });
                    this.onLoadData();
                }

                else {
                    this.setState({
                        show: true,
                        basicTitle: 'Something went wrong',
                        basicType: "danger",
                    });
                }
            })
    }
    onClickTab = (counterKey) => {
        // this.setState({
        //     rowData: this.state[counterKey]
        // });
    }

    render() {
        const modalStyles = {
            width: '1300px !important',
        }
        var transporters = []
        var trans = this.state.transporters;
        if (trans.length > 0) {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
        const { usermanualmodal } = this.state;
        var hideChilds = true;
        if (this.state.departmentCode == "LOG-PRT") {
            hideChilds = false
        }
        const columnwithDefs = [
            // {
            //     headerName: '',
            //     field: '_id',
            //     width: 80,
            //     checkboxSelection: true
            // },
           
            {
                headerName: "Holiday Date",
                field: "holiday_date",
                width: 150,
                filter: "agSetColumnFilter",
                resizable: true,
                valueGetter:function(params){
                    try {
                        if(params.data.holiday_date !== "" && params.data.holiday_date !== undefined)
                        {
                            return getHyphenDDMMMYYYY(params.data.holiday_date);
                        }
                        else{
                            return "";
                        }
                    } catch (error) {
                        
                    }
                },
                editable: function(params) {
                    if (params.data.is_editable == 1) {
                        return true;
                    } else {
                        return false;
                    }
                }
                
            },
            {
                headerName: "Name",
                field: "holiday_name",
                width: 100,
                filter: "agSetColumnFilter",
                // editable: AlphaTextEditor,
                resizable: true,
                editable: function(params) {
                    if (params.data.is_editable == 1) {
                        return true;
                    } else {
                        return false;
                    }
                }    
              
            },
            {
                headerName: "State",
                field: "state",
                width: 100,
                filter: "agSetColumnFilter",
                resizable: true,
                editable: function(params) {
                    if (params.data.is_editable == 1) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                headerName: "City",
                field: "city",
                width: 100,
                filter: "agSetColumnFilter",
                resizable: true,
                editable: function(params) {
                    if (params.data.is_editable == 1) {
                        return true;
                    } else {
                        return false;
                    }
                }
                
            },
            {
                headerName: "Zone",
                field: "zone",
                width: 100,
                filter: "agSetColumnFilter",
                resizable: true,
                editable: function(params) {
                    if (params.data.is_editable == 1) {
                        return true;
                    } else {
                        return false;
                    }
                }
                
            },
            {
                headerName: "Region Code",
                field: "region_code",
                width: 150,
                filter: "agSetColumnFilter",
                resizable: true,
                editable: function(params) {
                    if (params.data.is_editable == 1) {
                        return true;
                    } else {
                        return false;
                    }
                }
                
            },
            {
                pinned: "left",
                headerName: "",
                field: "delete",
                width: 50,
                resizable: true,
                checkboxSelection: true,
                headerCheckboxSelection: true,
                editable: false,
                cellRenderer: params => {
                    return '<i class="icofont icofont-ui-delete"></i>';
                },
                filter: false,
            } 
        ];

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 m-3">
                        <div className="card">
                            <div className="card-header col-xl-12 col-lg-12">
                                <h5 style={{ width: "100%", float: "left", marginBottom: "20px" }}>
                                    <i className="icofont icofont-read-book-alt cus-i"></i> <span>Holiday Calendar</span>
                                </h5>
                                <button className="btn btn-success float-right" onClick={this.onClickHolidayCalender.bind(this)}>Add New Holiday</button>
                                {/* <button className="btn btn-warning float-right" onClick={this.updateRowDataFunc}>Update</button> */}
                                <button className="btn btn-danger float-right" onClick={this.deleteRowDataFunc}>Delete</button>
                                {/* <button className="btn btn-info" onClick={this.showBulkHolidayUpload}>Bulk Upload Holidays</button> */}

                            </div>


                            <div className="card-body m-3">
                                <div id="myGrid" style={{ height: "450px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        // rowData={this.state.produceRowData}
                                        columnDefs={columnwithDefs}
                                        stopEditingWhenGridLosesFocus={true}
                                        onGridReady={this.onGridReady}
                                        components={this.state.components}
                                        statusBar={this.state.statusBar}
                                        rowSelection={this.state.rowSelection}
                                        //  onCellEditingStopped={this.onCellEdit}
                                        modules={this.state.modules}
                                        rowData={this.state.rowData}
                                        // enableCharts={false}
                                        // paginationAutoPageSize={true}
                                        // paginationPageSize={this.state.paginationPageSize}
                                        // pagination={true}
                                        enableRangeSelection={true}
                                        // onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        onCellClicked={this.onCellClicked}
                                        // // deleteRowHandler={this.deleteRowHandler}
                                        // // onCellClicked={this.onRowClicked.bind(this)}
                                        // // detailCellRendererParams={this.state.detailCellRendererParams}
                                        // masterDetail={true}
                                        // // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        // frameworkComponents={this.state.frameworkComponents}
                                        // //editType={this.state.editType}
                                        // stopEditingWhenGridLosesFocus={true}
                                        // // floatingFilter={true}
                                        // enableCellChangeFlash={true}
                                        // suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                    // enableCellTextSelection={true}
                                    updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    {/* <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div> */}
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <div className={"slide-r  "+(this.state.sliderrowshow)}>
                    <div className="slide-r-title">
                        <h4>Upload New Holiday
                            <span
                                className="float-right closebtn"
                                style={{ marginRight: "25px" }}
                                onClick={this.onCloseSliderDiv}
                            >
                                X
                            </span>
                        </h4>
                    </div>
                    <div className="col-sm-12">
                    
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <form className="theme-form" id="holiday" method="POST" enctype="multipart/form-data"  onSubmit={this.onClickupdateRowDataFunc.bind(this)}>
                                <div className="row p-20p">
                                    
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Holiday Name<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="holiday_name" id="holiday_name" value={this.state.holiday_name} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Holiday Date<span className="rclr fbold">*</span></label>                                        
                                        {/* <input type="text" name="delivered_date" id="delivered_date" value={this.state.delivered_date} className="form-control" onChange={this.changeHandler} required /> */}
                                        <Datetime 
                                        inputProps={{ placeholder: 'Holiday Date', name: 'holiday_date',id:'holiday_date', autoComplete:'off',readOnly:true }}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true} 
                                        dateFormat="DD-MMM-YYYY" 
                                        timeFormat="HH:mm"  
                                        name="holiday_date"
                                        value={this.state.holiday_date}
                                        onChange={this.changeHolidayDateTimeValue.bind(this)}
                                        />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>State<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="holiday_state" id="holiday_state" value={this.state.holiday_state} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>City<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="city" id="city" value={this.state.city} className="form-control" onChange={this.changeHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Zone<span className="rclr fbold">*</span></label>   
                                        <input type="text" name="zone" id="zone" value={this.state.zone} className="form-control" onChange={this.changeHandler2} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Region Code<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="region_code" id="region_code" value={this.state.region_code} className="form-control" onChange={this.changeHandler2} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button  type ="submit" className="btn btn-success">Submit</button>
                                        {/* <button type="button" className="btn btn-default" onClick={this.reseUpload}>Cancel</button> */}
                                    </div>
                                </div>    
                            </form>
                        </div>
                    </div>
                </div>
               


            </div>

        );
    }
}
function getDatePicker() {
    function Datepicker() { }
    Datepicker.prototype.init = function (params) {
        this.eInput = document.createElement('input');
        this.eInput.value = params.value;
        // $(this.eInput).datepicker({ dateFormat: 'dd-mm-yy', minDate: 0 });
        $(this.eInput).datepicker({ dateFormat: 'dd-mm-yy' });
    };
    Datepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Datepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Datepicker.prototype.destroy = function () { };
    Datepicker.prototype.isPopup = function () {
        return false;
    };
    return Datepicker;
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    console.log("eINput", this.eInput)
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    console.log("eINput", this.eInput.value)
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        //console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        // console.log(date1Number, date2Number, "comparator")
        return date1Number - date2Number;
    }
}
function monthToComparableNumber1(date) {
    //console.log(date.length);
    //console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function loadDateTimeScript() {
    // alert("timeDate");
    // $('.datetimepicker_mask').datetimepicker({
    //     format: 'd-m-Y H:i'
    // });
    // $('.device_filter_data').datetimepicker({
    //     format: 'Y-m-d H:i',
    //     timepicker: true
    // });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function AlphaTextEditor() {}

    AlphaTextEditor.prototype.init = function(params) {
        this.eInput = document.createElement('input');
        this.eInput.value = params.value;
        var that = this;
        this.eInput.addEventListener('keypress', function(event) {
        var regex = new RegExp("^[a-zA-Z]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                that.eInput.focus();
                return false;
            }
        });
    };
    
    AlphaTextEditor.prototype.getGui = function() {
        return this.eInput;
    };

    AlphaTextEditor.prototype.getValue = function() {
        var regex = new RegExp("^[a-zA-Z]+$");
        var oVal = this.eInput.value;
        if (!regex.test(oVal) === true) {
            this.eInput.focus();
            return "";
        }   
        else{
            return this.eInput.value;
        }
    };

    AlphaTextEditor.prototype.destroy = function() {};
    
    
    AlphaTextEditor.prototype.isPopup = function() {
        return false;
    };
