import React, {Component} from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import $ from 'jquery';
import "jquery-ui/dist/themes/base/jquery-ui.css"
var redirectURL = require('../redirectURL');
export default class AddManualOrder extends Component {
    constructor(props){
        super(props);
        this.state={
            modules:AllModules,
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
				sortable:true
			},
            frameworkComponents:{
            },
            components: { datePicker: getDatePicker() },
			detailCellRendererParams : {},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            produceRowData:[],
            rowSelection:"single",
            overlay:"show-n",
            rownode:[],
            poitemDesc:[],
            customers:[],
            allcustomers:[]
        }
        this.onCellEdit = this.onCellEdit.bind(this);
    }
	
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    
    addItemRow(){
        var row = [{
            truck_no:"",
            gc_waybill_no:"",
            gc_date:"",
            consignee_name:"",
            allowable_weight:"",
            per_kg_slab_rate:"",    
            basic_trans_cost:""
        }]
        this.gridApi.updateRowData({
            add: row
        });
    }
    onClickTriggerParentFn(){
        setTimeout(() => {
            let rownodes = [];
            this.gridApi.forEachNode(node => {
                if(node.data.consignee_name !== "" && node.data.consignee_name !== undefined)
                {
                    var cc = node.data.consignee_name.split(" - ");
                    // console.log("cc ",cc)
                    node.data.consignee_code = cc[1];
                    node.data.consignee_name = cc[0];
                    var allcustomers = this.props.customers;
                    if(allcustomers.length > 0)
                    {
                        var customer = allcustomers.filter((f) => f.consignee_code === cc[1])
                        if(customer.length > 0)
                        {
                            // console.log("customer ", customer)
                            node.data.consignee_pincode = customer[0].postal_code
                            node.data.consignee_city = customer[0].consignee_city
                            node.data.consignee_state = customer[0].consignee_state
                            node.data.consignee_zone = customer[0].consignee_zone
                        }
                        
                    }
                }
                if(node.data.shipping_type !== "" && node.data.shipping_type !== undefined)
                {
                    if(node.data.shipping_type === "surface"){
                        node.data.shipping_type = "surface";
                        node.data.shipping_type_code = "1";
                    }
                    if(node.data.shipping_type === "air"){
                        node.data.shipping_type = "air";
                        node.data.shipping_type_code = "2";
                    }
                    if(node.data.shipping_type === "train"){
                        node.data.shipping_type = "train";
                        node.data.shipping_type_code = "3";
                    }
                }
                rownodes.push(node.data)
            });
            var params ={
                rownodes: JSON.stringify(rownodes)
            }
            //  console.log("params ", params)
            redirectURL.post("/consignments/uploadBulkManualData",params,{
            headers: {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem("userid")
                }
            })
            .then((response) => {
                if(response.data.status === "success")
                {
                    this.props.context.uploadManualBulkFormHandler();
                }
                else{
                    this.props.context.FailedManualOrder();
                }
                  window.location.reload();
                
            })
        },500)
        
        
    }
    onCellEdit = async (node) => {
        // console.log(node,"clickedData")
        var updateCheck = 0;
        var clickedValue = node.value;
        if (clickedValue >= 0 && clickedValue != '' && clickedValue != undefined) {
            updateCheck = 1;
        }
        if (updateCheck == 1) {
            var rowData=[];
            this.gridApi.forEachNode((e) => {
                var a = (e.data.allowable_weight !== "" && e.data.allowable_weight !== undefined)?e.data.allowable_weight:0;
                var b = (e.data.per_kg_slab_rate !== "" && e.data.per_kg_slab_rate !== undefined)?e.data.per_kg_slab_rate:0;
                e.data.basic_trans_cost = parseFloat(a)*parseFloat(b);
                rowData.push(e.data)
            })
            this.setState({
                produceRowData:rowData
            });
            this.gridApi.refreshCells();
        }
    }
    render(){
        const {produceRowData} = this.state;
		var customers = this.props.customers
        var allcustomers = []
        if(customers.length > 0)
        {
            customers.map((item) => {
                allcustomers.push(item.consignee_name+" - "+item.consignee_code)
            })
        }
        // console.log("allcustomers ", allcustomers)
        var columnProduceDefs = [
            
            {
				headerName: "Truck No",
				field: "truck_no",
				width: 120,
				filter: false,
                editable:true
			},
            {
				headerName: "GC Way Bill No",
				field: "gc_waybill_no",
				width: 120,
				filter: false,
                editable:true
			},
            {
				headerName: "GC Date",
				field: "gc_date",
				width: 120,
                filter: false,
                editable:true,
                cellEditor: DateEditor,
                // cellEditor: "datePicker",
			},
            {
				headerName: "Consignee Name",
				field: "consignee_name",
				width: 120,
				filter: false,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: allcustomers 
                },
			},
            {
				headerName: "Shipment Type",
				field: "shipping_type",
				width: 120,
				filter: false,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['surface','air','train'] 
                },
			},
            {
				headerName: "Allowable Weight",
				field: "allowable_weight",
				width: 120,
				filter: false,
                editable:true
			},
            {
				headerName: "Per KG Slab Rate",
				field: "per_kg_slab_rate",
				width: 120,
				filter: false,
                editable:true
			},
            {
				headerName: "Total Cost",
				field: "basic_trans_cost",
				width: 120,
				filter: false,
                editable:false,
                valueSetter:function(params){
                    try{
                        var a = (params.data.allowable_weight !== "" && params.data.allowable_weight !== undefined)?params.data.allowable_weight:0;
                        var b = (params.data.per_kg_slab_rate !== "" && params.data.per_kg_slab_rate !== undefined)?params.data.per_kg_slab_rate:0;
                        var basic_trans_cost = parseFloat(a)*parseFloat(b);
                        return basic_trans_cost;
                    }
                    catch(e){}
                }
			}
        ]
        return (

            <div className="col-sm-12 p-20p" style={{marginTop:"20px"}} >   
                <div className="row" style={{marginTop:"20px"}} >   
                <button type="button" className="btn btn-primary float-right" onClick={this.addItemRow.bind(this)}>Add Item</button>     
                </div>
                <div className="row" style={{marginTop:"20px"}} >   
                                 
                        
                    <div className="col-sm-12 p-20p" style={{marginTop:"20px"}} id="produceItemData">  

                        <div id="myGrid1" style={{width:"100%",height:"50vh"}} className="ag-theme-balham">
                            <AgGridReact 
                                modules={this.state.modules}
                                rowData={produceRowData} 
                                columnDefs={columnProduceDefs}
                                stopEditingWhenGridLosesFocus= {false}
                                onGridReady={this.onGridReady}
                                components={this.state.components}
                                // rowSelection={this.state.rowSelection}
                                onCellEditingStopped={this.onCellEdit}
                            />
                        </div> 
                        <button type="button" onClick={this.onClickTriggerParentFn.bind(this)} className="btn btn-success float-right">Submit</button>

                    </div>
                </div>
            </div>
        );
    }
}   
function onClickUpdateItem(rowdn){
    console.log("rowdn ", rowdn)
}

function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement('input');
	  this.eInput.value = params.value;
    //   console.log("this.eInput ", this.eInput)
	  $(this.eInput).datepicker({ dateFormat: 'dd-mm-yy' });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }

  function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    // console.log("this.eInput ", this.eInput)
    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};