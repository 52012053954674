import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

drilldown(Highcharts);
export default class ColumnDrilldown extends Component {

    constructor(props) {
        super(props);
            this.afterChartCreated = this.afterChartCreated.bind(this);

        this.state = {			
            series : {},
            drilldownseries : {}
		}
    }
    componentDidMount (){
        // console.log(this.props.drilldownseries,"props")
        this.setState({
            drilldownseries:this.props.drilldownseries.series,
            series: this.props.series,
            title: this.props.title,
            subtitle: this.props.title,
            categories: this.props.categories,
            yaxistitle: this.props.categories,
        })

    }
    componentWillReceiveProps (newprops){
        if(newprops.drilldownseries.series !=this.state.drilldownseries)
        {
            // console.log(newprops.drilldownseries.series,"newprops")
            this.setState({
                drilldownseries:newprops.drilldownseries.series,
                series: newprops.series,
                title: newprops.title,
                subtitle: newprops.title,
                categories: newprops.categories,
                yaxistitle: newprops.categories,
            })
            for (var i = 0; i < Highcharts.charts.length; i++) {
                if (Highcharts.charts[i] !== undefined) {
                  Highcharts.charts[i].reflow();
                }
              }

        }
        
    }
    afterChartCreated(chart) {
        // this.internalChart = chart;
        // this.forceUpdate();
    }
    render(){
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions =  {
            chart: {
              type: 'column'
            },
            credits : false,
            title: {
                text: this.props.title
            },
            subtitle: {
                text: this.props.subtitle
            },
            
            xAxis: {
                type: 'category',
                title: {
                    text: null
                },
                labels: {
                    enabled:true,//default is true
                    //y : 20, rotation: -45, align: 'right' 
                }
    
            },
            yAxis: {
                min: 0,
                title: {
                    text: this.props.yaxistitle
                }
            },
            legend: {
              enabled: true
            },
            // color: ["#53d9db","#50ed45","#3d3538"],
            plotOptions: {
                pointWidth:"40px",
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                },
                colorByPoint : true,
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    },
                    cursor: 'pointer',
                    point: {
                        
                    }
                },
            },
          
            series: this.state.series,
            drilldown:{
                series: this.state.drilldownseries
            }
        }
                // console.log(this.props.chartType ,"this.props.chartTyp");
        highchartoptions.chart= {type: "column",backgroundColor:'rgba(0,0,0,0)',style: {
            color: "#fff"
        }};

        return ( 
            <div className='col-sm-12'>
                {this.state.drilldownseries !=undefined && this.state.drilldownseries !="" ?
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} callback={this.afterChartCreated} allowChartUpdate={true} immutable={true}/> : ""}
            </div>
        );
    }
}