import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import Select from 'react-select';
import * as Datetime from 'react-datetime';
import PRTOnTimeChart from './PRTOnTimeChart';
import SweetAlert from 'react-bootstrap-sweetalert';

import $ from 'jquery';
import { getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD,getHyphenDDMMMYYYY } from '../common/utils';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var aggriddata = [];
var originaldata;
const couriercolumns=[
    {
        headerName: "Consignement Code",
        field: "consignment_code",
        width: 115,
        editable: false,
        // pinned: 'left',
        filter: "agSetColumnFilter",
        //cellRenderer: "agGroupCellRenderer"
    },
    {
        headerName: "GC/Way Bill No",
        field: "gc_waybill_no",
        width: 115,
        editable: false,
        // pinned: 'left',
        filter: "agSetColumnFilter",
        //cellRenderer: "agGroupCellRenderer"
    },{
        headerName: "GC date",
        field: "gc_date",
        width: 120,
        // filter: "agSetColumnFilter",
        cellRenderer: '',	
        // pinned: 'left',
        //  sort: "desc",
        valueGetter: function (params) {
            return getHyphenDDMMMYYYYHHMM(params.data.gc_date)
        },
        // filter: "agDateColumnFilter",
        // comparator: dateComparator1,

    },
    {
        headerName: "Shipment Completion Date",
        field: "shipment_completion_date",
        width: 150,
        // pinned: 'left',
        resizable: true,
        valueGetter: function (params) {
            if (params.data.shipment_completion_date !== "") {
                return getHyphenDDMMMYYYY(params.data.shipment_completion_date);
            }
        },
    },
    {
        headerName: "Planned Delivery Date",
        field: "estimated_datetime",
        width: 120,
        // pinned: 'left',
        resizable: true,
        valueGetter: function (params) {
            if(params.data.transit_remark === "NA")
            {
                return "NA";
            }
            else{
                
                if(params.data.transporter_code !== "" && params.data.transporter_code !== undefined)
                {
                    if(params.data.transporter_code === "7402033")
                    {
                        return getHyphenDDMMMYYYY(params.data.gc_date);
                    }
                    else{
                        if (params.data.service_provider == "" || params.data.service_provider == undefined) {
                            return getHyphenDDMMMYYYY(params.data.actual_delivery_date);
                        } else {
                            return getHyphenDDMMMYYYY(params.data.estimated_datetime);
                        }
                    }
                }
                else{
                    if (params.data.service_provider == "" || params.data.service_provider == undefined) {
                        return getHyphenDDMMMYYYY(params.data.actual_delivery_date);
                    } else {
                        return getHyphenDDMMMYYYY(params.data.estimated_datetime);
                    }
                }
            }
            
        },
        //filter: "agDateColumnFilter",
        // comparator: dateComparator,
    },
    {
        headerName: "Status",
        field: "consignment_status",
        width: 120,
        // pinned: 'left',
        filter: "agSetColumnFilter",
        cellStyle: function (params) {
            if (params.value == 'Delivered') {
                //mark police cells as red
                return { color: 'green' };
            } else {
                return null;
            }
        },
        valueGetter: function (params) {
            
            if (params.data.consignment_status == "Cancelled") {
                return "Cancelled As Per Ashok Leyland"
            }
            else {
                return params.data.consignment_status
            }
        
        },
        cellRendererSelector: function(params){
            try {
                if(params.data.consignment_status === "Invalid Docket" || params.data.consignment_status === "Invalid docket")
                {
                    return {
                        component:"InvalidDocket"
                    }
                }
                else{
                    return "";
                }
            } catch (error) {
                
            }
        },
    },
    {
        headerName: "Delivery Status",
        field: "ontime_delayed_status",
        width: 130,
        // pinned: 'left',
        filter: "agSetColumnFilter",
        cellRenderer: '',
        cellClass: function (params) {
            if(params.data.transporter_code !== "" && params.data.transporter_code !== undefined)
            {
                if(params.data.transit_remark === "NA")
                {
                    return 'bgColorDangerMedium'
                }
                else{
                    if(params.data.transporter_code === "7402033")
                    {
                        return 'bgColorSuccessMedium'
                    }
                    else{
                        if (params.data.ontime_delayed_status == "Delayed") {
                            return 'bgColorDangerMedium'
                        }
                        return 'bgColorSuccessMedium'
                    }
                }
            }
            else{
                if (params.data.ontime_delayed_status == "Delayed") {
                    return 'bgColorDangerMedium'
                }
                return 'bgColorSuccessMedium'
            }
        
        },
        valueGetter:function(params)
        {
            try {
                if(params.data.transit_remark === "NA")
                {
                    return "Fixed Transit Days - NA";
                }
                else{
                    if(params.data.transporter_code !== "" && params.data.transporter_code !== undefined)
                    {
                        if(params.data.transporter_code === "7402033")
                        {
                            return "On Time";
                        }
                        else{
                            return params.data.ontime_delayed_status;	
                        }
                    }
                    else{
                        return params.data.ontime_delayed_status;
                    }
                }
            } catch (error) {
                
            }
        }
    },
    {
        headerName: "Actual Delivery Date",
        field: "revised_eta",
        width: 120,
        // pinned: 'left',
        resizable: true,
        valueGetter: function (params) {
            //console.log(" ETA ", params.data)
            if (['DELIVERED', "Delivered",].includes(params.data.consignment_status)) {

                if (params.data.revised_eta == '' || params.data.revised_eta == undefined) {
                    return getHyphenDDMMMYYYY(params.data.revised_eta);
                }
                else {
                    return getHyphenDDMMMYYYY(params.data.revised_eta);
                }
            }
            else {
                return ""
            }

        },
        //filter: "agDateColumnFilter",
        // comparator: dateComparator,
        //   filterParams: {
        // 	  browserDatePicker: true,
        // 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
        // 	  // provide comparator function
        // 	  comparator: function(filteredDate,cellValue,secondDate) {
        // 		cellValue = cellValue.replace(/\//g,"-")
        // 		  if(cellValue != "" && cellValue != " "){
        // 			  cellValue = cellValue.split(" ")[0].split("-");
        // 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
        // 			  var cellDate = new Date(cellValue);
        // 			  if(filteredDate.getMonth() < 10){
        // 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        // 			  }
        // 			  else{
        // 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        // 			  }
        // 			  if(filterValue.split("-")[2].length < 2)
        // 			  {
        // 				  var temp = filterValue
        // 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
        // 			  }
        // 			  var filteredDate = new Date(filterValue);
        // 			  cellDate = cellDate.getTime();
        // 			  filteredDate = filteredDate.getTime();
        // 			  // console.log(cellDate,filteredDate);
        // 			  if(cellDate === filteredDate)
        // 			  {
        // 				  return 0;
        // 			  }
        // 			  if(cellDate < filteredDate){
        // 				  return -1;
        // 			  }
        // 			  if(cellDate > filteredDate)
        // 			  {
        // 				  return 1;
        // 			  }
        // 		  }
        // 	  }
        //   },
        // cellClass: function (params) {
        // 	let hourDifference = 0;
        // 	if (params.data.revised_eta && params.data.expected_eta) {
        // 		let revisedDateOnlyMoment = moment(params.data.revised_eta);
        // 		let expectedDateOnlyMoment = moment(params.data.expected_eta);
        // 		hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
        // 		//console.log("diff= ", params.data.consignment_code, hourDifference);
        // 	} else {
        // 		//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
        // 	}
        // 	if (['DELIVERED', "Delivered"].includes(params.data.consignment_status)) {
        // 		if (hourDifference >= 1) {
        // 			return 'bgColorDangerMedium'
        // 		}

        // 		if (hourDifference <= -1) {
        // 			return 'bgColorSuccessMedium'
        // 		}
        // 		else {
        // 			return ''
        // 		}
        // 	}

        // }
        cellClass: function (params) {
            let dayDifference;
            if (params.data.revised_eta && params.data.expected_eta) {
                let revisedDateOnlyMoment = moment(params.data.revised_eta);
                let expectedDateOnlyMoment = moment(params.data.estimated_datetime);
                dayDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'days');
                // console.log("dayDifference" , dayDifference)
                // console.log("expectedDateOnlyMoment" , expectedDateOnlyMoment)
                //console.log("diff= ", params.data.consignment_code, hourDifference);
            } else {
                //console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
            }
            // if(params.data.transporter_code === "7402033")
            // {
                
                if (['DELIVERED', "Delivered"].includes(params.data.consignment_status)) {
                    if (dayDifference > 0) {
                        return 'bgColorDangerMedium'
                    }

                    if (dayDifference <= 0) {
                        return 'bgColorSuccessMedium'
                    }
                    else {
                        return ''
                    }
                }
            // }
        }
    },
    // {
    //     headerName: "Delivered?",
    //     field: "",
    //     width: 120,
    //     pinned: "left",
    //     //checkboxSelection: true,
    //     cellRendererSelector: function (params) {

    //         var rendComponent = {
    //             component: 'deliverconsignment'
    //         };
    //         return rendComponent

    //     },
    //     // valueGetter:function(params){
    //     //     if(params.data.tpt_confirm_delivery == 1)
    //     //     {
    //     //         //console.log("tpt_delivery_date ",params.data.tpt_delivery_date);
    //     //         return getHyphenDDMMMYYYYHHMM(params.data.tpt_delivery_date)
    //     //     }
    //     //     else{
    //     //         return '';
    //     //     }
    //     // },
    //     filter: false,
    //     resizable: true
    // },
    // {
    // 	headerName: "Service Provider Name",
    // 	field: "service_provider",
    // 	width: 135,
    // 	filter: "agSetColumnFilter",
    // 	valueGetter: function (params) {
    // 		return params.data.service_provider;
    // 	},
    // },
    {
        headerName: "Plant Code",
        field: "consigner_code",
        width: 100,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    // {
    //     headerName: "Plant Name",
    //     field: "plant_name",
    //     width: 150,
    //     filter: "agSetColumnFilter",
    //     resizable: true,
    // },
    {
        headerName: "Plant location",
        field: "consigner_location",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Vehicle No",
        field: "truck_no",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: 'Courier Type',
        field: 'courier_type',
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
        
    },
    {
        headerName: "Transporter Name",
        field: "service_provider",
        width: 135,
        filter: "agSetColumnFilter",
        resizable: true,
        editable: false,
        valueGetter: function (params) {
            return params.data.service_provider;
        },
    },
    {
        headerName: "Transporter Code",
        field: "transporter_code",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Dealer Code",
        field: "consignee_code",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Dealer Name",
        field: "consignee_name",
        width: 130,
        filter: "agSetColumnFilter",
        resizable: true
    },
    {
        headerName: "Dealer City",
        field: "consignee_city",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Dealer State",
        field: "consignee_state",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Shipment Type",
        field: "shipping_type",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: '',
        valueGetter:function(params){
            try {
                var sdata = params.data.shipping_type; 
                var sdataoVal = sdata;
                if(sdata === "05" || sdata === 5)
                {
                    var sdataoVal = "surface";
                }
                if(sdata === "07" || sdata === 7)
                {
                    var sdataoVal = "air";
                }
                if(sdata === "22" || sdata === 22)
                {
                    var sdataoVal = "train";
                }
                if(sdata === "48" || sdata === 48 || sdata === "47" || sdata === 47)
                {
                    var sdataoVal = "Part Load";
                }
                return sdataoVal;
            } catch (error) {
                
            }
        }
    },
    {
        headerName: "Destination Type",
        field: "destination_type",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Fixed Transit Time (Days)",
        field: "TAT",
        width: 200,
        filter: "agSetColumnFilter",
        valueGetter:function(params){
            try {
                if(params.data.TAT !== "" && params.data.TAT !== undefined && params.data.TAT !== 0 && params.data.TAT !== "0")
                {
                    return params.data.TAT;
                }
                else{
                    return params.data.transit_remark;
                }
            } catch (error) {
                
            }
        },
        cellRendererSelector: function(params){
            try {
                if(params.data.transit_remark === "NA" || params.data.transit_remark === "NA")
                {
                    return {
                        component:"UpdateTransitDay"
                    }
                }
                else{
                    return "";
                }
            } catch (error) {
                
            }
        },
    },
    
    {
        headerName: "Holidays (Days)",
        field: "holiday_count",
        width: 130,
        filter: "agSetColumnFilter",
        // cellRenderer: '',
        valueGetter:function(params){
            try {
                if(params.data.transit_remark === "NA")
                {
                    return "NA";
                }
                else{
                    var s = (params.data.count_sunday !== "" && params.data.count_sunday !== undefined)?params.data.count_sunday:0;
                    var h = (params.data.holiday_count !== "" && params.data.holiday_count !== undefined)?params.data.holiday_count:0;
                    var d = parseInt(s)+parseInt(h);
                    return d;
                }
            } catch (error) {
                
            }
        }
    },
    {
        headerName: "Grace (Days)",
        field: "grace_count",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    // {
    // 	headerName: "Cut Off Time (Days)",
    // 	field: "cutOffDays",
    // 	width: 130,
    // 	filter: "agSetColumnFilter",
    // 	cellRenderer: ''
    // },
    
    {
        headerName: "ODA Time (Days)",
        field: "ODA_TAT",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Total Transit Days",
        field: "transit_time_days",
        width: 130,
        filter: "agSetColumnFilter",
        valueGetter:function(params){
            try {
                if(params.data.transit_remark === "NA")
                {
                    return "NA";
                }
                else{
                    return params.data.transit_time_days;
                }
            } catch (error) {
                
            }
        }
    },
    // {
    // 	headerName: "Transit Remarks",
    // 	field: "transit_remark",
    // 	width: 130,
    // 	filter: "agSetColumnFilter",
    // 	cellRenderer: ''
    // },
    
    // {
    // 	headerName: "Elapsed Transit Time (Days)",
    // 	field: "elapsed_transit_time",
    // 	width: 130,
    // 	filter: "agSetColumnFilter",
    // 	cellRenderer: ''
    // },
    {
        headerName: "Pin Code",
        field: "consignee_pincode",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Allowable Weight(Kgs)",
        field: "allowable_weight",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Per Kg Slab Rate(Rps)",
        field: "per_kg_slab_rate",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    {
        headerName: "Basic Trans Cost(Rps)",
        field: "basic_trans_cost",
        width: 130,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
    // {
    // 	headerName: "Expected Transit Time (Days)",
    // 	field: "transit_time_days",
    // 	width: 150,
    // 	filter: true,
    // 	resizable: true,
    // 	hide: true,
    // 	editable: false
    // },
    {
        headerName: "Delivered On",
        field: "delivery_datetime",
        width: 150,
        filter: "agSetColumnFilter",
        cellRenderer: '',
        valueGetter: function (params) {
            if (params.data.consignment_status !== 'IN TRANSIT') {
                return getHyphenDDMMMYYYYHHMM(params.data.delivery_datetime)
            }
        },
        //filter: "agDateColumnFilter",
        // comparator: dateComparator,

    },
    {
        headerName: "Transit Delays (Days)",
        field: "",
        width: 130,
        valueGetter: function (params) {
            var revisedeta = params.data.revised_eta;
            var originaleta = params.data.estimated_datetime;
            // console.log("Original ETA", originaleta)
            // console.log("Revised ETA", revisedeta)
            
            if (originaleta != "" && originaleta != null && originaleta != NaN && revisedeta != "" && revisedeta != null && revisedeta != NaN) {
                if (new Date(originaleta) > new Date(revisedeta)) return 0
                var diff = differenceInDays(revisedeta, originaleta);
                //   console.log("Difference Delay", diff)
                
                return diff;
            }
            else {
                return "";
            }
        },
        cellClass: function (params) {
            var revisedeta = params.data.revised_eta;
            var originaleta = params.data.estimated_datetime;
            if (originaleta != "" && originaleta != null && originaleta != NaN && revisedeta != "" && revisedeta != null && revisedeta != NaN) {
                if (new Date(originaleta) > new Date(revisedeta)) return 0
                var diff = differenceInDays(revisedeta, originaleta);

                return diff;

            }
            else {
                return "";
            }
        }
    },
    {
        headerName: "Delivery in Transit (Days)",
        field: "",
        width: 150,
        // hide : !hidden,
        valueGetter: function (params) {
            var delivery_datetime = params.data.delivery_datetime;
            var gc_date = params.data.gc_date;
            if (delivery_datetime == "" || delivery_datetime == undefined || delivery_datetime == " " || delivery_datetime == NaN || delivery_datetime == "Invalid date") {
                delivery_datetime = new Date();
            }
            if (['DELIVERED', "Delivered"].includes(params.data.consignment_status)) {
                if (gc_date != "" && gc_date != null && gc_date != NaN) {
                    var diff = deliveryInTransit(delivery_datetime, gc_date);
                    if (diff > 0) {
                        return diff - 1;
                    } else {
                        return 0;
                    }
                }
                else {
                    return "";
                }
            }
        }
    },
 
    {
        headerName: "Courier Status",
        field: "last_status",
        width: 150,
        filter: "agSetColumnFilter",
      
    },
    {
        headerName: "Courier Location",
        field: "last_location",
        width: 150,
        filter: "agSetColumnFilter"
    },

    {
        headerName: "Updated On",
        field: "last_date",
        width: 150,
        // filter: "agSetColumnFilter",
        valueGetter: function (params) {
            if (params.data.last_date != "" && params.data.last_date !== undefined) {
                return getHyphenDDMMMYYYYHHMM(params.data.last_date);
            }
        },
        //filter: "agDateColumnFilter",
        // comparator: dateComparator,

    },
    {
        headerName: "Last Processed On",
        field: "last_process_on",
        width: 150,
        // filter: "agSetColumnFilter",
        valueGetter: function (params) {
            if (params.data.last_process_on != "" && params.data.last_process_on !== undefined) {
                return getHyphenDDMMMYYYYHHMM(params.data.last_process_on);
            }
        },
        //filter: "agDateColumnFilter",
        // comparator: dateComparator,

    },
    {
        headerName: "Vehicle Mode",
        field: "mode_of_transport",
        width: 130,
        filter: "agSetColumnFilter",
        resizable: true,
        hide: true,
    },
    {
        headerName: "Delivered At",
        field: "delivery_station",
        width: 150,
        filter: "agSetColumnFilter",
        cellRenderer: ''
    },
]


const prtcolumns = [
    {
        headerName: "Truck No",
        field: "truck_no",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consignment Code",
        field: "consignment_code",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consigner Code",
        field: "consigner_code",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consignee Code",
        field: "consignee_code",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Gate Out Time",
        field: "gate_out_time",
        width:130,
        resizable: true,
        ediatable: false,
        valueGetter: function(params)
        {
            if(params.data.gate_out_time)
            {
                return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)
            }
            else
            {
                return ""
            }
        }
    },
    {
        headerName: "Consignee State",
        field: "consignee_state",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consignee City",
        field: "consignee_city",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Zone",
        field: "zone",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Expected ETA",
        field: "expected_trip_end",
        width:130,
        resizable: true,
        ediatable: false,
        valueGetter: function(params)
        {
            if(params.data.expected_trip_end)
            {
                return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end)
            }
            else
            {
                return ""
            }
        }
    },
    {
        headerName: "Reached Destination At",
        field: "recent_dealer_reported",
        width:130,
        resizable: true,
        ediatable: false,
        valueGetter: function(params)
        {
            if(params.data.recent_dealer_reported)
            {
                return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported)
            }
            else
            {
                return ""
            }
        }
    },
    {
        headerName: "MSIL Transit Days",
        field: "transit_time",
        width:110,
        resizable: true,
        ediatable: false,
    },
    {
        headerName: "Actual Transit Days",
        field: "",
        width:110,
        resizable: true,
        ediatable: false,
        valueGetter: function(params)
        {
            const oneDay = 24 * 60 * 60 * 1000
            var startDate = params.data.gate_out_time ;
            var endDate = params.data.recent_dealer_reported;
            return Math.round(Math.abs((new Date(moment.parseZone(startDate).format("YYYY-MM-DD HH:MM")) - new Date(moment.parseZone(endDate).format("YYYY-MM-DD HH:MM"))) / oneDay));
        }
    },
    {
        headerName: "Transporter Code",
        field: "parent_transporter_code",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Transporter Name",
        field: "transporter_name",
        width:130,
        resizable: true,
        ediatable: false
    },

]

export default class PRTOnTimeComponent extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            loadshow:'show-m',
            overly:'show-m',
            couriercolumns: couriercolumns,
            prtcolumns: prtcolumns,
            modules: AllModules,
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            dept_code:'',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                autoHeight:true,
                resizable: true
            },
            rowData: [],
            headerHeight: 60,
            rowHeight:50,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            griddata:[],
            rowSelection: "single" ,
            context: { componentParent: this },
           
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize:50,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            pagetitle:"Surface - On Time Performance",
            start_date: "",
            end_date: "",
            selected_lsps: [{"label": "All", "value": "all"}],
            selected_cities: [{"label": "All", "value": "all"}],
            lspOptions: [],
            cityOptions: [],
            courierLSPOptions: [],
            courierCityOptions: [],
            firstChartArr : [],
            secondChartArr : [],
            // thirdChartArr : [],
            fourthChartArr : [],
            DealerChartArr:[],
            DealerChartArr2:[],
            prtCourierActive: "",
            prtActive: "activet",
            prtSurfaceCourierActive: "",
            firstChartQuery: "",
            secondChartQuery: "",
            thirdChartQuery: "",
            fourthChartQuery: "",
            columnwithdefs: [],
            showChartData: "show-m",
            showGridData:  "show-n",
            gridTab: "",
            chartTab: "activet",
            shipping_type:"",
            roadDispatchOptions:[{"label": "all", "value": "all"},{"label": "Interplant", "value": "Interplant"},{"label": "Local Loads", "value": "Local Loads"}],
            courierDispatchOptions:[{"label": "all", "value": "all"},{"label": "Local Loads", "value": "Local Loads"}],
            selectedDispatchType: {"value": "all", "label": "all"},
            dispatchTypeOptions: [{"label": "ALL", "value": "ALL"},{"label": "Interplant", "value": "Interplant"},{"label": "Local Loads", "value": "Local Loads"}],
            statusTypeOptions: [{"value": "ALL", "label": "ALL"}, {"value": "Delivered", "label": "Delivered"}],
            selectedStatusType: {"value": "Delivered", "label": "Delivered"},
            transporter_data:[{"value": "all", "label": "All"}],
            stateList:[{"value": "all", "label": "All"}],
            shipping_type_tab:"05",
            dealerList : [],
            selected_shipping_type: {label:"COURIER",value:"COURIER"},
            changed_shipping_type : "COURIER",
            current_state_list :[],
            current_transporter_list:[],
            top_on_time_data:[],
            low_on_time_data:[],
            on_time_df : []
        };
        this.getFirstandThirdChartData = this.getFirstandThirdChartData.bind(this);
        this.getSecondChartData = this.getSecondChartData.bind(this);
        this.getFourthChartData = this.getFourthChartData.bind(this);
        this.getInitialData = this.getInitialData.bind(this);
    }
    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
           
        });
       
    }
   
    async componentDidMount(){
       var edate = moment.parseZone().format('YYYY-MM-DD');
       var sdate = moment.parseZone(new Date()).subtract(7, 'days').format("YYYY-MM-DD");
       var params = {
        start_date: sdate,
        end_date: edate
       }
       await redirectURL.post("/masters/getinputDataformisdashboard" , params).then((response)=>{
            var state_list = response.data.stateList
            var transporterData  = response.data.transporter_data
            var stateList = [{value:"all",label:"All"}]
            var transporter_data = [{value:"all",label:"All"}]
        state_list.map((e)=>{
            stateList.push({
                label:e,
                value:e
            })
        })
        transporterData.map((b)=>{
            transporter_data.push({
                value:b.transporter_code,
                label:b.transporter_name + " - "+b.transporter_code
            })
        })
        this.setState({
            transporter_data :transporter_data,
            stateList:stateList
        })

       })
       this.setState({
           start_date: sdate,
           end_date: edate
       })
       var params = {
           start_date: sdate,
           end_date: edate
       }
       await this.getInitialData(params)
       var firstChartQuery = {
            from_date: sdate+" 00:00:00",
            to_date: edate+" 23:59:59",
            // chart_mode: "prt",
            state_list: "all",
            transporterData : "all",
            api_name: "prtZoneWiseOnTimeAPI",
            shipping_type: this.state.shipping_type_tab,
            
       }
       
      

    //    var firstchart = await this.getFirstandThirdChartData(firstChartQuery, "firstChartArr")
    //    Promise.all([firstchart]).then(async ()=>{
        var secondChartQuery = {
            from_date: sdate+" 00:00:00",
            to_date: edate+" 23:59:59",
            // chart_type: "prt",
            state_list: "all",
            transporterData : "all",
            api_name: "StateWiseOnTimeAPI",
            shipping_type: this.state.shipping_type_tab,
          
        }
       
        var secondchart = await this.getSecondChartData(secondChartQuery)
        Promise.all([secondchart]).then(async ()=>{
            var fourthChartQuery = {
                from_date: sdate+" 00:00:00",
                to_date: edate+" 23:59:59",
                // chart_type: "prt",
                state_list: "all",
                transporterData : "all",
                api_name: "prtLspWiseOnTimeAPI",
                shipping_type: this.state.shipping_type_tab,
               
            }
           
            var fourthchart = await this.getFourthChartData(fourthChartQuery)
            Promise.all([fourthchart]).then(async ()=>{
                var thirdChartQuery = {
                    from_date: sdate+" 00:00:00",
                    to_date: edate+" 23:59:59",
                    // chart_mode: "prt",
                    state_list : "all",
                    transporterData : "all",
                    api_name: "prtZoneWiseOnTimeAPI",
                    shipping_type: this.state.shipping_type_tab,
                   
                }
               
                // await this.getFirstandThirdChartData(thirdChartQuery, "thirdChartArr")
                this.setState({
                    firstChartQuery: firstChartQuery,
                    secondChartQuery: secondChartQuery,
                    // thirdChartQuery: thirdChartQuery,
                    fourthChartQuery: fourthChartQuery,
                })
                
                // await this.getDealerDataForMisDashboard("","","05")
                
                var dealer = this.state.dealerList.length > 0? this.state.dealerList[0].value : ""
                var params = {
                    from_date: this.state.start_date + " 00:00:00",
                    to_date: this.state.end_date + " 23:59:59",
                    api_name: "dealerWiseTransporterOnTimeAPI",
                    // consignee_code: dealer,
                    state_list: "all",
                    transporterData: "all",
                    shipping_type: "05",
                }
                await this.getDealerChartData(params)
                var q = {"from_date": sdate+" 00:00:00",
                "to_date": edate+" 23:59:59",
                // "chart_type": "prt",
                state_list : "all",
                transporterData: "all",
                shipping_type: this.state.shipping_type_tab,
                }
 
        redirectURL.post("/masters/prtontimegriddata",q).then((response)=>{
            console.log(response.data , "1729_records")
            var records = response.data
            var today = new Date();
				var dd = String(today.getDate()).padStart(2, '0');
				var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy = today.getFullYear();
				today = yyyy + '-' + mm + '-' + dd;
            records.forEach(e => {

                if (e.revised_eta == "" || e.revised_eta == undefined) {
                    // e.elapsed_transit_time = differenceInDays(today, e.gc_date);
                    e.elapsed_transit_time = differenceInDays(today, e.gc_date);
                    e.ontime_delayed_status = "Delayed";
                } else {
                    e.elapsed_transit_time = differenceInDays(e.revised_eta, e.gc_date)
                    if (moment.parseZone(e.revised_eta).startOf('day') - moment.parseZone(e.expected_eta).startOf('day') <= 0) {
                        e.ontime_delayed_status = "On Time";
                        
                    }
                     else {
                        e.ontime_delayed_status = "Delayed";
                    }
                }
                return e
                
            });
            this.setState({
                rowData: records,
                columnwithdefs: this.state.couriercolumns
            })
        }).catch((e)=>{
            console.log(e)
        })
                // this.setState({
                //     selected_dealer : {label :this.state.dealerList.length > 0?  this.state.dealerList[0].label :"", value :this.state.dealerList.length > 0?  this.state.dealerList[0].value : "" , key : this.state.dealerList[0].key}
                //                 })
            })
        })
    //    })
       
    }

    getFirstandThirdChartData(params, statevar)
    {
        redirectURL.post("/masters/prtOnTimePerformanceChart",params).then((response)=>{
            // console.log(response.data)
            var first_level = JSON.parse(response.data.on_time_df)
            var second_level = JSON.parse(response.data.lsp_df)
            var arr = [];
            var categories = [];
            var series = [{
                name: "Zones",
                colorByPoint: true,
                data: []
            }];

            var drilldown = [];
            var top_chart = first_level.map(function(e){
                categories.push(e.zone)
                series[0]['data'].push({
                    name: e.zone,
                    y: Math.round(e.on_time_percentage),
                    total_records: e.total_records,
                    on_time_records: e.on_time_records,
                    drilldown: e.zone
                })
                var filtered_data = second_level.filter(function(k){
                    return k.zone == e.zone
                })
                var drilldata = {
                    "name": e.zone,
                    "id": e.zone,
                    "data": []
                }
                filtered_data.map(function(j){
                    drilldata['data'].push({name: j.transporter_name,
                                         y: Math.round(j.on_time_percentage),
                                         total_records: j.total_records,
                                         on_time_records: j.on_time_records,})
                })
                drilldown.push(drilldata)
            })
            Promise.all([top_chart]).then(()=>{
                arr.push({
                    categories: categories,
                    series: series,
                    drilldown: drilldown
                })
                // console.log(arr, "first chart data")
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    [statevar]: arr
                })
            })
           
        }).catch((e)=>{console.log(e)})
    }
   
    getSecondChartData(params)
    {
       
        redirectURL.post("/masters/prtOnTimePerformanceChart",params).then((response)=>{
            var first_level = JSON.parse(response.data.state_wise_df)
            var second_level = JSON.parse(response.data.lsp_df)
            var arr = [];
            var categories = [];
            var series = [{
                name: "States",
                colorByPoint: true,
                data: []
            }];

            var drilldown = [];
            var top_chart = first_level.map(function(e){
                categories.push(e.state)
                series[0]['data'].push({
                    name: e.state,
                    y: Math.round(e.on_time_percentage),
                    total_records: e.total_records,
                    on_time_records: e.on_time_records,
                    drilldown: e.state
                })
                var filtered_data = second_level.filter(function(k){
                    return k.state == e.state
                })
                var drilldata = {
                    "name": e.state,
                    "id": e.state,
                    "data": []
                }
                filtered_data.map(function(j){
                    drilldata['data'].push({name: j.transporter_name,y: Math.round(j.on_time_percentage),
                                            total_records: j.total_records,
                                            on_time_records: j.on_time_records})
                })
                drilldown.push(drilldata)
            })
            Promise.all([top_chart]).then(()=>{
                arr.push({
                    categories: categories,
                    series: series,
                    drilldown: drilldown
                })
                // console.log(arr, "first chart data")
                this.setState({
                    // loadshow: "show-n",
                    // overly: "show-n",
                    secondChartArr: arr
                })
            })
           
        }).catch((e)=>{console.log(e)})
    }

    getFourthChartData(params)
    {
        redirectURL.post("/masters/prtOnTimePerformanceChart",params).then((response)=>{
            var first_level = JSON.parse(response.data.lsp_df)
            var arr = [];
            var categories = [];
            var series = [{
                name: "Transporter Name",
                colorByPoint: true,
                data: []
            }];

            var drilldown = [];
            var top_chart = first_level.map(function(e){
                categories.push(e.transporter_name)
                series[0]['data'].push({
                    name: e.transporter_name,
                    y: Math.round(e.on_time_percentage),
                    total_records: e.total_records,
                    on_time_records: e.on_time_records,
                    drilldown: e.transporter_name
                })
                var drilldata = {
                    "name": e.transporter_name,
                    "id": e.transporter_name,
                    "data": []
                }
                if(![undefined , null , "" , "undefined"].includes(e.direct_total)){
                    drilldata['data'].push({
                        name: 'Direct',
                        y: Math.round(e.direct_total),
                        total_records: e.direct_total,
                        showTooltip:1
                        // on_time_records: e.on_time_records
                    })
                }
                if(![undefined , null , "" , "undefined"].includes(e.oda_total)){
                    drilldata['data'].push({
                        name: 'ODA',
                        y: Math.round(e.oda_total),
                        total_records: e.oda_total,
                        showTooltip:1
                        // on_time_records: e.on_time_records
                    })
                }
                
                
                drilldown.push(drilldata)
               
            })
            Promise.all([top_chart]).then(()=>{
                arr.push({
                    categories: categories,
                    series: series,
                    drilldown: drilldown
                })
                // console.log(arr, "fourth chart data")
                this.setState({
                    // loadshow: "show-n",
                    // overly: "show-n",
                    fourthChartArr: arr
                })
            })
           
        }).catch((e)=>{console.log(e)})
    }

    getDealerDataForMisDashboard = async(state , transporter , shipping_type) => {
        var params = {
            from_date : this.state.start_date+" 00:00:00",
            to_date : this.state.end_date+" 23:59:59",
            shipping_type:shipping_type
        }

        if(!["" , null , undefined , "undefined"].includes(state)){
            params.state = state
        }
        if(!["" , null , undefined , "undefined"].includes(transporter)){
            params.transporter = transporter
        }

        await redirectURL.post("/masters/getDealerDataForMisDashboard" ,params).then(async response => {
            var dealer_data = response.data.dealer_data
            var dealerList = []
            dealer_data.map(e => {
                if (![undefined, null, "", "undefined"].includes(e._id.consignee_code) && ![undefined, null, "", "undefined"].includes(e._id.consignee_name)) {
                    dealerList.push({
                        value: e._id.consignee_code,
                        label: e._id.consignee_code,
                        key: e._id.consignee_name
                    })
                }

            })
           await this.setState({
                dealerList:dealerList
            })
            }) 

    }

   async  getDealerChartData(params)
    {
        await this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        redirectURL.post("/masters/prtOnTimePerformanceChart",params).then((response)=>{
            var lsp_df = JSON.parse(response.data.lsp_df)
            var on_time_df = JSON.parse(response.data.on_time_df).sort(GetSortDescOrder("on_time_percentage"))
            var top_on_time_data = (on_time_df.filter(e  => e.on_time_percentage > 50)).slice(0,50)
            var low_on_time_data = (on_time_df.filter(e  => e.on_time_percentage <= 50)).slice(-50)
            console.log(lsp_df , "lsp_df")

            var arr = [];
            var categories = [];
            var series = [{
                name: "Dealer",
                colorByPoint: true,
                data: []
            }];

            var drilldown = [];
            if(top_on_time_data.length > 0){
                var top_chart = top_on_time_data.map(function(e){
                    categories.push(e.consignee_code)
                    series[0]['data'].push({
                        name: e.consignee_code,
                        y: Math.round(e.on_time_percentage),
                        total_records: e.total_records,
                        on_time_records: e.on_time_records,
                        drilldown: e.consignee_code
                    })
                    var drilldata = {
                        "name": e.consignee_name,
                        "id": e.consignee_code,
                        "data": []
                    }
                    var trans_df = lsp_df.filter(f => f.consignee_code ==e.consignee_code)
                    if (trans_df.length > 0) {
                        trans_df.map(e => {
                            drilldata.data.push(
                                {
                                    name: e.transporter_name,
                                    y: Math.round(e.on_time_percentage),
                                    total_records: e.total_records,
                                    on_time_records: e.on_time_records,
                                }
                            )
                        })
                    }
                    
                   drilldown.push(drilldata)
                   
                })
            }
            
            var arr2 = [];
            var categories2 = [];
            var series2 = [{
                name: "Dealer",
                colorByPoint: true,
                data: []
            }];

            var drilldown2 = [];
            if(low_on_time_data.length > 0){
                var top_chart2 = low_on_time_data.map(function(e){
                    categories2.push(e.consignee_code)
                    series2[0]['data'].push({
                        name: e.consignee_code,
                        y: Math.round(e.on_time_percentage),
                        total_records: e.total_records,
                        on_time_records: e.on_time_records,
                        drilldown: e.consignee_code
                    })
                    var drilldata = {
                        "name": e.consignee_name,
                        "id": e.consignee_code,
                        "data": []
                    }
                    var trans_df = lsp_df.filter(f => f.consignee_code ==e.consignee_code)
                    if (trans_df.length > 0) {
                        trans_df.map(e => {
                            drilldata.data.push(
                                {
                                    name: e.transporter_name,
                                    y: Math.round(e.on_time_percentage),
                                    total_records: e.total_records,
                                    on_time_records: e.on_time_records,
                                }
                            )
                        })
                    }
                    
                   drilldown2.push(drilldata)
                   
                })
            }
           
            Promise.all([top_chart]).then(()=>{
                arr.push({
                    categories: categories,
                    series: series,
                    drilldown: drilldown
                })
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    on_time_df:on_time_df,
                    DealerChartArr: arr,
                })
            })
            Promise.all([top_chart2]).then(()=>{
                arr2.push({
                    categories: categories2,
                    series: series2,
                    drilldown: drilldown2
                })
                console.log(arr2 , "arr2")
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    on_time_df:on_time_df,
                    DealerChartArr2:arr2
                })
            })
           
        }).catch((e)=>{console.log(e)})
    }

    getOptions(lst)
    {
        // console.log(lst,'pop')
        var lstval = [{"label": "ALL", "value": "ALL"}]
        lst.map(function(e){
            lstval.push({"label": e, "value": e})
        })
        return lstval
    }
    getInitialData(params)
    {
        redirectURL.post("/masters/getinputDataformisdashboard", params).then((response)=>{

            var lspOptions = this.getOptions(response.data.transporter_data);
            var cityOptions = this.getOptions(response.data.stateList);
            // console.log(response.data,"initial response")
            // var courierLSPOptions = this.getOptions(response.data.courier_transporter_data);
            // var courierCityOptions = this.getOptions(response.data.courier_stateList);

            this.setState({
                lspOptions: lspOptions,
                cityOptions: cityOptions,
                // courierLSPOptions: courierLSPOptions,
                // courierCityOptions: courierCityOptions
            })
        })
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
   
    onGridReady = params => {
        this.gridApi = params.api;
        this.setState({
            gridapi : params.api
        })
        this.gridColumnApi = params.columnApi;
       
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            // node.setSelected(true);
         
        });
       
    };
    onGridState = () => {
         console.log(this.gridApi);
       
         /*Get  Current Columns State and Store in this.colState */
           this.colState = this.gridColumnApi.getColumnState();

         /*Get Current RowGroup Columns State and Store in this.rowGroupState */
           this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

         /*Get Current Pivot Columns State and Store in this.pivotColumns,
          * User should enable the Pivot mode.*/
           this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
         /*Get Current Filter State and Store in window.filterState */
           window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
       
        //
    }
    // isValidFromDate = (current) => {
    //     const { end_date } = this.state;
    
    //     // Parse the start_date and current to moment objects
    //     const endDateMoment = moment(end_date, 'YYYY-MM-DD');
    //     const currentMoment = moment(current, 'YYYY-MM-DD');
    //     return current.isSameOrBefore(moment(), 'day') && currentMoment.isSameOrBefore(endDateMoment, 'day') // Use your date library here
    // };
    // isValidEndDate = (current) => {
    //     const { start_date } = this.state;
    
    //     // Parse the start_date and current to moment objects
    //     const startDateMoment = moment(start_date, 'YYYY-MM-DD');
    //     const currentMoment = moment(current, 'YYYY-MM-DD');
    
    //     // Allow dates equal to or after the start_date
    //     return currentMoment.isSameOrAfter(startDateMoment, 'day') && current.isSameOrBefore(moment(), 'day');
    // };
    handlerGridStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (this.state.end_date != "" && startdate > this.state.end_date) {
            this.setState({
                show: true,
                basicTitle: 'From date should be less than To date',
                basicType: "danger",
                loadshow: 'show-n',
                startDate: "",
            })
        }
        else {
            this.setState({
                start_date: startdate
            });
        }
    }
    handlerGridEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var edate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (this.state.start_date != "" && edate < this.state.start_date) {
            this.setState({
                show: true,
                basicTitle: 'To date should be greater than From date',
                basicType: "danger",
                loadshow: 'show-n',
                endDate: "",
            })
        }
        else {
            this.setState({
                end_date: edate
            });
        }
    }

    async onClickFilterDashboard(){
        // console.log("selected_lsps",this.state.selected_lsps)
        // console.log(this.state.selectedDispatchType,"selectedDispatchType")
            this.setState({
                firstChartArr: [],
                secondChartArr: [],
                // thirdChartArr: [],
                fourthChartArr: [],
                loadshow: "show-m",
                overly: "show-m",
                rowData: ""
            })
    
            var transporterData = [];
            if(this.state.selected_lsps != undefined && this.state.selected_lsps != null)
            {
                if(this.state.selected_lsps.indexOf("all") == -1)
                {
                    this.state.selected_lsps.map(function(e){
                        if(e.value != "all")
                        {
                            transporterData.push(e.value)
                        }
                    })
                    if(transporterData.length > 0)
                    {
                        transporterData = JSON.stringify(transporterData)
                    }
                }
            }
            // console.log("transporterData",transporterData)
           
           
            var state_list = [];
            if(this.state.selected_cities != undefined && this.state.selected_cities != null)
            {
                if(this.state.selected_cities.indexOf("all") == -1)
                {
                    this.state.selected_cities.map(function(e){
                        if(e.value != "all")
                        {
                            state_list.push(e.value)
                        }
                    })
                    if(state_list.length > 0)
                    {
                        state_list = JSON.stringify(state_list)
                    }
                }
            }
    
            // var dispatch_type = "ALL";
            // if(this.state.selectedDispatchType != undefined && this.state.selectedDispatchType != null)
            // {
            //     if(this.state.selectedDispatchType.value != "ALL")
            //     {
            //         dispatch_type = this.state.selectedDispatchType.value
            //     }
            // }
    
            // var status_type = "ALL";
            // if(this.state.selectedStatusType != undefined && this.state.selectedStatusType != null)
            // {
            //     if(this.state.selectedStatusType.value != "ALL")
            //     {
            //         status_type = this.state.selectedStatusType.value
            //     }
            // }
           
             var chart_type = ""
            var shipping_type = ""
            // if(this.state.prtActive != "")
            // {
            //     chart_type = "prt";
            // }
            // else
            // {
            //     chart_type = "prtcourier"
            //     if(this.state.prtCourierActive != "")
            //     {
            //         shipping_type = "07"
            //     }
            //     else
            //     {
            //         shipping_type = "05"
            //     }
            // }
            var shipping_type  =this.state.selected_shipping_type.value == "PTL" ? "48" : this.state.selected_shipping_type.value == "FTL" ? [1,2,3,4,10,11,12,14,15,17,18,21,24,25,29,30,31,32,45,46] : this.state.shipping_type_tab
            var q = {"from_date": this.state.start_date+" 00:00:00",
                    "to_date": this.state.end_date+" 23:59:59",
                    // "chart_type": chart_type,
                    state_list: "all",
                    transporterData: "all",
                    shipping_type: shipping_type,
                    }
                    // console.log("q",q)
           
             var cols = this.state.couriercolumns
    
            // if(chart_type != "prt-courier")
            // {
            //     cols = this.state.couriercolumns
            // }
            redirectURL.post("/masters/prtontimegriddata",q).then((response)=>{
                console.log(response.data , "1729_records")
                var records = response.data
            var today = new Date();
				var dd = String(today.getDate()).padStart(2, '0');
				var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy = today.getFullYear();
				today = yyyy + '-' + mm + '-' + dd;
            records.forEach(e => {

                if (e.revised_eta == "" || e.revised_eta == undefined) {
                    // e.elapsed_transit_time = differenceInDays(today, e.gc_date);
                    e.elapsed_transit_time = differenceInDays(today, e.gc_date);
                    e.ontime_delayed_status = "Delayed";
                } else {
                    e.elapsed_transit_time = differenceInDays(e.revised_eta, e.gc_date)
                    if (moment.parseZone(e.revised_eta).startOf('day') - moment.parseZone(e.expected_eta).startOf('day') <= 0) {
                        e.ontime_delayed_status = "On Time";
                        
                    }
                     else {
                        e.ontime_delayed_status = "Delayed";
                    }
                }
                return e
                
            });
                this.setState({
                    rowData:records,
                    columnwithdefs: cols
                })
            }).catch((e)=>{
                console.log(e)
            })
    
            var firstChartQuery = {
                from_date: this.state.start_date+" 00:00:00",
                to_date: this.state.end_date+" 23:59:59",
                // chart_mode: chart_type,
                state_list: "all",
                transporterData: "all",
                api_name: "prtZoneWiseOnTimeAPI",
                shipping_type: shipping_type,
               
            }
            if(transporterData.length > 0)
            {
                firstChartQuery['transporterData'] = transporterData
            }
            if(state_list.length > 0)
            {
                firstChartQuery['state_list'] = state_list
            }
           
            if(this.state.prtActive != "")
            {
                // var firstchart = await this.getFirstandThirdChartData(firstChartQuery, "firstChartArr")
            }
            var secondChartQuery = {
                from_date: this.state.start_date+" 00:00:00",
                to_date: this.state.end_date+" 23:59:59",
                // chart_type: chart_type,
                state_list: "all",
                transporterData: "all",
                api_name: "StateWiseOnTimeAPI",
                shipping_type:shipping_type,
               
            }
           
            if(transporterData.length > 0)
            {
                secondChartQuery['transporterData'] = transporterData
            }
            if(state_list.length > 0)
            {
                secondChartQuery['state_list'] = state_list
            }
    
            var secondchart = await this.getSecondChartData(secondChartQuery)
           
            // var thirdChartQuery = {
            //     from_date: this.state.start_date+" 00:00:00",
            //     to_date: this.state.end_date+" 23:59:59",
            //     // chart_mode: chart_type,
            //     state_list: "all",
            //     transporterData: "all", 
            //     api_name: "prtZoneWiseOnTimeAPI",
            //     "shipping_type": "05",
             
            // }
    
            // if(transporterData.length > 0)
            // {
            //     thirdChartQuery['transporterData'] = transporterData
            // }
            // if(state_list.length > 0)
            // {
            //     thirdChartQuery['state_list'] = state_list
            // }
    
            // await this.getFirstandThirdChartData(thirdChartQuery, "thirdChartArr")
           
           
            var fourthChartQuery = {
                from_date: this.state.start_date+" 00:00:00",
                to_date: this.state.end_date+" 23:59:59",
                // chart_type: chart_type,
                state_list: "all",
                transporterData: "all",
                api_name: "prtLspWiseOnTimeAPI",
                shipping_type:shipping_type,
                
            }
           
            if(transporterData.length > 0)
            {
                fourthChartQuery['transporterData'] = transporterData
            }
            if(state_list.length > 0)
            {
                fourthChartQuery['state_list'] = state_list
            }
    
            var fourthchart = await this.getFourthChartData(fourthChartQuery)
    
            this.setState({
                firstChartQuery: firstChartQuery,
                secondChartQuery: secondChartQuery,
                // thirdChartQuery: thirdChartQuery,
                fourthChartQuery: fourthChartQuery,
            })
    
            var state = ""
            var transporter = ""
            if (state_list.length > 0) state = state_list
            if (transporterData.length > 0) transporter = transporterData
            // await this.getDealerDataForMisDashboard(state, transporter, shipping_type)
    
            var dealer = this.state.dealerList.length > 0? this.state.dealerList[0].value : ""
            var params = {
                from_date: this.state.start_date + " 00:00:00",
                to_date: this.state.end_date + " 23:59:59",
                api_name: "dealerWiseTransporterOnTimeAPI",
                // consignee_code: dealer,
                shipping_type:shipping_type
            }
            if(transporterData.length > 0)
            {
                params['transporterData'] = transporterData
            }
            if(state_list.length > 0)
            {
                params['state_list'] = state_list
            }
    
            await this.getDealerChartData(params)
            this.setState({
                // selected_dealer : {label :this.state.dealerList.length > 0?  this.state.dealerList[0].label :"", value :this.state.dealerList.length > 0?  this.state.dealerList[0].value : "",key : this.state.dealerList[0].key},
                current_state_list : state_list,
                current_transporter_list : transporterData,
                changed_shipping_type : this.state.selected_shipping_type.value
            })
        
        
    }

   async onClickTab(tab, shipping_type)
    {
        this.setState({
            firstChartArr: [],
            secondChartArr: [],
            thirdChartArr: [],
            fourthChartArr: [],
            loadshow: "show-n",
            overly: "show-m",
            showChartData : "show-m",
            showGridData: "show-n",
            chartTab: "activet",
            gridTab: "",
            rowData: ""
        })
        var transporterData = [];
            if(this.state.selected_lsps != undefined && this.state.selected_lsps != null)
            {
                if(this.state.selected_lsps.indexOf("all") == -1)
                {
                    this.state.selected_lsps.map(function(e){
                        if(e.value != "all")
                        {
                            transporterData.push(e.value)
                        }
                    })
                    if(transporterData.length > 0)
                    {
                        transporterData = JSON.stringify(transporterData)
                    }
                }
            }
            // console.log("transporterData",transporterData)
           
           
            var state_list = [];
            if(this.state.selected_cities != undefined && this.state.selected_cities != null)
            {
                if(this.state.selected_cities.indexOf("all") == -1)
                {
                    this.state.selected_cities.map(function(e){
                        if(e.value != "all")
                        {
                            state_list.push(e.value)
                        }
                    })
                    if(state_list.length > 0)
                    {
                        state_list = JSON.stringify(state_list)
                    }
                }
            }
        var screen_type = ""
        if(tab == "prt")
        {
            screen_type = "prt"
            if(shipping_type == "05")
            {
             pagetitle = "surface - Courier On Time Performace"
            }
            this.setState({
                prtActive: "activet",
                prtCourierActive: "",
                prtSurfaceCourierActive: "",
                cityOptions: this.state.cityOptions,
                lspOptions: this.state.lspOptions,
                pagetitle: pagetitle,
                shipping_type_tab:shipping_type
               
            })
            var state = ""
            var transporter = ""
            if (this.state.current_state_list.length > 0) state = this.state.current_state_list
            if (this.state.current_transporter_list.length > 0) transporter = this.state.current_transporter_list
            // await this.getDealerDataForMisDashboard(state, transporter, "05")

            var dealer =this.state.dealerList.length > 0? this.state.dealerList[0].value : ""
            var params = {
                from_date: this.state.start_date + " 00:00:00",
                to_date: this.state.end_date + " 23:59:59",
                api_name: "dealerWiseTransporterOnTimeAPI",
                // consignee_code: dealer,
                shipping_type: "05",
            }
            if(transporterData.length > 0)
            {
                params['transporterData'] = transporterData
            }
            if(state_list.length > 0)
            {
                params['state_list'] = state_list
            }
    
            await this.getDealerChartData(params)
            // this.setState({
            //     selected_dealer : {label :this.state.dealerList.length > 0?  this.state.dealerList[0].label :"", value :this.state.dealerList.length > 0?  this.state.dealerList[0].value : "",key : this.state.dealerList[0].key}
            // })
        }
        else
         if(tab == "prt-courier")
        {
            screen_type = "prtcourier";
            var pagetitle=""
            if(shipping_type == "07")
            {
                pagetitle = "Air - Courier On Time Performance"
            }
          
            this.setState({
                prtActive: "",
                prtCourierActive: "activet",
                prtSurfaceCourierActive: "",
                cityOptions: this.state.courierCityOptions,
                lspOptions: this.state.courierLSPOptions,
                pagetitle: pagetitle,
                shipping_type_tab:shipping_type
            })
            var state = ""
            var transporter = ""
            if (this.state.current_state_list.length > 0) state = this.state.current_state_list
            if (this.state.current_transporter_list.length > 0) transporter = this.state.current_transporter_list
            // await this.getDealerDataForMisDashboard(state, transporter, "07")                
                var dealer = this.state.dealerList.length > 0? this.state.dealerList[0].value : ""
                var params = {
                    from_date: this.state.start_date + " 00:00:00",
                    to_date: this.state.end_date + " 23:59:59",
                    api_name: "dealerWiseTransporterOnTimeAPI",
                    // consignee_code: dealer,
                    shipping_type: "07",
                }
                if(transporterData.length > 0)
            {
                params['transporterData'] = transporterData
            }
            if(state_list.length > 0)
            {
                params['state_list'] = state_list
            }
    
                await this.getDealerChartData(params)
                // this.setState({
                //     selected_dealer : {label :this.state.dealerList.length > 0?  this.state.dealerList[0].label :"", value :this.state.dealerList.length > 0?  this.state.dealerList[0].value : "",key : this.state.dealerList[0].key}
                // })
        }
        else if(tab == "prt-surface-courier")
        {
            screen_type = "prt-surface-courier";
            var pagetitle=""
            if(shipping_type == "22")
            {
                pagetitle = "Train - Courier On Time Performance"
            }
           
            this.setState({
                prtActive: "",
                prtSurfaceCourierActive: "activet",
                prtCourierActive: "",
                cityOptions: this.state.courierCityOptions,
                lspOptions: this.state.courierLSPOptions,
                pagetitle: pagetitle,
                shipping_type_tab:shipping_type
            })
            var state = ""
            var transporter = ""
            if (this.state.current_state_list.length > 0) state = this.state.current_state_list
            if (this.state.current_transporter_list.length > 0) transporter = this.state.current_transporter_list
            // await this.getDealerDataForMisDashboard(state, transporter, "22")

                
                var dealer =this.state.dealerList.length > 0? this.state.dealerList[0].value : ""
                var params = {
                    from_date: this.state.start_date + " 00:00:00",
                    to_date: this.state.end_date + " 23:59:59",
                    api_name: "dealerWiseTransporterOnTimeAPI",
                    // consignee_code: dealer,
                    shipping_type: "22",
                }
                if(transporterData.length > 0)
            {
                params['transporterData'] = transporterData
            }
            if(state_list.length > 0)
            {
                params['state_list'] = state_list
            }
    
                await this.getDealerChartData(params)
                // this.setState({
                //     selected_dealer : {label :this.state.dealerList.length > 0?  this.state.dealerList[0].label :"", value :this.state.dealerList.length > 0?  this.state.dealerList[0].value : "",key : this.state.dealerList[0].key}
                // })
        }

        var firstChartQuery = this.state.firstChartQuery;
        firstChartQuery['chart_mode'] = screen_type;
        delete firstChartQuery['state_list']
        delete firstChartQuery['transporterData']

        var cols = this.state.couriercolumns

        if(screen_type != "prt")
        {
            cols = this.state.couriercolumns
        }
        // var dispatch_type = "ALL";
        // if(this.state.selectedDispatchType != undefined && this.state.selectedDispatchType != null)
        // {
        //     if(this.state.selectedDispatchType.value != "ALL")
        //     {
        //         dispatch_type = this.state.selectedDispatchType.value
        //     }
        // }

        // var status_type = "ALL";
        // if(this.state.selectedStatusType != undefined && this.state.selectedStatusType != null)
        // {
        //     if(this.state.selectedStatusType.value != "ALL")
        //     {
        //         status_type = this.state.selectedStatusType.value
        //     }
        // }

        redirectURL.post("/masters/prtontimegriddata",{"from_date": firstChartQuery.from_date,
         "to_date": firstChartQuery.to_date,"chart_type": screen_type,"shipping_type": shipping_type,
         "state_list": [],
         "transporterData": []}).then((response)=>{
            console.log(response.data , "1729_records")
            var records = response.data
            var today = new Date();
				var dd = String(today.getDate()).padStart(2, '0');
				var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy = today.getFullYear();
				today = yyyy + '-' + mm + '-' + dd;
            records.forEach(e => {

                if (e.revised_eta == "" || e.revised_eta == undefined) {
                    // e.elapsed_transit_time = differenceInDays(today, e.gc_date);
                    e.elapsed_transit_time = differenceInDays(today, e.gc_date);
                    e.ontime_delayed_status = "Delayed";
                } else {
                    e.elapsed_transit_time = differenceInDays(e.revised_eta, e.gc_date)
                    if (moment.parseZone(e.revised_eta).startOf('day') - moment.parseZone(e.expected_eta).startOf('day') <= 0) {
                        e.ontime_delayed_status = "On Time";
                        
                    }
                     else {
                        e.ontime_delayed_status = "Delayed";
                    }
                }
                return e
                
            });
            this.setState({
                rowData: records,
                columnwithdefs: cols
            })
        }).catch((e)=>{
            console.log(e)
        })

        this.setState({
            selected_cities: [{"label": "all", "value": "all"}],
            selected_lsps: [{"label": "all", "value": "all"}]
        })


        var secondChartQuery = this.state.secondChartQuery;
        secondChartQuery['chart_type'] = screen_type;
        delete secondChartQuery['state_list']
        delete secondChartQuery['transporterData']

        // var thirdChartQuery = this.state.thirdChartQuery;
        // thirdChartQuery['chart_mode'] = screen_type;
        // delete thirdChartQuery['state_list']
        // delete thirdChartQuery['transporterData']

        var fourthChartQuery = this.state.fourthChartQuery;
        fourthChartQuery['chart_type'] = screen_type;
        delete fourthChartQuery['state_list']
        delete fourthChartQuery['transporterData']
       

        if(tab == "chart")
        {
            // firstChartQuery['chart_mode'] = "prt-courier"
            firstChartQuery['shipping_type'] = shipping_type
           
            // var firstchart = await this.getFirstandThirdChartData(firstChartQuery, "firstChartArr")
        }
        secondChartQuery['shipping_type'] = shipping_type
     
        var secondchart = await this.getSecondChartData(secondChartQuery)
       
        fourthChartQuery['shipping_type'] = shipping_type
       
        var fourthchart = await this.getFourthChartData(fourthChartQuery)
       
        // thirdChartQuery['shipping_type'] = shipping_type
     
        // await this.getFirstandThirdChartData(thirdChartQuery, "thirdChartArr")
    }
    onClickDataTab(tab)
    {
        if(tab == "chart")
        {
            this.setState({
                showChartData: "show-m",
                showGridData:  "show-n",
                chartTab: "activet",
                gridTab: ""
            })
        }
        else
        {
            this.setState({
                showGridData: "show-m",
                showChartData: "show-n",
                chartTab: "",
                gridTab: "activet"
            })
        }
    }
    render(){

        var columnwithDefs = [
            {
                headerName: "Consignee Code",
                field: "consignee_code",
                width: 115,
                editable: false,
                // pinned: 'left',
                filter: "agSetColumnFilter",
                //cellRenderer: "agGroupCellRenderer"
            },
            {
                headerName: "Consignee Name",
                field: "consignee_name",
                width: 115,
                editable: false,
                // pinned: 'left',
                filter: "agSetColumnFilter",
                //cellRenderer: "agGroupCellRenderer"
            },
            {
                headerName: "Total Records",
                field: "total_records",
                width: 115,
                editable: false,
                // pinned: 'left',
                filter: "agSetColumnFilter",
                //cellRenderer: "agGroupCellRenderer"
            },
            {
                headerName: "On Time Records",
                field: "on_time_records",
                width: 115,
                editable: false,
                // pinned: 'left',
                filter: "agSetColumnFilter",
                //cellRenderer: "agGroupCellRenderer"
            },
            {
                headerName: "On Time Percentage",
                field: "on_time_percentage",
                width: 115,
                editable: false,
                // pinned: 'left',
                filter: "agSetColumnFilter",
                //cellRenderer: "agGroupCellRenderer",
                cellClass: function (params) {
                    if(parseFloat(params.data.on_time_percentage) > 50)
                    {
                        return 'bgColorSuccessMedium'

                    }
                    else{
                        return 'bgColorDangerMedium'
                    }
                
                },
            },
        ]

       
        return (
            <div className="container-fluid">
                <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div  className="row col-xl-12 col-lg-12">                  
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>Start Date </label>
                        <Datetime
                        value={this.state.start_date}
                        disableCloseOnClickOutside={false}
                        closeOnSelect={true}
                        inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off',readOnly:true }}
                        dateFormat="YYYY-MM-DD"
                        className='date-z-index'
                        name="startDate"
                        timeFormat={false}
                        // isValidDate={this.isValidFromDate} 
                        onChange={this.handlerGridStartDateTime.bind(this)} />
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>End Date </label>
                        <Datetime value={this.state.end_date}
                        disableCloseOnClickOutside={false}
                        closeOnSelect={true}
                        inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off',readOnly:true }}
                        timeFormat={false}
                        className='date-z-index'
                        dateFormat="YYYY-MM-DD"
                        name="endDate"
                        // isValidDate={this.isValidEndDate} 
                        onChange={this.handlerGridEndDateTime.bind(this)} />  
                    </div>
                    <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                        <label>State </label>
                            <Select placeholder={"All"}
                            isMulti={true}
                            value={this.state.selected_cities}
                            className="border-radius-0"
                            onChange={(e)=>this.setState({selected_cities: e})}
                            style={{borderRadius:"0px"}}
                            options={this.state.stateList} required />
                    </div>
                    {/* <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                        <label>Status Type: </label>
                            <Select placeholder={"All"}
                            isMulti={false}
                            value={this.state.selectedStatusType}
                            className="border-radius-0"
                            onChange={(e)=>this.setState({selectedStatusType: e})}
                            style={{borderRadius:"0px"}}
                            options={this.state.statusTypeOptions} required />
                    </div> */}
                    {/* <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                        <label>Location: </label>
                            <Select placeholder={"All"} isMulti={true} value={this.state.selected_cities} className="border-radius-0" onChange={(e)=>this.setState({selected_cities: e})} style={{borderRadius:"0px"}} options={this.state.cityOptions} required />
                    </div> */}
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>Transporter </label>
                        <Select placeholder={"All"} isMulti={true} value={this.state.selected_lsps} className="border-radius-0" onChange={(e)=>this.setState({selected_lsps: e})} style={{borderRadius:"0px"}} options={this.state.transporter_data} required />
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>Type </label>
                        <Select placeholder={""} isMulti={false} value={this.state.selected_shipping_type} className="border-radius-0" onChange={(e)=>this.setState({selected_shipping_type: e})} style={{borderRadius:"0px"}} options={[
                            {
                                label:"COURIER",value:"COURIER"
                            },
                            {
                                label:"PTL",value:"PTL"
                            },
                            // {
                            //     label:"FTL",value:"FTL"
                            // }
                            ]} required />
                    </div>
                    
                    <div className="form-group col-xl-1 col-lg-1">
                        <label>&nbsp;</label><br />
                        <button type="button" className="btn btn-success"
                        // style={{position:"absolute", top:"2.2em", "left": "15em"}}
                        onClick={this.onClickFilterDashboard.bind(this)}>Submit</button>
                    </div>
                </div>
                {this.state.changed_shipping_type == "COURIER"? 
                <div className="col-xl-12 col-lg-12">
                    <button className={"btn btn-default "+this.state.prtActive} onClick={this.onClickTab.bind(this, "prt", "05")}>Surface - On Time Performance</button>
                    <button className={"btn btn-default "+this.state.prtCourierActive} onClick={this.onClickTab.bind(this, "prt-courier","07")}>Air - Courier On Time Performace</button>
                    <button className={"btn btn-default "+this.state.prtSurfaceCourierActive} onClick={this.onClickTab.bind(this, "prt-surface-courier", "22")}>Train - Courier On Time Performace</button>
                </div>: ""}
                {this.state.changed_shipping_type == "PTL"? 
                <div className="col-xl-12 col-lg-12">
                    <button className={"btn btn-default "+this.state.prtActive}>PTL - On Time Performance</button>
                    
                </div>: ""}{this.state.changed_shipping_type == "FTL"? 
                <div className="col-xl-12 col-lg-12">
                    <button className={"btn btn-default "+this.state.prtActive} >Surface - On Time Performance</button>
                </div>: ""}

               
                <div className="col-xl-12 col-lg-12 mt-20p">
                    {/* <h4 >{this.state.pagetitle}</h4> */}
                </div>
                <div className="col-xl-12 col-lg-12">
                    <button className={"btn btn-default "+this.state.chartTab} onClick={this.onClickDataTab.bind(this, "chart")}>Chart Data</button>
                    <button className={"btn btn-default "+this.state.gridTab} onClick={this.onClickDataTab.bind(this, "complete")}>Complete Data</button>
                </div>
                <div className={"row col-sm-12 "+this.state.showChartData}>
                    <div className="card-body">
                        <div className={"col-xl-12 col-lg-12 padding-10px"} style={{background:"#ffffff"}}>
                            {this.state.fourthChartArr.length > 0?  
                                this.state.fourthChartArr.map(item =>
                                    <div className="col-xl-12 col-lg-12  p-10">
                                        <PRTOnTimeChart
                                            title={(this.state.prtActive == "activet")?
                                                    "Transporter Wise On Time Performance":
                                                    "Transporter Wise On Time Performance"}
                                                    subtitle={item.series[0].data.length>0 ? "" : 'No Data Found'}
                                            context={this}
                                            series={item.series}
                                            drilldown = {item.drilldown}
                                            categories={item.categories}
                                            yaxistitle={"On Time %"}
                                        />
                                    </div>
                                )
                            :""}
                        </div>    
                    </div>
                </div>
                    
                <div className="row col-sm-12">
                    <div className={"card-body "+this.state.showChartData}>
                        <div className={" col-xl-12 col-lg-12 padding-10px" } style={{background:"#ffffff"}} >   
                            {this.state.secondChartArr.length > 0?  
                                this.state.secondChartArr.map(item =>
                                    <div className="col-xl-12 col-lg-12 p-10">
                                        <PRTOnTimeChart
                                            title={"State Wise On Time Performance"}
                                            subtitle={item.series[0].data.length>0 ? "" : 'No Data Found'}
                                            context={this}
                                            series={item.series}
                                            drilldown = {item.drilldown}
                                            categories={item.categories}
                                            yaxistitle={"On Time %"}
                                        />
                                    </div>
                                
                                )
                            :""}
                        </div>            
                    </div>
                    <div className={"row col-sm-12 " + this.state.showChartData}>

                        <div className="card-body">

                                <div className={"col-xl-12 col-lg-12 padding-10px"} style={{ background: "#ffffff" }}>
                                    {this.state.DealerChartArr.length > 0 ?
                                        this.state.DealerChartArr.map(item =>
                                            <div className="col-xl-12 col-lg-12  p-10">
                                                <PRTOnTimeChart
                                                    title={(this.state.prtActive == "activet") ?
                                                        "Dealer Wise Top On Time Performance" :
                                                        "Dealer Wise Top On Time Performance"}
                                                        subtitle={item.series[0].data.length>0 ? "" : 'No Data Found'}
                                                        context={this}
                                                    series={item.series}
                                                    drilldown={item.drilldown}
                                                    categories={item.categories}
                                                    yaxistitle={"On Time %"}
                                                    for_dealer={1}

                                                />
                                            </div>
                                        )
                                        : ""}
                                </div>

                                <div className={"col-xl-12 col-lg-12 padding-10px"} style={{ background: "#ffffff" }}>
                                    {this.state.DealerChartArr2.length > 0 ?
                                        this.state.DealerChartArr2.map(item =>
                                            <div className="col-xl-12 col-lg-12  p-10">
                                                <PRTOnTimeChart
                                                    title={(this.state.prtActive == "activet") ?
                                                        "Dealer Wise Least On Time Performance" :
                                                        "Dealer Wise Least On Time Performance"}
                                                    subtitle={item.series[0].data.length>0 ? "" : 'No Data Found'}
                                                    context={this}
                                                    series={item.series}
                                                    drilldown={item.drilldown}
                                                    categories={item.categories}
                                                    yaxistitle={"On Time %"}
                                                    for_dealer={1}
                                                />
                                            </div>
                                        )
                                        : ""}
                                </div>

                                <div className={"col-xl-12 col-lg-12 padding-10px"} style={{ background: "#ffffff" }}>
                                    {this.state.on_time_df.length > 0 ?
                                    <>
                                     <div className="form-group col-xl-8 col-lg-8">
                                        <h5>Overall Dealer Data</h5>
                                    </div>
                                        <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham col-xl-12 col-lg-12 "}>
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.on_time_df}
                                                enableCharts={false}
                                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                frameworkComponents={this.state.frameworkComponents}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                stopEditingWhenGridLosesFocus={true}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                // components={this.state.components}
                                                enableRangeSelection={true}
                                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                            />
                                        </div>
                                    </>
                                       
                                        : ""}
                            </div>



                        </div>
                    </div>           

                        {/* {this.state.thirdChartArr.length > 0?  
                            this.state.thirdChartArr.map(item =>
                                <div className="col-xl-12 col-lg-12">
                                    <PRTOnTimeChart
                                        title={"Consigner Wise On Time Performance"}
                                        subtitle={""}
                                        context={this}
                                        series={item.series}
                                        drilldown = {item.drilldown}
                                        categories={item.categories}
                                        yaxistitle={"On Time %"}
                                    />
                                </div>
                               
                            )
                        :""} */}

                        {/* {this.state.firstChartArr.length > 0?  
                            this.state.firstChartArr.map(item =>
                                <div className="col-xl-12 col-lg-12">
                                    <PRTOnTimeChart
                                        title={"Zone Wise On Time Performance"}
                                        subtitle={""}
                                        context={this}
                                        series={item.series}
                                        drilldown = {item.drilldown}
                                        categories={item.categories}
                                        yaxistitle={"On Time %"}
                                    />
                                </div>
                               
                            )
                        :""} */}
           
                    
                    <div className={"card-body "+this.state.showGridData}>
                        <div className={"col-xl-12 col-lg-12"} style={{background:"#fff",padding:"20px 10px"}}>
                        <div className="form-group col-xl-8 col-lg-8">
                                        <h5>Overall Consignment Data</h5>
                                    </div>
                            <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham col-xl-12 col-lg-12 "+this.state.showGridData}>    
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.columnwithdefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus= {true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection= {true}
                                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                    />
                            </div>
                        </div>
                    </div>

                    <div className={"dataLoadpage " +(this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                    <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                </div>
            </div>
        )
    }
}
function differenceInDays(startDate, endDate) {
	// console.log("startDate, endDate", startDate,endDate);
	startDate = moment.parseZone(startDate);
	endDate = moment.parseZone(endDate);
	// var sDate = startDate.setHours(0, 0, 0, 0);
	// var eDate = endDate.setHours(0, 0, 0, 0);
	// console.log("start",sDate);
	// console.log("end",eDate);
	var res = Math.abs((startDate - endDate) / 1000);
	return Math.floor((res) / 86400);
}
function deliveryInTransit(startDate, endDate) {
	// console.log(startDate,endDate);
	endDate = endDate.split("T")[0];
	if (typeof (startDate) == "string") {
		if (startDate.indexOf(":") >= 0) {
			startDate = startDate.split(" ")[0];
		}
		if (startDate.indexOf(":") >= 0 && startDate.indexOf("T") >= 0) {
			startDate = startDate.split("T")[0];
		}

		// console.log(startDate);
		// console.log(startDate.split("-")[1]+"/"+startDate.split("-")[2]+"/"+startDate.split("-")[0]);
		// console.log(endDate.split("-")[1]+"/"+endDate.split("-")[2]+"/"+endDate.split("-")[0]);
		if (startDate.indexOf("/") < 0) {
			startDate = new Date(startDate.split("-")[1] + "/" + startDate.split("-")[2] + "/" + startDate.split("-")[0]);
		}
		else {
			startDate = new Date(startDate.split("/")[1] + "/" + startDate.split("/")[2] + "/" + startDate.split("/")[0]);
		}
	}
	else {
		var dd = String(startDate.getDate()).padStart(2, '0');
		var mm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = startDate.getFullYear();
		startDate = mm + '/' + dd + '/' + yyyy;
		startDate = new Date(startDate);
	}

	endDate = new Date(endDate.split("-")[1] + "/" + endDate.split("-")[2] + "/" + endDate.split("-")[0]);
	// console.log(startDate,endDate);
	var difference = startDate.getTime() - endDate.getTime();
	// console.log(difference);
	// var res = Math.abs((startDate - endDate)/1000);
	return Math.abs(Math.round((difference) / (1000 * 3600 * 24)));

}

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    };
}