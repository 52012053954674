/* eslint-disable no-self-assign */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, {Component} from 'react';
import Select from 'react-select';
import redirectURL from '../redirectURL';

export default class Configurations extends Component {
    constructor(props){
        super(props);
        this.state={
            fetchFrom:{"value":"","label":"Select"},
            transporter_podUpload:{"value":"no","label":"No"},
            customer_podUpload:{"value":"no","label":"No"},
            dieselCost:0,
            roadCost:0,
            driverCost:0,
            tollCost:0,
            showApiInp:"show-n",
            apiURL:"",
            is_diesel_price_revision:{"value":"no","label":"No"},
            deiselinp:"show-n",
            is_road_fixed:{"value":"no","label":"No"},
            roadinp:"show-n",
            is_driver_fixed:{"value":"no","label":"No"},
            driverinp:"show-n",
            is_toll_price_revision:{"value":"no","label":"No"},
            tollinp:"show-n",
            transporter_podType:{"value":"","label":"Select"},
            customer_podType:{"value":"","label":"Select"},
            confData:[],
            diesel_website:{"value":"","label":"Select"},
            formulachk1:false,
            formulachk2:false,
            formula1:1,
            formula2:2,
            dieselpricecalculation:"",
            formula2inp:"show-n",
            diesel_impact_percent:"",
            capturepricechk1:false,
            capturepricechk2:false,
            captureprice1:1,
            captureprice2:2,
            dieselpricecapture:"",
            capture1inp:"show-n",
            capture2inp:"show-n",
            freight_rate_percent:"",
            capture_diesel_price_from_day:{"value":"","label":"Select"},
            capture_diesel_price_from_month:{"value":"","label":"Select"},
            capture_diesel_price_to_day:{"value":"","label":"Select"},
            capture_diesel_price_to_month:{"value":"","label":"Select"},
            capture_diesel_price_from_avg:{"value":"","label":"Select"},
            capture_diesel_price_to_avg:{"value":"","label":"Select"},
            applicable_diesel_price_from:{"value":"","label":"Select"},
            is_freight_rate_approve:{"value":"","label":"Select"},
            diesel_frequence_option:{"value":"","label":"Select"},
            toll_frequence_option:{"value":"","label":"Select"},
            diesel_week_freq:[],
            diesel_month_freq:{"value":"","label":""},
            diesel_quater_freq:{"value":"","label":""},
            diesel_semiannual_freq:{"value":"","label":""},
            toll_week_freq:[],
            toll_month_freq:{"value":"","label":""},
            toll_quater_freq:{"value":"","label":""},
            toll_semiannual_freq:{"value":"","label":""},
            nooflevels:"",
            showlevels:"show-n",
            dFrqDaily:"show-n",
            dFrqWeek:"show-n",
            dFrqFortNightly:"show-n",
            dFrqMonth:"show-n",
            dFrqQuater:"show-n",
            dFrqSemiAnnual:"show-n",
            tFrqDaily:"show-n",
            tFrqWeek:"show-n",
            tFrqFortNightly:"show-n",
            tFrqMonth:"show-n",
            tFrqQuater:"show-n",
            tFrqSemiAnnual:"show-n",
            approval_level_emails:[],
            otherCosts:{"value":"no","label":"NO"},
            penality:{"value":"no","label":"NO"},
            lsp_gst:{"value":"","label":"Select"},
            gst_multi:{"value":"","label":"Select"},
        }
    }
	componentDidMount(){
        console.log("check")
        redirectURL.post("/billing/configurations")
		.then((response) => {
            var records = response.data.records;
            console.log("records ", records)
            if(records.length > 0)
            {
                setTimeout(()=>{
                    if(records[0].diesel_price_formula == 1)
                    {
                        var dieselpricecalculation = 1;
                        var rdcheckd1=true;
                        var rdcheckd2=false;
                    }
                    if(records[0].diesel_price_formula == 2)
                    {
                        var dieselpricecalculation = 2;
                        var rdcheckd1=false;
                        var rdcheckd2=true;
                    }
                    if(records[0].diesel_price_capture_val == 1)
                    {
                        var dieselpricecapture = 1;
                        var capcheckd1=true;
                        var capcheckd2=false;
                    }
                    if(records[0].diesel_price_capture_val == 2)
                    {
                        var dieselpricecapture = 2;
                        var capcheckd1=false;
                        var capcheckd2=true;
                    }
                    // Start Diesel and Toll Frequency
                    var diesel_week_freq = [];
                    var diesel_month_freq = {"value":"","label":""};
                    var diesel_quater_freq={"value":"","label":""};
                    var diesel_semiannual_freq={"value":"","label":""};
                    var toll_week_freq=[];
                    var toll_month_freq={"value":"","label":""};
                    var toll_quater_freq={"value":"","label":""};
                    var toll_semiannual_freq={"value":"","label":""};
                    if(records[0].diesel_frequence_option === "Daily")
                    {
                        var diesel_revision_day = records[0].diesel_revision_day[0];
                    }
                    else if(records[0].diesel_frequence_option === "Weekly")
                    {
                        
                        var diesel_weekfrqs = []
                        if(records[0].diesel_revision_day != "")
                        {
                            if(records[0].diesel_revision_day.length > 0)
                            {
                                records[0].diesel_revision_day.map((itm) => {
                                    diesel_weekfrqs.push({value:itm,label:itm});
                                })
                            }
                        }
                        
                        diesel_week_freq = diesel_weekfrqs;
                    }
                    else if(records[0].diesel_frequence_option === "FortNightly")
                    {
                        var diesel_week_freq = JSON.stringify(["1st and 16th"]);
                    }
                    
                    else if(records[0].diesel_frequence_option === "Monthly")
                    {
                        if(records[0].diesel_revision_day != "" && records[0].diesel_revision_day != undefined)
                        {
                            diesel_month_freq={value:records[0].diesel_revision_day[0],label:records[0].diesel_revision_day[0]};
                        }
                    }
                    else if(records[0].diesel_frequence_option === "Quaterly")
                    {
                        if(records[0].diesel_revision_day != "" && records[0].diesel_revision_day != undefined)
                        {
                            diesel_month_freq={value:records[0].diesel_revision_day[0],label:records[0].diesel_revision_day[0]};
                        }
                    }
                    else if(records[0].diesel_frequence_option === "Semi-Annual")
                    {
                        if(records[0].diesel_revision_day != "" && records[0].diesel_revision_day != undefined)
                        {
                            diesel_month_freq={value:records[0].diesel_revision_day[0],label:records[0].diesel_revision_day[0]};
                        }
                    }
            
                    if(records[0].toll_frequence_option === "Daily")
                    {
                        var toll_revision_day = records[0].toll_revision_day[0];
                    }
                    else if(records[0].toll_frequence_option === "Weekly")
                    {
                        
                        var toll_week_freq = []
                        if(records[0].toll_revision_day != "")
                        {
                            if(records[0].toll_revision_day.length > 0)
                            {
                                records[0].toll_revision_day.map((itm) => {
                                    toll_week_freq.push({value:itm,label:itm});
                                })
                            }
                        }
                        
                        toll_week_freq = toll_week_freq;
                    }
                    else if(records[0].toll_frequence_option === "FortNightly")
                    {
                        
                    }
                    
                    else if(records[0].toll_frequence_option === "Monthly")
                    {
                        if(records[0].toll_revision_day != "" && records[0].toll_revision_day != undefined)
                        {
                            toll_month_freq={value:records[0].toll_revision_day[0],label:records[0].toll_revision_day[0]};
                        }
                    }
                    else if(records[0].toll_frequence_option === "Quaterly")
                    {
                        if(records[0].toll_revision_day != "" && records[0].toll_revision_day != undefined)
                        {
                            toll_month_freq={value:records[0].toll_revision_day[0],label:records[0].toll_revision_day[0]};
                        }
                    }
                    else if(records[0].toll_frequence_option === "Semi-Annual")
                    {
                        if(records[0].toll_revision_day != "" && records[0].toll_revision_day != undefined)
                        {
                            toll_month_freq={value:records[0].toll_revision_day[0],label:records[0].toll_revision_day[0]};
                        }
                    }
                    // END
                   if(records[0].lsp_gst == "RCM"){
                    var gstlbl = "Reverse Charge Mechanism (RCM)"
                   }
                   else if(records[0].lsp_gst == "FCM"){
                    var gstlbl = "Forward Charge Mechanism"
                   }
                   else if(records[0].lsp_gst == "both"){
                    var gstlbl = "Both"
                   }
                   else{
                    var gstlbl = "Select"
                   }
                //    console.log("records[0].lsp_gst_multi ", records[0].lsp_gst_multi)
                   if(records[0].lsp_gst_multi != "" || records[0].lsp_gst_multi != undefined)
                   {
                    try{
                        var gstmulti = records[0].lsp_gst_multi.toUpperCase();
                    }
                    catch(e){}
                    
                   }
                   else{
                    var gstmulti = "Select"
                   }
                    this.setState({
                        fetchFrom:{"value":records[0].trips_data_from,"label":(records[0].trips_data_from != "" && records[0].trips_data_from != undefined)?records[0].trips_data_from.toUpperCase().replace(/_/g," "):"Select"},
                        transporter_podUpload:{"value":records[0].is_transporter_upload_pod ,"label":records[0].is_transporter_upload_pod.toUpperCase()},
                        customer_podUpload:{"value":records[0].is_customer_upload_pod ,"label":records[0].is_customer_upload_pod.toUpperCase()},
                        showApiInp:(records[0].trips_data_from == "api"?"show-m":"show-n"),
                        apiURL:records[0].api_url,
                        is_diesel_price_revision:{"value":records[0].is_diesel_price_revision,"label":records[0].is_diesel_price_revision.toUpperCase()},
                        deiselinp:(records[0].is_diesel_price_revision == "yes"?"show-m":"show-n"),
                        is_toll_price_revision:{"value":records[0].is_toll_price_revision,"label":records[0].is_toll_price_revision.toUpperCase()},
                        transporter_podType:{"value":records[0].transporter_pod_submit_type,"label":(records[0].transporter_pod_submit_type != "")?records[0].transporter_pod_submit_type.toUpperCase().replace(/_/g," "):"Select"},
                        customer_podType:{"value":records[0].customer_pod_submit_type,"label":(records[0].customer_pod_submit_type != "")?records[0].customer_pod_submit_type.toUpperCase().replace(/_/g," "):"Select"},
                        diesel_website:{"value":records[0].diesel_website,"label":records[0].diesel_website.toUpperCase()},
                        formulachk1:rdcheckd1,
                        formulachk2:rdcheckd2,
                        dieselpricecalculation:dieselpricecalculation,
                        formula2inp:(dieselpricecalculation == 2)?"show-m":"show-n",
                        diesel_impact_percent:records[0].diesel_impact_percent,
                        capturepricechk1:capcheckd1,
                        capturepricechk2:capcheckd2,
                        dieselpricecapture:dieselpricecapture,
                        capture1inp:(dieselpricecapture == "1")?"show-m":"show-n",
                        capture2inp:(dieselpricecapture == "2")?"show-m":"show-n",
                        diesel_price_x:records[0].diesel_price_x,
                        applicable_diesel_price_from:{"value":records[0].applicable_diesel_price_from,"label":records[0].applicable_diesel_price_from},
                        capture_diesel_price_from_day:{"value":records[0].capture_diesel_price_from_day,"label":records[0].capture_diesel_price_from_day},
                        capture_diesel_price_from_avg:{"value":records[0].capture_diesel_price_from_avg,"label":records[0].capture_diesel_price_from_avg},
                        capture_diesel_price_to_day:{"value":records[0].capture_diesel_price_to_day,"label":records[0].capture_diesel_price_to_day},
                        capture_diesel_price_to_avg:{"value":records[0].capture_diesel_price_to_avg,"label":records[0].capture_diesel_price_to_avg},
                        is_freight_rate_approve:{"value":records[0].is_freight_rate_approve,"label":records[0].is_freight_rate_approve.toUpperCase()},
                        showlevels:(records[0].is_freight_rate_approve == "no")?"show-m":"show-n",
                        nooflevels:records[0].approval_levels,
                        approval_level_emails:records[0].approval_level_emails,
                        diesel_frequence_option:{value:records[0].diesel_frequence_option,label:records[0].diesel_frequence_option},
                        toll_frequence_option:{value:records[0].toll_frequence_option,label:records[0].toll_frequence_option},
                        diesel_week_freq:diesel_week_freq,
                        diesel_month_freq:diesel_month_freq,
                        diesel_quater_freq:diesel_quater_freq,
                        diesel_semiannual_freq:diesel_semiannual_freq,
                        toll_week_freq:toll_week_freq,
                        toll_month_freq:toll_month_freq,
                        toll_quater_freq:toll_quater_freq,
                        toll_semiannual_freq:toll_semiannual_freq,
                        dFrqDaily:(records[0].diesel_frequence_option === "Daily")?"show-m":"show-n",
                        dFrqWeek:(records[0].diesel_frequence_option === "Weekly")?"show-m":"show-n",
                        dFrqFortNightly:(records[0].diesel_frequence_option === "FortNightly")?"show-m":"show-n",
                        dFrqMonth:(records[0].diesel_frequence_option === "Monthly")?"show-m":"show-n",
                        dFrqQuater:(records[0].diesel_frequence_option === "Quaterly")?"show-m":"show-n",
                        dFrqSemiAnnual:(records[0].diesel_frequence_option === "Semi-Annually")?"show-m":"show-n",
                        tFrqDaily:(records[0].toll_frequence_option === "Daily")?"show-m":"show-n",
                        tFrqWeek:(records[0].toll_frequence_option === "Weekly")?"show-m":"show-n",
                        tFrqFortNightly:(records[0].toll_frequence_option === "FortNightly")?"show-m":"show-n",
                        tFrqMonth:(records[0].toll_frequence_option === "Monthly")?"show-m":"show-n",
                        tFrqQuater:(records[0].toll_frequence_option === "Quaterly")?"show-m":"show-n",
                        tFrqSemiAnnual:(records[0].toll_frequence_option === "Semi-Annually")?"show-m":"show-n",
                        otherCosts:{"value":records[0].other_costs,"label":records[0].other_costs.toUpperCase()},
                        penality:{"value":records[0].penality_cost,"label":records[0].penality_cost.toUpperCase()},
                        lsp_gst:{"value":records[0].lsp_gst,"label":gstlbl},
                        gst_multi:{"value":records[0].lsp_gst_multi,"label":gstmulti},
                        
                    });
                    if(records[0].approval_level_emails != "" && records[0].approval_level_emails != undefined)
                    {
                        var lEmails = records[0].approval_level_emails;
                        var html = '';
                        if(lEmails.length > 0)
                        {
                            lEmails.map((itm) => {
                                html += '<div class="row"><div class="col-5"><label>Level - '+(itm.level)+'</label></div>';
                                html += '<div class="col-2"> <div class="form-group">';
                                html += '<input type="text" class="form-control levelEmails lEmail_'+(itm.level)+'" value="'+itm.email+'" placeholder="Enter Email ID" name="levelEmails"  />';
                                html += '</div>';
                                html += '</div></div>';
                            })
                        }
                        document.getElementById("apprvLevls").innerHTML = html;
                    }
                },1000)
                
            }
            
		})
	}
    fetchOptions(){
        var items = [];
        items.push({
            value:"api",
            label:"API"
        },{
            value:"upload_csv",
            label:"UPLOAD CSV"
        });
        return items;
    }
    handleChange = (fetchFrom) => {
        this.setState({ fetchFrom }, () =>{
            if(this.state.fetchFrom.value === "api")
            {
                this.setState({
                    showApiInp:'show-m'
                })
            }
            else{
                this.setState({
                    showApiInp:'show-n',
                    apiURL:""
                })
            }
        }
        );
      };
      
    fetchPODOptions(){
        var items = [];
        items.push({
            value:"yes",
            label:"YES"
        },{
            value:"no",
            label:"NO"
        });
        return items;
    }
    handlePODChange = (transporter_podUpload) => {
        this.setState({ transporter_podUpload });
    };
    handleCustomerPODChange = (customer_podUpload) => {
        this.setState({ customer_podUpload });
    };
    changeInputHandler(event){
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    
    fetchDieselOptions(){
        var items = [];
        items.push({
            value:"no",
            label:"NO"
        },{
            value:"yes",
            label:"YES"
        });
        return items;
    }
    handleDieselChange = (is_diesel_price_revision) => {
        this.setState({ is_diesel_price_revision }, () =>
          {
            // console.log(`Option selected:`, this.state.is_diesel_price_revision)
            if(this.state.is_diesel_price_revision.value == "yes")
            {
                this.setState({
                    deiselinp:'show-m'
                })
            }
            else{
                this.setState({
                    deiselinp:'show-n'
                })
            }
          }
        );
    };
    
    fetchTollOptions(){
        var items = [];
        items.push({
            value:"no",
            label:"NO"
        },{
            value:"yes",
            label:"YES"
        });
        return items;
    }
    handleTollChange = (is_toll_price_revision) => {
        this.setState({ is_toll_price_revision }, () =>
          {
            console.log(`Option selected:`, this.state.is_toll_price_revision)
            // if(this.state.is_toll_price_revision.value == "yes")
            // {
            //     this.setState({
            //         tollinp:'show-m'
            //     })
            // }
            // else{
                this.setState({
                    tollinp:'show-n'
                })
            // }
          }
        );
    };
    fetchPODTypeOptions(){
        var items = [];
        items.push({
            value:"",
            label:"Select"
        },{
            value:"upload_doc",
            label:"UPLOAD DOC"
        },{
            value:"manual_entry",
            label:"MANUAL ENTRY"
        },{
            value:"otp_based",
            label:"OTP BASED"
        },{
            value:"api",
            label:"API (EPOD)"
        });
        return items;
    }
    handlePODTypeChange = (transporter_podType) => {
        this.setState({ transporter_podType }, () =>
          {
            // console.log(`Option selected:`, this.state.transporter_podType)
            
          }
        );
    };
    
    fetchApprovalsOptions(){
        var items = [];
        items.push({
            value:"yes",
            label:"YES"
        },{
            value:"no",
            label:"NO"
        });
        return items;
    }
    handleApprovalsChange = (is_freight_rate_approve) => {
        this.setState({ is_freight_rate_approve },()=>{
            if(this.state.is_freight_rate_approve.value == "no")
            {
                this.setState({
                    showlevels:"show-m"
                })
            }
            else{
                
                this.setState({
                    showlevels:"show-n"
                })
            }
        });
    };
    handleCustomerPODTypeChange = (customer_podType) => {
        this.setState({ customer_podType }, () =>
          {
            // console.log(`Option selected:`, this.state.customer_podType)
            
          }
        );
    };
    fetchDieselWebsites(){
        var items = [];
        items.push({
            value:"IOCL",
            label:"IOCL"
        },
        // {
        //     value:"HPCL",
        //     label:"HPCL"
        // }
        );
        return items;
    }
    handleDieselWebsite(diesel_website){
        this.setState({ diesel_website }, () =>
        {
        //   console.log(`Option selected:`, this.state.diesel_website)
          
        }
      );
    }
    changeRadioHandler(val){
        if(val == 1)
        {
            this.setState({
                formulachk1:true,
                formulachk2:false,
                formula2inp:"show-n",
                dieselpricecalculation:1
            })
        }
        else{
            this.setState({
                formulachk1:false,
                formulachk2:true,
                formula2inp:"show-m",
                dieselpricecalculation:2
            })
        }
    }
    
    changeRadioCaptureHandler(val){
        if(val == 1)
        {
            this.setState({
                capturepricechk1:true,
                capturepricechk2:false,
                capture1inp:"show-m",
                capture2inp:"show-n",
                dieselpricecapture:1
            })
        }
        else{
            this.setState({
                capturepricechk1:false,
                capturepricechk2:true,
                dieselpricecapture:2,
                capture1inp:"show-n",
                capture2inp:"show-m"
            })
        }
    }
    
    handleOtherCostChange = (otherCosts) => {
        this.setState({ otherCosts });
    };
    handlePenalityChange = (penality) => {
        this.setState({ penality });
    };
    onSubmitConfigurations(event){
        event.preventDefault();
        var fetchFrom = this.state.fetchFrom.value;
        var apiURL = this.state.apiURL;
        var transporter_podUpload = this.state.transporter_podUpload.value;
        var customer_podUpload = this.state.customer_podUpload.value;
        var is_diesel_price_revision = this.state.is_diesel_price_revision.value;
        var is_toll_price_revision = this.state.is_toll_price_revision.value;
        var transporter_podType = this.state.transporter_podType.value;
        var customer_podType = this.state.customer_podType.value;
        var diesel_website = this.state.diesel_website.value;
        var dieselpricecalculation = this.state.dieselpricecalculation;
        var diesel_frequence_option = this.state.diesel_frequence_option.value;
        var toll_frequence_option = this.state.toll_frequence_option.value;
        var otherCosts = this.state.otherCosts.value;
        var penality = this.state.penality.value;
        var lsp_gst = this.state.lsp_gst.value;
        var gst_multi = this.state.gst_multi.value;
        if(diesel_frequence_option === "Daily")
        {
            var diesel_revision_day = JSON.stringify(["everyday"]);
        }
        else if(diesel_frequence_option === "Weekly")
        {
            if(this.state.diesel_week_freq.length > 0)
            {
                var diesel_weekfrqs = []
                this.state.diesel_week_freq.map((itm) => {
                    diesel_weekfrqs.push(itm.value);
                })
            }
            var diesel_revision_day = JSON.stringify(diesel_weekfrqs);
        }
        else if(diesel_frequence_option === "FortNightly")
        {
            var diesel_revision_day = JSON.stringify(["1st and 16th"]);
        }
        
        else if(diesel_frequence_option === "Monthly")
        {
            var diesel_revision_day = JSON.stringify([this.state.diesel_month_freq.value]);
        }
        else if(diesel_frequence_option === "Quaterly")
        {
            var diesel_revision_day = JSON.stringify([this.state.diesel_quater_freq.value]);
        }
        else if(diesel_frequence_option === "Semi-Annual")
        {
            var diesel_revision_day = JSON.stringify([this.state.diesel_semiannual_freq.value]);
        }
        else{
            var diesel_revision_day = "";
        }

        if(toll_frequence_option === "Daily")
        {
            var toll_revision_day = JSON.stringify(["everyday"])
        }
        else if(toll_frequence_option === "Weekly")
        {
            if(this.state.toll_week_freq.length > 0)
            {
                var toll_weekfrqs = []
                this.state.toll_week_freq.map((itm) => {
                    toll_weekfrqs.push(itm.value);
                })
            }
            var toll_revision_day = JSON.stringify(toll_weekfrqs);
        }
        else if(toll_frequence_option === "FortNightly")
        {
            var toll_revision_day = JSON.stringify(["1st and 16th"]);
        }
        
        else if(toll_frequence_option === "Monthly")
        {
            var toll_revision_day = JSON.stringify([this.state.toll_month_freq.value]);
        }
        else if(toll_frequence_option === "Quaterly")
        {
            var toll_revision_day = JSON.stringify([this.state.toll_quater_freq.value]);
        }
        else if(toll_frequence_option === "Semi-Annual")
        {
            var toll_revision_day = JSON.stringify([this.state.toll_semiannual_freq.value]);
        }
        else{
            var toll_revision_day = "";
        }


        if(dieselpricecalculation == 1)
        {
            var diesel_impact_percent = "";
        }
        else{
            var diesel_impact_percent = this.state.diesel_impact_percent;
        }
        var dieselpricecapture = this.state.dieselpricecapture;
        if(dieselpricecapture == 1)
        {
            var diesel_price_x = this.state.diesel_price_x;

            var applicable_diesel_price_from = this.state.applicable_diesel_price_from.value;
            var capture_diesel_price_from_day = "";
            var capture_diesel_price_from_avg = "";
            var capture_diesel_price_to_day = "";
            var capture_diesel_price_to_avg = "";
        }
        else{
            
            var diesel_price_x = "";

            var applicable_diesel_price_from = "";
            var capture_diesel_price_from_day = this.state.capture_diesel_price_from_day.value;
            var capture_diesel_price_from_avg = this.state.capture_diesel_price_from_avg.value;
            var capture_diesel_price_to_day = this.state.capture_diesel_price_to_day.value;
            var capture_diesel_price_to_avg = this.state.capture_diesel_price_to_avg.value;
        }
        var is_freight_rate_approve = this.state.is_freight_rate_approve.value;
        var nooflevels = this.state.nooflevels;

        var levelEmails = document.getElementsByClassName("levelEmails");
        var approvedLevelEmails = [];
        for(var i=0;i<levelEmails.length;i++)
        {
            approvedLevelEmails.push({"level":(i+1),email:levelEmails[i].value});
        }
        if(approvedLevelEmails.length > 0)
        {
            var approval_level_emails = JSON.stringify(approvedLevelEmails);
        }
        else{
            var approval_level_emails = "";
        }
        var params = {
            fetchFrom:fetchFrom,
            apiURL:apiURL,
            transporter_podUpload:transporter_podUpload,
            customer_podUpload:customer_podUpload,
            transporter_podType:transporter_podType,
            customer_podType:customer_podType,
            is_diesel_price_revision:is_diesel_price_revision,
            is_toll_price_revision:is_toll_price_revision,
            diesel_website:diesel_website,
            dieselpricecalculation:dieselpricecalculation,
            diesel_impact_percent:diesel_impact_percent,
            diesel_price_capture_val:dieselpricecapture,
            diesel_price_x:diesel_price_x,
            applicable_diesel_price_from:applicable_diesel_price_from,
            capture_diesel_price_from_day:capture_diesel_price_from_day,
            capture_diesel_price_from_avg:capture_diesel_price_from_avg,
            capture_diesel_price_to_day:capture_diesel_price_to_day,
            capture_diesel_price_to_avg:capture_diesel_price_to_avg,
            is_freight_rate_approve:is_freight_rate_approve,
            nooflevels:nooflevels,
            approvedLevelEmails:approval_level_emails,
            diesel_frequence_option:diesel_frequence_option,
            diesel_revision_day:diesel_revision_day,
            toll_frequence_option:toll_frequence_option,
            toll_revision_day:toll_revision_day,
            otherCosts:otherCosts,
            penality:penality,
            lsp_gst:lsp_gst,
            gst_multi:gst_multi
        }
        //console.log("parameters",params);
        redirectURL.post("/saveConfigurations",params)
        .then((response) => {
            // console.log("response ", response.data)
            if(response.data.status == "success")
            {
                if(fetchFrom == "api")
                {
                    // redirectToAPIConfigurations()
                }
                else{
                    // redirectToUploadTrips()
                }
                window.location.reload();
            }
        })
    }
    dayOptions(){
        var items = [];
        for(var d=1;d<32;d++)
        {
            items.push({
                value:d,
                label:d
            });
        }
        return items;
    }
    monthOptions(){
        var items = [];
        var months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
        for(var m=0;m<12;m++)
        {
            items.push({
                value:m+1,
                label:months[m]
            });
        }
        return items;
    }
    handleCaptureDieselPriceFromday(capture_diesel_price_from_day)
    {
        this.setState({ capture_diesel_price_from_day })
    }
    handleCaptureDieselPriceToday(capture_diesel_price_to_day)
    {
        this.setState({ capture_diesel_price_to_day })   
    }
    handleCaptureDieselPriceFromMonth(capture_diesel_price_from_month)
    {
        this.setState({ capture_diesel_price_from_month })   
    }
    handleCaptureDieselPriceToMonth(capture_diesel_price_to_month)
    {
        this.setState({ capture_diesel_price_to_month })   
    }
    handleCaptureDieselPriceFromAvg(capture_diesel_price_from_avg){
        this.setState({
            capture_diesel_price_from_avg
        })
    }
    
    avgOptions(){
        var items = [];
        items.push({
            value:"n",
            label:"n"
        });
        for(var m=1;m<7;m++)
        {
            items.push({
                value:"n-"+m,
                label:"n"+"-"+m
            });
        }
        return items;
    }
    handleCaptureDieselPriceToAvg(capture_diesel_price_to_avg){
        this.setState({
            capture_diesel_price_to_avg
        })
    }
    
    handleApplicableFrom(applicable_diesel_price_from)
    {
        this.setState({ applicable_diesel_price_from })   
    }
    onChangeHandlerNumber(event){
        var name = event.target.name;
        var value = event.target.value;
        if(name === "nooflevels")
        {            
            if(isNaN(value) == true)
            {

            }
            else{
                this.setState({
                    [name]:value
                })
            }
            if(value != "")
            {
                var html = '';
                for(var n=0;n<value;n++)
                {
                    html += '<div class="row"><div class="col-5"><label>Level - '+(n+1)+'</label></div>';
                    html += '<div class="col-2"> <div class="form-group">';
                    if(this.state.approval_level_emails.length > 0)
                    {
                        if(this.state.approval_level_emails[n] != undefined)
                        {
                            // console.log("this.state.approval_level_emails[n] ", this.state.approval_level_emails[n])
                            var existEmail = this.state.approval_level_emails[n].email;
                        }
                        else{
                            var existEmail = "";
                        }
                        
                    }
                    else{
                        var existEmail = "";
                    }
                    html += '<input type="text" class="form-control levelEmails lEmail_'+(n+1)+'" value="'+existEmail+'" placeholder="Enter Email ID" name="levelEmails"  />';
                    html += '</div>';
                    html += '</div></div>';
                }
                // document.getElementById("apprvLevls").insertAdjacentHTML("afterend",html);
                document.getElementById("apprvLevls").innerHTML = html;
            }
            
        }
        else{
            this.setState({
                [name]:value
            })
        }
    }
    fetchDieselFrequncyOptions(){
        var items = [];
        items.push({
            value:"Daily",
            label:"Daily"
        },{
            value:"Weekly",
            label:"Weekly"
        },{
            value:"FortNightly",
            label:"FortNightly"
        },{
            value:"Monthly",
            label:"Monthly"
        },{
            value:"Quaterly",
            label:"Quaterly"
        },{
            value:"Semi-Annually",
            label:"Semi-Annually"
        });
        
        return items;
    }
    handleDieselFrequence(diesel_frequence_option){
        this.setState({diesel_frequence_option},()=>{
            if(this.state.diesel_frequence_option.value === "Daily")
            {
                this.setState({
                    dFrqDaily:"show-m",
                    dFrqWeek:"show-n",
                    dFrqFortNightly:"show-n",
                    dFrqMonth:"show-n",
                    dFrqQuater:"show-n",
                    dFrqSemiAnnual:"show-n",
                })
            }
            if(this.state.diesel_frequence_option.value === "Weekly")
            {
                this.setState({
                    dFrqDaily:"show-n",
                    dFrqWeek:"show-m",
                    dFrqFortNightly:"show-n",
                    dFrqMonth:"show-n",
                    dFrqQuater:"show-n",
                    dFrqSemiAnnual:"show-n",
                })
            }
            if(this.state.diesel_frequence_option.value === "FortNightly")
            {
                this.setState({
                    dFrqDaily:"show-n",
                    dFrqWeek:"show-n",
                    dFrqFortNightly:"show-m",
                    dFrqMonth:"show-n",
                    dFrqQuater:"show-n",
                    dFrqSemiAnnual:"show-n",
                })
            }
            if(this.state.diesel_frequence_option.value === "Monthly")
            {
                this.setState({
                    dFrqDaily:"show-n",
                    dFrqWeek:"show-n",
                    dFrqMonth:"show-m",
                    dFrqFortNightly:"show-n",
                    dFrqQuater:"show-n",
                    dFrqSemiAnnual:"show-n",
                })
            }
            if(this.state.diesel_frequence_option.value === "Quaterly")
            {
                this.setState({
                    dFrqDaily:"show-n",
                    dFrqWeek:"show-n",
                    dFrqMonth:"show-n",
                    dFrqFortNightly:"show-n",
                    dFrqQuater:"show-m",
                    dFrqSemiAnnual:"show-n",
                })
            }
            if(this.state.diesel_frequence_option.value === "Semi-Annually")
            {
                this.setState({
                    dFrqDaily:"show-n",
                    dFrqWeek:"show-n",
                    dFrqFortNightly:"show-n",
                    dFrqMonth:"show-n",
                    dFrqQuater:"show-n",
                    dFrqSemiAnnual:"show-m",
                })
            }
        })
    }
    handleTollFrequence(toll_frequence_option){
        this.setState({toll_frequence_option},()=>{
            if(this.state.toll_frequence_option.value === "Daily")
            {
                this.setState({
                    tFrqDaily:"show-m",
                    tFrqWeek:"show-n",
                    tFrqFortNightly:"show-n",
                    tFrqMonth:"show-n",
                    tFrqQuater:"show-n",
                    tFrqSemiAnnual:"show-n",
                })
            }
            if(this.state.toll_frequence_option.value === "Weekly")
            {
                this.setState({
                    tFrqWeek:"show-m",
                    tFrqDaily:"show-n",
                    tFrqFortNightly:"show-n",
                    tFrqMonth:"show-n",
                    tFrqQuater:"show-n",
                    tFrqSemiAnnual:"show-n",
                })
            }
            if(this.state.toll_frequence_option.value === "FortNightly")
            {
                this.setState({
                    tFrqWeek:"show-n",
                    tFrqDaily:"show-n",
                    tFrqFortNightly:"show-m",
                    tFrqMonth:"show-n",
                    tFrqQuater:"show-n",
                    tFrqSemiAnnual:"show-n",
                })
            }
            if(this.state.toll_frequence_option.value === "Monthly")
            {
                this.setState({
                    tFrqWeek:"show-n",
                    tFrqDaily:"show-n",
                    tFrqFortNightly:"show-n",
                    tFrqMonth:"show-m",
                    tFrqQuater:"show-n",
                    tFrqSemiAnnual:"show-n",
                })
            }
            if(this.state.toll_frequence_option.value === "Quaterly")
            {
                this.setState({
                    tFrqWeek:"show-n",
                    tFrqDaily:"show-n",
                    tFrqFortNightly:"show-n",
                    tFrqMonth:"show-n",
                    tFrqQuater:"show-m",
                    tFrqSemiAnnual:"show-n",
                })
            }
            if(this.state.toll_frequence_option.value === "Semi-Annually")
            {
                this.setState({
                    tFrqDaily:"show-n",
                    tFrqFortNightly:"show-n",
                    tFrqWeek:"show-n",
                    tFrqMonth:"show-n",
                    tFrqQuater:"show-n",
                    tFrqSemiAnnual:"show-m",
                })
            }
        })
    }
    fetchWeeklyDaysOptions(){
        var items = [];
        // items.push({
        //     value:"1",
        //     label:"1"
        // },{
        //     value:"7",
        //     label:"7"
        // },{
        //     value:"14",
        //     label:"14"
        // },{
        //     value:"21",
        //     label:"21"
        // },{
        //     value:"28",
        //     label:"28"
        // });

        items.push({
            value:"1",
            label:"Week 1"
        },{
            value:"2",
            label:"Week 2"
        },{
            value:"3",
            label:"Week 3"
        },{
            value:"4",
            label:"Week 4"
        },{
            value:"5",
            label:"Week 5"
        });
        
        return items;
    }
    handleDieselWeekFrequence(diesel_week_freq){
        this.setState({diesel_week_freq})
    }
    
    handleDieselMonthFrequence(diesel_month_freq){
        this.setState({diesel_month_freq})
    }
    handleDieselQuaterFrequence(diesel_quater_freq){
        this.setState({diesel_quater_freq})
    }
    handleDieselSemiFrequence(diesel_semiannual_freq){
        this.setState({diesel_semiannual_freq})
    }

    
    handleTollWeekFrequence(toll_week_freq){
        this.setState({toll_week_freq},()=>{console.log(this.state.toll_week_freq)})
    }
    
    handleTollMonthFrequence(toll_month_freq){
        this.setState({toll_month_freq})
    }
    handleTollQuaterFrequence(toll_quater_freq){
        this.setState({toll_quater_freq})
    }
    handleTollSemiFrequence(toll_semiannual_freq){
        this.setState({toll_semiannual_freq})
    }

    fetchGSTptions(){
        var items = [];
        items.push({
            value:"",
            label:"Select"
        });
        items.push({
            value:"FCM",
            label:"Forward Charge Mechanism (FCM)"
        },{
            value:"RCM",
            label:"Reverse Charge Mechanism (RCM)"
        },{
            value:"both",
            label:"Both"
        });
        return items;
    }
    handleGSTChange(lsp_gst){
        this.setState({
            lsp_gst
        })
    }
    fetchMultipleOptions(){
        var items = [];
        items.push({
            value:"",
            label:"Select"
        });
        items.push({
            value:"yes",
            label:"YES"
        },{
            value:"no",
            label:"NO"
        });
        return items;
    }
    handleGSTMultiChange(gst_multi){
        this.setState({
            gst_multi
        })
    }
    render(){
		
        return (
            <div className="container-fluid">
				<div className="d-sm-flex align-items-center justify-content-between mb-4">
					<h1 className="h3 mb-0 text-gray-800">Billing Module Configurations</h1>
				</div>
				<div className="row">

					<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="card-body">
                                <form className="user" onSubmit={this.onSubmitConfigurations.bind(this)}>
                                    
                                    <div className="row form-group border-bottom no-gutters align-items-center">
                                        <div className="col-5">
                                            <label>Fetch Trips data from *</label>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group">
                                                <Select 
                                                options={this.fetchOptions()} 
                                                value={this.state.fetchFrom}
                                                onChange={this.handleChange.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={" "+(this.state.showApiInp)}>
                                        <div className={"row form-group border-bottom  no-gutters align-items-center mt-4 "}>
                                            <div className={"col-3 "}>
                                                <label>Enter API URL *</label>
                                            </div>
                                            <div className={"col-3 "}>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="apiURL" value={this.state.apiURL} onChange={this.changeInputHandler.bind(this)} placeholder="Ex: https://www.domain.com/api/v1/demolist" />
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row form-group no-gutters align-items-center mt-4">
                                        <div className="col-12">
                                            <h4>Transporter Settings</h4>
                                            <div className="row form-group border-bottom  no-gutters align-items-center mt-4">
                                                <div className="col-5">
                                                    <label>POD document upload required *</label>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <Select 
                                                        options={this.fetchPODOptions()} 
                                                        value={this.state.transporter_podUpload}
                                                        onChange={this.handlePODChange.bind(this)}
                                                        />
                                                    </div>
                                                
                                                </div>
                                            </div>
                                            
                                            <div className="row form-group border-bottom no-gutters align-items-center">
                                                <div className="col-5">
                                                    <label>Document sharing through *</label>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <Select 
                                                        options={this.fetchPODTypeOptions()} 
                                                        value={this.state.transporter_podType}
                                                        onChange={this.handlePODTypeChange.bind(this)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row form-group border-bottom no-gutters align-items-center">
                                                <div className="col-5">
                                                    <label>Transpoter GST configuration *</label>
                                                </div>
                                                <div className="col-2">
                                                    <Select 
                                                    options={this.fetchGSTptions()} 
                                                    value={this.state.lsp_gst}
                                                    onChange={this.handleGSTChange.bind(this)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-group border-bottom no-gutters align-items-center">
                                                <div className="col-5">
                                                    <label>Are transporter allowed multiple GST selections *</label>
                                                </div>
                                                <div className="col-2">
                                                    <Select 
                                                    options={this.fetchMultipleOptions()} 
                                                    value={this.state.gst_multi}
                                                    onChange={this.handleGSTMultiChange.bind(this)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-group border-bottom no-gutters align-items-center">
                                                <div className="col-5">
                                                    <label><b>Deviation work flow *</b></label>
                                                </div>
                                                <div className="col-2">
                                                    
                                                </div>
                                            </div>
                                            <div className="row form-group border-bottom  no-gutters align-items-center mt-4">
                                                <div className="col-5">
                                                    <label>i. Is transporter allowed to update any other costs over and above the base freight? *</label>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                    <Select 
                                                        options={this.fetchPODOptions()} 
                                                        value={this.state.otherCosts}
                                                        onChange={this.handleOtherCostChange.bind(this)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row form-group border-bottom  no-gutters align-items-center mt-4">
                                                <div className="col-5">
                                                    <label>ii. Is transporter allowed request for any cost waivers (Penalities etc.,) ? *</label>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                    <Select 
                                                        options={this.fetchPODOptions()} 
                                                        value={this.state.penality}
                                                        onChange={this.handlePenalityChange.bind(this)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
{/* 
                                            <pre>
                                                
Freight revision frequency? <br />
Diesel frequency								Daily/Weekly/FortNightly/Monthly/Quaterly/Semi-Anually <br />
<br />

Select the day for revisions					if Daily option (Every Day)<br />
												weekly === 1st, 7th 14th 21th 28th<br />
												FortNightly  ===   1st , 16th (Default)<br />
												Monthly  =====    Days(Dropdown) of month<br />
												Quaterly =====     Days(Dropdwon)<br />
												Semi-Anually  ===== Days(dropdown)<br />
												<br />
												
Toll Frequency									Daily/Weekly/FortNightly/Monthly/Quaterly/Semi-Anually <br />
<br />
Select the day for revisions					if Daily option (Every Day)<br />
												weekly === 1st, 7th 14th 21th 28th<br />
												FortNightly  ===   1st , 16th (Default)<br />
												Monthly  =====    Days(Dropdown) of month<br />
												Quaterly =====     Days(Dropdwon)<br />
												Semi-Anually  ===== Days(dropdown)
												
												
                                            </pre> */}
                                        </div>
                                        <div className="col-12">
                                            <h4>Customer Settings</h4>
                                            <div className="row form-group border-bottom  no-gutters align-items-center mt-4">
                                                <div className="col-5">
                                                    <label>POD document upload required *</label>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <Select 
                                                        options={this.fetchPODOptions()} 
                                                        value={this.state.customer_podUpload}
                                                        onChange={this.handleCustomerPODChange.bind(this)}
                                                        />
                                                    </div>
                                                
                                                </div>
                                            </div>
                                            
                                            <div className="row form-group border-bottom no-gutters align-items-center">
                                                <div className="col-5">
                                                    <label>Document sharing through *</label>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <Select 
                                                        options={this.fetchPODTypeOptions()} 
                                                        value={this.state.customer_podType}
                                                        onChange={this.handleCustomerPODTypeChange.bind(this)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                   
                                    <div className="row form-group border-bottom  no-gutters align-items-center mt-4">
                                        
                                        <div className="col-5">
                                            <h4>Freight Rates Settings</h4>
                                        </div>
                                    </div>
                                    <div className="row form-group border-bottom  no-gutters align-items-center mt-4">
                                     
                                        <div className="col-5">
                                            <label>Do you want to consider change in diesel price for frieght rate revisions *</label>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group">
                                            <Select 
                                                options={this.fetchDieselOptions()} 
                                                value={this.state.is_diesel_price_revision}
                                                onChange={this.handleDieselChange.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row form-group no-gutters align-items-center mt-4 "+this.state.deiselinp}>
                                    
                                        <div className={"col-12 "+this.state.deiselinp}>
                                        </div>
                                    </div>
                                    <div className={"row form-group border-bottom  no-gutters align-items-center mt-4 "+this.state.deiselinp}>
                                        <div className="row form-group border-bottom  no-gutters align-items-center mt-4">
                                            <div className="col-5">
                                                <label>i. Diesel price source *</label>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group">
                                                <Select 
                                                    options={this.fetchDieselWebsites()} 
                                                    value={this.state.diesel_website}
                                                    onChange={this.handleDieselWebsite.bind(this)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"row "}>
                                            <div className={"col-5 "+this.state.deiselinp}>
                                                ii. Please choose diesel price change calculation method : 
                                            </div>
                                            <div className={"col-7 "+this.state.deiselinp}>
                                                
                                                <div className="form-group">
                                                    <input type="radio" name="diesel_formula" value={this.state.formula1} checked={this.state.formulachk1} onChange={this.changeRadioHandler.bind(this, 1)} /> (Distance * (latest Diesel Price - Base Diesel Price)) / Mileage
                                                </div>
                                            
                                                <div className="form-group">
                                                    <input type="radio" name="diesel_formula" value={this.state.formula2} checked={this.state.formulachk2} onChange={this.changeRadioHandler.bind(this, 2)} /> Diesel impact as a percentage of Base Freight Rate * Base Freight Rate * ((Latest Diesel Price - Base Diesel Price) / Base Diesel Price)
                                                </div>
                                                <div className={"form-group "+(this.state.formula2inp)}>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <input type="text" className="form-control" placeholder='Enter the Diesel Impact as percentage of base freight' name="diesel_impact_percent" value={this.state.diesel_impact_percent}  onChange={this.changeInputHandler.bind(this)} /> 
                                                        </div>
                                                        <div className="col-4">
                                                            %
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row form-group border-bottom  no-gutters align-items-center mt-4 "+this.state.deiselinp}>
                                        <div className={"row "}>
                                            <div className={"col-5 "+this.state.deiselinp}>
                                                iii. Please choose methodology for considering diesel prices : 
                                            </div>
                                            <div className={"col-7 "+this.state.deiselinp}>
                                                
                                                <div className="form-group">
                                                    <input type="radio" name="diesel_price_capture" value={this.state.captureprice1} checked={this.state.capturepricechk1} onChange={this.changeRadioCaptureHandler.bind(this, 1)} /> Change freight rate only if the diesel price is changed by <b style={{fontSize:"20px"}}>&plusmn;x %</b>

                                                </div>
                                                <div className={"form-group "+(this.state.capture1inp)}>
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <input type="text" className="form-control" placeholder='Enter the value of x' name="diesel_price_x" value={this.state.diesel_price_x}  onChange={this.changeInputHandler.bind(this)} /> 
                                                        </div>
                                                        
                                                        <div className="col-4">
                                                            %
                                                        </div>
                                                            
                                                    </div>
                                                    {/* <div className="row mt-4">
                                                        <div className="col-4">
                                                            Applicable diesel prices from 
                                                        </div>
                                                        <div className="col-2">
                                                            <Select
                                                                value={this.state.applicable_diesel_price_from}
                                                                onChange={this.handleApplicableFrom.bind(this)}
                                                                options={this.dayOptions()}
                                                            />
                                                        </div>
                                                        <div className="col-2">
                                                            of month
                                                        </div>
                                                        
                                                    </div>
                                                     */}
                                                </div>
                                            
                                                <div className="form-group">
                                                    <input type="radio" name="diesel_price_capture" value={this.state.captureprice2} checked={this.state.capturepricechk2} onChange={this.changeRadioCaptureHandler.bind(this, 2)} />  Change freight rate basis daily diesel price averages. <br />
                                                    For nth month diesel price change following dates to be considered for diesel price average.
                                                    <div className={" "+(this.state.capture2inp)}>
                                                    <table width="100%" border="1" className='mt-4' cellPadding={"10"}>
                                                        <tr>
                                                            <th style={{textAlign:"center"}} colspan="2">From</th>
                                                            <th style={{textAlign:"center"}} colspan="2">To</th>
                                                        </tr>
                                                        <tr>
                                                            <td style={{textAlign:"center"}}>Day</td>
                                                            <td style={{textAlign:"center"}}>Month</td>
                                                            <td style={{textAlign:"center"}}>Day</td>
                                                            <td style={{textAlign:"center"}}>Month</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Select
                                                                    value={this.state.capture_diesel_price_from_day}
                                                                    onChange={this.handleCaptureDieselPriceFromday.bind(this)}
                                                                    options={this.dayOptions()}
                                                                />
                                                            </td>
                                                            <td>
                                                            <Select
                                                                        value={this.state.capture_diesel_price_from_avg}
                                                                        onChange={this.handleCaptureDieselPriceFromAvg.bind(this)}
                                                                        options={this.avgOptions()}
                                                                    />
                                                            </td>
                                                            <td>
                                                                <Select
                                                                    value={this.state.capture_diesel_price_to_day}
                                                                    onChange={this.handleCaptureDieselPriceToday.bind(this)}
                                                                    options={this.dayOptions()}
                                                                />
                                                            </td>
                                                            <td>
                                                            <Select
                                                                        value={this.state.capture_diesel_price_to_avg}
                                                                        onChange={this.handleCaptureDieselPriceToAvg.bind(this)}
                                                                        options={this.avgOptions()}
                                                                    />
                                                            </td>
                                                        </tr>
                                                      
                                                    </table>

                                                    </div>
                                                </div>
                                                
                                                <div className="form-group">
                                                
                                                </div>

                                            
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row form-group border-bottom  no-gutters align-items-center mt-4">
                                        <div className="col-5">
                                            <label>Do you want to consider change in toll price for frieght rate revisions *</label>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group">
                                            <Select 
                                                options={this.fetchTollOptions()} 
                                                value={this.state.is_toll_price_revision}
                                                onChange={this.handleTollChange.bind(this)}
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="row form-group border-bottom no-gutters align-items-center">
                                                <div className="col-5">
                                                    <label>Freight revision frequency? *</label>
                                                </div>
                                                <div className="col-7">
                                                    <div className="row form-group">
                                                        <div className="col-6">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <label>Freight Frequency:</label>
                                                                </div>
                                                                <div className="col-5">
                                                                    <Select 
                                                                    options={this.fetchDieselFrequncyOptions()} 
                                                                    value={this.state.diesel_frequence_option}
                                                                    onChange={this.handleDieselFrequence.bind(this)}
                                                                    />
                                                                </div>
                                                            </div>
                                                              
                                                            <div className={"row "+(this.state.dFrqDaily)} style={{marginTop:"10px"}}>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <label>Select the day for revisions::</label>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        EveryDay
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                            <div className={"row "+(this.state.dFrqFortNightly)} style={{marginTop:"10px"}}>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <label>Select the day for revisions::</label>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        1st and 16th
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div className={"row "+(this.state.dFrqWeek)} style={{marginTop:"10px"}}>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <label>Select the day for revisions::</label>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <Select 
                                                                        isMulti={true}
                                                                        options={this.fetchWeeklyDaysOptions()} 
                                                                        value={this.state.diesel_week_freq}
                                                                        onChange={this.handleDieselWeekFrequence.bind(this)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"row "+(this.state.dFrqMonth)} style={{marginTop:"10px"}}>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <label>Select the day for revisions::</label>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <Select 
                                                                        options={this.dayOptions()} 
                                                                        value={this.state.diesel_month_freq}
                                                                        onChange={this.handleDieselMonthFrequence.bind(this)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"row "+(this.state.dFrqQuater)} style={{marginTop:"10px"}}>
                                                                <div className="row">
                                                                   <div className="col-6">
                                                                        <label>Select the day for revisions::</label>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <Select 
                                                                        options={this.dayOptions()} 
                                                                        value={this.state.diesel_quater_freq}
                                                                        onChange={this.handleDieselQuaterFrequence.bind(this)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className={"row "+(this.state.dFrqSemiAnnual)} style={{marginTop:"10px"}}>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <label>Select the day for revisions::</label>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <Select 
                                                                        options={this.dayOptions()} 
                                                                        value={this.state.diesel_semiannual_freq}
                                                                        onChange={this.handleDieselSemiFrequence.bind(this)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                  
                                                        {/* <div className="col-6">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <label>Toll Frequency:</label>
                                                                </div>
                                                                <div className="col-5">
                                                                    <Select 
                                                                    options={this.fetchDieselFrequncyOptions()} 
                                                                    value={this.state.toll_frequence_option}
                                                                    onChange={this.handleTollFrequence.bind(this)}
                                                                    />
                                                                </div>
                                                            </div>
                                                               
                                                            <div className={"row "+(this.state.tFrqDaily)}>
                                                                <div className="col-6">
                                                                    <label>Select the day for revisions::</label>
                                                                </div>
                                                                <div className="col-5">
                                                                    EveryDay
                                                                </div>
                                                            </div> 
                                                            <div className={"row "+(this.state.tFrqFortNightly)}>
                                                                <div className="col-6">
                                                                    <label>Select the day for revisions::</label>
                                                                </div>
                                                                <div className="col-5">
                                                                    1st and 16th
                                                                </div>
                                                            </div>
                                                            <div className={"row "+(this.state.tFrqWeek)}>
                                                                <div className="col-6">
                                                                    <label>Select the day for revisions::</label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <Select 
                                                                    isMulti={true}
                                                                    options={this.fetchWeeklyDaysOptions()} 
                                                                    value={this.state.toll_week_freq}
                                                                    onChange={this.handleTollWeekFrequence.bind(this)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={"row "+(this.state.tFrqMonth)}>
                                                                <div className="col-6">
                                                                    <label>Select the day for revisions::</label>
                                                                </div>
                                                                <div className="col-5">
                                                                    <Select 
                                                                    options={this.dayOptions()} 
                                                                    value={this.state.toll_month_freq}
                                                                    onChange={this.handleTollMonthFrequence.bind(this)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={"row "+(this.state.tFrqQuater)}>
                                                                <div className="col-6">
                                                                    <label>Select the day for revisions::</label>
                                                                </div>
                                                                <div className="col-5">
                                                                    <Select 
                                                                    options={this.dayOptions()} 
                                                                    value={this.state.toll_quater_freq}
                                                                    onChange={this.handleTollQuaterFrequence.bind(this)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className={"row "+(this.state.tFrqSemiAnnual)}>
                                                                <div className="col-6">
                                                                    <label>Select the day for revisions::</label>
                                                                </div>
                                                                <div className="col-5">
                                                                    <Select 
                                                                    options={this.dayOptions()} 
                                                                    value={this.state.toll_semiannual_freq}
                                                                    onChange={this.handleTollSemiFrequence.bind(this)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                    
                                    <div className="row form-group border-bottom  no-gutters align-items-center mt-4">
                                        <div className="col-5">
                                            <label>Do you want the freight rates to be approved automatically ? *</label>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group">
                                            <Select 
                                                options={this.fetchApprovalsOptions()} 
                                                value={this.state.is_freight_rate_approve}
                                                onChange={this.handleApprovalsChange.bind(this)}
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                    <div className={"form-group border-bottom  no-gutters align-items-center mt-4 "+(this.state.showlevels) }>
                                        <div className="row">
                                            <div className="col-5">
                                                <label>Please select the number of level for approvals *</label>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder='Enter value' name="nooflevels" value={this.state.nooflevels} onChange={this.onChangeHandlerNumber.bind(this)} />
                                                </div>
                                            </div>   
                                        </div>  
                                        <div className="" id="apprvLevls">
                                               
                                        </div>                                     
                                    </div>


                                    {/* <div className="row form-group border-bottom  no-gutters align-items-center mt-4">
                                        <div className="col-6">
                                            <label>Diesel Cost per kms *</label>
                                        </div>
                                        <div className="col-6"></div>
                                        <div className="col-6">
                                            <label>Is diesel cost fixed *</label>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                            <Select 
                                                options={this.fetchDieselOptions()} 
                                                value={this.state.is_diesel_fixed}
                                                onChange={this.handleDieselChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={this.state.deiselinp}>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <input type="text" style={{width:"100px"}} className="form-control" name="dieselCost" value={this.state.dieselCost} onChange={this.changeInputHandler.bind(this)} />
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div> */}
                                    
                                    {/* <div className="row form-group border-bottom  no-gutters align-items-center mt-4">
                                        <div className="col-6">
                                            <label>Road Cost *</label>
                                        </div>
                                        <div className="col-6"></div>
                                        <div className="col-6">
                                            <label>Is road cost fixed *</label>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                            <Select 
                                                options={this.fetchRoadOptions()} 
                                                value={this.state.is_road_fixed}
                                                onChange={this.handleRoadChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={" "+this.state.roadinp}>
                                            <div className="col-12">
                                                <div className="form-group">
                                                <input type="text" style={{width:"100px"}} className="form-control" name="roadCost" value={this.state.roadCost} onChange={this.changeInputHandler.bind(this)} />
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row form-group border-bottom  no-gutters align-items-center mt-4">
                                        <div className="col-6">
                                            <label>Driver Cost *</label>
                                        </div>
                                        <div className="col-6"></div>
                                        <div className="col-6">
                                            <label>Is driver cost fixed *</label>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                            <Select 
                                                options={this.fetchDriverOptions()} 
                                                value={this.state.is_driver_fixed}
                                                onChange={this.handleDriverChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={" "+this.state.driverinp}>
                                            <div className="col-2">
                                                <div className="form-group">
                                                <input type="text" style={{width:"100px"}} className="form-control" name="driverCost" value={this.state.driverCost} onChange={this.changeInputHandler.bind(this)} />
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row form-group border-bottom  no-gutters align-items-center mt-4">
                                        <div className="col-6">
                                            <label>Toll Cost *</label>
                                        </div>
                                        <div className="col-6"></div>
                                        <div className="col-6">
                                            <label>Is toll cost fixed *</label>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                            <Select 
                                                options={this.fetchTollOptions()} 
                                                value={this.state.is_toll_fixed}
                                                onChange={this.handleTollChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={this.state.tollinp}>
                                            <div className="col-2">
                                                <div className="form-group">
                                                <input type="text" style={{width:"100px"}} className="form-control" name="tollCost" value={this.state.tollCost} onChange={this.changeInputHandler.bind(this)} />
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div> */}
                                    
                                    <div className="row form-group no-gutters align-items-center mt-4">
                                        <div className="col-12">
                                            <div className="form-group text-center">
                                                <button type="submit" className="btn btn-info">Save &amp; Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
							</div>
						</div>
					</div>
					
				</div>
                    

			</div>
        );
    }
}
function redirectToAPIConfigurations()
{
    window.location.href="/apisetup";
}
function redirectToUploadTrips(){
    window.location.href="/upload-trips";
}