/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CSVFileValidator from 'csv-file-validator';
import more from 'material-ui/svg-icons/notification/more';
import $ from 'jquery';
//import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var redirectURL = require('../redirectURL');
var moment = require('moment');

export default class TransportersCodeMaster extends Component {
    state = {
        show: false,
        basicTitle: '',
        basicType: "default",
        loadshow: "show-n",
        overly: "show-n",
        rowData: [],
        pivotmodeis: false,
        pivotcols: [],
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true

        },
        frameworkComponents: {
        },
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        csvcontent: [],
        file: '',
        slider: 'show-n',
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = () => {
        redirectURL.post("/masters/getTransportersMaster", {},{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
              }
          }).then(res => {
            let respData = res.data
            // console.log("respData",respData)
            this.setState({
                rowData: respData.transporterMasters,
            })
            // console.log("resData", respData)
        })
    }
    closeAlert = () => {
        $("#uploadFile").val("")
        this.setState({
            show: false,
            basicType: "",
            basicTitle: ""
        })
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    changeTptFileHandler = async (e) => {
        if(e.target.files.length !== 0){

            const config = {
                headers: [
                    {
                        name: 'transporter_code',
                        inputName: 'transporter_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'transporter_name',
                        inputName: 'transporter_name',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    // {
                    //     name: 'shipping_type',
                    //     inputName: 'shipping_type',
                    //     required: true,
                    //     requiredError: function (headerName, rowNumber, columnNumber) {
                    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    //     }
                    // },
                                     
                    {
                        name: 'transporter_contact_no',
                        inputName: 'transporter_contact_no',
                        required: false,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },                   
                    {
                        name: 'transporter_email',
                        inputName: 'transporter_email',
                        required: false,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },            
                    {
                        name: 'transporter_secondary_contact_no',
                        inputName: 'transporter_secondary_contact_no',
                        required: false,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },                   
                    {
                        name: 'transporter_secondary_email',
                        inputName: 'transporter_secondary_email',
                        required: false,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                  
                ]
            }
            const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9 -.@/(),#_&]*$/); // define allowed characters using regex
            const validEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            const validateEmail = new RegExp(/^(^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/);
            const validMobile = new RegExp(/^[6789] \d{9}$/);
            const validText = new RegExp(/^[a-zA-Z .@-_&/()]*$/);
            
            var data = CSVFileValidator(e.target.files[0], config)
                .then((csvData) => {
                    console.log('csvData', csvData)
                    let keys = Object.keys(csvData.data[0])
                    let items = ['transporter_name','transporter_code','transporter_contact_no','transporter_email','transporter_secondary_contact_no','transporter_secondary_email']
                    let check = false
                    if(csvData.inValidMessages.length === 0)
                    {
                        for (let i in keys){
                            // console.log("items.includes(keys[i]) ", items.includes(keys[i]))
                            if(items.includes(keys[i])){                 
                                check = true             
                            }else{
                                check = false
                                break
                            }
                        }
                    }
                    else{
                        check = false
                        
                    }
                    var dateemailMes = 1;
                    var mobMes = 1;
                    var textMes = 1;
                    let csvaluesarr = Object.values(csvData.data)
                    // console.log("csvalues", csvaluesarr)
                    if(csvaluesarr.length > 0)
                    {
                        for(var a=0;a<csvaluesarr.length; a++)
                        {
                            var csvalues = Object.values(csvaluesarr[a])
                            for(var c in csvalues)
                            {
                                    console.log("c",c)
                             
                                     console.log("csvalues value ",c+"====="+aplhanumeichypen.test(csvalues[c]))
                                    if(aplhanumeichypen.test(csvalues[c]) === false)
                                    {
                                        check = false;
                                        break;
                                    }
                                
                            }
                        }
                    }
                    // console.log("check", check)
                    if (check === false) {
                        $("#uploadFile").val("")
                      
                            this.setState({
                                show: true,
                                basicTitle: 'dont upload other fields or special characters',
                                basicType: 'danger'
                            });
                       
                    } else {
                        this.setState({
                            csvcontent: csvData.data
                        });
                    }
                })
                .catch(err => { })
            // console.log("e.target.files[0]",e.target.result);
            var contentList = []
    
            var out = new Promise(function (reject, resolve) {
                var reader = new FileReader();
                reader.onload = async function (e) {
                    var contents = await e.target.result;
                    // document.getElementById("appednCSVData").innerHTML = contents
                    resolve(contents);
                };
                var tt = reader.readAsText(e.target.files[0]);
            });
            // console.log(contentList);
            this.setState({
                file: e.target.files[0]
            });
            // console.log("e.target.files[0].type ", e.target.files[0]);
    
            if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))
    
            }
            else {
                e.target.value = null;
                this.setState({
                    uploadFile: '',
                    file: "",
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }
    }

    uploadBulkFormHandler = e => {
        e.preventDefault()
        const { csvcontent } = this.state
        // console.log("csvcontent", csvcontent)
        if(csvcontent.length >0){
            redirectURL.post("/masters/uploadTransportersMasterData", csvcontent,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                }
            }).then(res => {
                // console.log("response", res)
                // console.log("response", res.data.message)
                if (res.data.message === 'success') {
                    this.setState({
                        slider: "show-n",
                        csvcontent: [],
                        file: '',
                        overly: "show-n",
                        loadshow: 'show-n',
                        basicTitle: "Success.",
                        basicType: "success",
                        show: true
                    })
                    $("#uploadFile").val("")
                    // document.getElementById("appednCSVData").innerHTML = ''
                }
                setTimeout(() =>{
                    this.loadData()
                }, 1000)
            })
        }
        else{
            this.setState({
                overly: "show-n",
                loadshow: 'show-n',
                basicTitle: "cant insert empty data",
                basicType: "warning",
                show: true
            })
        }

    }

    bulkUploadTptMaster = () => {
        this.setState({
            slider: 'slider-translate-40p',
            overly: "show-m",
            loadshow: 'show-n'
        })
    }
    resetUpload = () => {
        $("#uploadFile").val("")
        // document.getElementById("appednCSVData").innerHTML = ''
        // this.setState({
        //     slider: 'show-n',
        //     overly: "show-n",
        //     loadshow: 'show-n'
        // })
    }
    onCloseSliderDiv = () => {
        $("#uploadFile").val("")
        // document.getElementById("appednCSVData").innerHTML = ''
        this.setState({
            slider: 'show-n',
            overly: "show-n",
            loadshow: 'show-n'
        })
    }

    onClickHideAll = () => {
        $("#uploadFile").val("")
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            slider: "",
        });
    }

    onCellClicked = (e) => {
        if (e.colDef.field == "delete") {
            var rowId = e.data._id;
            // console.log("delete",e.data);
            var qry = {
                rowId: rowId,
                collection : 'master_transporter_code',
                email : localStorage.getItem('email')
            }
            //var confirm = confirm("Are You Sure! Do you want really delete?");
            if (window.confirm("Are You Sure! Do you want really delete?")) {
                redirectURL.post("/masters/deleteRowData", qry,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  }).then((response) => {
                    // console.log("response",response);
                    if (response.data.status == "success") {
                        this.setState({
                            basicTitle: "Deleted Successfully.",
                            basicType: "success",
                            show: true
                        })
                        //window.location.reload();
                        this.loadData()
                    }
                    else {
                        this.setState({
                            basicTitle: "Failed to Delete.",
                            basicType: "warning",
                            show: true
                        })
                    }
                }).catch((e) => {
                    console.log(e);
                })
            }
        }
    }

    render() {
        const { show, basicType, basicTitle, rowData, modules, defaultColDef, statusBar, slider, loadshow, overly, csvcontent } = this.state
        const transportersColumnDefs = [
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 130,
                filter: "agSetColumnFilter",
                resizable: true,
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 150,
                filter: "agSetColumnFilter",
                resizable: true,
            },
            // {
            //     headerName: "Shipping Type",
            //     field: "shipping_type",
            //     width: 130,
            //     filter: "agSetColumnFilter",
            //     resizable: true,
            // },         
            // {
            //     headerName: "Status",
            //     field: "status",
            //     width: 130,
            //     filter: "agSetColumnFilter",
            //     resizable: true,
            // },  
            {
                headerName: "Transporter Contact no",
                field: "transporter_contact_no",
                width: 130,
                filter: "agSetColumnFilter",
                resizable: true,
            },
            {
                headerName: "Transporter Email",
                field: "transporter_email",
                width: 130,
                filter: "agSetColumnFilter",
                resizable: true,
                // valueGetter: param => {
                //     if (!["", NaN, undefined, null].includes(param.data.state)) return param.data.state
                // }
            },
            
            {
                headerName: "Secondary Contact no",
                field: "transporter_secondary_contact_no",
                width: 130,
                filter: "agSetColumnFilter",
                resizable: true,
            },
            {
                headerName: "Secondary  Email",
                field: "transporter_secondary_email",
                width: 130,
                filter: "agSetColumnFilter",
                resizable: true,
                // valueGetter: param => {
                //     if (!["", NaN, undefined, null].includes(param.data.state)) return param.data.state
                // }
            },
            
          
            // {
            //     headerName: "Remarks",
            //     field: "remarks",
            //     width: 130,
            //     filter: "agSetColumnFilter",
            //     resizable: true,
            //     valueGetter: param => {
            //         if (!["", NaN, undefined, null].includes(param.data.remarks)) return param.data.remarks
            //     }
            // },
            {
                pinned: "left",
                headerName: "",
                field: "delete",
                width: 50,
                resizable: true,
                editable: false,
                cellRenderer: params => {
                    return '<i class="icofont icofont-ui-delete"></i>';
                },
                filter: false,
            }
        ]

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className='row'>
                    <div className="card col-xl-12 col-lg-12">
                        <div className="card-header" style={{ padding: '10px' }}>
                            <h4>Transporters Master</h4>
                            <div className="row">
                                <div className="col-sm-12">
                                    <button className="float-right btn btn-warning" onClick={this.bulkUploadTptMaster}>
                                        Bulk Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="height-equal equal-height-lg" style={{ padding: '10px' }}>
                            <div className="card-body" style={{ padding: '10px' }}>
                                <div id="myGrid" style={{ height: "400px" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={modules}
                                        columnDefs={transportersColumnDefs}
                                        defaultColDef={defaultColDef}
                                        rowData={rowData}
                                        enableCharts={false}
                                        enableRangeSelection={true}
                                        statusBar={statusBar}
                                        pagination={true}
                                        groupHeaderHeight={40}
                                        headerHeight={40}
                                        floatingFiltersHeight={50}
                                        pivotGroupHeaderHeight={30}
                                        pivotHeaderHeight={50}
                                        rowHeight={40}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        onCellClicked={this.onCellClicked}
                                    //sideBar={sideBar}
                                    // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                    />
                                </div>

                            </div>

                        </div>
                        <div className={"dataLoadpage " + (this.state.loadshow)}>
                        </div>
                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>


                    </div>
                    {/* tpt bulk upload  */}
                    <div className={"slide-r " + (slider)} style={{ overflow: "hidden" }}>
                        <h3 className="subH">Bulk Upload Transporters Data
                            <span
                                className="float-right closebtn"
                                style={{ marginRight: "25px" }}
                                onClick={this.onCloseSliderDiv}
                            >
                                X
                            </span>
                        </h3>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="container-fluid">
                                <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                                    <div className="form-group mt-20p">
                                        <label className="">Upload File</label>
                                        <input type="file" id="uploadFile" name="uploadFile" onChange={this.changeTptFileHandler} className="form-control" required />
                                            <div style={{color:"#ff0000",marginTop:"10px"}}>
                                                ** Allowed Space and Special Characters are <span style={{fontWeight:"700", fontSize:"18px", fontFamily:"arial"}}>-._,&#@   </span>
                                            </div>
                                    </div>
                                    {/* <div className='p-2' id="appednCSVData"></div> */}
                                    <div className="form-group col-sm-12 mb-20p">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" className="btn btn-default" onClick={this.resetUpload}>Cancel</button>
                                    </div>
                                </form>
                                <div className="form-group">
                                    <a className="btn btn-primary" href={require('../../assets/json/bulk_master_transporter.csv')} target="_blank">Sample Template</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}