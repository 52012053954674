
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var propsData = "";
 
const DeliverConsignment = (props) => {
    //console.log("props ", props)
    propsData = props.data.tpt_delivery_date;
    const handleOverspeedClick = (e) => {

        e.stopPropagation();
        props.context.componentParent.onClickShowDeliverPopup(props);
    };
    var colr='gr-check';
    //console.log("rail_consignment", props.data.rail_consignment);
    if(props.data.rail_consignment == 0 || props.data.rail_consignment == undefined)
    {
        var flag = 1;
    }
    else
    {
        if(props.data.rail_consignment == 1)
        {
            if(props.data.last_mile_truck_no != '' && typeof props.data.last_mile_truck_no != 'undefined')
            {
                var flag=1;
            }
            else{
                var flag=0;
            }
        }
        else
        {
            var flag=0;
        }
    }
    

    return (
        <div>
            {propsData != undefined? 
            <input type="checkbox" value="1" id="handlecheck" checked />
            :
            (flag == 1)?
            <button onClick={handleOverspeedClick} className={"custom-btn f12 btn btn-default "+(colr)} title="Delivered" style={{}}>&nbsp;</button>
                :""
        }
            
        </div>
    );
};

export default DeliverConsignment;
