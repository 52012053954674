/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, {Component} from 'react';
import Select from 'react-select';
// import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
// import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
// import 'ag-grid-enterprise';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import redirectURL from '../redirectURL';
import Constants from "../constants";
import {getHyphenDDMMMYYYYHHMM} from "../common/utils";
// import icn from "../../img/undraw_profile.svg";
import $ from 'jquery';
import 'jquery-ui';
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
// import withRouter from './withRouter';
import ViewDoc from "./viewdocaction";
import DownloadDoc from "./downloadDocaction";


class FreightClosedDocuments extends Component {
    constructor(props){
        super(props);
        // console.log(props)
        this.state={
            modules:AllModules,
            isuploadcsv:0,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                sortable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
			},
            frameworkComponents:{
                ViewDoc:ViewDoc,
                DownloadDoc:DownloadDoc
            },
			detailCellRendererParams : {
				detailGridOptions: {
				  columnDefs: [
					{ field: 'callId' },
					{ field: 'direction' },
					{ field: 'number', minWidth: 150 },
					{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
					{ field: 'switchCode', minWidth: 150 },
				  ],
				  defaultColDef: {
					flex: 1,
				  },
				},
				getDetailRowData: (params) => {
				  params.successCallback(params.data.callRecords);
				},
			},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            conf:"",            
            showUploadCSVForm:"show-n",
            slider:"",
            closedDocs:[],
            otherCosts:[]
        }
        
        this.loadApprovedDocuments = this.loadApprovedDocuments.bind(this);
    }
	componentDidMount = async () => {
        var currentURL = window.location.pathname;
        // console.log("currentURL ", currentURL)
        if(currentURL.includes("view-document") === true)
        {
            if(localStorage.getItem("email") != "" && localStorage.getItem("email") != undefined)
            {
                this.setState({
                    showUserApproveBtn:"show-m",
                    hideUserApproveTbl:"show-m"
                })
            }
        }
        redirectURL.post("/freightothercosts")
		.then(async (response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            this.setState({
                otherCosts:records
            })
        });
        await setTimeout(() => {
            redirectURL.post("/configurations")
            .then((response) => {
                // console.log("response ", response.data)
                var records = response.data.records;
                if(records.length > 0)
                {
                    this.setState({
                        conf:records[0]
                    })
                   
                    if(records[0].diesel_price_capture_val === "1")
                    {
                        var diesel_percent = records[0].diesel_price_x;
                    }
                    
                    if(records[0].diesel_price_capture_val === "2")
                    {
                        var diesel_percent = "";
                    }
                    // this.loadDieselPrices({diesel_percent:diesel_percent})
                    var params = {
                        document_no:this.props.params.document_no
                    }
                }
                // else{
                //     redirectToConfigurations()
                // }
            })
            
        },500)
        
        this.loadApprovedDocuments();
	}
   
    loadApprovedDocuments(){
        redirectURL.post("/docapprovals")
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records ", records)
            if(records.length > 0)
            {
                this.setState({
                    closedDocs:records
                })
            }
            this.props.context.onLoadTabClosedCount(records.length);
		})
    }
    
    
    render(){
        var otherCosts = this.state.otherCosts;
	
        var closedcolumnDefs=[
            {
                headerName:Constants.FREIGHT_APPROVALS_DOCUMENT_NO,
                field:"document_no"
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_REVISION_MONTH,
                field:"diesel_price_changed_date"
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_APPLICABLE_PERIOD_FROM,
                field:"diesel_frequence_from_period",
                valueGetter:function(params){
                    try{
                        if(params.data.diesel_frequence_from_period !== "" || params.data.diesel_frequence_from_period !== undefined)
                        {
                           return params.data.diesel_frequence_from_period;
                        }
                        else{
                            return "";
                        }
                        
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_APPLICABLE_PERIOD_TO,
                field:"diesel_frequence_from_period",
                valueGetter:function(params){
                    try{
                        if(params.data.diesel_frequence_to_period !== "" || params.data.diesel_frequence_to_period !== undefined)
                        {
                            return params.data.diesel_frequence_to_period;
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_STATUS,
                field:"approval_status",
                valueGetter:function(params){
                    try{
                        if(params.data.approval_status === 2 || params.data.approval_status === "2")
                        {
                            return "Approved";
                        }
                        
                        if(params.data.approval_status === 3 || params.data.approval_status === "3")
                        {
                            return "Rejected";
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_VIEW_DOC,
                field:"document_no",
                cellRendererSelector:function(params){
                    return {
                        component:"ViewDoc"
                    }
                }
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_DOWNLOAD_DOC,
                field:"document_no",
                cellRendererSelector:function(params){
                    return {
                        component:"DownloadDoc"
                    }
                }
            }
        ]
       
        return (
            	<div className={"row "} >
					<div className="col-xl-12 col-md-12 mb-4">
                        <div style={{width:"100%",height:"60vh"}} className="ag-theme-balham">
                        <AgGridReact 
		          		    modules={this.state.modules}
                            rowData={this.state.closedDocs} 
                            columnDefs={closedcolumnDefs}
                            defaultColDef ={this.state.defaultColDef }
                            enableRangeSelection={true}
                            enableCharts={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            masterDetail={true}
                            onGridReady={this.onGridReady}
                            sideBar={this.state.sideBar}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            
                        />
                        </div> 
					</div>					
                
                </div>
                
                
        );
    }
}
function redirectToConfigurations()
{
    window.location.href="/configurations"
}
// console.log("icn ", icn)
function pushNot(){
    // showNotification();
    
	// setInterval(function(){ showNotification(); }, 20000);
}


// function showNotification() {	
// 	if (!Notification) {
// 		$('body').append('<h4 style="color:red">*Browser does not support Web Notification</h4>');
// 		return;
// 	}
// 	if (Notification.permission !== "granted") {		
// 		Notification.requestPermission();
// 	} else {		
// 		$.ajax({
//             url:"http://localhost:5005/billing",
// 			type: "GET",
// 			success: function(data, textStatus, jqXHR) {
				
//                 var theurl = "http://localhost:3005/";
//                 var notifikasi = new Notification("Freight Rates", {
//                     icon: icn,
//                     body: "You are checking Freight Rates information.",
//                 });
//                 notifikasi.onclick = function () {
//                     window.open(theurl); 
//                     notifikasi.close();     
//                 };
//                 setTimeout(function(){
//                     notifikasi.close();
//                 }, 5000);
        
// 			},
// 			error: function(jqXHR, textStatus, errorThrown)	{}
// 		}); 
// 	}
// };
export default FreightClosedDocuments ;