/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
// import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
// import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import { getHyphenDDMMMYYYYHHMM } from "../common/utils";
// import { createStaticHandler } from '@remix-run/router';
import ViewDoc from "./viewdocument";
import withRouter from './withRouter';

class FreighLevelApprovals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isuploadcsv: 0,
            modules: AllModules,
            defaultColDef: {
                // flex: 1,
                minWidth: 100,
                filter: true,
                resizable: true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
                sortable: true
            },
            frameworkComponents: {
                ViewDoc: ViewDoc
            },
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: [
                        { field: 'callId' },
                        { field: 'direction' },
                        { field: 'number', minWidth: 150 },
                        { field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
                        { field: 'switchCode', minWidth: 150 },
                    ],
                    defaultColDef: {
                        flex: 1,
                    },
                },
                getDetailRowData: (params) => {
                    params.successCallback(params.data.callRecords);
                },
            },
            rowData: [],
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
        }
        this.loadFreightApprovals = this.loadFreightApprovals.bind(this);
    }
    componentDidMount() {
        redirectURL.post("/billing/configurations")
            .then((response) => {
                // console.log("response ", response.data)
                var records = response.data.records;
                if (records.length > 0) {
                    this.setState({
                        conf: records[0]
                    })
                    var params = {};
                    if (localStorage.getItem("email") != "" && localStorage.getItem("email") != undefined) {
                        params.email = localStorage.getItem("email");
                    }
                    this.loadFreightApprovals(params);
                }
                // else {
                //     redirectToConfigurations()
                // }
            })

    }
    loadFreightApprovals(params) {
        redirectURL.post("/billing/freightapprovals", params)
            .then((response) => {
                // console.log("response ", response.data)
                var records = response.data.records;
                var recordsarr = [];
                if (records.length > 0) {
                    records.map((item) => {
                        var aprstat = "Pending for Approvals";
                        var apprColor = "#7e022a";

                        if (item.approval_status === "2" || item.approval_status === 2) {
                            aprstat = "Approved";
                            apprColor = "#016e48";
                        }
                        else if (item.approval_status === "3" || item.approval_status === 3) {
                            aprstat = "Rejected";
                            apprColor = "#016e48";
                        }
                        else {
                            aprstat = "Pending for Approvals";
                            apprColor = "#7e022a";
                        }
                        // console.log("item.approval_status",item.approval_status)
                        if (item.changed_diesel_price != "") {
                            if (item.approval_status === "2" || item.approval_status === 2) {
                                var changed_diesel_price = item.changed_diesel_price;
                            }
                            else {
                                var changed_diesel_price = item.applicable_diesel_price;
                            }

                        }
                        else {
                            var changed_diesel_price = item.applicable_diesel_price
                        }
                        // console.log("changed_diesel_price ", changed_diesel_price)
                        recordsarr.push({
                            _id: item._id,
                            document_no: item.document_no,
                            approval_status: aprstat,
                            apprColor: apprColor,
                            applicable_diesel_price: changed_diesel_price
                        })
                    })
                }
                // console.log("recordsarr ", recordsarr)
                this.setState({
                    rowData: recordsarr
                })

            })
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

    };
    render() {
        var columnDefs = [
            {
                headerName: "",
                field: "document_no",
                width: 120,
                cellRendererSelector: function (params) {
                    return {
                        component: "ViewDoc"
                    }
                },
            },
            {
                headerName: Constants.FREIGHT_APPROVALS_DOCUMENT_NO,
                field: "document_no",
                width: 120,
            },
            {
                headerName: Constants.FREIGHT_APPROVALS_APPROVAL_STATUS,
                field: "approval_status",
                width: 160
            },
            {
                headerName: Constants.FREIGHT_APPROVALS_APPLICABLE_DIESEL_PRICE,
                field: "applicable_diesel_price",
                width: 180,
            }
        ]
        return (
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="col-xl-12 h3 mb-0 text-gray-800">Approvals

                    </h1>
                </div>
                <div className="row">

                    <div className="col-xl-12 col-md-12 mb-4">
                        <div className="card shadow h-100 py-2">
                            <div className="card-body">
                                <div style={{ width: "100%", height: "60vh" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        rowData={this.state.rowData}
                                        columnDefs={columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        enableRangeSelection={true}
                                        enableCharts={true}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        masterDetail={true}
                                        onGridReady={this.onGridReady}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        );
    }
}
function redirectToConfigurations() {
    window.location.href = "/configurations"
}

// export default withRouter(FreighLevelApprovals);
export default (FreighLevelApprovals);