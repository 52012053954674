import React,{useState, useRef, useEffect, useMemo} from 'react'

import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import moment from "moment";
import Select from 'react-select';
import EditCellRenderer from './EditCellRenderer';

var redirectURL = require('../redirectURL');


const DealerMaster = () => {
  const [sweetAlert, setSweetAlert] = useState({ show: false, basicType: "default", basicTitle: "", refetch: false });
  // const [columnDef, setColumnDef] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [defaultColDef, setdefaultColDef] = useState({
    sortable: true,
    filter: true,
    resizable: true,
    pivot: false,
    enableValue: true,
    enableRowGroup: true,
  });
  const [loader, setLoader] = useState(false);
  const [fyOptions, setFYOptions] = useState([]);
  const [selectFy, setSelectFY] = useState("");

  const [opDealer, setOpDealer] = useState({
    "customer" : "",
    "customer_heir" : "",
    "customer_name" : "",
    "customer_group_text" : "",
    "sales_group_desc" : "",
    "city" : "",
    "state" : "",
    "sac_type" : "",
    "ro_ao" : "",
    "zone" : "",
    // "active_status" : "",
    "latitude" : "",
    "longitude" : "",
    // "consignee_code" : "",
    // "address" : "",
    // "pincode" : ""
  });

  const [slider, setSlider] = useState(false);

  const gridApi = useRef(null);
  const gridColumnApi = useRef(null);

  const fetchData = ()=>{
    setLoader(true);
    redirectURL.post('/billing/getdealerMaster', {}).then(response => {
      setLoader(false);
      if(response.data && response.data.records && response.data.records.length>0){
        let records = response.data.records;
        setRowData(records);
      }
      else{
        setRowData([]);
      }
      console.log("test --", response.data);
    })
    .catch(error => {
      setRowData([]);
      setLoader(false);
      console.error('Error:', error.response || error.message);
    })
  }

  useEffect(()=>{
    fetchData();
  },[])

  const onSubmitHandler = async(e)=>{
    e.preventDefault();
  }


  const onGridReady = params => {
    gridApi.current = params.api;
    //console.log("Grid is: ", params);
    gridColumnApi.current = params.columnApi;
  };

  const onEditClick = (data)=>{
    // console.log("test --",data );
    setSlider(true);
    setOpDealer((prev)=>{
      return {
        ...prev,
        id: data._id,
        "customer" : data.customer,
        "customer_heir" : data.customer_heir,
        "customer_name" : data.customer_name,
        "customer_group_text" : data.customer_group_text,
        "sales_group_desc" : data.sales_group_desc,
        "city" : data.city,
        "state" : data.state,
        "sac_type" : data.sac_type,
        "ro_ao" : data["RO/ AO"],
        "zone" : data.zone,
        "latitude" : data.latitude,
        "longitude" : data.longitude,
       }
    })

  }

  const columnDef = useMemo(()=>{
    let temp_coldef = [
      {
        headerName: "",
        field: "edit",
        colId: "edit",
        width: 45,
        cellRenderer: "EditCellRenderer",
        cellRendererParams:{
          onClick: onEditClick
        }
      },
      {
        headerName: "Customer Code",
        field: "customer",
        colId: "customer",
        width: 100,
      },
      {
        headerName: "Customer Heir",
        field: "customer_heir",
        colId: "customer_heir",
        width: 125,
      },
      {
        headerName: "Customer Name",
        field: "customer_name",
        colId: "customer_name",
        width: 230,
      },
      {
        headerName: "Customer Group Text",
        field: "customer_group_text",
        colId: "customer_group_text",
        width: 160,
      },
      {
        headerName: "Sales Group Desc",
        field: "sales_group_desc",
        colId: "sales_group_desc",
        width: 135,
      },
      {
        headerName: "City",
        field: "city",
        colId: "city",
        width: 140,
      },
      {
        headerName: "State",
        field: "state",
        colId: "state",
        width: 125,
      },
      {
        headerName: "Zone",
        field: "zone",
        colId: "zone",
        width: 100,
      },
      {
        headerName: "SAC Type",
        field: "sac_type",
        colId: "sac_type",
        width: 100,
      },
      {
        headerName: "RO/ AO",
        field: "RO/ AO",
        colId: "RO/ AO",
        width: 125,
      },
      {
        headerName: "Latitude",
        field: "latitude",
        colId: "latitude",
        width: 155,
      },
      {
        headerName: "Longitude",
        field: "longitude",
        colId: "longitude",
        width: 155,
      },
      {
        headerName: "Consignee Code",
        field: "consignee_code",
        colId: "consignee_code",
        width: 140,
      },
      {
        headerName: "Address",
        field: "address",
        colId: "address",
      },
      {
        headerName: "Pincode",
        field: "pincode",
        colId: "pincode",
        width: 115,
      },
    ]
    return temp_coldef
  },[])

  const onSumbitFormHandler = async (e)=>{
    e.preventDefault();

    let payload = {...opDealer};
    payload.consignee_code = opDealer ? opDealer.customer.toString().padStart(10, "0"): "";
    try{
      setLoader(true);
      let resp1 = await redirectURL.post('/billing/getLocationAddress', { latitude: opDealer.latitude, longitude: opDealer.longitude });

      if(resp1.data.records){
        payload.address = resp1.data.records.address;
        payload.pincode = resp1.data.records.pincode;

        // setLoader(true);
        redirectURL.post('/billing/sumbitDealerData', payload).then(response => {
          setLoader(false);
          if(response.data && response.data.status === "success"){
            
            setSweetAlert({ show: true, basicType: "success", basicTitle: "Dealer data has been successfully submitted.", refetch: true });
          }
          else{
            setSweetAlert({ show: true, basicType: "danger", basicTitle: response.data.message || "Something went wrong", refetch: false });
          }
        })
        .catch(error => {
          setLoader(false);
          setSweetAlert({show: true, basicType: "danger", basicTitle: "Something went wrong", refetch: false});
          console.error('Error:', error.response || error.message);
        })
      }
      else{
        setSweetAlert({show: true, basicType: "warning", basicTitle: "Provide valid latitude and longitude", refetch: false});
        setLoader(false);
      }
    }
    catch(err){
      setLoader(false);
      console.log("error - ", err);
    }

  }

  const onChangeHandler = (e)=>{
    let {name, value} = e.target;

    setOpDealer((prev)=>({...prev, [name]: value}))
  }

  const closeAlert = () => {
    setSweetAlert({ show: false, basicType: "default", basicTitle: "", refetch: false});

    if(sweetAlert.refetch){
      onCloseSlider();
      fetchData();
    }
  };
  const onCloseSlider = () => {
    setSlider(false);
    setOpDealer({
      "customer" : "",
      "customer_heir" : "",
      "customer_name" : "",
      "customer_group_text" : "",
      "sales_group_desc" : "",
      "city" : "",
      "state" : "",
      "sac_type" : "",
      "ro_ao" : "",
      "zone" : "",
      "latitude" : "",
      "longitude" : "",
      // "active_status" : "",
      // "consignee_code" : "",
      // "address" : "",
      // "pincode" : ""
    })
  };

  return (
    <div className="container-fluid">
      <SweetAlert
          show={sweetAlert.show}
          type={sweetAlert.basicType}
          title={sweetAlert.basicTitle}
          onConfirm={closeAlert}
      >
      </SweetAlert>
      <div className='row'>
          <div className="card col-xl-12 col-lg-12" style={{height: "80vh"}}>
              <div className="card-header" style={{ padding: '10px', borderBottom:"0px" }}>
                <h5>Dealer Master</h5>
                <div className="row">
                  <div className="col-sm-12">
                    <button className="float-right btn btn-warning" onClick={()=>{
                      setSlider(true);
                    }} >
                        Add Dealer
                    </button>
                  </div>
                </div>
              </div>
              {/* <form onSubmit={onSubmitHandler}>
                <div className="d-flex align-items-center mr-4" style={{float: "right", gap: "10px"}}>
                  <div className='d-flex align-items-center' style={{ gap: "10px"}}>
                    <label className="" style={{marginBottom: "0px"}}>Financial Year<span className='err-txt'>*</span> <span className='err-txt'>*</span></label>
                    <div style={{width: "200px"}}>
                      <Select
                          value={selectFy}
                          closeMenuOnSelect={true}
                          onChange={(selectedOption) => setSelectFY(selectedOption)}
                          options={fyOptions}
                          styles={{
                              control: (provided, state) => ({
                                  ...provided,
                                  minHeight: '30px', // Add your desired min-height here
                              }),
                          }}
                          required
                      />

                    </div>
                  </div>
                  <div>
                    <button type='submit' className='btn btn-success'>Submit</button>
                  </div>
                </div>
              </form> */}
              <div className="height-equal equal-height-lg" style={{ padding: '10px', height: "100%" }}>
                  <div className="card-body" style={{ padding: '10px',height: "100%"  }}>
                      <div id="myGrid" style={{ height: "100%" }} className="ag-theme-balham">
                          <AgGridReact
                              // modules={modules}
                              columnDefs={columnDef}
                              defaultColDef={defaultColDef}
                              rowData={rowData}
                              enableCharts={false}
                              enableRangeSelection={true}
                              statusBar={{
                                statusPanels: [
                                  { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left", },
                                  { statusPanel: "agTotalRowCountComponent", align: "center", },
                                  { statusPanel: "agFilteredRowCountComponent" },
                                  { statusPanel: "agSelectedRowCountComponent" },
                                  { statusPanel: "agAggregationComponent" },
                                ],
                              }}
                              frameworkComponents={{
                                EditCellRenderer: EditCellRenderer,
                              }}
                              // sideBar={{
                              //   toolPanels: [
                              //     {
                              //       id: "columns",
                              //       labelDefault: "Columns",
                              //       labelKey: "columns",
                              //       iconKey: "columns",
                              //       toolPanel: "agColumnsToolPanel",
                              //       toolPanelParams: { suppressPivotMode: true, },
                              //     },
                              //     {
                              //       id: "filters",
                              //       labelDefault: "Filters",
                              //       labelKey: "filters",
                              //       iconKey: "filter",
                              //       toolPanel: "agFiltersToolPanel",
                              //     },
                              //   ],
                              // }}
                              pagination={true}
                              groupHeaderHeight={40}
                              headerHeight={40}
                              floatingFiltersHeight={50}
                              pivotGroupHeaderHeight={30}
                              pivotHeaderHeight={50}
                              rowHeight={40}
                              onGridReady={onGridReady}
                              // onGridState={this.onGridState}
                              // onCellClicked={this.onCellClicked}
                          //sideBar={sideBar}
                          // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                          />
                      </div>

                  </div>

              </div>
              <div className={"dataLoadpage " + (loader ? "show-m":"show-n")}>
              </div>
              <div className={"dataLoadpageimg " + (loader ? "show-m":"show-n")}>
                  <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
              </div>
              {/* <div className={"overlay-part " + (loader ? "show-m":"show-n")} onClick={this.onClickHideAll}></div> */}

              {/* dealer slider  */}
              <div className={"overlay-part " + (slider ? "show-m" : "show-n")} onClick={onCloseSlider}></div>
              <div className={"slide-r " + (slider ? "slider-translate-30p" : "")} style={{ right: "0px" }}>
                <h3 className="subH">{opDealer.id ? "Edit Dealer" :"Add Dealer"}
                    <span
                        className="float-right closebtn"
                        style={{ }}
                        onClick={onCloseSlider}
                    >
                        X
                    </span>
                </h3>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="container-fluid">
                        <form className="theme-form" onSubmit={onSumbitFormHandler}>
                            <div className="form-group">
                              <label className="">Customer Code <span className='err-txt'>*</span></label>
                              <input 
                                type="text" 
                                id="customer" 
                                name="customer" 
                                value={opDealer.customer}
                                className="form-control" 
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\s*(\d+(\.\d*)?)?\s*$/.test(value)) {
                                    onChangeHandler(e);  
                                  }
                                }}
                                style={{cursor: opDealer.id ? "not-allowed": ""}}
                                readOnly={opDealer.id ? true: false}
                                maxLength={30}
                                required 
                              />
                            </div>
                            <div className="form-group mt-20p">
                              <label className="">Customer Heir <span className='err-txt'>*</span></label>
                              <input 
                                type="text" 
                                id="customer_heir" 
                                name="customer_heir" 
                                value={opDealer.customer_heir}
                                className="form-control"
                                maxLength={30}
                                onChange={(e) => {
                                  onChangeHandler(e);  
                                }}
                                required 
                              />
                            </div>

                            <div className="form-group mt-20p">
                              <label className="">Customer Name <span className='err-txt'>*</span></label>
                              <input 
                                type="text" 
                                id="customer_name" 
                                name="customer_name" 
                                value={opDealer.customer_name}
                                className="form-control" 
                                onChange={(e) => {
                                  onChangeHandler(e);  
                                }}
                                maxLength={100}
                                required 
                              />
                            </div>
                            <div className="form-group mt-20p">
                              <label className="">City <span className='err-txt'>*</span></label>
                              <input 
                                type="text" 
                                id="city" 
                                name="city" 
                                value={opDealer.city}
                                className="form-control" 
                                onChange={(e) => {
                                  onChangeHandler(e);  
                                }}
                                maxLength={100}
                                required 
                              />
                            </div>
                            <div className="form-group mt-20p">
                              <label className="">State <span className='err-txt'>*</span></label>
                              <input 
                                type="text" 
                                id="state" 
                                name="state" 
                                value={opDealer.state}
                                className="form-control" 
                                onChange={(e) => {
                                  onChangeHandler(e);  
                                }}
                                maxLength={100}
                                required 
                              />
                            </div>
                            <div className="form-group mt-20p">
                              <label className="">Latitude <span className='err-txt'>*</span></label>
                              <input 
                                type="number" 
                                id="latitude" 
                                name="latitude" 
                                value={opDealer.latitude}
                                className="form-control" 
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\s*(\d+(\.\d*)?)?\s*$/.test(value)) {
                                    onChangeHandler(e);  
                                  }
                                }}
                                maxLength={100}
                                required 
                              />
                            </div>
                            <div className="form-group mt-20p">
                              <label className="">Longitude <span className='err-txt'>*</span></label>
                              <input 
                                type="number" 
                                id="longitude" 
                                name="longitude" 
                                value={opDealer.longitude}
                                className="form-control" 
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\s*(\d+(\.\d*)?)?\s*$/.test(value)) {
                                    onChangeHandler(e);  
                                  }
                                }}
                                maxLength={100}
                                required 
                              />
                            </div>
                            <div className="form-group mt-20p">
                              <label className="">SAC Type <span className='err-txt'>*</span></label>
                              <input 
                                type="text" 
                                id="sac_type" 
                                name="sac_type" 
                                value={opDealer.sac_type}
                                className="form-control" 
                                onChange={(e) => {
                                  onChangeHandler(e);  
                                }}
                                maxLength={100}
                                required 
                              />
                            </div>
                            <div className="form-group mt-20p">
                              <label className="">RO/ AO <span className='err-txt'>*</span></label>
                              <input 
                                type="text" 
                                id="ro_ao" 
                                name="ro_ao" 
                                value={opDealer.ro_ao}
                                className="form-control" 
                                onChange={(e) => {
                                  onChangeHandler(e);  
                                }}
                                maxLength={100}
                                required 
                              />
                            </div>
                            <div className="form-group mt-20p">
                              <label className="">Zone <span className='err-txt'>*</span></label>
                              <input 
                                type="text" 
                                id="zone" 
                                name="zone" 
                                value={opDealer.zone}
                                className="form-control" 
                                onChange={(e) => {
                                  onChangeHandler(e);  
                                }}
                                maxLength={100}
                                required 
                              />
                            </div>
                            
                            <div className="form-group mt-20p">
                              <label className="">Customer Group Text <span className='err-txt'>*</span></label>
                              <input 
                                type="text" 
                                id="customer_group_text" 
                                name="customer_group_text" 
                                value={opDealer.customer_group_text}
                                className="form-control" 
                                onChange={(e) => {
                                  onChangeHandler(e);  
                                }}
                                maxLength={100}
                                required 
                              />
                            </div>
                            <div className="form-group mt-20p">
                              <label className="">Sales Group Desc <span className='err-txt'>*</span></label>
                              <input 
                                type="text" 
                                id="sales_group_desc" 
                                name="sales_group_desc" 
                                value={opDealer.sales_group_desc}
                                className="form-control" 
                                onChange={(e) => {
                                  onChangeHandler(e);  
                                }}
                                maxLength={100}
                                required 
                              />
                            </div>

                            {/* <div className='p-2' id="appednCSVData"></div> */}
                            <div className="form-group d-flex alitems-center justify-content-around mb-20p" style={{gap: "15px"}}>
                                <button type="button" className="btn btn-default" onClick={onCloseSlider}>Cancel</button>
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                        </form>
                        
                    </div>
                </div>
            </div>

          </div>
              
        </div>

    </div>
  )
}

export default DealerMaster