/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
import React, {Component} from 'react';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
// import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
// import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import Constants from "../constants";
import {getHyphenDDMMMYYYYHHMM} from "../common/utils";
// import icn from "../../img/undraw_profile.svg";
import $ from 'jquery';
import 'jquery-ui';
import CSVFileValidator from 'csv-file-validator';
import moment from 'moment';
import ViewDoc from "./viewdocument";
import CloseDocuments from './ClosedFreightDocuments';
import PendingDocuments from './BillingPendingApprovalsDocs';

export default class DieselPriceApproval extends Component {
    constructor(props){
        super(props);
        this.state={
            isuploadcsv:0,
            modules: AllModules,
            defaultColDef :{
				// flex: 1,
				minWidth: 100,
				filter: true,
				resizable:true,
                sortable:true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
			},
            frameworkComponents:{
            },
            pendingframeworkComponents:{
                ViewDoc:ViewDoc
            },
			detailCellRendererParams : {
				detailGridOptions: {
				  columnDefs: [
					{ field: 'callId' },
					{ field: 'direction' },
					{ field: 'number', minWidth: 150 },
					{ field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
					{ field: 'switchCode', minWidth: 150 },
				  ],
				  defaultColDef: {
					flex: 1,
				  },
				},
				getDetailRowData: (params) => {
				  params.successCallback(params.data.callRecords);
				},
			},
			rowData:[],
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            conf:"",            
            showUploadCSVForm:"show-n",
            slider:"",
            overlay:"show-n",
            csvcontent:"",
            month:{value:"",label:"Select"},
            showdata:"show-n",
            todayDieselPrice:0,
            avgDieselPrice:0,
            ctab:"btn-success",
            tltab:"btn-default",
            dtab:"show-m",
            ptab:"btn-default",
            tolltab:"show-n",
            dieselbasis:"",
            showAfterSubmit:"show-n",
            diesel_price_changed:"",
            diesel_price_changed_date:"",
            capture_diesel_price_from_day:"",
            capture_diesel_price_to_day:"",
            changed_diesel_price:"",
            changed_diesel_price_reason:"",
            changed_diesel_price_upload:"",
            approval_freight:"",
            freightapproval:[],
            approval_levelemails:0,
            isUserCreateDoc:0,
            pendingRowData:[],
            pendingCnt:0,
            closedCnt:0,
            dieselTooltip:"show-n"
        }
        this.loadFreightRates = this.loadFreightRates.bind(this);
        this.getDieselPrices = this.getDieselPrices.bind(this);
        this.getAvgDieselPrices = this.getAvgDieselPrices.bind(this);
        this.loadDieselPrices = this.loadDieselPrices.bind(this);
        this.onSubmitFormData = this.onSubmitFormData.bind(this);
        this.getDieselApprovalPrice = this.getDieselApprovalPrice.bind(this);
        this.onClickSendForApproavl = this.onClickSendForApproavl.bind(this);
        this.loadUserAccess = this.loadUserAccess.bind(this);
        this.loadFreightApprovals = this.loadFreightApprovals.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
    }
	componentDidMount(){
        redirectURL.post("/billing/configurations")
		.then((response) => {
			// console.log("response ", response.data)
            var records = response.data.records;
            if(records.length > 0)
            {
                this.setState({
                    conf:records[0]
                })
                if(records[0].diesel_price_capture_val === "1")
                {
                    var diesel_percent = records[0].diesel_price_x;
                }
                
                if(records[0].diesel_price_capture_val === "2")
                {
                    var diesel_percent = "";
                }
                this.loadDieselPrices({diesel_percent:diesel_percent});
                var params = {};
                if(localStorage.getItem("email") != "" && localStorage.getItem("email") != undefined)
                {
                    params.email=localStorage.getItem("email");
                }
                this.loadFreightApprovals(params);
            //    this.loadFreightRates()
            }
            // else{
            //     redirectToConfigurations()
            // }
		})
        
        var d = moment.parseZone().format("DDMMYYYY")
        redirectURL.get("https://enmovil.io/foxtrot/masters/fetchFuelPrices?report_date="+d+"&fuel_type=['Diesel']")
		.then((response) => {
			// console.log("response ", response.data)
            
		})
        
        this.loadUserAccess();
        pushNot();
	}
    loadFreightApprovals(params){
        redirectURL.post("/billing/freightapprovals",params)
		.then((response) => {
			// console.log("response ", response.data)
            var records = response.data.records;
            var recordsarr = [];
            if(records.length > 0)
            {
                records.map((item) => {
                    var aprstat = "Pending for Approvals";
                    var apprColor = "#7e022a";
                    
                    if(item.approval_status === "2" || item.approval_status === 2)
                    {
                         aprstat = "Approved";
                         apprColor = "#016e48";
                    }
                    else if(item.approval_status === "3" || item.approval_status === 3)
                    {
                         aprstat = "Rejected";
                         apprColor = "#016e48";
                    }
                    else{
                        aprstat = "Pending for Approvals";
                        apprColor = "#7e022a";
                    }
                    // console.log("item.approval_status",item.approval_status)
                    if(item.changed_diesel_price != "")
                    {
                        if(item.approval_status === "2" || item.approval_status === 2)
                        {
                            var changed_diesel_price = item.changed_diesel_price;
                        }
                        else{
                            var changed_diesel_price = item.applicable_diesel_price;
                        }
                        
                    }
                    else{
                        var changed_diesel_price = item.applicable_diesel_price
                    }
                    // console.log("changed_diesel_price ", changed_diesel_price)
                    recordsarr.push({
                        _id:item._id,
                        document_no:item.document_no,
                        approval_status:aprstat,
                        apprColor:apprColor,
                        applicable_diesel_price:changed_diesel_price
                    })
                })
            }
            // console.log("recordsarr ", recordsarr)
            this.setState({
                pendingRowData:recordsarr
            })
            
		})
    }
    loadUserAccess(){
        var params={
            email:localStorage.getItem("email")
        }
        redirectURL.post("/billing/usermoduleaccess",params)
        .then((response) => {
            var records = response.data.records;
            if(records.length > 0)
            {
                if(records[0].document_creation !== "" && records[0].document_creation !== undefined)
                {
                    if(records[0].document_creation === "Yes")
                    {
                        this.setState({
                            isUserCreateDoc:1,
                            ctab:"btn-success",
                            tltab:"btn-default",
                            ptab:"btn-default",
                        })
                    }
                    else{
                        this.setState({
                            isUserCreateDoc:0,
                            ctab:"btn-default",
                            tltab:"btn-default",
                            ptab:"btn-success",
                        })
                    }
                }
                else{
                    this.setState({
                        isUserCreateDoc:0,
                        ctab:"btn-default",
                        tltab:"btn-default",
                        ptab:"btn-success",
                    })
                }
            }
            else{
                this.setState({
                    isUserCreateDoc:0,
                    ctab:"btn-default",
                    tltab:"btn-default",
                    ptab:"btn-success",
                })
            }
            
        })
    }
    loadDieselPrices(params){
        redirectURL.post("/billing/dieselprices",params)
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records ", records)
            if(records.length > 0)
            {
                this.setState({
                    diesel_price_changed:records[0].diesel_price,
                    diesel_price_changed_date:(records[0].diesel_price_date != "" && records[0].diesel_price_date != undefined)?moment.parseZone(records[0].diesel_price_date).format("DD-MM-YYYY"):""
                })
            }
		})
    }
    loadFreightRates(){

        redirectURL.post("/billing/freightrates")
		.then((response) => {
			// console.log("response ", response.data)
            var records = response.data.records;
            var recordsarr = [];
            var formulacode = this.state.conf.diesel_price_formula;
            var diesel_impact_percent = (this.state.conf.diesel_impact_percent != "")?this.state.conf.diesel_impact_percent:0;
            
            if(records.length > 0)
            {
                records.map((item) => {
                    
                    // (Distance * (latest Diesel Price - Base Diesel Price)) / Mileage
                    // "Diesel impact as a percentage of Base Freight Rate * Base Freight Rate * ((Latest Diesel Price - Base Diesel Price) / Base Diesel Price)"
                    // console.log("item ", item)
                    var distance = item.total_distance;
                    var lastest_diesel_price = this.state.avgDieselPrice;
                    var base_diesel_price = item.base_diesel_rate_per_approval_ltr;
                    var mileage = item.mileage;
                    var base_freight_rate = item.base_freight_rate_per_approval;
                    var diesel_price_consider_for_revision = this.state.approval_freight;
                    var changeDieselPrice = 0;
                    // console.log("formulacode ", formulacode)
                    if(formulacode === "1")
                    {   
                        // console.log('(parseFloat(lastest_diesel_price) - parseFloat(base_diesel_price)) ', (parseFloat(lastest_diesel_price) - parseFloat(base_diesel_price)))
                        changeDieselPrice = ((parseFloat(distance) * (parseFloat(lastest_diesel_price) - parseFloat(base_diesel_price)))/parseFloat(mileage)).toFixed(2);
                        // console.log("changeDieselPrice ", changeDieselPrice)
                        var diesel_impact = 0;
                    }
                    
                    if(formulacode === "2")
                    {   
                        changeDieselPrice = (((parseFloat(diesel_impact_percent)*parseFloat(base_freight_rate))/100) * base_freight_rate * ((lastest_diesel_price) - parseFloat(base_diesel_price)/parseFloat(base_diesel_price))).toFixed(2);
                        var diesel_impact = ((parseFloat(diesel_price_consider_for_revision)-parseFloat(base_diesel_price))/parseFloat(base_diesel_price)).toFixed(2);
                    }
                    item.change_in_diesel_cost = changeDieselPrice;
                    
                    item.diesel_price_impact =  diesel_impact*100;
                    if(this.state.changed_diesel_price != "")
                    {
                        item.diesel_price_consider_for_revision = this.state.approval_freight;
                    }
                    else{
                        item.diesel_price_consider_for_revision = this.state.approval_freight;
                    }
                    recordsarr.push(item); 
                })
            }
            this.setState({
                rowData:recordsarr,
                showAfterSubmit:"show-m"
            })
            
		})
    }
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    onClickShowCSVForm(){
        this.setState({
            showUploadCSVForm:"show-m",
            slider:"slide30",
            overlay:"show-m"
        })
    }
    hideSlideBlock(){
        this.setState({
            showUploadCSVForm:"show-n",
            slider:"",
            overlay:"show-n"
        })
    }
    monthOptions(){
        var items = [{value:"",label:"Select"}];
        var months = ["Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        var cmonth = moment.parseZone().format("MM");
        var cyear = moment.parseZone().format("YYYY");
        // console.log("cmonth ", parseInt(cmonth))
        for(var i=0;i<months.length;i++)
        {
            if(i<=(parseInt(cmonth)-1))
            {
                // console.log("Month ", months[i])
                if((i+1) < 10){
                    var dd = "0"+(i+1);
                }
                else{
                    var dd = i;
                }
                items.push({
                    value:cyear+"-"+dd,
                    label:months[i]+"-"+cyear
                })
            }
            
        }
        return items;
    }
    handleMonth(month){
        this.setState({month},async ()=>{
            // console.log("optin ", this.state.month)
            if(this.state.month.value != "")
            {
                var months = ["Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        
                this.setState({
                    overlay:"show-m"
                })
                
                var mn =this.state.month.value;
                if(mn != "")
                {
                    var ml = mn.split("-");
                    var conf = this.state.conf;
                    // console.log("conf ", conf);
                    if(conf.diesel_price_capture_val === "2")
                    {
                        var fd_avg = conf.capture_diesel_price_from_avg;
                        var fb = conf.capture_diesel_price_from_day;
                        if(fd_avg != "")
                        {
                            var frd = fd_avg.split("-");
                            var fmon = moment.parseZone().subtract(frd[1],"months").format("YYYY-MM");
                            var capture_diesel_price_from_day = moment.parseZone(new Date(fmon+"-"+fb+" 00:00:00")).format("DD-MM-YYYY");
                            // console.log("capture_diesel_price_from_day ", capture_diesel_price_from_day)
                        }
                        else{
                            var capture_diesel_price_from_day = "";
                        }
                        var td_avg = conf.capture_diesel_price_to_avg;
                        var td = conf.capture_diesel_price_to_day;
                        
                        if(td_avg != "")
                        {
                            var trd = td_avg.split("-");
                            var tmon = moment.parseZone().subtract(trd[1],"months").format("YYYY-MM");
                            // console.log("tmon+td ", tmon+"-"+td)
                            var capture_diesel_price_to_day = moment.parseZone(new Date(tmon+"-"+td+" 23:59:59")).format("DD-MM-YYYY");
                            // console.log("capture_diesel_price_to_day ", capture_diesel_price_to_day)
                        }
                        else{
                            var capture_diesel_price_to_day = "";
                        }
                        // console.log("tmon ",tmon)
                        // console.log("fmon ",fmon)
                        this.setState({
                            capture_diesel_price_from_day:capture_diesel_price_from_day,
                            capture_diesel_price_to_day:capture_diesel_price_to_day,
                            diesel_price_changed_date:""
                        })
                    }
                }
                var dates = await this.getStartAndENDDates();
                // console.log("dates ",dates)
                var params = {
                    from_date: dates.start.replace(/-/g,''),
                    to_date: dates.end.replace(/-/g,''),
                    start_date: dates.start,
                    end_date: dates.end
                }
                var avgDieselPrice = await this.getDieselPrices(params);
                await setTimeout(()=>{
                    
                // console.log("avgDieselPrice ", avgDieselPrice)
                    this.setState({
                        showdata:"show-m",
                        overlay:"show-n"
                        // avgDieselPrice:avgDieselPrice
                    })
                },2000)
                
            }
            else{
                this.setState({
                    showdata:"show-n",
                    overlay:"show-n"
                })
            }
        })
    }
    getDieselPrices(params){
        // console.log("params ", params)
        redirectURL.post("/billing/getDieselPrices",params)
		.then((response) => {
			// console.log("response ", response.data)
            var prices = response.data.prices;
            var avgprice = this.getAvgDieselPrices(prices);
            // console.log("avgprice ", avgprice)

            var conf = this.state.conf;
            var dPrice = 0;
            var dieselbasis = "";
            if(conf.diesel_price_capture_val != "" && conf.diesel_price_capture_val != undefined)
            {
                if(conf.diesel_price_capture_val == "1")
                {
                    var diesel_price_x = parseFloat(conf.diesel_price_x);
                    // console.log("diesel_price_x ", diesel_price_x)
                    // console.log("avgprice ", avgprice)
                    dPrice = parseFloat(avgprice)+(parseFloat(avgprice)*(parseFloat(diesel_price_x)/100));
                    // console.log("dPrice ", dPrice);
                    dieselbasis = "Diesel price changed by "+diesel_price_x+" %";
                    this.setState({
                        avgDieselPrice:dPrice.toFixed(2),
                        dieselbasis:dieselbasis
                    })
                }
                if(conf.diesel_price_capture_val == "2")
                {
                    // console.log("avgprice ", avgprice)
                    dieselbasis = " Daily diesel price averages ";
                    dPrice = avgprice;
                    this.setState({
                        avgDieselPrice:dPrice,
                        dieselbasis:dieselbasis
                    })
                }

            }
            else{
                dPrice = 0;
                this.setState({
                    avgDieselPrice:dPrice,
                    dieselbasis:""
                })
            }
            
            
		})
    }
    getAvgDieselPrices(prices){
        
        var allprices = []
        if(prices.length > 0)
        {
            prices.map((item) => {
                if(item.diesel_price != "" && item.diesel_price != undefined)
                {
                    allprices.push(parseFloat(item.diesel_price));
                }
                
            })
        }
        // console.log("allprices ", allprices.length)
        var avg =0;
        if(allprices.length > 0)
        {
            var sum = 0;
            for (let i = 0; i < allprices.length; i++) {
                sum += allprices[i];
            }
            avg = (sum/allprices.length).toFixed(2);
        }
        return avg;
    }
    getStartAndENDDates(){
        var freq = this.state.conf.diesel_frequence_option;
        var freqFrom = "";
        var freqTo = "";
        var month = this.state.month.value;
        if(this.state.conf.diesel_frequence_option == "FortNightly")
        {
            var fm = moment.parseZone(new Date(month+"-"+"01 00:00:00")).utcOffset("+05:30")._d;
            var frm = moment.parseZone(fm).format("DD-MM-YYYY");
            
            var tm = moment.parseZone(new Date(month+"-"+"16 00:00:00")).utcOffset("+05:30")._d;
            var trm = moment.parseZone(tm).format("DD-MM-YYYY");
            freqFrom = frm; 
            freqTo = trm; 
        }
        
        if(this.state.conf.diesel_frequence_option == "Weekly")
        {
            var days = this.state.conf.diesel_revision_day;
            if(days.length > 0)
            {
                var daylist = days.sort();

                var fm = moment.parseZone(new Date(month+"-"+"01 00:00:00")).utcOffset("+05:30")._d;
                var frm = moment.parseZone(fm).format("DD-MM-YYYY");
                
                var tm = moment.parseZone(new Date(month+"-"+"16 00:00:00")).utcOffset("+05:30")._d;
                var trm = moment.parseZone(tm).format("DD-MM-YYYY");
                freqFrom = frm; 
                freqTo = trm; 
            }
            
        }
        
        if(this.state.conf.diesel_frequence_option == "Monthly")
        {
            var days = this.state.conf.diesel_revision_day;
            if(days.length > 0)
            {
                var daylist = days[0];
                
                var fm = moment.parseZone(new Date(month+"-"+daylist+" 00:00:00")).utcOffset("+05:30")._d;
                var frm = moment.parseZone(fm).format("DD-MM-YYYY");
                
                var tm = moment.parseZone(new Date(month+"-"+daylist+" 00:00:00")).utcOffset("+05:30")._d;
                var trm = moment.parseZone(tm).format("DD-MM-YYYY");
                freqFrom = frm; 
                freqTo = trm; 
            }
            
        }


        if(this.state.conf.diesel_frequence_option == "Daily")
        {
            
            var c = moment.parseZone().format("DD");
            // console.log("CCCC ", c)
            var fm = moment.parseZone(new Date(month+"-"+c+" 00:00:00")).utcOffset("+05:30")._d;
            var frm = moment.parseZone(fm).format("DD-MM-YYYY");
            
            var tm = moment.parseZone(new Date(month+"-"+c+" 23:59:59")).utcOffset("+05:30")._d;
            
            var trm = moment.parseZone().format("DD-MM-YYYY");
            freqFrom = frm; 
            freqTo = trm; 
        
        }


        return {start:freqFrom,end:freqTo};
    }
    showTabView(oVal){
        if(oVal === 1)
        {
            if(this.state.month.value !== "")
            {
                var shown = "show-m";
            }
            else{
                var shown = "show-n";
            }
            this.setState({
                ctab:"btn-success",
                tltab:"btn-default",
                dtab:"show-m",
                tolltab:"show-n",
                showAfterSubmit:"show-n",
                showdata:shown,
                ptab:"btn-default"
            })
        }
        if(oVal === 2)
        {
            this.setState({
                ctab:"btn-default",
                tltab:"btn-success",
                dtab:"show-n",
                tolltab:"show-m",
                showAfterSubmit:"show-m",
                showdata:"show-n",
                ptab:"btn-default"
            })
        }
        
        if(oVal === 3)
        {
            this.setState({
                ctab:"btn-default",
                tltab:"btn-default",
                dtab:"show-n",
                tolltab:"show-n",
                showAfterSubmit:"show-n",
                showdata:"show-n",
                ptab:"btn-success"
            })
        }
    }
    
    changeHandler(event){
        console.log("event ", event)
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    changeFileHandler(event){
        // console.log("event.target.files[0] ", event.target.files[0])
        this.setState({
            changed_diesel_price_upload:event.target.files[0]
        })
    }
    onSubmitFormData(event){
        event.preventDefault();
        var month = this.state.month.value;
        var diesel_frequence_option = $("#frequency").val();
        var from_period = $("#from_period").val();
        var to_period = $("#to_period").val();
        var avgDieselPrice = $("#applicableprice").val();
        var dieselbasis = $("#dieselbasis").val();
        var diesel_price_changed_date = $("#diesel_price_changed_date").val();
        var capture_diesel_price_from_day = $("#capture_diesel_price_from_day").val();
        var capture_diesel_price_to_day = $("#capture_diesel_price_to_day").val();
        var changed_diesel_price = this.state.changed_diesel_price;
        var changed_diesel_price_reason = this.state.changed_diesel_price_reason;
        var changed_diesel_price_upload = this.state.changed_diesel_price_upload;
        var params = {
            selected_month:month,
            diesel_frequence_option:diesel_frequence_option,
            from_period:from_period,
            to_period:to_period,
            avgDieselPrice:avgDieselPrice,
            dieselbasis:dieselbasis,
            diesel_price_changed_date:diesel_price_changed_date,
            capture_diesel_price_from_day:capture_diesel_price_from_day,
            capture_diesel_price_to_day:capture_diesel_price_to_day,
            changed_diesel_price:changed_diesel_price,
            changed_diesel_price_reason:changed_diesel_price_reason,
            changed_diesel_price_upload:changed_diesel_price_upload
        }
        // var docForm = document.getElementById("docForm");
        const formData = new FormData();
        formData.append("selected_month",month);
        formData.append("diesel_frequence_option",diesel_frequence_option);
        formData.append("from_period",from_period);
        formData.append("to_period",to_period);
        formData.append("avgDieselPrice",avgDieselPrice);
        formData.append("dieselbasis",dieselbasis);
        formData.append("diesel_price_changed_date",diesel_price_changed_date);
        formData.append("capture_diesel_price_from_day",capture_diesel_price_from_day);
        formData.append("capture_diesel_price_to_day",capture_diesel_price_to_day);
        formData.append("changed_diesel_price",changed_diesel_price);
        formData.append("changed_diesel_price_reason",changed_diesel_price_reason);
        formData.append("changed_diesel_price_upload",changed_diesel_price_upload);
        console.log("changed_diesel_price_upload ", changed_diesel_price_upload)
        console.log("params",formData)
      
        redirectURL.post("/billing/savedieselapprovalform",formData)
		.then((response) => {
			//console.log("response ", response.data)
            // var records = response.data.records;
            // console.log("records ", records)
            if(response.data.status === "success")
            {
                var rownode = response.data.records[0];
                redirectToFreightApprovalGrid(rownode.document_no);
                this.getDieselApprovalPrice(rownode);
            }
		});
    }
    getDieselApprovalPrice(params){
        redirectURL.post("/billing/dieselapprovaldata",params)
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records ", records)
            if(records.length > 0)
            {
                this.setState({
                    approval_freight:records[0].applicable_diesel_price,
                    changed_diesel_price:records[0].changed_diesel_price,
                    changed_diesel_price_reason:records[0].changed_diesel_price_reason,
                    changed_diesel_price_upload:records[0].changed_diesel_price_upload,
                    freightapproval:records
                })
                this.loadFreightRates();
            }
		});
    }
    onClickSendForApproavl(){
        // console.log("freightapproval ", this.state.freightapproval);
        var params = {
            document_no:this.state.freightapproval[0].document_no
        }
        redirectURL.post("/billing/sendForApproval",params)
		.then((response) => {
			//console.log("response ", response.data)
            var records = response.data.records;
            // console.log("records ", records)
            if(records.length > 0)
            {
                this.setState({
                    approval_freight:records[0].applicable_diesel_price
                })
                this.loadFreightRates();
            }
		});
    }
    
    onLoadTabCount(count){
        this.setState({
            pendingCnt:count
        })
    }
    onLoadTabClosedCount(count){
        this.setState({
            closedCnt:count
        })
    }
    showToolTip(){
        this.setState({
            dieselTooltip:"show-m"
        });
    }
    onClickHideTooltip(){
        this.setState({
            dieselTooltip:"show-n"
        });
    }
    render(){
		var columnDefs = [
            {
                headerName:Constants.FREIGHT_RATE_ROUTE,
                field:"route",
                width:120,
                // cellRendererSelector:function(params){
                //     return {
                //         component:"ViewPOD"
                //     }
                // },
            },
            {
                headerName:Constants.FREIGHT_RATE_SOURCE,
                field:"source",
                width:120,
            },
            {
                headerName:Constants.FREIGHT_RATE_DESTINATION,
                field:"destination",
                width:160,
            },
            {
                headerName:Constants.FREIGHT_RATE_LSP,
                field:"transporter_name",
                width:140
            },
            {
                headerName:Constants.FREIGHT_RATE_RATE_CLASSIFICATION,
                field:"rate_classification",
                width:140
            },
            {
                headerName:Constants.FREIGHT_RATE_CONTRACT,
                field:"contract_approval_no",
                width:100,
            },
            {
                headerName:Constants.FREIGHT_RATE_TRUCK_TYPE,
                field:"truck_type",
                width:100,
            },
            {
                headerName:Constants.FREIGHT_RATE_DISTANCE,
                field:"total_distance",
                width:120,
            },
            {
                headerName:Constants.FREIGHT_RATE_MILEAGE,
                field:"mileage",
                width:120,
            },
            {
                headerName:Constants.FREIGHT_RATE_BASE_FREIGHT_RATE_PER_APPROVAL,
                field:"base_freight_rate_per_approval",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_BASE_DIESEL_RATE_PER_LTR,
                field:"base_diesel_rate_per_approval_ltr",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_TOLL_RATE_PER_APPROVAL,
                field:"base_toll_rate_per_approval",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_BASE_DIESEL_CITY,
                field:"base_diesel_city",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_BASE_DIESEL_STATE,
                field:"base_diesel_state",
                width:180,
            },
            // {
            //     headerName:Constants.FREIGHT_RATE_OTHER_COSTS,
            //     field:"other_costs",
            //     width:180,
            // },
            {
                headerName:Constants.FREIGHT_RATE_DIESEL_PRICE_APPLICABLE_DATE,
                field:"revised_diesel_price_applicable_date",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_DIESEL_PRICE_CONSIDERED_FOR_REVISION,
                field:"diesel_price_consider_for_revision",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_DIESEL_PRICE_IMPACT_PERCENT,
                field:"diesel_price_impact",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_CHANGE_IN_DIESEL_COST,
                field:"change_in_diesel_cost",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_TOLL_REVISION_APPLICABLE_DATE,
                field:"toll_revision_applicable_date",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_CHANGE_IN_TOLL_PRICE,
                field:"change_in_toll_price",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_COST,
                field:"revised_freight_cost",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_DATE,
                field:"revised_freight_cost",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_DATE_FROM,
                field:"revised_freight_cost",
                width:180,
            },
            {
                headerName:Constants.FREIGHT_RATE_REVISED_FREIGHT_DATE_TO,
                field:"revised_freight_cost",
                width:180,
            },
        ];

        var pendingcolumnDefs = [
            {
                headerName:"",
                field:"document_no",
                width:120,
                cellRendererSelector:function(params){
                    return {
                        component:"ViewDoc"
                    }
                },
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_DOCUMENT_NO,
                field:"document_no",
                width:120,
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_APPROVAL_STATUS,
                field:"approval_status",
                width:160
            },
            {
                headerName:Constants.FREIGHT_APPROVALS_APPLICABLE_DIESEL_PRICE,
                field:"applicable_diesel_price",
                width:180,
            }
        ]
        var month = this.state.month.value;
        // console.log("month ",month)
        var freq = this.state.conf.diesel_frequence_option;
        var freqFrom = "";
        var freqTo = "";
        
        if(this.state.conf.diesel_frequence_option == "FortNightly")
        {
            var fm = moment.parseZone(new Date(month+"-01 00:00:00")).utcOffset("+05:30")._d;
            var frm = moment.parseZone(fm).format("DD-MM-YYYY");
            
            var tm = moment.parseZone(new Date(month+"-16 23:59:59")).utcOffset("+05:30")._d;
            var trm = moment.parseZone(tm).format("DD-MM-YYYY");
            freqFrom = frm; 
            freqTo = trm; 
        }
        
        if(this.state.conf.diesel_frequence_option == "Weekly")
        {
            var days = this.state.conf.diesel_revision_day;
            console.log("dats ", days);
            var weekOfCurrentMonth = (moment().week() - (moment().month()*4));
            if(days.length > 0)
            {
                days.map((itm) => {
                    if(parseInt(itm) === weekOfCurrentMonth)
                    {

                    }
                })
                var daylist = days.sort();

                var fm = moment.parseZone(new Date(month+"-01 00:00:00")).utcOffset("+05:30")._d;
                var frm = moment.parseZone(fm).format("DD-MM-YYYY");
                
                var tm = moment.parseZone(new Date(month+"-15 23:59:59")).utcOffset("+05:30")._d;
                var trm = moment.parseZone(tm).format("DD-MM-YYYY");
                freqFrom = frm; 
                freqTo = trm; 
            }
            
        }
        
        if(this.state.conf.diesel_frequence_option == "Daily")
        {
            
            var c = moment.parseZone().format("DD");
            // console.log("CCCC ", c)
            var fm = moment.parseZone(new Date(month+"-"+c+" 00:00:00")).utcOffset("+05:30")._d;
            var frm = moment.parseZone(fm).format("DD-MM-YYYY");
            
            var tm = moment.parseZone(new Date(month+"-"+c+" 23:59:59")).utcOffset("+05:30")._d;
            
            var trm = moment.parseZone().format("DD-MM-YYYY");
            freqFrom = frm; 
            freqTo = trm; 
        
        }
        if(this.state.conf.diesel_frequence_option == "Monthly")
        {
            var days = this.state.conf.diesel_revision_day;
            if(days.length > 0)
            {
                var daylist = days[0];
                
                var fm = moment.parseZone(new Date(month+"-"+daylist+" 00:00:00")).utcOffset("+05:30")._d;
                var frm = moment.parseZone(fm).format("DD-MM-YYYY");
                
                var tm = moment.parseZone(new Date(month+"-"+daylist+" 23:59:59")).utcOffset("+05:30")._d;
                var trm = moment.parseZone(tm).add("1","month").format("DD-MM-YYYY");
                freqFrom = frm; 
                freqTo = trm; 
            }
            
        }
        return (
            <div className="container-fluid">
				<div className="d-sm-flex align-items-center justify-content-between mb-4">
					<h1 className="col-xl-12 h3 mb-0 text-gray-800">{Constants.PAGE_TITLE_DIESEL_APPROVAL}
                        <span style={{float:"right"}}>
                            <a href={"/dieselprices"} type="button" className={"btn btn-info"} style={{marginRight:"10px"}}>Diesel Prices</a>
                            {/* <a href={"/tollprices"} type="button" className={"btn btn-success"} style={{marginRight:"10px"}}>Tolls</a> */}
                            {/* <a href={"/approvals"} type="button" className={"btn btn-danger"}>Approvals</a> */}
                        </span>
                    </h1>
				</div>
                <div className="card shadow h-100 py-2">
                    <div className="card-body">
                    {(this.state.isUserCreateDoc === 1)?
                            
                            <ul className="navtabs">
                                <li>
                                    <a href={"/dieselpriceapprovals"} className={"btn cbtn "+(this.state.ctab)}>Initiate</a>
                                </li>
                                <li>
                                    <button onClick={this.showTabView.bind(this,3)} type="button" className={"posrel btn cbtn "+(this.state.ptab)}>
                                        Pending
                                        <span className="tabcounter">{this.state.pendingCnt}</span>
                                    </button>
                                </li>
                                <li>
                                    <button onClick={this.showTabView.bind(this,2)} type="button" className={"posrel btn cbtn "+(this.state.tltab)}>Close
                                        <span className="tabcounter">{this.state.closedCnt}</span></button>
                                </li>
                            </ul>
                            :
                            <ul className="navtabs">
                                <li>
                                    <button onClick={this.showTabView.bind(this,3)} type="button" className={"posrel btn cbtn "+(this.state.ptab)}>Pending
                                        <span className="tabcounter">{this.state.pendingCnt}</span></button>
                                </li>
                                <li>
                                    <button onClick={this.showTabView.bind(this,2)} type="button" className={"posrel btn cbtn "+(this.state.tltab)}>Close
                                        <span className="tabcounter">{this.state.closedCnt}</span></button>
                                </li>
                            </ul>
                            }
                        <div className={(this.state.ctab === "btn-success")?"show-m":"show-n"}>
                            <div className="row" style={{marginTop:"20px"}}>
                                <div className="col-xl-2 col-md-12 mb-2">
                                    Select Month:
                                </div>
                                <div className="col-xl-3 col-md-12 mb-2">
                                    <Select 
                                        options={this.monthOptions()} 
                                        value={this.state.month}
                                        onChange={this.handleMonth.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"col-12 "+(this.state.showdata)}>
                            <div className={"row form-group border-bottom  no-gutters align-items-center mt-2 "}>
                                <div className="col-xl-2 col-md-12 mb-2">
                                    Frequency: 
                                </div>
                                <div className="col-xl-2 col-md-12 mb-2">
                                {freq}
                                <input type="hidden" id="frequency" value={freq} />
                                </div>
                                <div className="col-xl-1 col-md-12 mb-2">&nbsp;</div>
                                <div className="col-xl-2 col-md-12 mb-2">
                                    Period: &nbsp;&nbsp;
                                    From : {freqFrom}
                                    <input type="hidden" id="from_period" value={freqFrom} />
                                </div>
                                <div className="col-xl-3 col-md-12 mb-2">
                                    To : {freqTo}
                                    <input type="hidden" id="to_period" value={freqTo} />
                                </div>
                            </div>
                            {/* <div className={"row form-group border-bottom  no-gutters align-items-center mt-4 "}>
                                <div className="col-xl-2 col-md-12 mb-1">
                                    Period: 
                                </div>
                                <div className="col-xl-3 col-md-12 mb-1">
                                    From : {freqFrom}
                                    <input type="hidden" id="from_period" value={freqFrom} />
                                </div>
                                <div className="col-xl-3 col-md-12 mb-1">
                                    To : {freqTo}
                                    <input type="hidden" id="to_period" value={freqTo} />
                                </div>
                            </div> */}
                            <div className={"row form-group border-bottom  no-gutters align-items-center mt-2 "}>
                                <div className="col-xl-2 col-md-12 mb-2">
                                    Applicable Diesel price: 
                                </div>
                                <div className="col-xl-3 col-md-12 mb-2">
                                    {this.state.avgDieselPrice}
                                    <input type="hidden" id="applicableprice" value={this.state.avgDieselPrice} />
                                    <span className={"posrel "} style={{margin:"10px"}}>
                                        <a href="javascript:;" onClick={this.showToolTip.bind(this)}><i className="fa fa-info-circle"></i></a>
                                        <span className={"priceTooltip "+(this.state.dieselTooltip)}>
                                            <a href="javascript:;" className="tooltipClose" onClick={this.onClickHideTooltip.bind(this)}>x</a>
                                            <span className="priceTooltipBody">We will consider the applicable diesel prices from averages between start and end dates.<br /> <div className="tooltip-highlite"><b>Applicable Diesel price = (Sum of diesel prices / count of diesel prices)</b></div></span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            
                            <div className={"row form-group border-bottom  no-gutters align-items-center mt-2 "}>
                                <div className="col-xl-2 col-md-12 mb-2">
                                    Diesel prices basis: 
                                </div>
                                <div className="col-xl-3 col-md-12 mb-2">
                                    {this.state.dieselbasis}
                                    <input type="hidden" id="dieselbasis" value={this.state.dieselbasis} />
                                </div>
                            
                                <div className="col-xl-2 col-md-12 mb-2">
                                    Diesel Price Dates: 
                                </div>
                                <div className="col-xl-3 col-md-12 mb-2">
                                    {(this.state.diesel_price_changed_date != "")?this.state.diesel_price_changed_date:""}
                                    {(this.state.capture_diesel_price_from_day != "")?"From : "+this.state.capture_diesel_price_from_day:""}
                                    
                                    <input type="hidden" id="diesel_price_changed_date" value={this.state.diesel_price_changed_date} />
                                    <input type="hidden" id="capture_diesel_price_from_day" value={this.state.capture_diesel_price_from_day} />
                                </div>
                                <div className="col-xl-3 col-md-12 mb-1">
                                    {(this.state.capture_diesel_price_to_day != "")?"To : "+this.state.capture_diesel_price_to_day:""}
                                    <input type="hidden" id="capture_diesel_price_to_day" value={this.state.capture_diesel_price_to_day} />
                                </div>
                            </div>
                            <form id="docForm" method="POST" enctype="multipart/form-data" className="theme" onSubmit={this.onSubmitFormData}>
                                <div className={"row form-group border-bottom  no-gutters align-items-center mt-2 "}>
                                    <div className="col-xl-2 col-md-12 mb-2">
                                    Do you want to change Diesel price? 
                                    </div>
                                    <div className="col-xl-2 col-md-12 mb-2">
                                        <input type="text" className="form-control" name="changed_diesel_price" onChange={this.changeHandler.bind(this)} value={this.state.changed_diesel_price} placeholder="Enter Diesel Price" />
                                    </div>
                                    <div className="col-xl-1 col-md-12 mb-2">
                                    &nbsp;
                                    </div>
                               
                                    <div className="col-xl-2 col-md-12 mb-2">
                                    Reson for changin price 
                                    </div>
                                    <div className="col-xl-3 col-md-12 mb-1">
                                        <input type="text" className="form-control" name="changed_diesel_price_reason" onChange={this.changeHandler.bind(this)} value={this.state.changed_diesel_price_reason} placeholder="Enter Reason" />
                                    </div>
                                </div>
                                
                                <div className={"row form-group border-bottom  no-gutters align-items-center mt-4 "}>
                                    <div className="col-xl-2 col-md-12 mb-1">
                                    Upload Document 
                                    </div>
                                    <div className="col-xl-3 col-md-12 mb-1">
                                        <input type="file" className="form-control" name="changed_diesel_price_upload" onChange={this.changeFileHandler}  />
                                    </div>
                                </div>
                                {(this.state.showAfterSubmit != "show-m")?
                                    (this.state.isUserCreateDoc === 1)?
                                    <div className={"row form-group border-bottom  no-gutters align-items-center mt-4 "}>
                                        <div className="col-xl-4 col-md-12 mb-2" style={{textAlign:"right"}}>
                                            <button type="submit" className="btn btn-danger">Submit</button>
                                        </div>
                                    </div> 
                                    :""
                                :""}
                           
                            </form>
                        </div>

                        
                    </div>
                </div>
                <div className={"row "+((this.state.ptab === "btn-success")?"show-m":"show-n")} style={{marginTop:"20px"}}>
					<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="card-body">
                                <PendingDocuments
                                    isDocumentCreator={this.state.isUserCreateDoc}
                                    context={this}
                                /> 
                            </div>
						</div>
					</div>					
				</div>
                {/* End Pending Approvals */}
                {/* Close Documents */}
				<div className={"row "+(this.state.showAfterSubmit)} style={{marginTop:"20px"}}>
					<div className="col-xl-12 col-md-12 mb-4">
						<div className="card shadow h-100 py-2">
							<div className="card-body">
                                <CloseDocuments                                
                                    isDocumentCreator={this.state.isUserCreateDoc}
                                    context={this}
                                 />
							</div>
						</div>
					</div>					
				</div>
                <div className={"overlay-block "+(this.state.overlay)} onClick={this.hideSlideBlock}></div>
                
                    

			</div>
        );
    }
}
function redirectToConfigurations()
{
    window.location.href="/configurations"
}
// console.log("icn ", icn)
function pushNot(){
    // showNotification();
    
	// setInterval(function(){ showNotification(); }, 20000);
}


// function showNotification() {	
// 	if (!Notification) {
// 		$('body').append('<h4 style="color:red">*Browser does not support Web Notification</h4>');
// 		return;
// 	}
// 	if (Notification.permission !== "granted") {		
// 		Notification.requestPermission();
// 	} else {		
// 		$.ajax({
//             url:"http://localhost:5005/billing",
// 			type: "GET",
// 			success: function(data, textStatus, jqXHR) {
				
//                 var theurl = "http://localhost:3005/";
//                 var notifikasi = new Notification("Freight Rates", {
//                     icon: icn,
//                     body: "You are checking Freight Rates information.",
//                 });
//                 notifikasi.onclick = function () {
//                     window.open(theurl); 
//                     notifikasi.close();     
//                 };
//                 setTimeout(function(){
//                     notifikasi.close();
//                 }, 5000);
        
// 			},
// 			error: function(jqXHR, textStatus, errorThrown)	{}
// 		}); 
// 	}
// };
function redirectToFreightApprovalGrid(document_no)
{
    window.location.href="/documents/"+document_no
}